import { DeleteOutlined } from '@ant-design/icons';
import { useLingui, Trans } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import { InputNumber, Input, Button, Tooltip, Checkbox, Select } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { StoredFunction } from '~/gql/function/function';
import {
  SDevisTh,
  SDevisTd,
  SDevisTableHead,
  SDevisTable,
  SDivBtnAddRow,
} from '~/pages/components/styles/styles';
import DurationItem from '~/pages/components/ui/DurationItem';
import { DevisInitRow, DevisProdRow, TPosteOption } from '~/pages/types/types';

type IProps = {
  row?: DevisInitRow;
  tasks: DevisProdRow[];
  functionTypes: StoredFunction[];
  postesByDevis: TPosteOption[];
  onOneTaskChange?: (task: DevisProdRow) => void;
  onChangeDurationExpected?: (durationExpected: number) => void;
  onDeleteTask?: (
    tasks: DevisProdRow[],
    id: number | undefined,
    taskId: number | undefined
  ) => void;
  onAddTaskInRow?: (id: number | undefined) => void;
  onAddClientTaskInRow?: (id: number | undefined) => void;
  withoutAdd?: boolean;
  disabled?: boolean;
};

function TasksGrouped({
  row,
  onDeleteTask,
  onOneTaskChange,
  tasks,
  withoutAdd,
  disabled,
  functionTypes,
  postesByDevis,
  onAddTaskInRow,
  onAddClientTaskInRow,
  onChangeDurationExpected,
}: IProps) {
  const { i18n } = useLingui();

  const [currentTasks, setCurrentTasks] = useState<DevisProdRow[]>(tasks);
  const [tasksDuration, setTasksDuration] = useState<number>(0);

  useEffect(() => {
    setCurrentTasks(tasks);
  }, [row, tasks]);

  useEffect(() => {
    setTasksDuration(
      tasks.reduce(function (acc: any, obj: any) {
        return acc + obj.duration;
      }, 0)
    );
  }, [currentTasks]);

  const onChangeTaskName = (name: string, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].taskName = name;
      setCurrentTasks(_tasks);
      onOneTaskChange && onOneTaskChange(_tasks[index]);
    }
  };

  const onChangePoste = async (value: number, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      // _tasks[index].posteId = value;
      // _tasks[index].devisInitId = postesByDevis.find(
      //   (p) => p.posteId === value
      // )?.devisInitId;

      _tasks[index].posteId = postesByDevis.find(
        (p) => p.devisInitId === value
      )?.posteId;
      _tasks[index].devisInitId = value;

      setCurrentTasks(_tasks);
      onOneTaskChange && onOneTaskChange(_tasks[index]);
    }
  };

  const onChangeTaskDuration = (duration: number, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].duration = duration;
      setCurrentTasks(_tasks);
      onOneTaskChange && onOneTaskChange(_tasks[index]);
    }
  };

  const onChangeRowDurationExpected = (durationExpected: number) => {
    onChangeDurationExpected && onChangeDurationExpected(durationExpected);
  };

  const onChangeTaskDurationDays = (durationDays: number, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].durationDays = durationDays;
      setCurrentTasks(_tasks);
      onOneTaskChange && onOneTaskChange(_tasks[index]);
    }
  };

  const onChangeTaskAmount = (amount: number, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].amount = amount;
      setCurrentTasks(_tasks);
      onOneTaskChange && onOneTaskChange(_tasks[index]);
    }
  };

  const onChangeTaskVisibleInRetro = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].visibleInRetro = e.target.checked;
      setCurrentTasks(_tasks);
      onOneTaskChange && onOneTaskChange(_tasks[index]);
    }
  };

  const onChangeTaskCalculatedAsAmount = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].calculatedAsAmount = e.target.checked;
      if (!e.target.checked) {
        _tasks[index].amount = 0;
      }
      setCurrentTasks(_tasks);
      onOneTaskChange && onOneTaskChange(_tasks[index]);
    }
  };

  function addElement() {
    onAddTaskInRow && onAddTaskInRow(row?.id);
  }

  function addClientElement() {
    onAddClientTaskInRow && onAddClientTaskInRow(row?.id);
  }

  function onDeleteClick(ind: number, taskId: number | undefined) {
    const _tasks = [...currentTasks];
    _tasks.splice(ind, 1);
    setCurrentTasks(_tasks);
    onDeleteTask?.(_tasks, row?.id, taskId);
  }

  return (
    <SDevisTable $margin="0 0">
      <SDevisTableHead>
        <tr>
          <SDevisTh $padding="0 5px" $minWidth="70px" $textAlign="left">
            {row?.functionType
              ? i18n._(row.functionType)
              : i18n._('common.type.another')}
          </SDevisTh>
          <SDevisTh $minWidth="280px" $padding="0px">
            <SDivDetails>
              {row && (
                <>
                  <InputNumber
                    style={{
                      width: '150px',
                      margin: '5px',
                      fontWeight: 'bold',
                    }}
                    placeholder={i18n._('form.amount.placeholder')}
                    value={row.amount}
                    controls={false}
                    step="0.01"
                    disabled={true}
                    addonAfter="€"
                  />
                  <Tooltip title="durée de devis initial (signé)">
                    <DurationItem
                      $bold
                      $allowCorrection={false}
                      duration={row.duration}
                    />
                  </Tooltip>
                </>
              )}
            </SDivDetails>
          </SDevisTh>
          <SDevisTh $minWidth="120px">
            {row && (
              <Tooltip title="durée de devis initial prod projetée">
                <DurationItem
                  $bold
                  $allowCorrection={row.id && !disabled ? true : false}
                  duration={row.durationExpected}
                  onChange={(value) => onChangeRowDurationExpected(value)}
                />
              </Tooltip>
            )}
          </SDevisTh>

          <SDevisTh $minWidth="120px"></SDevisTh>
          <SDevisTh $minWidth="120px" $padding="0px">
            <DurationItem
              $bold
              $allowCorrection={false}
              duration={tasksDuration}
            />
          </SDevisTh>
          <SDevisTh $minWidth="70px" $textAlign="center">
            In retro ?
          </SDevisTh>
          <SDevisTh $minWidth="70px" $textAlign="center">
            Calc comme somme
          </SDevisTh>
          <SDevisTh $minWidth="130px">Somme</SDevisTh>
        </tr>
      </SDevisTableHead>

      <tbody>
        {currentTasks.map((task, index) => (
          <tr key={index}>
            <SDevisTd $textAlign="left" $padding="0 0 0 15px">
              {
                postesByDevis.find((el) => el.posteId === task.posteId)
                  ?.functionType
              }
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <Input
                placeholder={i18n._('form.task-name.placeholder')}
                onChange={(e) => onChangeTaskName(e.target.value, index)}
                value={task.taskName}
                disabled={disabled}
              />
            </SDevisTd>
            <SDevisTd>
              {task.functionType !== FunctionType.CLIENT && (
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  onChange={async (value) => onChangePoste(value, index)}
                  value={task.devisInitId}
                  disabled={disabled}
                  status={!task.posteId ? 'warning' : ''}
                  optionFilterProp="label"
                  options={postesByDevis.map((item) => ({
                    value: item.devisInitId,
                    label: `${i18n._(item.label)} ${item.amount}`,
                  }))}
                />
              )}
            </SDevisTd>

            <SDevisTd>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder={i18n._('form.function.placeholder')}
                value={
                  functionTypes.find((_el) => _el.name === task.functionType)
                    ?.id
                }
                disabled={true}
                optionFilterProp="label"
                options={functionTypes.map((item) => ({
                  value: item.id,
                  label: i18n._(item.name),
                }))}
              />
            </SDevisTd>
            <SDevisTd $textAlign="center">
              {task.functionType !== FunctionType.CLIENT && (
                <DurationItem
                  $allowCorrection={!disabled}
                  duration={task.duration}
                  onChange={(value) => onChangeTaskDuration(value, index)}
                />
              )}
              {task.functionType === FunctionType.CLIENT && (
                <InputNumber
                  style={{ width: '120px', margin: '5px', fontWeight: 'bold' }}
                  placeholder={i18n._('form.durationDays.placeholder')}
                  value={task.durationClientDays}
                  controls={false}
                  addonAfter="j"
                  disabled={disabled}
                  min={1}
                  status={task.durationClientDays === 0 ? 'warning' : ''}
                  onChange={(value) =>
                    onChangeTaskDurationDays(value ?? 1, index)
                  }
                />
              )}
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <Checkbox
                checked={task.visibleInRetro}
                onChange={(e) => onChangeTaskVisibleInRetro(e, index)}
              />
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <Checkbox
                checked={task.calculatedAsAmount}
                onChange={(e) => onChangeTaskCalculatedAsAmount(e, index)}
              />
            </SDevisTd>
            <SDevisTd $textAlign="center">
              {task.calculatedAsAmount && (
                <InputNumber
                  style={{
                    width: '120px',
                    margin: '5px',
                    fontWeight: 'bold',
                  }}
                  placeholder={i18n._('form.amount.placeholder')}
                  value={task.amount}
                  controls={false}
                  step="0.01"
                  onChange={(value) => onChangeTaskAmount(value ?? 0, index)}
                  addonAfter="€"
                />
              )}
            </SDevisTd>
            {!disabled && (
              <SDevisTd>
                <Tooltip
                  title={
                    task.hasTimes
                      ? 'Cette tâche ne peut pas être supprimée car les intervenants ont indiqué leurs temps'
                      : 'supprimer'
                  }
                >
                  <Button
                    type="default"
                    disabled={task.hasTimes}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => onDeleteClick(index, task.id)}
                  />
                </Tooltip>
              </SDevisTd>
            )}
          </tr>
        ))}
        {!withoutAdd && (
          <tr>
            <th colSpan={3}>
              <SDivBtnAddRow>
                <Button
                  onClick={addElement}
                  type="default"
                  style={{
                    backgroundColor: '#0B446F4D',
                    color: '#0B446F',
                    marginRight: '10px',
                  }}
                >
                  <Trans id="common.add.row" />
                </Button>
                {!row && (
                  <Button
                    onClick={addClientElement}
                    type="default"
                    style={{ backgroundColor: '#0B446F4D', color: '#0B446F' }}
                  >
                    <Trans id="common.add.row.client" />
                  </Button>
                )}
              </SDivBtnAddRow>
            </th>
          </tr>
        )}
      </tbody>
    </SDevisTable>
  );
}

export default TasksGrouped;

const SDivDetails = styled.div`
  align-items: center;
  display: flex;
  & > div > div > div > div > input {
    font-weight: bold;
  }
`;
