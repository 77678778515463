import { useEffect, useState } from 'react';

import { DevisInitRowExtended, DevisProdRow } from '~/pages/types/types';

export default function useCalculetedDevisProdEdit(
  rows: DevisInitRowExtended[],
  taskSupp?: DevisProdRow[]
) {
  const [devisProdAmount, setDevisProdAmount] = useState<number>(0);
  const [devisProdDuration, setDevisProdDuration] = useState<number>(0);

  const [devisProdTaksAmount, setDevisProdTasksAmount] = useState<number>(0);
  const [devisProdTasksDuration, setDevisProdTasksDuration] =
    useState<number>(0);

  const [devisProdTaksSuppAmount, setDevisProdTasksSuppAmount] =
    useState<number>(0);
  const [devisProdTasksSuppDuration, setDevisProdTasksSuppDuration] =
    useState<number>(0);

  useEffect(() => {
    let _tasksAmount = 0;
    let _tasksDuration = 0;

    if (rows.length > 0) {
      for (const row of rows) {
        if (row.tasks) {
          for (const t of row.tasks) {
            _tasksAmount = _tasksAmount + t.amount;
            _tasksDuration = _tasksDuration + t.duration;
          }
        }
      }
    }

    setDevisProdTasksAmount(_tasksAmount);
    setDevisProdTasksDuration(_tasksDuration);
  }, [rows]);

  useEffect(() => {
    let _tasksSuppAmount = 0;
    let _tasksSuppDuration = 0;

    if (taskSupp && taskSupp.length > 0) {
      for (const row of taskSupp) {
        _tasksSuppAmount = _tasksSuppAmount + row.amount;
        _tasksSuppDuration = _tasksSuppDuration + row.duration;
      }
    }

    setDevisProdTasksSuppAmount(_tasksSuppAmount);
    setDevisProdTasksSuppDuration(_tasksSuppDuration);
  }, [taskSupp]);

  useEffect(() => {
    setDevisProdAmount(devisProdTaksAmount + devisProdTaksSuppAmount);
    setDevisProdDuration(devisProdTasksDuration + devisProdTasksSuppDuration);
  }, [
    devisProdTaksAmount,
    devisProdTaksSuppAmount,
    devisProdTasksDuration,
    devisProdTasksSuppDuration,
  ]);

  return {
    devisProdAmount,
    devisProdDuration,
    devisProdTaksAmount,
    devisProdTasksDuration,
    devisProdTaksSuppAmount,
    devisProdTasksSuppDuration,
  };
}
