import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';

type CustomIconComponentProps = GetProps<typeof Icon>;

export const HandSvg = () => (
  <svg
    fill="#000000"
    height="800px"
    width="800px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 485 485"
  >
    <g>
      <path
        d="M382.5,69.429c-7.441,0-14.5,1.646-20.852,4.573c-4.309-23.218-24.7-40.859-49.148-40.859
		c-7.68,0-14.958,1.744-21.467,4.852C285.641,16.205,265.932,0,242.5,0c-23.432,0-43.141,16.206-48.533,37.995
		c-6.508-3.107-13.787-4.852-21.467-4.852c-27.57,0-50,22.43-50,50v122.222c-6.129-2.686-12.891-4.187-20-4.187
		c-27.57,0-50,22.43-50,50V354c0,72.233,58.766,131,131,131h118c72.233,0,131-58.767,131-131V119.429
		C432.5,91.858,410.07,69.429,382.5,69.429z M402.5,354c0,55.691-45.309,101-101,101h-118c-55.691,0-101-45.309-101-101V251.178
		c0-11.028,8.972-20,20-20s20,8.972,20,20v80h30V83.143c0-11.028,8.972-20,20-20s20,8.972,20,20v158.035h30V50
		c0-11.028,8.972-20,20-20c11.028,0,20,8.972,20,20v191.178h30V83.143c0-11.028,8.972-20,20-20s20,8.972,20,20v158.035h30v-121.75
		c0-11.028,8.972-20,20-20s20,8.972,20,20V354z"
      />
    </g>
  </svg>
);

export const HandIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HandSvg} {...props} />
);

export const PersonEditSvg = () => (
  <svg
    fill="#000000"
    width="800px"
    height="800px"
    viewBox="0 0 1920 1920"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1281.536 1465.487c-118.756 82.552-340.248 201.842-634.147 187.745-237.831-11.213-422.266-104.872-540.594-187.638v-150.9c0-128.048 81.804-238.26 194.473-261.968 113.416-23.815 229.502-37.485 344.947-40.369 146.522-3.844 295.928 9.825 441.168 40.476 112.455 23.708 194.153 133.813 194.153 261.967v150.687ZM427.179 638.576v-6.942c45.815-12.388 75.076-39.193 98.037-60.445 26.699-24.563 42.825-39.408 80.31-39.408 36.737 0 52.65 14.738 79.028 39.194 31.077 28.514 73.581 67.601 152.716 67.601 57.776 0 95.475-21.252 123.882-43.786v43.786c0 147.27-119.717 266.987-266.987 266.987-147.27 0-266.986-119.717-266.986-266.987Zm266.986-373.781c123.241 0 226.191 84.367 256.842 198.104-13.243 9.718-24.67 20.184-35.35 30.01-26.271 24.241-41.97 38.872-78.387 38.872-37.485 0-53.61-14.844-80.31-39.407-30.756-28.407-73.047-67.387-151.434-67.387-79.135 0-121.64 39.193-152.717 67.707-8.65 8.01-16.232 14.525-23.708 20.291 9.825-138.299 124.309-248.19 265.064-248.19Zm415.218 683.592c-57.028-12.067-114.804-20.825-172.58-27.873 79.669-68.562 131.144-168.842 131.144-281.938V531.781C1067.947 325.774 900.279 158 694.165 158c-206.113 0-373.781 167.774-373.781 373.781v106.795c0 112.882 51.261 212.949 130.717 281.618-57.67 6.941-115.125 16.126-171.833 28.087C117.474 982.24 0 1136.346 0 1314.693v205.26l21.68 16.019c128.794 94.94 340.995 210.812 620.69 223.948 17.194.748 34.174 1.175 50.941 1.175 317.5 0 556.08-139.154 673.234-225.123l21.786-15.913V1314.8c0-178.346-117.368-332.45-278.948-366.412Zm399.818-256.37h-120.892V571.23l225.764-225.764 120.785 120.785-225.657 225.764Zm389.48-341.85L1730.16 181.75c-27.446-27.553-75.29-27.873-103.27-.107l-324.336 324.229c-13.563 13.776-21.038 32.038-21.038 51.368v241.57h241.57c19.222 0 37.27-7.37 51.688-21.36l324.015-324.014c28.3-28.408 28.3-74.756-.107-103.27Z"
      fill-rule="evenodd"
    />
  </svg>
);

export const PersonEdit = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PersonEditSvg} {...props} />
);
