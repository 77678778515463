import { useLingui } from '@lingui/react';
import { Tooltip } from 'antd';
import { StoredProjectRentaInfoBase } from '~/gql/project/project';
//import { useAuth } from '~/helpers/store/auth';
import { getColorForDuration } from '~/pages/components/common/utils-table/utils';
import {
  SDivRentaItem,
  SRentaSpan,
  SRentaTable,
  SRentaTableHead,
  SRentaTd,
  SRentaTh,
  SRentaTr,
} from '~/pages/components/styles/styles';

import { getHoursAndMinutesStrByDurationForTimes } from '~/utils/utils/utils';

type IProps = {
  renta: StoredProjectRentaInfoBase;
  projectName: string;
  withoutHeader?: boolean;
  borderTop?: boolean;
};

function RentaDetails({
  renta,
  projectName,
  withoutHeader,
  borderTop,
}: IProps) {
  const { i18n } = useLingui();
  //const { payload } = useAuth();

  return (
    <>
      <SRentaTable>
        {!withoutHeader && (
          <SRentaTableHead>
            <SRentaTr>
              <SRentaTh $width="15%" $borderLeft></SRentaTh>
              <SRentaTh $width="10.625%">
                {i18n._('common.sold.amount')}
              </SRentaTh>
              <SRentaTh $width="10.625%"> {i18n._('common.sold')}</SRentaTh>
              <SRentaTh $width="10.625%" $borderRight>
                {i18n._('common.duration.devis.prod')}
              </SRentaTh>
              <SRentaTh $width="10.625%">{i18n._('common.times')}</SRentaTh>
              <SRentaTh $width="10.625%" $borderRight>
                {i18n._('common.duration.used')}
              </SRentaTh>
              <SRentaTh $width="10.625%">
                {i18n._('common.result')} devis prod
              </SRentaTh>
              <SRentaTh $width="10.625%">
                {i18n._('common.result')} vendu h
              </SRentaTh>
              <SRentaTh $width="10.625%">
                {i18n._('common.result')} vendu €
              </SRentaTh>
            </SRentaTr>
          </SRentaTableHead>
        )}

        <tbody>
          <SRentaTr $firstBorderTop={borderTop}>
            <SRentaTd $borderLeft $width="15%">
              Projet: {projectName}
            </SRentaTd>
            <Tooltip title={'Somme de la vente'}>
              <SRentaTd $bold $align="right" $width="10.625%">
                {renta.projectAmountSold.toFixed(2) + '€'}
              </SRentaTd>
            </Tooltip>
            <Tooltip title={'Durée de la vente'}>
              <SRentaTd $bold $align="right" $width="10.625%">
                {getHoursAndMinutesStrByDurationForTimes(
                  renta.projectDurationSold
                )}
              </SRentaTd>
            </Tooltip>
            <Tooltip title={'Durée à partir du devis prod'}>
              <SRentaTd $bold $align="right" $borderRight $width="10.625%">
                <SDivRentaItem>
                  {getHoursAndMinutesStrByDurationForTimes(
                    renta.projectDuration
                  )}
                  {renta.projectSommeAmount !== 0 && (
                    <span>{renta.projectSommeAmount.toFixed(2) + '€'}</span>
                  )}
                </SDivRentaItem>
              </SRentaTd>
            </Tooltip>
            <SRentaTd $bold $align="right" $width="10.625%">
              <SDivRentaItem>
                <span>
                  {getHoursAndMinutesStrByDurationForTimes(
                    renta.projectSommeTime
                  )}
                </span>

                {renta.projectSommeAmountSpend !== 0 && (
                  <span>{renta.projectSommeAmountSpend.toFixed(2) + '€'}</span>
                )}
              </SDivRentaItem>
            </SRentaTd>
            <SRentaTd $bold $borderRight $align="right" $width="10.625%">
              <SDivRentaItem>
                {getHoursAndMinutesStrByDurationForTimes(
                  renta.projectDurationUsed
                ) || '-'}

                <span>
                  {renta.projectAmountUsed !== 0 && renta.projectAmountUsed
                    ? renta.projectAmountUsed.toFixed(2) + '€'
                    : ''}
                </span>
              </SDivRentaItem>
            </SRentaTd>

            <SRentaTd
              $bold
              $align="right"
              $width="10.625%"
              color={getColorForDuration(
                renta.projectDeltaDurationProd - renta.projectDurationUsed
              )}
            >
              <SDivRentaItem>
                <SRentaSpan
                  color={getColorForDuration(renta.projectDeltaDurationProd)}
                >
                  {getHoursAndMinutesStrByDurationForTimes(
                    renta.projectDeltaDurationProd
                  )}
                </SRentaSpan>

                <SRentaSpan
                  color={getColorForDuration(renta.projectDeltaAmountProd)}
                >
                  <span>
                    {renta.projectDeltaAmountProd !== 0 &&
                    renta.projectDeltaAmountProd
                      ? renta.projectDeltaAmountProd.toFixed(2) + '€'
                      : ''}
                  </span>
                </SRentaSpan>
              </SDivRentaItem>
            </SRentaTd>
            <SRentaTd
              $bold
              $align="right"
              $width="10.625%"
              color={getColorForDuration(renta.projectDeltaDurationSold)}
            >
              {getHoursAndMinutesStrByDurationForTimes(
                renta.projectDeltaDurationSold
              )}
            </SRentaTd>
            <SRentaTd
              color={getColorForDuration(renta.projectDeltaSommeSold)}
              $bold
              $align="right"
              $width="10.625%"
              $borderRight
            >
              {renta.projectDeltaSommeSold.toFixed(2) + '€'}
            </SRentaTd>
          </SRentaTr>
        </tbody>
      </SRentaTable>
    </>
  );
}

export default RentaDetails;
