import { useMutation, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GQL_UPDATE_PROJECT, GQL_GET_ONE_PROJECT } from '~/gql/project/project';
import {
  GQL_GET_TASKS_INTERN_BY_PROJECT_ID,
  StoredTaskShortInfo,
} from '~/gql/task/task';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import TasksProjectIntern from '~/pages/components/common/project/TasksProjectIntern';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import InternLayout from '~/pages/layouts/InternLayout';

function ProjectInternInfoPage() {
  const params = useParams();
  const { Text } = Typography;
  const { i18n } = useLingui();
  const { id } = params;
  const [tasksIntern, setTasksIntern] = useState<StoredTaskShortInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setCurrentProject } = useDataUserStore();
  const currentProject = useDataUserStore((state) => state.currentProject);
  const [projectName, setProjectName] = useState<string>('');
  const [debouncedProjectName, setDebouncedProjectName] = useState<string>('');

  const { refetch: getTasksIntern } = useQuery(
    GQL_GET_TASKS_INTERN_BY_PROJECT_ID,
    {
      variables: {
        id: Number(id),
      },
      skip: id === undefined,
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTasksIntern(data.getTasksInternByProjectId);
      },
    }
  );

  const { refetch: fetchProject } = useQuery(GQL_GET_ONE_PROJECT, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCurrentProject(data.getOneProject);
      setProjectName(data.getOneProject.name);
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_ONE_PROJECT', e);
    },
  });

  const [updateProject] = useMutation(GQL_UPDATE_PROJECT, {
    client: authClient,
  });

  async function getTasksInternData() {
    setLoading(true);
    if (id) {
      await getTasksIntern();
    }
    setLoading(false);
  }
  async function getProject() {
    setLoading(true);
    await fetchProject();
    setLoading(false);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedProjectName(projectName);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [projectName, 500]);

  useEffect(() => {
    if (debouncedProjectName && debouncedProjectName !== currentProject?.name)
      void handleChangeProjectName(debouncedProjectName);
  }, [debouncedProjectName]);

  useEffect(() => {
    if (!currentProject) {
      void getProject();
    }

    if (currentProject) {
      setProjectName(currentProject.name);
    }
  }, []);

  async function handleChangeProjectName(name: string) {
    if (!currentProject) return;

    await updateProject({
      variables: {
        id: currentProject.id,
        name: name,
      },
    });
  }

  useEffect(() => {
    if (id) {
      void getTasksInternData();
    }
  }, [id]);

  const handleChangeName = (name: string) => {
    setProjectName(name);
  };

  return (
    <>
      <DefaultLayout>
        <InternLayout>
          {loading ? (
            <>...loading</>
          ) : (
            <SDivContentWrapper>
              <SDivTitle>
                <Text style={{ marginRight: '10px' }}>
                  {i18n._('form.project-name')}
                </Text>
                <Input
                  style={{ width: '250px', marginRight: '10px' }}
                  placeholder={i18n._('form.project-name.placeholder')}
                  value={projectName}
                  onChange={async (e) => handleChangeName(e.target.value)}
                />
              </SDivTitle>
              {id && (
                <TasksProjectIntern
                  refresh={() => {
                    void getTasksIntern();
                  }}
                  projectId={Number(id)}
                  tasks={tasksIntern}
                />
              )}
            </SDivContentWrapper>
          )}
        </InternLayout>
      </DefaultLayout>
    </>
  );
}

export default ProjectInternInfoPage;

const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SDivTitle = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
`;
