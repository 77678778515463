import {
  AreaChartOutlined,
  BarChartOutlined,
  EuroCircleOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  QuestionCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import docCdp from '@assets/pdf/Doc_Times_CDP.pdf';
import { useLingui } from '@lingui/react';
import { Divider, Layout, Menu, MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

const { Sider } = Layout;

function ManagerNavBar() {
  const { i18n } = useLingui();
  const [value, setValue] = useState<string>('');

  const params = useParams();
  const { id } = params;

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const key = pathname.split('/')[2];

    setValue(key);
  }, [pathname]);

  if (value.length <= 0) {
    return <></>;
  }

  const helpItems = [
    {
      key: 'help',
      icon: <QuestionCircleOutlined />,
      label: (
        <div>
          {i18n._('common.project.help.export')}
          <Link
            to={id ? `/manager-projects/devis/${id}` : '/manager-projects'}
          />
        </div>
      ),
    },
  ];
  const menuItems = [
    {
      key: 'devis',
      icon: <EuroCircleOutlined />,
      label: (
        <div>
          {i18n._('common.devis')}
          <Link
            to={id ? `/manager-projects/devis/${id}` : '/manager-projects'}
          />
        </div>
      ),
    },
    {
      key: 'retro-planning',
      icon: <LineChartOutlined />,
      label: (
        <div>
          {i18n._('common.retroplanning')}
          <Link
            to={
              id
                ? `/manager-projects/retro-planning/${id}`
                : '/manager-projects'
            }
          />
        </div>
      ),
    },
    // je garde ça pour le moment
    // {
    //   key: 'planning',
    //   icon: <AreaChartOutlined />,
    //   label: (
    //     <div>
    //       {i18n._('common.planning')}
    //       <Link
    //         to={id ? `/manager-projects/planning/${id}` : '/manager-projects'}
    //       />
    //     </div>
    //   ),
    // },
    {
      key: 'planning-ext',
      icon: <AreaChartOutlined />,
      label: (
        <div>
          {i18n._('common.planning-ext')}
          <Link
            to={
              id ? `/manager-projects/planning-ext/${id}` : '/manager-projects'
            }
          />
        </div>
      ),
    },
    {
      key: 'cross-page',
      icon: <BarChartOutlined />,
      label: (
        <div>
          {i18n._('common.cross.page')}
          <Link
            to={id ? `/manager-projects/cross-page/${id}` : '/manager-projects'}
          />
        </div>
      ),
    },
    {
      key: 'project-details',
      icon: <SolutionOutlined />,
      label: (
        <div>
          {i18n._('common.project.details')}
          <Link
            to={
              id
                ? `/manager-projects/project-details/${id}`
                : '/manager-projects'
            }
          />
        </div>
      ),
    },
    {
      key: 'project-renta',
      icon: <InfoCircleOutlined />,
      label: (
        <div>
          {i18n._('common.intern.renta')}
          <Link
            to={
              id ? `/manager-projects/project-renta/${id}` : '/manager-projects'
            }
          />
        </div>
      ),
    },
  ];

  const onHelpClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'help') {
      downloadDoc();
    }
  };

  function downloadDoc() {
    const element = window.document.createElement('a');

    element.setAttribute('download', 'doc_Times_CDP.pdf');
    element.setAttribute('href', docCdp);
    window.document.body.append(element);
    element.click();
    element.remove();
  }

  return (
    <Sider collapsed breakpoint="lg" theme="light">
      <Menu
        theme="light"
        mode="inline"
        style={{ paddingTop: '50px', borderInlineEnd: 'none' }}
        defaultSelectedKeys={[value]}
        items={menuItems}
      />
      <Divider />
      <Menu
        onClick={onHelpClick}
        theme="light"
        mode="inline"
        style={{ borderInlineEnd: 'none' }}
        defaultSelectedKeys={[value]}
        items={helpItems}
      />
    </Sider>
  );
}

export default ManagerNavBar;
