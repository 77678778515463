import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useLingui, Trans } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import type { CollapseProps, DatePickerProps } from 'antd';
import {
  Collapse,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import 'dayjs/locale/fr';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { FormInstance } from 'antd/es/form';

import dayjs from 'dayjs';

import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import {
  GQL_UPDATE_DEVIS_PROD,
  GQL_UPDATE_DEVIS_INITIAL_DURATION_EXPECTED,
  StoredDevitInit,
  StoredDevitProd,
} from '~/gql/devis/devis';
import { StoredFunction } from '~/gql/function/function';
import { StoredPoste } from '~/gql/poste/poste';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import useCalculetedDevisInit from '~/pages/components/common/utils-table/hooks/useCalculetedDevisInit';
import { addElementAfterIndex } from '~/pages/components/common/utils-table/utils';
import {
  SDevisTable,
  SDevisTableHead,
  SDevisTd,
  SDevisTh,
  STrDevisRow,
} from '~/pages/components/styles/styles';

import ButtonAddTask from '~/pages/components/ui/button-add-task';
import DurationItem from '~/pages/components/ui/DurationItem';
import TasksGrouped from '~/pages/components/ui/TasksGrouped';

import {
  DevisProdRow,
  TPosteOption,
  TTaskInDevisTemplate,
} from '~/pages/types/types';
import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  postes: StoredPoste[];
  devisInit: StoredDevitInit | null;
  devisProd: StoredDevitProd | null;
  functionTypes: StoredFunction[];
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

function EditDevisProdP({
  functionTypes,
  devisInit,
  devisProd,
  postes,
  confirm,
  close,
}: IProps) {
  const { i18n } = useLingui();
  const { Text } = Typography;
  const [dateSigned, setDateSigned] = useState<Date | undefined>(undefined);
  const [dateFinish, setDateFinish] = useState<Date | undefined>(undefined);
  const formRef = React.useRef<FormInstance>(null);
  const { currentProject } = useDataUserStore();
  const [tasksRows, setTasksRows] = useState<DevisProdRow[]>([]);
  const [devisRowsWithTasks, setDevisRowsWithTasks] = useState<
    TTaskInDevisTemplate[]
  >([]);
  const [
    changedDevisInitDurationExpected,
    setChangedDevisInitDurationExpected,
  ] = useState<{ devisInitId: number; durationExpected: number }[]>([]);
  const [addedCount, setAddedCount] = useState<number>(-1);

  const [postesByDevis, setPostesByDevis] = useState<TPosteOption[]>([]);
  const { devisInitDurationExpected, devisInitAmount } =
    useCalculetedDevisInit(devisInit);

  const [updateDevisProd] = useMutation(GQL_UPDATE_DEVIS_PROD, {
    client: authClient,
  });

  const [updateDevisInitDurationExpected] = useMutation(
    GQL_UPDATE_DEVIS_INITIAL_DURATION_EXPECTED,
    {
      client: authClient,
    }
  );

  function getDevisRowsWithTasks(
    prodRows: DevisProdRow[]
  ): TTaskInDevisTemplate[] {
    let result: TTaskInDevisTemplate[] = [];

    if (devisInit?.devisInit) {
      result = devisInit.devisInit.map((dI) => {
        const posteEl = postes.find((p) => p.id === dI.posteId);
        const newResultRow: TTaskInDevisTemplate = {
          devisInit: {
            id: dI.id,
            amount: dI.amount,
            duration: dI.duration,
            durationExpected: dI.durationExpected,

            posteId: dI.posteId,
            posteName: posteEl?.name ?? '',
            functionType: dI.functionType,
            functionTypeId: functionTypes.find(
              (fT) => fT.name === dI.functionType
            )?.id,
          },
          tasks: [],
        };
        return newResultRow;
      });
    }

    const oldProdRows = [...prodRows];

    const tasksWithoutPoste: DevisProdRow[] = [];
    for (const row of oldProdRows) {
      if (row.devisInitId) {
        const indInResult = result.findIndex(
          (r) => r.devisInit && r.devisInit.id === row.devisInitId
        );
        if (indInResult !== -1) {
          if (result[indInResult]) {
            result[indInResult].tasks.push({
              id: row.id,
              devisInitId: row.devisInitId,
              taskName: row.taskName,
              duration: row.duration,
              functionType: row.functionType,
              durationDays: row.durationDays,
              durationClientDays: row.durationClientDays,
              visibleInRetro: row.visibleInRetro,
              supp: row.supp,
              calculatedAsAmount: row.calculatedAsAmount,
              posteId: row.posteId,
              amount: row.amount,
              amountSpend: row.amountSpend,
              hasTimes: row.hasTimes,
              order: row.order,
            });
          }
        }
      } else {
        tasksWithoutPoste.push({
          id: row.id,
          taskName: row.taskName,
          duration: row.duration,
          durationDays: row.durationDays,
          durationClientDays: row.durationClientDays,
          functionType: row.functionType,
          visibleInRetro: row.visibleInRetro,
          supp: row.supp,
          calculatedAsAmount: row.calculatedAsAmount,
          devisInitId: undefined,
          order: row.order,
          amount: row.amount,
          amountSpend: row.amountSpend,
        });
      }
    }
    if (tasksWithoutPoste.length > 0) {
      result.push({
        tasks: tasksWithoutPoste.filter((el) => !el.supp).sort(compareByOrder), // Changer peut-être
      });
    }

    return result;
  }

  useEffect(() => {
    setDateSigned(devisProd?.signedDate as Date);
    setDateFinish(devisProd?.finishDate as Date);
    //init 1
    let _postes: TPosteOption[] = [];
    if (devisInit?.devisInit) {
      _postes = devisInit.devisInit.map((el, index) => {
        const _item: TPosteOption = {
          id: index,
          posteId: el.posteId,
          label: postes.find((p) => p.id === el.posteId)?.name ?? '',
          functionType: el.functionType,
          devisInitId: el.id,
          amount: el.amount,
        };
        return _item;
      });

      setPostesByDevis(_postes);
    }

    if (!devisProd) return;
    let mappedTasks: DevisProdRow[] = [];
    if (devisProd.tasks) {
      mappedTasks = devisProd.tasks.map((t) => {
        const newEl: DevisProdRow = {
          id: t.id,
          taskName: t.name,
          amount: t.amount,
          amountSpend: t.amountSpend,
          duration: t.duration,
          order: t.order,
          devisInitId: t.devisInitId,
          functionType: t.functionType,
          durationDays: t.durationDays,
          durationClientDays: t.durationClientDays,
          visibleInRetro: t.visibleInRetro,
          supp: t.supp,
          calculatedAsAmount: t.calculatedAsAmount,
          hasTimes: t.user?.find((el) => el.hasTimes) ? true : false,
          posteId:
            t.posteId ??
            _postes.find((p) => p.functionType === t.functionType)?.posteId,
        };
        return newEl;
      });

      setTasksRows(mappedTasks.filter((el) => !el.supp).sort(compareByOrder));
      setDevisRowsWithTasks(getDevisRowsWithTasks(mappedTasks));
    }
  }, [devisProd]);

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0);
    setDateSigned(new Date(d));
  };
  const onChangeDateFinish: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0);
    setDateFinish(new Date(d));
  };

  async function submit() {
    if (!devisInit?.id || !currentProject?.id) return;

    const _taskRows = [];
    for (const task of tasksRows) {
      _taskRows.push({
        functionType:
          postesByDevis.find((p) => p.posteId === task.posteId)?.functionType ??
          (task.functionType as FunctionType),
        amount: task.amount,
        ...(task.amountSpend && { amountSpend: task.amountSpend }),
        // ...(task.functionType !== FunctionType.CLIENT && {
        //   duration: task.duration,
        // }),
        duration: task.duration,
        ...(task.functionType === FunctionType.CLIENT && {
          durationClientDays: task.durationClientDays,
        }),
        ...(task.id && task.id > 0 && { id: task.id }),
        name: task.taskName,
        projectId: currentProject.id,
        ...(task.functionType !== FunctionType.CLIENT && {
          devisInitId: task.devisInitId,
        }),

        visibleInRetro: task.visibleInRetro,
        calculatedAsAmount: task.calculatedAsAmount,
        ...(task.order !== undefined && { order: task.order }),
      });
    }

    if (!devisProd) return;

    const { data: _data } = await updateDevisProd({
      variables: {
        id: devisProd.id,
        ...(dateSigned && { signedDate: dateSigned }),
        ...(dateFinish && { finishDate: dateFinish }),
        updateTaskInput: _taskRows,
      },
    });

    if (changedDevisInitDurationExpected.length > 0) {
      //   const { data: _dataInit } =
      await updateDevisInitDurationExpected({
        variables: {
          updateDevisInitDurationExpectedInput:
            changedDevisInitDurationExpected.map((el) => {
              return {
                id: el.devisInitId,
                durationExpected: el.durationExpected,
              };
            }),
        },
      });
      //  if (_dataInit) console.log('done'); //TODO
    }

    if (_data) {
      confirm();
    }
    close();
  }

  const onChangeTaskName = (name: string, index: number) => {
    const _tasks = [...tasksRows];
    if (_tasks[index]) {
      _tasks[index].taskName = name;
    }
    setTasksRows(_tasks);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
  };

  const onChangePoste = async (value: number, index: number) => {
    const _tasks = [...tasksRows];
    if (_tasks[index]) {
      _tasks[index].posteId = postesByDevis.find(
        (p) => p.devisInitId === value
      )?.posteId;
      _tasks[index].devisInitId = value;

      _tasks[index].functionType = postesByDevis.find(
        (p) => p.devisInitId === value
      )?.functionType;

      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskDuration = (duration: number, index: number) => {
    const _tasks = [...tasksRows];
    if (_tasks[index]) {
      _tasks[index].duration = duration;
      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskDurationDays = (
    durationClientDays: number,
    index: number
  ) => {
    const _tasks = [...tasksRows];
    if (_tasks[index]) {
      _tasks[index].durationClientDays = durationClientDays;
      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  function onAddTaskInRow(devisInitId: number | undefined) {
    const fT = devisInit?.devisInit?.find((dI) => dI.id === devisInitId);
    const _rows = [...tasksRows];
    _rows.push({
      id: addedCount,
      functionType: fT?.functionType ?? FunctionType.GP,
      amount: 0,
      amountSpend: 0,
      duration: 0,
      devisInitId: devisInitId ?? 0,
      taskName: '',
      visibleInRetro: true,
      supp: false,
      calculatedAsAmount: false,
      posteId: fT?.posteId,
      order: getMaxOrder(),
    });
    setTasksRows(_rows);
    setAddedCount((old) => old - 1);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_rows));
  }

  function getMaxOrder(): number {
    let value = 0;
    for (const t of tasksRows) {
      if (t.order && t.order >= value) value = t.order + 1;
    }
    return value;
  }

  function onAddClientTaskInRow(devisInitId: number | undefined) {
    const _rows = [...tasksRows];
    _rows.push({
      id: addedCount,
      functionType: FunctionType.CLIENT,
      amount: 0,
      amountSpend: 0,
      duration: 0,
      durationDays: 0,
      durationClientDays: 0,
      devisInitId: devisInitId ?? 0,
      taskName: '',
      visibleInRetro: true,
      supp: false,
      calculatedAsAmount: false,
      order: tasksRows.length,
    });
    setTasksRows(_rows);
    setAddedCount((old) => old - 1);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_rows));
  }

  function onDeleteTasksInRow(
    tasks: DevisProdRow[],
    devisInitId: number | undefined,
    taskId: number | undefined
  ) {
    const _rows = [...devisRowsWithTasks];
    if (devisInitId) {
      const ind = _rows.findIndex((el) => el.devisInit?.id === devisInitId);
      if (ind !== -1) {
        _rows[ind].tasks = [...tasks];
        setDevisRowsWithTasks(_rows);
      }
      // delete de tasks avec taskId
      if (taskId) {
        const _tasks = [...tasksRows];

        const indToDelete = _tasks.findIndex((el) => el.id === taskId);
        _tasks.splice(indToDelete, 1);
        setTasksRows(_tasks);
      }
    } else {
      const indWithoutPoste = _rows.findIndex((el) => !el.devisInit);
      _rows[indWithoutPoste].tasks = [...tasks];
    }
  }

  function onChangeOneTaskInRow(task: DevisProdRow) {
    const _tasks = [...tasksRows];
    if (task.id) {
      const ind = _tasks.findIndex((el) => el.id === task.id);
      _tasks[ind] = task;
      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  }

  const onChangeTaskVisibleInRetro = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...tasksRows];
    if (_tasks[index]) {
      _tasks[index].visibleInRetro = e.target.checked;
      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskAmount = (amount: number, index: number) => {
    const _tasks = [...tasksRows];

    if (_tasks[index]) {
      _tasks[index].amount = amount;

      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskAmountSpend = (amount: number, index: number) => {
    const _tasks = [...tasksRows];

    if (_tasks[index]) {
      _tasks[index].amountSpend = amount;

      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskCalculatedAsAmount = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...tasksRows];
    if (_tasks[index]) {
      _tasks[index].calculatedAsAmount = e.target.checked;
      setTasksRows(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  function onDeleteClick(index: number) {
    const _tasks = [...tasksRows];
    _tasks.splice(index, 1);
    setTasksRows(_tasks);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
  }

  if (!devisInit) return null;

  function handleChangeRowDevisInitDurationExpected(
    durationExpected: number,
    rowDevisInitId?: number
  ) {
    if (!rowDevisInitId) return;

    const changedDevisInit = [...changedDevisInitDurationExpected];
    const indInChanged = changedDevisInit.findIndex(
      (el) => el.devisInitId === rowDevisInitId
    );
    if (indInChanged !== -1) {
      changedDevisInit[indInChanged].durationExpected = durationExpected;
    } else {
      changedDevisInit.push({
        devisInitId: rowDevisInitId,
        durationExpected: durationExpected,
      });
    }
    setChangedDevisInitDurationExpected(changedDevisInit);
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: i18n._('task.groupped.type'),
      children: (
        <>
          {devisRowsWithTasks.map((el, index) => (
            <TasksGrouped
              functionTypes={functionTypes}
              postesByDevis={postesByDevis}
              key={index}
              tasks={el.tasks}
              onAddTaskInRow={onAddTaskInRow}
              onAddClientTaskInRow={onAddClientTaskInRow}
              onDeleteTask={onDeleteTasksInRow}
              onOneTaskChange={onChangeOneTaskInRow}
              onChangeDurationExpected={(value) =>
                handleChangeRowDevisInitDurationExpected(
                  value,
                  el.devisInit?.id
                )
              }
              row={el.devisInit}
            />
          ))}
        </>
      ),
    },
  ];

  function handleAddTaskClick(index: number) {
    const newElement: DevisProdRow = {
      id: addedCount,
      functionType: undefined,
      amount: 0,
      amountSpend: 0,
      duration: 0,
      durationDays: 0,
      durationClientDays: 0,
      devisInitId: undefined,
      taskName: '',
      visibleInRetro: true,
      supp: false,
      calculatedAsAmount: false,
      order: tasksRows.length,
    };
    const _tasks = addElementAfterIndex(tasksRows, index, newElement);

    for (let i = 0; i < _tasks.length; i++) {
      _tasks[i].order = i + 1;
    }
    setTasksRows(_tasks);
    setAddedCount((old) => old - 1);

    setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
  }

  function devisCanBeSave(rows: TTaskInDevisTemplate[]): boolean {
    let result = true;
    const _devisRows = [...rows];
    const rowsWithoutPoste = _devisRows.filter((el) => !el.devisInit);
    for (const row of rowsWithoutPoste) {
      const existeTasks = row.tasks.filter(
        (t) => t.functionType && t.functionType !== FunctionType.CLIENT
      );
      if (existeTasks.length > 0) {
        result = false;
      }
    }

    const rowsClient = _devisRows.filter((el) => !el.devisInit);
    for (const row of rowsClient) {
      const existeTasksClient = row.tasks.filter(
        (t1) =>
          t1.functionType === FunctionType.CLIENT && !t1.durationClientDays
      );

      if (existeTasksClient.length > 0) result = false;
    }

    return result;
  }

  const onChangeFunctionType = async (value: number, index: number) => {
    const functionType = functionTypes.find((p) => p.id === value);
    if (!functionType) return;

    //const posteId = postesByDevis.find((el) => el.name === functionType as );

    if (functionType.name === FunctionType.CLIENT) {
      const _tasks = [...tasksRows];
      if (_tasks[index]) {
        _tasks[index].functionType = FunctionType.CLIENT;

        setTasksRows(_tasks);
        setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
      }
    }
  };

  return (
    <Container>
      <Form
        {...layout}
        ref={formRef}
        name="edit-devis-prod-templ"
        initialValues={{
          dateSigned: devisProd?.signedDate
            ? dayjs(devisProd.signedDate as string)
            : null,
          dateFinish: devisProd?.finishDate
            ? dayjs(devisProd.finishDate as string)
            : null,
          marge: currentProject?.marginPercent ?? 0,
        }}
        style={{ width: '100%' }}
      >
        <SDivBtn>
          <Button
            style={{ marginRight: '10px' }}
            htmlType="submit"
            onClick={close}
            type="default"
          >
            <Trans id="common.actions.cancel" />
          </Button>
          <Button
            htmlType="submit"
            disabled={!devisCanBeSave(devisRowsWithTasks)}
            style={{ width: '200px' }}
            onClick={submit}
            type="primary"
          >
            <Trans id="devis.prod.save" />
          </Button>
        </SDivBtn>
        <SDiv>
          <Form.Item
            name="dateSigned"
            style={{ width: '100%', margin: '10px 10px' }}
            label={i18n._('form.date.prod')}
          >
            <DatePicker
              minDate={dayjs().subtract(2, 'year')}
              format={dateFormat}
              onChange={onChangeDate}
            />
          </Form.Item>
          <Form.Item
            name="dateFinish"
            style={{ width: '100%', margin: '10px 10px' }}
            label={i18n._('form.date.prod.end')}
          >
            <DatePicker
              minDate={dayjs().subtract(2, 'year')}
              format={dateFormat}
              onChange={onChangeDateFinish}
            />
          </Form.Item>
        </SDiv>

        <SDiv>
          <Form.Item
            name="marge"
            style={{ minWidth: '350px', margin: '10px 10px' }}
            label={i18n._('form.marge.expected')}
          >
            <InputNumber
              style={{ width: '100px', marginRight: '10px' }}
              placeholder={i18n._('form.marge.placeholder')}
              controls={false}
              min={0}
              disabled
              addonAfter="%"
            />
          </Form.Item>
          <Text style={{ margin: '10px' }}>{i18n._('form.marge.prod')}</Text>
          <InputNumber
            style={{
              width: '100px',
              marginRight: '10px',
              borderRadius: `${devisInitDurationExpected === 0 ? '6px ' : 'none'}`,
              border: `${devisInitDurationExpected === 0 ? '1px solid #dd4848' : 'none'}`,
            }}
            controls={false}
            min={0}
            disabled
            status={devisInitDurationExpected === 0 ? 'warning' : ''}
            value={
              devisInitDurationExpected
                ? 100 -
                  Math.round(
                    (tasksRows
                      .filter((t) => t.functionType !== FunctionType.CLIENT)
                      .reduce(function (acc, obj) {
                        return acc + obj.duration;
                      }, 0) *
                      100) /
                      devisInitDurationExpected
                  )
                : null
            }
            addonAfter="%"
          />
        </SDiv>

        <SDivTasks>
          <SDevisTable>
            <SDevisTableHead>
              <tr>
                <SDevisTh colSpan={3} $minWidth="420px">
                  <SDivDetails>
                    Total
                    <InputNumber
                      style={{
                        width: '150px',
                        margin: '5px',
                        fontWeight: 'bold',
                      }}
                      placeholder={i18n._('form.amount.placeholder')}
                      value={devisInitAmount}
                      controls={false}
                      step="0.01"
                      disabled={true}
                      addonAfter="€"
                    />
                    <DurationItem
                      $width="120px"
                      $bold
                      duration={devisInitDurationExpected}
                    />
                  </SDivDetails>
                  <ButtonAddTask onClick={() => handleAddTaskClick(-1)} />
                </SDevisTh>
                <SDevisTh $minWidth="120px" $padding="0">
                  <SDivDurationHeader>
                    <DurationItem
                      $width="100%"
                      $bold
                      duration={tasksRows
                        .filter((t) => t.functionType !== FunctionType.CLIENT)
                        .reduce(function (acc, obj) {
                          return acc + obj.duration;
                        }, 0)}
                    />
                  </SDivDurationHeader>
                </SDevisTh>
                <SDevisTh>
                  <div>
                    <Tooltip title={i18n._('table.tipp.retro')}>
                      In retro ?
                    </Tooltip>
                  </div>
                </SDevisTh>
                <SDevisTh $textAlign="center">
                  <Tooltip title={i18n._('table.tipp.calc-as-somme')}>
                    somme?
                  </Tooltip>
                </SDevisTh>

                <SDevisTh $minWidth="100px">
                  <Tooltip title={i18n._('table.tipp.somme.expected')}>
                    {i18n._('common.amount.expected')}
                  </Tooltip>
                </SDevisTh>
                <SDevisTh $minWidth="100px">
                  <Tooltip title={i18n._('table.tipp.somme.spend')}>
                    {i18n._('common.amount.spend')}
                  </Tooltip>
                </SDevisTh>
              </tr>
            </SDevisTableHead>
            <tbody>
              {tasksRows.map((task, index) => (
                <STrDevisRow key={index}>
                  <SDevisTd
                    $minHeight="34px"
                    $height="34px"
                    $textAlign="center"
                    $padding="0 0 0 10px"
                  >
                    <Input
                      placeholder={i18n._('form.task-name.placeholder')}
                      onChange={(e) => onChangeTaskName(e.target.value, index)}
                      value={task.taskName}
                    />
                    <ButtonAddTask onClick={() => handleAddTaskClick(index)} />
                  </SDevisTd>
                  <SDevisTd $minWidth="150px">
                    <Select
                      showSearch
                      allowClear
                      style={{ width: '100%' }}
                      disabled={task.functionType === FunctionType.CLIENT}
                      onChange={async (value) => onChangePoste(value, index)}
                      value={task.devisInitId}
                      status={
                        !task.posteId &&
                        task.functionType !== FunctionType.CLIENT
                          ? 'warning'
                          : ''
                      }
                      optionFilterProp="label"
                      options={postesByDevis.map((item) => ({
                        value: item.devisInitId,
                        label: `${i18n._(item.label)} ${item.amount}`,
                      }))}
                    />
                  </SDevisTd>
                  <SDevisTd>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      value={
                        functionTypes.find(
                          (_el) => _el.name === task.functionType
                        )?.id
                      }
                      disabled={task.functionType !== undefined}
                      optionFilterProp="label"
                      options={
                        task.functionType !== undefined
                          ? functionTypes.map((item) => ({
                              value: item.id,
                              label: i18n._(item.name),
                            }))
                          : functionTypes
                              .filter((f) => f.name === 'CLIENT')
                              .map((item) => ({
                                value: item.id,
                                label: i18n._(item.name),
                              }))
                      }
                      onChange={async (value) =>
                        onChangeFunctionType(value, index)
                      }
                    />
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    {task.functionType === FunctionType.CLIENT ? (
                      <>
                        <InputNumber
                          style={{
                            width: '150px',
                            margin: '5px',
                            fontWeight: 'bold',
                          }}
                          placeholder={i18n._('form.amount.placeholder')}
                          value={task.durationClientDays}
                          controls={false}
                          addonAfter="j"
                          min={1}
                          status={
                            task.durationClientDays === 0 ? 'warning' : ''
                          }
                          onChange={(value) =>
                            onChangeTaskDurationDays(value ?? 1, index)
                          }
                        />
                      </>
                    ) : (
                      <DurationItem
                        $allowCorrection
                        duration={task.duration}
                        onChange={(value) => onChangeTaskDuration(value, index)}
                      />
                    )}
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    <Checkbox
                      checked={task.visibleInRetro}
                      onChange={(e) => onChangeTaskVisibleInRetro(e, index)}
                    />
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    <Checkbox
                      checked={task.calculatedAsAmount}
                      onChange={(e) => onChangeTaskCalculatedAsAmount(e, index)}
                    />
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    {task.calculatedAsAmount && (
                      <InputNumber
                        style={{
                          width: '120px',
                          margin: '5px',
                          fontWeight: 'bold',
                        }}
                        placeholder={i18n._('form.amount.placeholder')}
                        value={task.amount}
                        controls={false}
                        step="0.01"
                        min={0}
                        onChange={(value) =>
                          onChangeTaskAmount(value ?? 0, index)
                        }
                        addonAfter="€"
                      />
                    )}
                  </SDevisTd>

                  <SDevisTd $textAlign="center">
                    {task.calculatedAsAmount && (
                      <InputNumber
                        style={{
                          width: '120px',
                          margin: '5px',
                          fontWeight: 'bold',
                        }}
                        placeholder={i18n._('form.amount.placeholder')}
                        value={task.amountSpend}
                        controls={false}
                        step="0.01"
                        min={0}
                        onChange={(value) =>
                          onChangeTaskAmountSpend(value ?? 0, index)
                        }
                        addonAfter="€"
                      />
                    )}
                  </SDevisTd>

                  <SDevisTd>
                    <Tooltip
                      title={
                        task.hasTimes
                          ? 'Cette tâche ne peut pas être supprimée car les intervenants ont indiqué leurs temps'
                          : 'supprimer'
                      }
                    >
                      <Button
                        type="default"
                        shape="circle"
                        disabled={task.hasTimes}
                        icon={<DeleteOutlined />}
                        onClick={() => onDeleteClick(index)}
                      />
                    </Tooltip>
                  </SDevisTd>
                </STrDevisRow>
              ))}
            </tbody>
          </SDevisTable>
          <Collapse items={items} />
        </SDivTasks>
      </Form>
    </Container>
  );
}

export default EditDevisProdP;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: auto;
  width: 100%;
`;

const SDivBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const SDivTasks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const SDivDurationHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
`;

const SDivDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  & > div > div > div > div > input {
    font-weight: bold;
  }
`;
