import { ApolloProvider } from '@apollo/client';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes } from 'react-router-dom';
import { client } from '~/helpers/apollo';
import useLanguageStore from '~/helpers/store/locale/locale';
import { catalogs } from '~/locales/catalogs';
import { renderRoutes } from '~/pages/utils/router';
import { classic } from '~/themes/index';
import './index.css';

export function Main() {
  i18n.load(catalogs);
  const { locale } = useLanguageStore();

  useEffect(() => {
    i18n.activate(locale);
  }, [locale]);
  //    <React.StrictMode>
  return (
    <I18nProvider i18n={i18n}>
      <ConfigProvider theme={classic} locale={frFR}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Routes>{renderRoutes()}</Routes>
          </BrowserRouter>
        </ApolloProvider>
      </ConfigProvider>
    </I18nProvider>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Main />
);
