//import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useLingui, Trans } from '@lingui/react';

import { Input, Typography, Select, Button } from 'antd';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  GQL_DEVIS_BY_PROJECTID,
  StoredDevitInit,
  StoredDevitProd,
} from '~/gql/devis/devis';
import { GQL_FUNCTIONS, StoredFunction } from '~/gql/function/function';
import { GQL_POSTES, StoredPoste } from '~/gql/poste/poste';
import { GQL_UPDATE_PROJECT, GQL_GET_ONE_PROJECT } from '~/gql/project/project';
import { GQL_USERS, StoredUsers } from '~/gql/users/users';
import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import AddDevisInitP from '~/pages/components/common/devis/AddDevisInitP';
import AddDevisProd from '~/pages/components/common/devis/AddDevisProd';
import AddDevisProdTemplate from '~/pages/components/common/devis/AddDevisProdTemplate';
import EditDevisInit from '~/pages/components/common/devis/EditDevisInitP';
import EditDevisProdP from '~/pages/components/common/devis/EditDevisProdP';
import ViewDevisInit from '~/pages/components/common/devis/ViewDevisInitP';
import ViewDevisProdP from '~/pages/components/common/devis/ViewDevisProdP';
import Frame from '~/pages/components/ui/Frame';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ManagerLayout from '~/pages/layouts/ManagerLayout';
import { compareString } from '~/utils/utils/utils';

function DevisPage() {
  const params = useParams();
  const { id } = params;
  const { setCurrentProject } = useDataUserStore();
  const currentProject = useDataUserStore((state) => state.currentProject);

  const { Text } = Typography;
  const { payload } = useAuth();
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);
  const [devisInit, setDevisInit] = useState<StoredDevitInit | null>(null);
  const [devisProd, setDevisProd] = useState<StoredDevitProd | null>(null);
  const [projectName, setProjectName] = useState<string>('');
  const [debouncedProjectName, setDebouncedProjectName] = useState<string>('');
  const [projectManagerList, setProjectManagerList] = useState<StoredUsers[]>(
    []
  );
  const [selectedProjectManager, setSelectedProjectManager] = useState<
    number | null
  >(null);
  const [openAddDevisInit, setOpenAddDevisInit] = useState<boolean>(false);
  const [openEditDevisInit, setOpenEditDevisInit] = useState<boolean>(false);

  const [openAddDevisProd, setOpenAddDevisProd] = useState<boolean>(false);
  const [openAddDevisProdTemplate, setOpenAddDevisProdTemplate] =
    useState<boolean>(false);

  const [openEditDevisProd, setOpenEditDevisProd] = useState<boolean>(false);

  const [functionTypes, setFunctionTypes] = useState<StoredFunction[]>([]);
  const [postes, setPostes] = useState<StoredPoste[]>([]);

  const [isOpenedDevisInit, setIsOpenedDevisInit] = useState<boolean>(false);
  const [isOpenedDevisProd, setIsOpenedDevisProd] = useState<boolean>(true);

  const { refetch: fetchDevis } = useQuery(GQL_DEVIS_BY_PROJECTID, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let devisInitOrdered: StoredDevitInit | null =
        data.getDevisByProjectId.devisInit ?? null;

      if (devisInitOrdered?.devisInit) {
        const sortered = [...devisInitOrdered.devisInit].sort(compareByOrder);
        devisInitOrdered = { ...devisInitOrdered, devisInit: [...sortered] };
      }

      setDevisInit(devisInitOrdered);
      setDevisProd(data.getDevisByProjectId.devisProd ?? null);
    },
    onError: (e) => {
      console.log('erreur dans GQL_DEVIS_BY_PROJECTID', e);
    },
  });

  function compareByOrder(a: any, b: any) {
    if (a.order !== undefined && b.order !== undefined) {
      //  return b.order - a.order;
      return a.order - b.order;
    }

    return 0;
  }

  const [getFunctions] = useLazyQuery(GQL_FUNCTIONS, {
    client: authClient,
    onCompleted: (data) => {
      setFunctionTypes(data.getFunctions);
    },
  });

  const [getPostes] = useLazyQuery(GQL_POSTES, {
    client: authClient,
    onCompleted: (data) => {
      setPostes([...data.getPostes].sort(compareString));
    },
  });

  const [updateProject] = useMutation(GQL_UPDATE_PROJECT, {
    client: authClient,
  });

  const { refetch: fetchProject } = useQuery(GQL_GET_ONE_PROJECT, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCurrentProject(data.getOneProject);
      setProjectName(data.getOneProject.name);
      if (data.getOneProject.projectManager.id) {
        setSelectedProjectManager(data.getOneProject.projectManager.id);
      }
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_ONE_PROJECT', e);
    },
  });

  const [getAllUsers] = useLazyQuery(GQL_USERS, {
    client: authClient,
    onCompleted: (data) => {
      const listProjectManager = data.getUsers.filter(
        (elem) => elem.role.name === 'Manager' || elem.role.name === 'Admin'
      );
      setProjectManagerList(listProjectManager);
    },
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedProjectName(projectName);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [projectName, 500]);

  useEffect(() => {
    if (debouncedProjectName && debouncedProjectName !== currentProject?.name)
      void handleChangeProjectName(debouncedProjectName);
  }, [debouncedProjectName]);

  useEffect(() => {
    if (!currentProject) {
      void getProject();
    }
    void getDevis();
    void getUsers();
    void getFunctionTypes();
    void getPostesTypes();
    if (currentProject) {
      setProjectName(currentProject.name);
      if (currentProject.projectManager.id) {
        setSelectedProjectManager(currentProject.projectManager.id);
      }
    }
  }, []);

  useEffect(() => {
    if (devisProd) setIsOpenedDevisInit(false);
    else setIsOpenedDevisInit(true);
  }, [devisProd]);

  async function getFunctionTypes() {
    setLoading(true);
    await getFunctions();
    setLoading(false);
  }

  async function getPostesTypes() {
    setLoading(true);
    await getPostes();
    setLoading(false);
  }

  useEffect(() => {
    if (currentProject) {
      setProjectName(currentProject.name);
      setSelectedProjectManager(currentProject.projectManager.id);
    }
  }, [currentProject]);

  async function getDevis() {
    setLoading(true);
    await fetchDevis();
    setLoading(false);
  }

  async function getUsers() {
    setLoading(true);
    await getAllUsers();
    setLoading(false);
  }

  async function getProject() {
    setLoading(true);
    await fetchProject();
    setLoading(false);
  }

  async function handleChangeProjectName(name: string) {
    if (!currentProject) return;

    await updateProject({
      variables: {
        id: currentProject.id,
        name: name,
      },
    });
  }

  const handleChangeManager = async (value: number) => {
    setSelectedProjectManager(value);
    if (!currentProject || !value) return;
    await updateProject({
      variables: {
        id: currentProject.id,
        projectManagerId: value,
      },
    });
  };

  const handleChangeName = (name: string) => {
    setProjectName(name);
  };

  /* dans input
    suffix={
                    <Tooltip title={i18n._('form.project-name.advice')}>
                      <InfoCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
  */

  return (
    <>
      <DefaultLayout>
        <ManagerLayout>
          {loading ? (
            <p>{i18n._('common.loading')} ...</p>
          ) : (
            <SDivContentWrapper>
              <SDivTitle>
                <Text style={{ marginRight: '10px' }}>
                  {i18n._('form.project-name')}
                </Text>
                <Input
                  style={{ width: '250px', marginRight: '10px' }}
                  placeholder={i18n._('form.project-name.placeholder')}
                  value={projectName}
                  onChange={async (e) => handleChangeName(e.target.value)}
                />

                <Text style={{ margin: '10px' }}>
                  {i18n._('form.project.manager')}
                </Text>
                <Select
                  showSearch
                  disabled={payload?.roles === 'Admin' ? false : true}
                  style={{ width: '250px' }}
                  placeholder={i18n._('form.project.manager.placeholder')}
                  value={selectedProjectManager}
                  onChange={handleChangeManager}
                  optionFilterProp="label"
                  options={projectManagerList.map((item) => ({
                    value: item.id,
                    label: item.firstName + ' ' + item.lastName,
                  }))}
                />
              </SDivTitle>
              <SDivDevisWrapper>
                <Frame
                  isOpen={isOpenedDevisInit}
                  minWidth={
                    !devisInit
                      ? '500px'
                      : openEditDevisInit
                        ? '1030px'
                        : '910px'
                  }
                  onClick={() => setIsOpenedDevisInit(!isOpenedDevisInit)}
                >
                  {devisInit ? (
                    <>
                      {!openEditDevisInit && (
                        <Button
                          style={{ width: '200px', marginBottom: '10px' }}
                          onClick={() => setOpenEditDevisInit(true)}
                          type="primary"
                        >
                          <Trans id="devis.init.edit" />
                        </Button>
                      )}

                      {openEditDevisInit && currentProject ? (
                        <EditDevisInit
                          postes={postes}
                          close={() => setOpenEditDevisInit(false)}
                          confirm={() => {
                            void getProject();
                            void getDevis();
                          }}
                          functionTypes={functionTypes}
                          devisInit={devisInit}
                        />
                      ) : (
                        <>
                          {currentProject && (
                            <ViewDevisInit
                              postes={postes}
                              functionTypes={functionTypes}
                              devisInit={devisInit}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      style={{ width: '200px', marginBottom: '10px' }}
                      onClick={() => setOpenAddDevisInit(true)}
                      type="primary"
                    >
                      <Trans id="devis.init.add" />
                    </Button>
                  )}
                </Frame>

                {devisInit && (
                  <Frame
                    isOpen={isOpenedDevisProd}
                    minWidth={!devisProd ? '550px' : '910px'}
                    onClick={() => setIsOpenedDevisProd(!isOpenedDevisProd)}
                  >
                    {devisProd ? (
                      <>
                        {!openEditDevisProd && (
                          <Button
                            style={{ width: '200px', marginBottom: '10px' }}
                            onClick={() => setOpenEditDevisProd(true)}
                            type="primary"
                          >
                            <Trans id="devis.prod.edit" />
                          </Button>
                        )}

                        {currentProject && openEditDevisProd ? (
                          <EditDevisProdP
                            postes={postes}
                            devisProd={devisProd}
                            close={() => setOpenEditDevisProd(false)}
                            confirm={() => {
                              void getDevis();
                            }}
                            functionTypes={functionTypes}
                            devisInit={devisInit}
                          />
                        ) : (
                          <>
                            {currentProject && (
                              <ViewDevisProdP
                                postes={postes}
                                functionTypes={functionTypes}
                                devisProd={devisProd}
                                devisInit={devisInit}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {!openAddDevisProd && !openAddDevisProdTemplate && (
                          <>
                            <Button
                              style={{ width: '250px' }}
                              onClick={() => {
                                setOpenAddDevisProdTemplate(true);
                              }}
                              type="primary"
                            >
                              <Trans id="devis.prod.add.template" />
                            </Button>
                          </>
                        )}
                        {openAddDevisProd && currentProject && (
                          <AddDevisProd
                            devisInit={devisInit}
                            close={() => setOpenAddDevisProd(false)}
                            confirm={() => {
                              void getDevis();
                            }}
                            functionTypes={functionTypes}
                          />
                        )}
                        {openAddDevisProdTemplate && currentProject && (
                          <AddDevisProdTemplate
                            postes={postes}
                            devisInit={devisInit}
                            close={() => setOpenAddDevisProdTemplate(false)}
                            confirm={() => {
                              void getDevis();
                            }}
                            functionTypes={functionTypes}
                          />
                        )}
                      </>
                    )}
                  </Frame>
                )}
              </SDivDevisWrapper>
            </SDivContentWrapper>
          )}
          {currentProject && (
            <AddDevisInitP
              postes={postes}
              functionTypes={functionTypes}
              title={i18n._('devis.init.add')}
              isOpen={openAddDevisInit}
              close={() => setOpenAddDevisInit(false)}
              confirm={() => {
                void getDevis();
              }}
            />
          )}
        </ManagerLayout>
      </DefaultLayout>
    </>
  );
}

/*
ne pas supprimer pour le moment ! peut-être remettre ?
                          <Button
                            style={{ width: '200px', marginRight: '10px' }}
                            onClick={() => {
                              setOpenAddDevisProd(true);
                            }}
                            type="primary"
                          >
                            <Trans id="devis.prod.add" />
                          </Button>
*/

export default DevisPage;

const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SDivTitle = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const SDivDevisWrapper = styled.div`
  display: flex;
`;
