import { Layout } from 'antd';

const { Footer: Foot } = Layout;

function Footer() {
  return (
    <Foot style={{ textAlign: 'center' }}>MySG ©2024 My-Serious-Times</Foot>
  );
}

export default Footer;
