import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { Avatar, Divider, List, Spin, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { Buffer } from 'buffer';
import {
  GQL_ALL_MY_FILES,
  GQL_DOWNLOAD,
  GQL_UPLOAD,
  StoredFiles,
} from '~/gql/file/file';
import { authClient } from '~/helpers/apollo';

function Files() {
  const [files, setFiles] = useState<StoredFiles[]>([]);

  const { refetch: fetchFiles, loading: loadingAllMyFiles } = useQuery(
    GQL_ALL_MY_FILES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      client: authClient,
      onCompleted: (_files) => {
        setFiles(_files.getAllMyFiles);
      },
    }
  );

  const [download] = useLazyQuery(GQL_DOWNLOAD, {
    client: authClient,
    onCompleted: (p) => {
      const t = Buffer.from(p.downloadFile.buffer, 'base64');
      const blobData = new Blob([t], {
        type: p.downloadFile.fileMimeType,
      });
      const fileURL = window.URL.createObjectURL(blobData);
      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = p.downloadFile.fileName;
      alink.click();
      document.body.removeChild(alink);
    },
  });

  const [upload] = useMutation(GQL_UPLOAD, {
    client: authClient,
    onCompleted: async () => {
      await message.success(`File uploaded successfully`);
    },
    onError: async () => {
      await message.error(`File upload failed`);
    },
  });

  const downloadComp = async (eTag: string) => {
    await download({ variables: { eTag } });
  };

  const uploadComp = async (d: any) => {
    await upload({ variables: { file: d.target.files[0] } });
    await fetchFiles();
  };

  return (
    <div style={{ paddingTop: ' 25px' }}>
      <List
        itemLayout="horizontal"
        bordered
        dataSource={files}
        renderItem={(item) =>
          loadingAllMyFiles ? (
            <Spin />
          ) : (
            <List.Item
              onClick={async () => downloadComp(item.eTag)}
              style={{ cursor: 'pointer' }}
            >
              <List.Item.Meta
                key={item.eTag}
                avatar={
                  <Avatar
                    style={{
                      margin: '10px 10px 10px 10px',
                      backgroundColor: '#118888',
                    }}
                  >
                    <DownloadOutlined />
                  </Avatar>
                }
                title={item.fileName}
                description={`${item.createdAt} - ${item.fileMimeType}`}
              />
            </List.Item>
          )
        }
      />

      <Divider />
      <FileUpload htmlFor="file-upload">
        <CloudUploadOutlined /> Upload
      </FileUpload>
      <InputFile
        id="file-upload"
        type="file"
        onChange={async (d) => uploadComp(d)}
      />
    </div>
  );
}

export default Files;

const InputFile = styled.input`
  display: none;
`;

const FileUpload = styled.label`
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #118888;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;
