import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { GQL_LOGIN_UNIQUE } from '~/gql/auth/auth';
import { env } from '~/helpers/env';
import { Payload, useAuth } from '~/helpers/store/auth';
import { parseJwt } from '~/utils/jwt';

function LoginSSO() {
  const { setPayload } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [uniqueAuth] = useLazyQuery(GQL_LOGIN_UNIQUE);

  const jwt = searchParams.get('jwt');
  const uniqueCode = searchParams.get('uniqueCode');

  useEffect(() => {
    if (jwt && uniqueCode) {
      const dataJwt = parseJwt(jwt);
      if (!dataJwt) {
        return;
      }
      uniqueAuth({ variables: { code: uniqueCode } })
        .then((t) => {
          if (!t.data) throw new Error('Error login SSO');
          const payload: Payload = {
            access_token: t.data.uniqueAuthFromSso.access_token,
            refresh_token: 'SSO',
            firstName: t.data.uniqueAuthFromSso.firstName,
            lastName: t.data.uniqueAuthFromSso.lastName,
            exp: dataJwt.exp,
            iat: dataJwt.iat,
            sub: t.data.uniqueAuthFromSso.sub,
            roles: t.data.uniqueAuthFromSso.role,
            email: t.data.uniqueAuthFromSso.email,
          };
          setPayload(payload);
          if (payload.roles === 'Admin' || payload.roles === 'Manager') {
            navigate('/manager-projects');
          } else {
            navigate('/times');
          }
        })
        .catch(() => {
          navigate('/');
        });
    }
  }, [jwt]);

  useEffect(() => {
    if (!jwt && !uniqueCode) location.href = `${env.api.url}/azure-ad/connect`;
  }, []);

  return (
    <>
      <SButton
        style={{ marginTop: '10px' }}
        className="login-form-button"
        onClick={() => (location.href = `${env.api.url}/azure-ad/connect`)}
      >
        <p style={{ color: 'white' }}>
          <img
            style={{ verticalAlign: 'sub', marginRight: '10px' }}
            width={20}
            src="https://www.microsoft.com/fr/microsoft-365/blog/wp-content/uploads/sites/30/2022/06/cropped-microsoft_logo_element.png"
          />
          Connexion via Microsoft
        </p>
      </SButton>
    </>
  );
}

export default LoginSSO;

const SButton = styled.button`
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #2d572c;
  margin: 0 auto;
  cursor: pointer;
  transition: border-color 0.25s;
  &:hover {
    border-color: #646cff;
  }
  &:focus,
  &:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
`;
