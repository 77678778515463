import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {
  calcTotalPlanningDurationByDate,
  calcTotalTimeByDate,
} from '~/pages/components/common/utils-table/utils-task-times-table';
import { TCellTaskTime, TDateTimes } from '~/pages/types/types';
import { getHoursAndMinutesStrByDuration } from '~/utils/utils/utils';

type IProps = {
  tasks: TCellTaskTime[];
  calendar: TDateTimes[];
  fileName: string;
};

export const exportUserTasksTimes = async ({
  tasks,
  calendar,
  fileName,
}: IProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Planning-Réalisé');

  const calendarHeaders: any[] = [];
  for (let index = 0; index < calendar.length; index++) {
    const el = calendar[index];
    calendarHeaders.push(
      new Date(el.date as Date).toLocaleDateString() + ' Plan'
    );
    calendarHeaders.push(
      new Date(el.date as Date).toLocaleDateString() + ' Réalisé'
    );
  }
  calendarHeaders.push('Total planifié');
  calendarHeaders.push('Total réalisé');

  const headers = ['Projet', 'Tâche'];
  worksheet.addRow(headers.concat(calendarHeaders));

  worksheet.addRow([]);

  tasks.forEach((task) => {
    const taskData: any[] = [task.projectName, task.taskName];
    const calendarData: any[] = [];

    for (let index = 0; index < task.calendar.length; index++) {
      const element = task.calendar[index];
      calendarData.push({
        value: `${element.planningDuration ? getHoursAndMinutesStrByDuration(element.planningDuration) : ''} `,
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            color: {
              argb: '00FFFF',
            },
            fgColor: {
              argb: 'FFFFFF',
            },
          },
        },
      });
      calendarData.push(
        `${element.time ? getHoursAndMinutesStrByDuration(element.time) : ''} ${element.timeComment ? ' : ' + element.timeComment : ''}`
      );
    }
    const allPlanning = task.calendar.reduce(function (
      acc: any,
      obj: TDateTimes
    ) {
      return obj.planningDuration !== undefined
        ? acc + obj.planningDuration
        : acc;
    }, 0);

    calendarData.push({
      value: `${allPlanning ? getHoursAndMinutesStrByDuration(allPlanning) : ''} `,
      style: {
        font: {
          color: { argb: 'FF00FF' },
        },
      },
    });

    const allTime = task.calendar.reduce(function (acc: any, obj: TDateTimes) {
      return obj.time !== undefined ? acc + obj.time : acc;
    }, 0);
    calendarData.push(
      `${allTime ? getHoursAndMinutesStrByDuration(allTime) : ''} `
    );

    const row = worksheet.addRow(taskData.concat(calendarData));

    calendarData.forEach((cell, index) => {
      if (typeof cell === 'object') {
        const excelCell = row.getCell(headers.length + 1 + index);
        // excelCell.fill = cell.style.fill;

        if (Object.hasOwnProperty.bind(cell)('value')) {
          const v = cell.value;
          excelCell.value = v;
          excelCell.font = {
            color: { argb: 'FF00FF' },
          };
        }
      }
    });

    // const cellPlanning = calendarData[calendarData.length - 2];
    // cellPlanning.font = {
    //   color: { argb: 'FF00FF' },
    // };
  });

  worksheet.addRow([]);

  // footer
  let allPlanning = 0;
  let allTime = 0;
  const calendarTotal: any[] = [];
  for (let index = 0; index < calendar.length; index++) {
    const el = calendar[index];

    const dayPlanning = calcTotalPlanningDurationByDate(el.date, tasks);
    allPlanning = allPlanning + dayPlanning;
    calendarTotal.push({
      value: `${dayPlanning ? getHoursAndMinutesStrByDuration(dayPlanning) : ''} `,
      style: {
        font: {
          color: { argb: 'FF00FF' },
          bold: true,
        },
      },
    });

    const dayTime = calcTotalTimeByDate(el.date, tasks);
    allTime = allTime + dayTime;
    calendarTotal.push({
      value: `${dayTime ? getHoursAndMinutesStrByDuration(dayTime) : ''} `,
      style: {
        font: {
          bold: true,
        },
      },
    });
  }

  calendarTotal.push({
    value: `${allPlanning ? getHoursAndMinutesStrByDuration(allPlanning) : ''} `,
    style: {
      font: {
        color: { argb: 'FF00FF' },
        bold: true,
      },
    },
  });
  calendarTotal.push({
    value: `${allTime ? getHoursAndMinutesStrByDuration(allTime) : ''} `,
    style: {
      font: {
        bold: true,
      },
    },
  });

  const footers = ['', 'Total'];
  const footRow = worksheet.addRow(footers.concat(calendarTotal));
  calendarTotal.forEach((cell, index) => {
    if (typeof cell === 'object') {
      const excelCell = footRow.getCell(headers.length + 1 + index);
      excelCell.font = cell.style.font;

      if (Object.hasOwnProperty.bind(cell)('value')) {
        const v = cell.value;
        excelCell.value = v;
      }
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};
