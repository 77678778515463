import ProjectRenta from '~/pages/components/common/details/ProjectRenta';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ManagerLayout from '~/pages/layouts/ManagerLayout';

function ProjectInternDetailsPage() {
  return (
    <>
      <DefaultLayout>
        <ManagerLayout>
          <ProjectRenta />
        </ManagerLayout>
      </DefaultLayout>
    </>
  );
}

export default ProjectInternDetailsPage;
