import { Avatar, Tooltip } from 'antd';
import { styled } from 'styled-components';
import { StoredUsers } from '~/gql/users/users';
import { couleurRandomHex } from '~/utils/utils/utils';

type IProps = {
  users: StoredUsers[] | undefined;
  maxCount?: number;
};

function AvatarList({ users, maxCount }: IProps) {
  return (
    <Container>
      <Avatar.Group
        maxCount={maxCount ? maxCount : 2}
        maxStyle={{ color: '#0B446F', backgroundColor: '#dcecf9' }}
        size={'small'}
      >
        {users?.map((user, index) => (
          <Tooltip
            key={index}
            title={
              user.firstName + ' ' + user.lastName + ' / ' + user.role.name
            }
            placement="top"
          >
            <Avatar style={{ backgroundColor: `${couleurRandomHex()}` }}>
              {user.firstName[0]}
              {user.lastName[0]}
            </Avatar>
          </Tooltip>
        ))}
      </Avatar.Group>
    </Container>
  );
}

export default AvatarList;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
