import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/helpers/store/auth';
import { Role } from '~/pages/utils/router';

function ImAuth({ role }: { role: Role[] }) {
  const { imLoggedAndValid, payload } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const myRole = payload?.roles;
    // If im not logged or my token is not valid i redirect to login page
    if (!imLoggedAndValid() || !myRole) {
      navigate('/');
      return;
    }

    const isAllowed = role.includes(myRole as Role);

    if (!isAllowed) navigate('/denied');

    // // A rework c'est crade
    // switch (role) {
    //   case Role.Admin: {
    //     if (myRole != Role.Admin) navigate('/denied');
    //     break;
    //   }
    //   case Role.User: {
    //     if (myRole == Role.Admin) break;
    //     if (myRole != Role.User) navigate('/denied');
    //     break;
    //   }
    //   default: {
    //     // Nothing to do if this page is guest mode
    //     break;
    //   }
    // }
  }, []);
  return <></>;
}

export default ImAuth;
