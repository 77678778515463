import ProjectRenta from '~/pages/components/common/details/ProjectRenta';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import InternLayout from '~/pages/layouts/InternLayout';

function ProjectInternDetailsPage() {
  return (
    <>
      <DefaultLayout>
        <InternLayout>
          A déterminer les infos souhaitées et pour l'instant c'est identique à
          la renta de projet externe
          <ProjectRenta />
        </InternLayout>
      </DefaultLayout>
    </>
  );
}

export default ProjectInternDetailsPage;
