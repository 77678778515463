import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { FunctionType } from '@zeus/index';

import { Button, Divider, Form, Select, Tooltip, Typography } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
// import {
//   GQL_CREATE_OR_UPDATE_USERS_IN_TASK,
//   GQL_CREATE_OR_UPDATE_USERS_IN_TASK_INTERN,
// } from '~/gql/task/task';
import { StoredUsers, GQL_USERS } from '~/gql/users/users';

import { authClient } from '~/helpers/apollo';
import { getUsersListWithoutUsersWithTimeByFunctionType } from '~/pages/components/common/utils-table/utils';
//import useDataUserStore from '~/helpers/store/data-user/data-user';

import ModalCustom from '~/pages/components/ui/ModalCustom';
import { TUsersByFunctionType } from '~/pages/types/types';
// import { classic } from '~/themes';
// import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
  //taskId: number;
  onChangeUsers: (users: TUsersByFunctionType[]) => void;
  usersByFunctionType: TUsersByFunctionType[];
  usersWithTimeByFunctionType: TUsersByFunctionType[];
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

function EditAllUsers({
  title,
  isOpen,
  confirm,
  onChangeUsers,
  usersByFunctionType,
  usersWithTimeByFunctionType,
  close,
}: IProps) {
  const { i18n } = useLingui();

  const { Text } = Typography;
  const [userList, setUserList] = useState<StoredUsers[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<TUsersByFunctionType[]>(
    []
  );

  const formRef = React.useRef<FormInstance>(null);

  const [getAllUsers] = useLazyQuery(GQL_USERS, {
    client: authClient,
    onCompleted: (data) => {
      setUserList(data.getUsers);
    },
  });

  async function getUserList() {
    await getAllUsers();
  }

  useEffect(() => {
    //formRef.current?.resetFields();

    setSelectedUsers([]);
    if (usersByFunctionType.length > 0) {
      setSelectedUsers([...usersByFunctionType]);
    }
  }, [usersByFunctionType]);

  useEffect(() => {
    formRef.current?.resetFields();
    void getUserList();
  }, []);

  async function submit() {
    const allSelected = [...selectedUsers];
    for (const sUWT of usersWithTimeByFunctionType) {
      const ind = allSelected.findIndex(
        (el) => el.functionType === sUWT.functionType
      );
      if (ind !== -1) {
        for (const u of sUWT.usersIds) {
          allSelected[ind].usersIds.push(u);
        }
      } else {
        allSelected.push({
          functionType: sUWT.functionType,
          usersIds: [...sUWT.usersIds],
        });
      }
    }
    void onChangeUsers(allSelected);
    confirm();
  }

  function handleUsersChange(value: number[], functionType: FunctionType) {
    const _selectedUsers = [...selectedUsers];
    const ind = _selectedUsers.findIndex(
      (el) => el.functionType === functionType
    );
    if (ind !== -1) {
      _selectedUsers[ind].usersIds = [...value];
      setSelectedUsers(_selectedUsers);
    }
  }

  function handleDeleteClick(functionType: FunctionType) {
    const _selectedUsers = [...selectedUsers];
    const ind = _selectedUsers.findIndex(
      (el) => el.functionType === functionType
    );
    _selectedUsers.splice(ind, 1);
    setSelectedUsers(_selectedUsers);
  }

  return (
    <ModalCustom title={title} isOpen={isOpen} confirm={submit} close={close}>
      <Form
        {...layout}
        ref={formRef}
        name="task-edit-users"
        style={{ minWidth: 800 }}
      >
        <>
          <SDivAdvice>
            <Text type="danger" style={{ marginBottom: '10px' }}>
              {i18n._('task.edit.all.users.warning')}
            </Text>
            <br />
            <Text style={{ marginBottom: '10px' }}>
              {i18n._('task.edit.all.users.advice')}
            </Text>
          </SDivAdvice>

          <SDivItemWrapper>
            <SDivType $align="center">
              <Text
                style={{
                  fontWeight: 'bold',
                }}
              >
                {i18n._('common.function.type')}
              </Text>
            </SDivType>
            <SDivUsers $align="center">
              <Text
                style={{
                  fontWeight: 'bold',
                }}
              >
                {i18n._('form.edit.users')}
              </Text>
            </SDivUsers>
          </SDivItemWrapper>

          {selectedUsers.map((usersItem, index) => (
            <SDivItemWrapper key={index}>
              <SDivType>{i18n._(usersItem.functionType)}</SDivType>
              <SDivUsers>
                <Select
                  mode="multiple"
                  showSearch
                  placeholder={i18n._('form.edit.users.placeholder')}
                  value={usersItem.usersIds}
                  onChange={(value) =>
                    handleUsersChange(value, usersItem.functionType)
                  }
                  style={{ width: '100%' }}
                  optionFilterProp="label"
                  options={getUsersListWithoutUsersWithTimeByFunctionType(
                    userList,
                    usersWithTimeByFunctionType,
                    usersItem.functionType
                  ).map((item) => ({
                    value: item.id,
                    label: item.firstName + ' ' + item.lastName,
                  }))}
                />
              </SDivUsers>
              <SDivAction>
                <Tooltip title="supprimer">
                  <Button
                    type="default"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteClick(usersItem.functionType)}
                  />
                </Tooltip>
              </SDivAction>
            </SDivItemWrapper>
          ))}
          <Divider />
          {usersWithTimeByFunctionType.map((usersItem, index) => (
            <div key={index}>
              <span>{i18n._('users.edit.warning')}</span>
              <SDivItemWrapper key={index}>
                <SDivType>{i18n._(usersItem.functionType)}</SDivType>
                <SDivUsers>
                  <Select
                    mode="multiple"
                    disabled
                    showSearch
                    placeholder={i18n._('form.edit.users.placeholder')}
                    value={usersItem.usersIds}
                    onChange={(value) =>
                      handleUsersChange(value, usersItem.functionType)
                    }
                    style={{ width: '100%' }}
                    optionFilterProp="label"
                    options={userList.map((item) => ({
                      value: item.id,
                      label: item.firstName + ' ' + item.lastName,
                    }))}
                  />
                </SDivUsers>
                <SDivAction>
                  <Tooltip title="supprimer">
                    <Button
                      type="default"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteClick(usersItem.functionType)}
                    />
                  </Tooltip>
                </SDivAction>
              </SDivItemWrapper>
            </div>
          ))}
        </>
      </Form>
    </ModalCustom>
  );
}

/*
 <Form.Item
                  wrapperCol={{ span: 24 }}
                  style={{ width: '100%' }}
                  name="users"
                >
                  {i18n._(usersItem.functionType)}
                </Form.Item>
 */
export default EditAllUsers;

const SDivAdvice = styled.div`
  padding: 0 0 10px 0;
  width: 100%;
`;
const SDivItemWrapper = styled.div`
  display: flex;
  border: 1px solid grey;
  padding: 5px;
  width: 100%;
`;

const SDivType = styled.div<{ $align?: string }>`
  flex: 0 0 30%;
  display: flex;
  padding: 0 10px 0 0;
  justify-content: ${({ $align }) => ($align ? $align : 'flex-start')};
`;

const SDivUsers = styled.div<{ $align?: string }>`
  display: flex;
  flex: 0 0 60%;
  padding: 0 10px 0 0;
  justify-content: ${({ $align }) => ($align ? $align : 'flex-start')};
`;

const SDivAction = styled.div<{ $align?: string }>`
  flex: 0 0 10%;
  display: flex;

  justify-content: ${({ $align }) => ($align ? $align : 'flex-start')};
`;
