import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { StoredDevitProd } from '~/gql/devis/devis';
import { StoredProjectFullInfo } from '~/gql/project/project';

export type DataUser = {
  project: StoredProjectFullInfo;
};

type DataUserState = {
  currentProject: StoredProjectFullInfo | null;
  setCurrentProject: (project: StoredProjectFullInfo | null) => void;
  currentDevisProd: StoredDevitProd | null;
  setCurrentDevisProd: (project: StoredDevitProd | null) => void;
};

const useDataUserStore = create<DataUserState>()(
  persist(
    (set) => ({
      currentProject: null, // default project
      setCurrentProject: (currentProject: StoredProjectFullInfo | null) =>
        set({ currentProject }),
      currentDevisProd: null,
      setCurrentDevisProd: (currentDevisProd: StoredDevitProd | null) =>
        set({ currentDevisProd }),
    }),
    {
      name: 'data-user-storage',
    }
  )
);

export default useDataUserStore;
