import { useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Form, Input, Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GQL_FINISH_REGISTER } from '~/gql/auth/auth';
import { SH2, SH3, SLink, SText, StyledButton } from '~/utils/ui/utils';
import { classic } from '~/themes';
import { Background } from '~/pages/App';
import { LoginForm, SForm } from '~/pages/login/Login';

function CreateAccount() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [, setPasswordConfirm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const [form] = Form.useForm();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [finishRegister] = useMutation(GQL_FINISH_REGISTER, {
    onCompleted: (data) => {
      if (data.finishRegister) {
        notification.success({
          message: i18n._('common.register'),
          description: i18n._('good.register.message'),
          placement: 'topRight',
        });
        navigate('/');
      } else {
        notification.error({
          message: i18n._('common.register'),
          description: i18n._('bad.register.message'),
          placement: 'topRight',
        });
      }
    },
    onError: () => {
      notification.error({
        message: i18n._('common.register'),
        description: i18n._('bad.register.message'),
        placement: 'topRight',
      });
    },
  });

  const validatePassword = async (_rule: any, value: string) => {
    return new Promise((resolve, reject) => {
      const _password = form.getFieldValue('password');
      if (_password && value !== _password) {
        reject(i18n._('password.not-match.message'));
      } else {
        resolve('');
      }
    });
  };

  const onFinish = async () => {
    if (token) {
      await finishRegister({
        variables: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
          token: token,
        },
      });
    }
  };

  return (
    <Container>
      <Background />
      <LoginForm>
        <SForm form={form} onFinish={onFinish}>
          <Form.Item>
            <SH2
              $color={classic.token?.colorPrimary}
              $texttransform="uppercase"
            >
              {i18n._('finish.register')}
            </SH2>
            <SText $margin="15px 0">{i18n._('finish.register.message')}</SText>
          </Form.Item>
          <Form.Item
            name="email"
            className="customInput"
            rules={[
              {
                required: true,
                message: i18n._('input.required.message'),
              },
              {
                pattern: new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                message: i18n._('input.email.message'),
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder={i18n._('common.email')}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
              type="text"
            />
          </Form.Item>

          <Form.Item
            name="lastname"
            className="customInput"
            rules={[
              { required: true, message: i18n._('input.required.message') },
            ]}
          >
            <Input
              type="lastname"
              placeholder={i18n._('common.lastname')}
              onChange={(e) => setLastName(e.target.value)}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="firstname"
            className="customInput"
            rules={[
              { required: true, message: i18n._('input.required.message') },
            ]}
          >
            <Input
              type="firstname"
              placeholder={i18n._('common.firstname')}
              onChange={(e) => setFirstName(e.target.value)}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="customInput"
            rules={[{ required: true, message: i18n._('input.password') }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={i18n._('common.password')}
              onChange={(e) => setPassword(e.target.value)}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="passwordConfirm"
            className="customInput"
            rules={[
              { required: true, message: i18n._('input.password-confirm') },
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={i18n._('password.confirm')}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <StyledButton type="submit">
              {i18n._('common.register')}
            </StyledButton>
          </Form.Item>

          <SLink
            $color="#9291a5"
            $textdecoration="underline"
            onClick={() => navigate('/')}
          >
            {i18n._('login.return-message')}
          </SLink>
        </SForm>
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onOk={handleOk}
            centered
            closeIcon={false}
            style={{ textAlign: 'center' }}
            footer={false}
            onCancel={handleCancel}
          >
            <SH3 $color={classic.token?.colorPrimary}>
              {i18n._('bad.connection')}
            </SH3>
            <SText $fontweight="bold" $margin="37px 0">
              {i18n._('bad.connection.message')}
            </SText>
            <StyledButton onClick={() => handleCancel()}>
              {i18n._('common.close')}
            </StyledButton>
          </Modal>
        )}
      </LoginForm>
    </Container>
  );
}

export default CreateAccount;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
