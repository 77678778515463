import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { useEffect, useState } from 'react';
import { GQL_TASKS_INFO_BY_USER_ID } from '~/gql/task/task';
import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import TasksInfoByUser from '~/pages/components/common/details/TasksInfoByUser';
import { mappedTasksForUserInfo } from '~/pages/components/common/utils-table/utils-project-details-table';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import { TTaskForUserInfo } from '~/pages/types/types';

function UserTaskPage() {
  const { payload } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [tasksForUser, setTasksForUser] = useState<TTaskForUserInfo[]>([]);
  const { i18n } = useLingui();

  const { refetch: fetchTasks } = useQuery(GQL_TASKS_INFO_BY_USER_ID, {
    variables: {
      id: Number(payload?.sub),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTasksForUser(mappedTasksForUserInfo(data.getTasksInfoByUserId));
    },
    onError: (e) => {
      console.log('erreur dans GQL_TASKS_INFO_BY_USER_ID', e);
    },
  });

  async function getTasksByUserId() {
    setLoading(true);
    void fetchTasks();
    setLoading(false);
  }

  useEffect(() => {
    void getTasksByUserId;
  }, []);

  function handleSetTaskOpen(taskId: number) {
    const _tasks = [...tasksForUser];
    const ind = _tasks.findIndex((el) => el.id === taskId);
    if (ind === -1) return;
    _tasks[ind].isOpened = !_tasks[ind].isOpened;
    setTasksForUser(_tasks);
  }

  return (
    <>
      <DefaultLayout>
        {loading ? (
          <p>{i18n._('common.loading')} ...</p>
        ) : (
          <>
            <TasksInfoByUser
              tasks={tasksForUser}
              onTaskOpen={handleSetTaskOpen}
            />
          </>
        )}
      </DefaultLayout>
    </>
  );
}

export default UserTaskPage;
