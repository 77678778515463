import DefaultLayout from '~/pages/layouts/DefaultLayout';

function NotificationPage() {
  return (
    <>
      <DefaultLayout>Notification Page n'est pas encore réalisée</DefaultLayout>
    </>
  );
}

export default NotificationPage;
