import { useLingui } from '@lingui/react';
import {
  ConfigProvider,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  notification,
} from 'antd';
import { FormGroup, SH2, StyledButton } from '~/utils/ui/utils';
import { classic } from '~/themes';
import styled from 'styled-components';
import { CloseOutlined, MailOutlined } from '@ant-design/icons';
import { GQL_ROLES, StoredRoles } from '~/gql/role/role';
import { useMutation, useQuery } from '@apollo/client';
import { authClient } from '~/helpers/apollo';
import { useState } from 'react';
import { useAuth } from '~/helpers/store/auth';
import { GQL_UPDATE_USER, StoredUsers } from '~/gql/users/users';
import { Role } from '~/pages/utils/router';

type IProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: () => void;
  rowSelected: StoredUsers;
};

function UserUpdateModal({ open, setOpen, refresh, rowSelected }: IProps) {
  const [roles, setRoles] = useState<StoredRoles[]>([]);
  const [firstName, setFirstName] = useState<string>(rowSelected.firstName);
  const [lastName, setLastName] = useState<string>(rowSelected.lastName);
  const [roleId, setRoleId] = useState<number>(rowSelected.role.id);
  const [email, setEmail] = useState<string>(rowSelected.email);
  const { payload } = useAuth();
  const [form] = Form.useForm();
  const { i18n } = useLingui();

  useQuery(GQL_ROLES, {
    client: authClient,
    onCompleted: (data) => {
      setRoles(data.getRoles);
    },
  });

  const [updateUser] = useMutation(GQL_UPDATE_USER, {
    client: authClient,
    onCompleted: () => {
      notification.success({
        message: i18n._('update.user'),
        description: i18n._('good.update.user'),
        placement: 'topRight',
      });
      refresh();
      setOpen(false);
    },
    onError: () => {
      notification.error({
        message: i18n._('update.user'),
        description: i18n._('bad.update.user'),
        placement: 'topRight',
      });
      setOpen(false);
    },
  });

  const handleChange = (value: number) => {
    setRoleId(value);
  };

  const onFinish = async () => {
    await updateUser({
      variables: {
        updateUserInput: {
          id: rowSelected.id,
          email,
          firstName,
          lastName,
          roleId,
        },
      },
    });
  };

  return (
    <Modal
      open={open}
      centered
      closeIcon={false}
      style={{ textAlign: 'center' }}
      footer={false}
      onCancel={() => setOpen(false)}
    >
      <SForm
        initialValues={{
          lastname: rowSelected.lastName,
          firstname: rowSelected.firstName,
          email: rowSelected.email,
          roles: rowSelected.role.id,
        }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item>
          <SH2 $color={classic.token?.colorPrimary}>
            {i18n._('update.user')}
          </SH2>
        </Form.Item>

        <Form.Item
          name="email"
          className="customInput"
          rules={[
            {
              required: true,
              message: i18n._('input.required.message'),
            },
            {
              pattern: new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
              message: i18n._('input.email.message'),
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder={i18n._('common.email')}
            value={'raphaelmortier@oulook.fr'}
            onChange={(e) => setEmail(e.target.value)}
            size="large"
            type="text"
          />
        </Form.Item>

        <FormGroup $direction="column" $gap="5px">
          <Form.Item
            name="lastname"
            className="customInput"
            rules={[
              {
                required: true,
                message: i18n._('input.required.message'),
              },
            ]}
          >
            <Input
              placeholder={i18n._('common.lastname')}
              onChange={(e) => setLastName(e.target.value)}
              size="large"
              type="text"
            />
          </Form.Item>

          <Form.Item
            name="firstname"
            className="customInput"
            rules={[
              {
                required: true,
                message: i18n._('input.required.message'),
              },
            ]}
          >
            <Input
              placeholder={i18n._('common.firstname')}
              onChange={(e) => setFirstName(e.target.value)}
              size="large"
              type="text"
            />
          </Form.Item>
        </FormGroup>

        {payload && payload.roles === Role.Admin && (
          <Form.Item
            name="roles"
            className="customInput"
            rules={[
              {
                required: true,
                message: i18n._('input.required.message'),
              },
            ]}
          >
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description={i18n._('no.content')}
                  imageStyle={{ height: 60 }}
                />
              )}
            >
              <Select
                options={roles.map((_role) => ({
                  value: _role.id,
                  label: _role.name,
                }))}
                defaultValue={rowSelected.role.id}
                size="large"
                onChange={handleChange}
              />
            </ConfigProvider>
          </Form.Item>
        )}

        <StyledButton $margin="15px 0 0" type="submit">
          {i18n._('common.update')}
        </StyledButton>
      </SForm>
      <CloseOutlined
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fontSize: '25px',
          cursor: 'pointer',
          color: classic.token?.colorFillSecondary,
        }}
        onClick={() => setOpen(false)}
      />
    </Modal>
  );
}

export default UserUpdateModal;

const SForm = styled(Form)`
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .customInput {
    width: 100%;
    margin-bottom: 10px;
    & span:nth-child(1),
    & input,
    & .ant-select-selector {
      text-align: left;
    }
  }

  & .ant-form-item-explain-error {
    text-align: left;
  }
`;
