import { useLingui } from '@lingui/react';
import { Form } from 'antd';
import styled from 'styled-components';
import { classic } from '~/themes';

import { SH2 } from '~/utils/ui/utils';
import LoginSSO from './LoginSSO';

function Login() {
  const { i18n } = useLingui();

  return (
    <LoginForm>
      <SForm initialValues={{ remember: true }}>
        <Form.Item>
          <SH2 $color={classic.token?.colorPrimary}>
            {i18n._('common.login')}
          </SH2>
        </Form.Item>
        <LoginSSO />
      </SForm>
    </LoginForm>
  );
}

export default Login;

export const LoginForm = styled.div`
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 992px) {
    height: auto;
    width: 100%;
  }
`;

export const SForm = styled(Form)`
  min-width: 25vw;
  background: white;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;

  & .customInput {
    width: 80%;

    & span:nth-child(1),
    & input {
      background-color: rgba(240, 237, 255, 0.8);
    }
  }

  @media (max-width: 992px) {
    & .customInput {
      width: 100%;
    }
  }
`;

export const SButton = styled.button`
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
  &:hover {
    border-color: #646cff;
  }
  &:focus,
  &:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
`;
