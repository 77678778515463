import React from 'react';
import { Route } from 'react-router-dom';
import App from '~/pages/App';
import Files from '~/pages/files/Files';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import CreateAccount from '~/pages/login/CreateAccount';
import Denied from '~/pages/login/Denied';
import Logout from '~/pages/login/Logout';
import NewPassword from '~/pages/login/NewPassword';
import ResetPassword from '~/pages/login/ResetPassword';
import DevisPage from '~/pages/pages/DevisPage';
import EmployeeDetailsPage from '~/pages/pages/EmployeeDetailsPage';
import EmployeesPage from '~/pages/pages/EmployeesPage';
import ManagerInternPage from '~/pages/pages/ManagerInternPage';
import ManagerPage from '~/pages/pages/ManagerPage';
import NotificationPage from '~/pages/pages/NotificationPage';
import PlanningExtPage from '~/pages/pages/PlanningExtPage';
import PlanningPage from '~/pages/pages/PlanningPage';
import ProjectDetailsPage from '~/pages/pages/ProjectDetailsPage';
import ProjectInternDetailsPage from '~/pages/pages/ProjectInternDetailsPage';
import ProjectInternInfoPage from '~/pages/pages/ProjectInternInfoPage';
import ProjectInternRentaPage from '~/pages/pages/ProjectInternRentaPage';
import ProjectPage from '~/pages/pages/ProjectPage';
import ProjectRentaPage from '~/pages/pages/ProjectRentaPage';
import ProjectsInternPage from '~/pages/pages/ProjectsInternPage';
import RetroPlanningPage from '~/pages/pages/RetroPlanningPage';
import SettingsPage from '~/pages/pages/SettingsPage';
import SettingsUserPage from '~/pages/pages/SettingsUserPage';
import SuperProjectInfoPage from '~/pages/pages/SuperProjectInfoPage';
import SuperProjectPage from '~/pages/pages/SuperProjectPage';
import SuperProjectRentaPage from '~/pages/pages/SuperProjectRentaPage';
import TimesPage from '~/pages/pages/TimesPage';
import UserTaskPage from '~/pages/pages/UserTaskPage';
import UserTable from '~/pages/user/UserTable';
import ImAuth from '~/pages/utils/ImAuth';
import NotFound from '~/pages/utils/NotFound';
import Error from './Error';

export enum Role {
  Admin = 'Admin',
  User = 'User',
  Manager = 'Manager',
  Guest = 'Guest',
  Viewer = 'Viewer',
}

type RouteParam = {
  path: string;
  element: React.JSX.Element;
  role: Role[];
};

// Routes à définir
const routes: RouteParam[] = [
  {
    path: '/',
    element: <App />,
    role: [Role.Guest, Role.Admin, Role.Manager, Role.User],
  },
  {
    path: '/manager-projects',
    element: <ManagerPage />,
    role: [Role.Admin, Role.Manager, Role.Viewer],
  },
  {
    path: '/super-projects',
    element: <SuperProjectPage />,
    role: [Role.Admin, Role.Manager, Role.Viewer],
  },
  {
    path: '/super-projects/info/:id',
    element: <SuperProjectInfoPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/super-projects/renta/:id',
    element: <SuperProjectRentaPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/projects/:id',
    element: <ProjectPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-projects/devis/:id',
    element: <DevisPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-projects/planning/:id',
    element: <PlanningPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-projects/planning-ext/:id',
    element: <PlanningExtPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-projects/retro-planning/:id',
    element: <RetroPlanningPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-projects/cross-page/:id',
    element: <ProjectPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-projects/project-details/:id',
    element: <ProjectDetailsPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-projects/project-renta/:id',
    element: <ProjectRentaPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/employees/:id',
    element: <EmployeeDetailsPage />,
    role: [Role.Admin, Role.Manager, Role.Viewer],
  },
  {
    path: '/times',
    element: <TimesPage />,
    role: [Role.Admin, Role.Manager, Role.User, Role.Viewer],
  },
  {
    path: '/tasks',
    element: <UserTaskPage />,
    role: [Role.Admin, Role.Manager, Role.User, Role.Viewer],
  },

  {
    path: '/settings-user',
    element: <SettingsUserPage />,
    role: [Role.Admin, Role.Manager, Role.User, Role.Viewer],
  },
  {
    path: '/notifications',
    element: <NotificationPage />,
    role: [Role.Admin, Role.Manager, Role.User, Role.Viewer],
  },
  {
    path: '/users',
    element: (
      <DefaultLayout>
        <UserTable />
      </DefaultLayout>
    ),
    role: [Role.Admin, Role.Manager, Role.User, Role.Viewer],
  },
  {
    path: '/files',
    element: (
      <DefaultLayout>
        <Files />
      </DefaultLayout>
    ),
    role: [Role.Admin, Role.Manager, Role.User, Role.Viewer],
  },
  {
    path: '/employees',
    element: <EmployeesPage />,
    role: [Role.Admin, Role.Manager, Role.Viewer],
  },
  {
    path: '/settings',
    element: <SettingsPage />,
    role: [Role.Admin, Role.Manager, Role.Viewer],
  },
  {
    path: '/projects-intern',
    element: <ProjectsInternPage />,
    role: [Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-intern',
    element: <ManagerInternPage />,
    role: [Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-intern/info/:id',
    element: <ProjectInternInfoPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },

  {
    path: '/manager-intern/project-details/:id',
    element: <ProjectInternDetailsPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/manager-intern/project-renta/:id',
    element: <ProjectInternRentaPage />,
    role: [Role.Manager, Role.Admin, Role.Viewer],
  },
  {
    path: '/logout',
    element: <Logout />,
    role: [],
  },
  {
    path: '/denied',
    element: <Denied />,
    role: [],
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    role: [],
  },
  {
    path: '/new-password',
    element: <NewPassword />,
    role: [],
  },
  {
    path: '/error',
    element: <Error />,
    role: [],
  },
  {
    path: '*',
    element: <NotFound />,
    role: [],
  },
  {
    path: '/createAccount',
    element: <CreateAccount />,
    role: [Role.Guest, Role.Admin, Role.Manager, Role.User, Role.Viewer],
  },
];

export const renderRoutes = () => {
  return routes.map((r, i) => {
    return (
      <Route
        path={r.path}
        element={
          r.role.length != 0 ? (
            <>
              <ImAuth role={r.role} />
              {r.element}
            </>
          ) : (
            r.element
          )
        }
        key={i}
      />
    );
  });
};
