/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	DateTime: `scalar.DateTime` as const,
	AccountStatus: "enum" as const,
	NotificationState: "enum" as const,
	ProjectType: "enum" as const,
	FunctionType: "enum" as const,
	StateType: "enum" as const,
	DevisType: "enum" as const,
	Query:{
		getRole:{

		},
		getPermission:{

		},
		getConfig:{

		},
		getMail:{

		},
		loginAsGuest:{

		},
		login:{

		},
		uniqueAuthFromSso:{

		},
		getUser:{

		},
		getFilteredUsers:{
			findManyUserWithFiltersInput:"FindManyUserWithFiltersInput"
		},
		downloadFile:{

		},
		getFilteredProjectsInfo:{
			filterProjectInput:"FilterProjectInput"
		},
		getAllProjectsWithTasks:{

		},
		getOneProject:{

		},
		getRentaByProjectId:{

		},
		getFilteredSuperProjectsInfo:{
			filterProjectInput:"FilterSuperProjectInput"
		},
		getOneSuperProject:{

		},
		getRentaBySuperProjectId:{

		},
		getDevisByProjectId:{

		},
		getFunction:{

		},
		isUsersCanBeChangedInTask:{
			createOrUpdateUsersInTaskInput:"CreateOrUpdateUsersInTaskInput"
		},
		getTaskListByProjectId:{

		},
		getTasksInternByProjectId:{

		},
		getTasksInfoByUserId:{

		},
		getPlanningByProjectId:{

		},
		getPlanningForPeriodByUserId:{
			filterUserPlanningInput:"FilterUserPlanningInput"
		},
		getTimesUserPlanningsForPeriodByUserId:{
			filterUserPlanningInput:"FilterUserPlanningInput"
		},
		getAllTimesUserPlanningsForPeriodByProjectId:{
			filterTimeUserPlanningByProjectInput:"FilterTimeUserPlanningByProjectInput"
		},
		getTimesUserPlanningsForPeriodByProjectId:{
			filterTimeUserPlanningByProjectInput:"FilterTimeUserPlanningByProjectInput"
		},
		getTimesForPeriodByUserId:{
			filterTimeInput:"FilterTimeInput"
		},
		getExportUserTimesForPeriodByProjectId:{
			filterTimeUserPlanningByProjectInput:"FilterTimeUserPlanningByProjectInput"
		},
		getHolidaysForPeriod:{
			filterHolidaysInput:"FilterHolidaysInput"
		},
		getPoste:{

		}
	},
	EError: "enum" as const,
	FindManyUserWithFiltersInput:{
		orderBy:"UserOrderByInput",
		filterBy:"UserFilterByInput"
	},
	UserOrderByInput:{
		email:"OrderByEnum",
		firstName:"OrderByEnum",
		lastName:"OrderByEnum",
		lastConnectionAt:"OrderByEnum",
		role:"OrderByEnum"
	},
	OrderByEnum: "enum" as const,
	UserFilterByInput:{

	},
	FilterProjectInput:{
		orderBy:"ProjectOrderByInput",
		projectType:"ProjectType"
	},
	ProjectOrderByInput:{
		name:"OrderByEnum"
	},
	FilterSuperProjectInput:{
		orderBy:"SuperProjectOrderByInput"
	},
	SuperProjectOrderByInput:{
		name:"OrderByEnum"
	},
	CreateOrUpdateUsersInTaskInput:{

	},
	FilterUserPlanningInput:{
		startDate:"DateTime",
		endDate:"DateTime"
	},
	FilterTimeUserPlanningByProjectInput:{
		startDate:"DateTime",
		endDate:"DateTime"
	},
	FilterTimeInput:{
		startDate:"DateTime",
		endDate:"DateTime"
	},
	FilterHolidaysInput:{
		startDate:"DateTime",
		endDate:"DateTime"
	},
	Mutation:{
		askForResetPassword:{

		},
		resetPassword:{

		},
		verifyEmail:{

		},
		register:{

		},
		askForRegister:{
			registerUserInput:"RegisterUserInput"
		},
		finishRegister:{

		},
		finishRegisterAsGuest:{

		},
		createUser:{
			createUserInput:"CreateUserInput"
		},
		updateUser:{
			updateUserInput:"UpdateUserInput"
		},
		deleteUser:{

		},
		deleteFile:{

		},
		uploadFile:{
			file:"Upload"
		},
		createProject:{
			createProjectInput:"CreateProjectInput"
		},
		createProjectIntern:{
			createProjectInput:"CreateProjectInput"
		},
		updateProject:{
			updateProjectInput:"UpdateProjectInput"
		},
		setRetroPlanningDone:{

		},
		setPlanningDone:{

		},
		updateRetroPlanningDone:{

		},
		updatePlanningDone:{

		},
		deleteProject:{

		},
		createSuperProject:{
			createSuperProjectInput:"CreateSuperProjectInput"
		},
		updateSuperProject:{
			updateProjectInput:"UpdateSuperProjectInput"
		},
		deleteSuperProject:{

		},
		createDevisInitial:{
			createDevisInput:"CreateDevisInput"
		},
		createDevisProd:{
			createDevisProdInput:"CreateDevisProdInput"
		},
		updateDevisInitial:{
			updateDevisInput:"UpdateDevisInput"
		},
		updateDevisProd:{
			updateDevisProdInput:"UpdateDevisProdInput"
		},
		updateDevisProdDate:{
			updateDevisProdDateInput:"UpdateDevisProdDateInput"
		},
		updateDevisInitDurationExpected:{
			updateDevisInitDurationExpectedInput:"UpdateDevisInitDurationExpectedInput"
		},
		createDevisInit:{
			createDevisInitInput:"CreateDevisInitInput"
		},
		updateTaskOrder:{
			updateTaskOrderInput:"UpdateTaskOrderInput"
		},
		updateTaskSupp:{
			updateTaskSuppInput:"UpdateTaskSuppInput"
		},
		updateTaskRetro:{
			updateTaskRetroInput:"UpdateTaskRetroInput"
		},
		createOrUpdateUsersInTaskByFunctionType:{
			createOrUpdateUsersByFunctionTypeInput:"CreateOrUpdateUsersByFunctionTypeInput"
		},
		createOrUpdateUsersInTask:{
			createOrUpdateUsersInTaskInput:"CreateOrUpdateUsersInTaskInput"
		},
		createOrUpdateUsersInTaskIntern:{
			createOrUpdateUsersInTaskInput:"CreateOrUpdateUsersInTaskInput"
		},
		createTaskSupp:{
			createTaskSuppInput:"CreateTaskSuppInput"
		},
		createTaskIntern:{
			createTaskInternInput:"CreateTaskInternInput"
		},
		deleteTask:{

		},
		deleteTaskIntern:{

		},
		createOrUpdateTaskPlanning:{
			updateTaskPlanningInput:"UpdateTaskPlanningInput"
		},
		createUserPlanning:{
			createUserPlanningInput:"CreateUserPlanningInput"
		},
		deleteUserPlanning:{

		},
		createOrUpdateTime:{
			createOrUpdateTimeInput:"CreateOrUpdateTimeInput"
		},
		deleteTime:{

		},
		createHolidays:{
			createHolidaysInput:"CreateHolidaysInput"
		},
		deleteHolidays:{

		},
		updateHolidaysForPeriod:{
			updateHolidaysInput:"UpdateHolidaysInput"
		}
	},
	RegisterUserInput:{

	},
	CreateUserInput:{

	},
	UpdateUserInput:{

	},
	Upload: `scalar.Upload` as const,
	CreateProjectInput:{
		projectType:"ProjectType"
	},
	UpdateProjectInput:{
		projectType:"ProjectType"
	},
	CreateSuperProjectInput:{

	},
	UpdateSuperProjectInput:{

	},
	CreateDevisInput:{
		type:"DevisType",
		signedDate:"DateTime",
		createDevisInitInput:"CreateDevisInitInput"
	},
	CreateDevisInitInput:{
		functionType:"FunctionType"
	},
	CreateDevisProdInput:{
		type:"DevisType",
		signedDate:"DateTime",
		finishDate:"DateTime",
		createTaskInput:"CreateTaskInput"
	},
	CreateTaskInput:{
		functionType:"FunctionType"
	},
	UpdateDevisInput:{
		signedDate:"DateTime",
		createDevisInitInput:"CreateDevisInitInput"
	},
	UpdateDevisProdInput:{
		signedDate:"DateTime",
		finishDate:"DateTime",
		updateTaskInput:"UpdateTaskInput"
	},
	UpdateTaskInput:{
		functionType:"FunctionType",
		stateType:"StateType"
	},
	UpdateDevisProdDateInput:{
		signedDate:"DateTime",
		finishDate:"DateTime"
	},
	UpdateDevisInitDurationExpectedInput:{

	},
	UpdateTaskOrderInput:{

	},
	UpdateTaskSuppInput:{
		functionType:"FunctionType"
	},
	UpdateTaskRetroInput:{

	},
	CreateOrUpdateUsersByFunctionTypeInput:{
		usersByFunctionType:"UsersByFunctionTypeInput"
	},
	UsersByFunctionTypeInput:{
		functionType:"FunctionType"
	},
	CreateTaskSuppInput:{
		functionType:"FunctionType"
	},
	CreateTaskInternInput:{

	},
	UpdateTaskPlanningInput:{
		dates:"UpdateDatePlanningInput"
	},
	UpdateDatePlanningInput:{
		date:"DateTime"
	},
	CreateUserPlanningInput:{
		date:"DateTime"
	},
	CreateOrUpdateTimeInput:{
		date:"DateTime"
	},
	CreateHolidaysInput:{
		date:"DateTime"
	},
	UpdateHolidaysInput:{
		dates:"DateTime",
		startDate:"DateTime",
		endDate:"DateTime"
	}
}

export const ReturnTypes: Record<string,any> = {
	Health:{
		env:"String",
		time:"String",
		status:"String"
	},
	Permissions:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		roles:"Roles"
	},
	DateTime: `scalar.DateTime` as const,
	Users:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		status:"AccountStatus",
		email:"String",
		password:"String",
		passwordResetToken:"String",
		passwordResetExpiryDate:"DateTime",
		activateAccountToken:"String",
		activateAccountExpiryDate:"DateTime",
		emailVerified:"Boolean",
		verifyEmailToken:"String",
		verifyEmailExpiryDate:"DateTime",
		firstName:"String",
		lastName:"String",
		refreshToken:"String",
		role:"Roles",
		roleId:"Int"
	},
	UserInfoForDevis:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		status:"AccountStatus",
		email:"String",
		password:"String",
		passwordResetToken:"String",
		passwordResetExpiryDate:"DateTime",
		activateAccountToken:"String",
		activateAccountExpiryDate:"DateTime",
		emailVerified:"Boolean",
		verifyEmailToken:"String",
		verifyEmailExpiryDate:"DateTime",
		firstName:"String",
		lastName:"String",
		refreshToken:"String",
		role:"Roles",
		roleId:"Int",
		hasTimes:"Boolean"
	},
	UserPagination:{
		users:"Users",
		total:"Int"
	},
	UserInfo:{
		id:"Int",
		email:"String",
		firstName:"String",
		lastName:"String",
		roleId:"Int",
		roleName:"String"
	},
	Roles:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		permissions:"Permissions",
		users:"Users"
	},
	UniqueAuth:{
		access_token:"String",
		firstName:"String",
		lastName:"String",
		role:"String",
		email:"String",
		sub:"Int"
	},
	Configs:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		key:"String",
		value:"String"
	},
	Poste:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String"
	},
	Notification:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		comment:"String",
		state:"NotificationState",
		user:"Users",
		task:"Task"
	},
	Project:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		projectType:"ProjectType",
		projectManager:"Users",
		projectManagerId:"Int",
		startDateInit:"DateTime",
		startDatePlanning:"DateTime",
		marginPercent:"Int",
		retroPlanningDone:"Boolean",
		planningDone:"Boolean",
		superProject:"Project",
		superProjectId:"Int"
	},
	ProjectInfo:{
		id:"Int",
		name:"String",
		projectType:"ProjectType",
		projectManagerId:"Int",
		startDateInit:"DateTime",
		startDatePlanning:"DateTime",
		marginPercent:"Int",
		retroPlanningDone:"Boolean",
		planningDone:"Boolean",
		superProjectId:"Int",
		projectManagerName:"String"
	},
	ProjectInfoPagination:{
		projects:"ProjectInfo",
		total:"Int"
	},
	ProjectFullInfo:{
		id:"Int",
		name:"String",
		projectType:"ProjectType",
		projectManagerId:"Int",
		startDateInit:"DateTime",
		startDatePlanning:"DateTime",
		marginPercent:"Int",
		retroPlanningDone:"Boolean",
		planningDone:"Boolean",
		superProjectId:"Int",
		projectManager:"UserInfo"
	},
	ProjectShortInfo:{
		id:"Int",
		name:"String",
		projectType:"ProjectType",
		superProjectId:"Int",
		tasks:"TaskShortInfo"
	},
	SuppRentaInfo:{
		amountByTasks:"Float",
		amountSpendByTasks:"Float",
		durationByTasks:"Int",
		timesByTasks:"Int"
	},
	ProjectRentaInfoBase:{
		projectId:"Int",
		name:"String",
		rentaPercent:"Float",
		projectDurationSold:"Int",
		projectAmountSold:"Float",
		projectDuration:"Int",
		projectDeltaDurationProd:"Int",
		projectDeltaAmountProd:"Float",
		projectSommeTime:"Int",
		projectSommeAmount:"Float",
		projectSommeAmountSpend:"Float",
		projectDurationUsed:"Int",
		projectAmountUsed:"Float",
		projectDeltaDurationSold:"Int",
		projectDeltaSommeSold:"Float"
	},
	ProjectRentaInfo:{
		id:"Int",
		name:"String",
		projectType:"ProjectType",
		projectManager:"Users",
		superProjectId:"Int",
		renta:"ProjectRentaInfoBase",
		tasks:"TaskRentaInfo",
		devisInit:"DevisInitRentaInfo",
		supp:"SuppRentaInfo"
	},
	ProjectInfoForSuperProject:{
		id:"Int",
		name:"String"
	},
	TaskPlanning:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		task:"Task",
		taskId:"Int",
		date:"DateTime",
		duration:"Int"
	},
	TaskPlanningInfo:{
		id:"Int",
		taskId:"Int",
		date:"DateTime",
		duration:"Int"
	},
	TimeForUserInfo:{
		date:"DateTime",
		duration:"Int",
		comment:"String"
	},
	Time:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		comment:"String",
		date:"DateTime",
		duration:"Int",
		user:"Users",
		userId:"Int",
		task:"Task",
		taskId:"Int"
	},
	TimeInfo:{
		id:"Int",
		comment:"String",
		date:"DateTime",
		duration:"Int",
		user:"Users",
		userId:"Int",
		taskId:"Int",
		task:"TaskInfoForDevis"
	},
	TimeShortInfo:{
		id:"Int",
		comment:"String",
		date:"DateTime",
		duration:"Int",
		user:"Users",
		userId:"Int",
		taskId:"Int",
		task:"TaskInfoForDevis"
	},
	TimeRentaInfo:{
		id:"Int",
		comment:"String",
		date:"DateTime",
		duration:"Int",
		user:"Users",
		userId:"Int",
		taskId:"Int"
	},
	UserTime:{
		user:"Users",
		duration:"Int"
	},
	DateUserTime:{
		date:"DateTime",
		totalTimeByDate:"Int",
		userTime:"UserTime"
	},
	ProjectUserTimesInfo:{
		projectId:"Int",
		projectName:"String",
		projectManager:"Users",
		calendar:"DateUserTime"
	},
	DatePlanningTimes:{
		label:"String",
		date:"DateTime",
		selectible:"Boolean",
		planningDuration:"Int",
		time:"Int",
		timeId:"Int",
		timeComment:"String"
	},
	UserPlanningForUserInfo:{
		date:"DateTime",
		duration:"Int"
	},
	UserPlanningTimeForUserInfo:{
		date:"DateTime",
		userPlanningDuration:"Int",
		timeDuration:"Int"
	},
	UserPlanningInfo:{
		id:"Int",
		taskPlanningId:"Int",
		user:"Users",
		userId:"Int",
		date:"DateTime",
		duration:"Int",
		task:"TaskInfoForDevis"
	},
	TimeUserPlanningInfo:{
		userPlanning:"UserPlanningInfo",
		time:"TimeInfo"
	},
	UserTimePlanningInfo:{
		userId:"Int",
		userFirstName:"String",
		userLastName:"String",
		userPlanning:"UserPlanningShortInfo",
		time:"TimeShortInfo"
	},
	UserPlanningShortInfo:{
		id:"Int",
		taskPlanningId:"Int",
		user:"Users",
		userId:"Int",
		date:"DateTime",
		duration:"Int",
		task:"TaskInfoForDevis"
	},
	Calendar:{
		id:"Int",
		date:"DateTime",
		label:"String",
		selectible:"Boolean",
		retroChecked:"Boolean",
		checked:"Boolean",
		duration:"Int"
	},
	Task:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		functionType:"FunctionType",
		amount:"Float",
		amountSpend:"Float",
		duration:"Int",
		startDateInit:"Int",
		durationDays:"Int",
		durationClientDays:"Int",
		visibleInRetro:"Boolean",
		calculatedAsAmount:"Boolean",
		supp:"Boolean",
		stateType:"StateType",
		statePercent:"Int",
		devis:"Devis",
		devisId:"Int",
		devisInit:"DevisInit",
		devisInitId:"Int",
		project:"Project",
		projectId:"Int",
		order:"Int",
		user:"Users",
		notifications:"Notification",
		time:"Time",
		taskPlanning:"TaskPlanning"
	},
	TaskInfoForDevis:{
		id:"Int",
		name:"String",
		functionType:"FunctionType",
		amount:"Float",
		amountSpend:"Float",
		duration:"Int",
		startDateInit:"Int",
		durationDays:"Int",
		durationClientDays:"Int",
		visibleInRetro:"Boolean",
		calculatedAsAmount:"Boolean",
		supp:"Boolean",
		devisId:"Int",
		devisInitId:"Int",
		projectId:"Int",
		order:"Int",
		taskPlanning:"TaskPlanningInfo",
		posteId:"Int",
		projectType:"ProjectType",
		projectName:"String",
		user:"UserInfoForDevis"
	},
	TaskInfoForPlanning:{
		id:"Int",
		name:"String",
		functionType:"FunctionType",
		amount:"Float",
		amountSpend:"Float",
		duration:"Int",
		startDateInit:"Int",
		durationDays:"Int",
		durationClientDays:"Int",
		visibleInRetro:"Boolean",
		calculatedAsAmount:"Boolean",
		supp:"Boolean",
		devisId:"Int",
		devisInitId:"Int",
		projectId:"Int",
		order:"Int",
		taskPlanning:"TaskPlanningInfo",
		posteId:"Int",
		projectType:"ProjectType",
		projectName:"String",
		user:"UserInfoForDevis",
		calendar:"Calendar"
	},
	Planning:{
		calendar:"Calendar",
		tasks:"TaskInfoForPlanning",
		tasksSupp:"TaskInfoForPlanning"
	},
	TaskShortInfo:{
		id:"Int",
		name:"String",
		functionType:"FunctionType",
		amountSpend:"Float",
		visibleInRetro:"Boolean",
		calculatedAsAmount:"Boolean",
		supp:"Boolean",
		devisId:"Int",
		devisInitId:"Int",
		projectId:"Int",
		order:"Int",
		user:"UserInfoForDevis"
	},
	UserRentaInfo:{
		userId:"Int",
		userFirstName:"String",
		userLastName:"String",
		planningDuration:"Int",
		sommeTime:"Int",
		deltaDuration:"Int"
	},
	TaskRentaInfo:{
		id:"Int",
		name:"String",
		amount:"Float",
		amountSpend:"Float",
		duration:"Int",
		visibleInRetro:"Boolean",
		calculatedAsAmount:"Boolean",
		supp:"Boolean",
		projectId:"Int",
		order:"Int",
		deltaDuration:"Int",
		sommeTime:"Int",
		time:"TimeRentaInfo",
		userRentaInfo:"UserRentaInfo"
	},
	TaskForUserInfo:{
		id:"Int",
		name:"String",
		order:"Int",
		projectName:"String",
		projectType:"ProjectType",
		projectManagerFirstName:"String",
		projectManagerLastName:"String",
		allUserPlanning:"Int",
		userPlanning:"UserPlanningForUserInfo",
		allTime:"Int",
		time:"TimeForUserInfo",
		timeAndUserPlanning:"UserPlanningTimeForUserInfo"
	},
	DevisInit:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		functionType:"FunctionType",
		amount:"Float",
		duration:"Int",
		order:"Int",
		durationExpected:"Int",
		durationUsed:"Int",
		amountUsed:"Float",
		devis:"Devis",
		devisId:"Int",
		poste:"Poste",
		posteId:"Int",
		customPosteName:"String"
	},
	DevisInitInfo:{
		id:"Int",
		functionType:"FunctionType",
		amount:"Float",
		duration:"Int",
		order:"Int",
		durationExpected:"Int",
		durationUsed:"Int",
		amountUsed:"Float",
		devisId:"Int",
		poste:"Poste",
		posteId:"Int",
		customPosteName:"String"
	},
	DevisInitRentaInfo:{
		id:"Int",
		functionType:"FunctionType",
		amount:"Float",
		duration:"Int",
		durationExpected:"Int",
		durationUsed:"Int",
		amountUsed:"Float",
		customPosteName:"String",
		durationByTasks:"Int",
		timesByTasks:"Int",
		amountByTasks:"Float",
		amountSpendByTasks:"Float",
		deltaDurationProd:"Int",
		deltaAmountProd:"Float",
		deltaDurationSold:"Int",
		deltaSommeSold:"Float",
		poste:"Poste",
		tasks:"TaskRentaInfo"
	},
	Devis:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		type:"DevisType",
		project:"Project",
		projectId:"Int",
		signedDate:"DateTime",
		finishDate:"DateTime",
		tasks:"Task",
		devisInit:"DevisInit"
	},
	DevisForProjectInit:{
		id:"Int",
		type:"DevisType",
		signedDate:"DateTime",
		finishDate:"DateTime",
		project:"ProjectInfo",
		devisInit:"DevisInitInfo"
	},
	DevisForProjectProd:{
		id:"Int",
		type:"DevisType",
		signedDate:"DateTime",
		finishDate:"DateTime",
		project:"ProjectInfo",
		tasks:"TaskInfoForDevis"
	},
	DevisForProject:{
		devisInit:"DevisForProjectInit",
		devisProd:"DevisForProjectProd"
	},
	DownloadFile:{
		fileName:"String",
		fileMimeType:"String",
		buffer:"String"
	},
	MyFiles:{
		eTag:"String",
		location:"String",
		fileName:"String",
		fileMimeType:"String",
		createdAt:"DateTime",
		ownerId:"Int"
	},
	Function:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"FunctionType",
		order:"Int"
	},
	Holidays:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		date:"DateTime"
	},
	Mail:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		key:"String",
		to:"String",
		data:"String"
	},
	SuperProject:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		projects:"ProjectInfoForSuperProject"
	},
	SuperProjectInfoPagination:{
		projects:"SuperProject",
		total:"Int"
	},
	SuperProjectRentaDetailsInfo:{
		id:"Int",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		projects:"ProjectInfoForSuperProject",
		renta:"ProjectRentaInfoBase",
		projectsRenta:"ProjectRentaInfo"
	},
	Query:{
		getHealthGql:"Health",
		getRole:"Roles",
		getRoles:"Roles",
		getPermission:"Permissions",
		getPermissions:"Permissions",
		getConfig:"Configs",
		getConfigs:"Configs",
		getMail:"Mail",
		getMails:"Mail",
		sendMail:"Boolean",
		loginAsGuest:"AuthType",
		login:"AuthType",
		uniqueAuthFromSso:"UniqueAuth",
		getUser:"Users",
		getUsers:"Users",
		getFilteredUsers:"UserPagination",
		downloadFile:"DownloadFile",
		getAllMyFiles:"MyFiles",
		getFilteredProjectsInfo:"ProjectInfoPagination",
		getAllProjectByManager:"ProjectInfoForSuperProject",
		getAllProjectsWithTasks:"ProjectShortInfo",
		getOneProject:"ProjectFullInfo",
		getRentaByProjectId:"ProjectRentaInfo",
		getFilteredSuperProjectsInfo:"SuperProjectInfoPagination",
		getOneSuperProject:"SuperProject",
		getRentaBySuperProjectId:"SuperProjectRentaDetailsInfo",
		getDevisByProjectId:"DevisForProject",
		getFunction:"Function",
		getFunctions:"Function",
		isUsersCanBeChangedInTask:"DevisForProjectProd",
		getTaskListByProjectId:"TaskShortInfo",
		getTasksInternByProjectId:"TaskShortInfo",
		getTasksInfoByUserId:"TaskForUserInfo",
		getPlanningByProjectId:"Planning",
		getPlanningForPeriodByUserId:"UserPlanningInfo",
		getTimesUserPlanningsForPeriodByUserId:"TimeUserPlanningInfo",
		getAllTimesUserPlanningsForPeriodByProjectId:"TimeUserPlanningInfo",
		getTimesUserPlanningsForPeriodByProjectId:"UserTimePlanningInfo",
		getTimesForPeriodByUserId:"TimeInfo",
		getExportUserTimesForPeriodByProjectId:"ProjectUserTimesInfo",
		getHolidaysForPeriod:"Holidays",
		getAllHolidays:"Holidays",
		getPoste:"Poste",
		getPostes:"Poste"
	},
	AuthType:{
		"...on Tokens":"Tokens",
		"...on CustomError":"CustomError"
	},
	Tokens:{
		access_token:"String",
		refresh_token:"String"
	},
	CustomError:{
		error:"EError",
		message:"String"
	},
	Mutation:{
		askForResetPassword:"Boolean",
		resetPassword:"Boolean",
		verifyEmail:"Boolean",
		register:"AuthType",
		askForRegister:"Boolean",
		finishRegister:"Boolean",
		finishRegisterAsGuest:"Boolean",
		refresh:"AuthType",
		logout:"Boolean",
		createUser:"Users",
		updateUser:"Users",
		deleteUser:"Boolean",
		deleteFile:"Boolean",
		uploadFile:"Boolean",
		createProject:"Boolean",
		createProjectIntern:"Boolean",
		updateProject:"Boolean",
		setRetroPlanningDone:"Boolean",
		setPlanningDone:"Boolean",
		updateRetroPlanningDone:"Boolean",
		updatePlanningDone:"Boolean",
		deleteProject:"Boolean",
		createSuperProject:"Boolean",
		updateSuperProject:"Boolean",
		deleteSuperProject:"Boolean",
		createDevisInitial:"Boolean",
		createDevisProd:"Boolean",
		updateDevisInitial:"Boolean",
		updateDevisProd:"Boolean",
		updateDevisProdDate:"Boolean",
		updateDevisInitDurationExpected:"Boolean",
		createDevisInit:"Boolean",
		updateTaskOrder:"DevisForProjectProd",
		updateTaskSupp:"DevisForProjectProd",
		updateTaskRetro:"DevisForProjectProd",
		createOrUpdateUsersInTaskByFunctionType:"DevisForProjectProd",
		createOrUpdateUsersInTask:"DevisForProjectProd",
		createOrUpdateUsersInTaskIntern:"Boolean",
		createTaskSupp:"DevisForProjectProd",
		createTaskIntern:"Boolean",
		deleteTask:"DevisForProjectProd",
		deleteTaskIntern:"Boolean",
		createOrUpdateTaskPlanning:"DevisForProjectProd",
		createUserPlanning:"DevisForProjectProd",
		deleteUserPlanning:"Boolean",
		createOrUpdateTime:"Boolean",
		deleteTime:"Boolean",
		createHolidays:"Boolean",
		deleteHolidays:"Boolean",
		updateHolidaysForPeriod:"Holidays"
	},
	Upload: `scalar.Upload` as const
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}