function format(headers: string[], data: (number | string)[][]) {
  const content = `${headers.join(';')}\n${data
    .map((row) => row.join(';'))
    .join('\n')}`;

  return new Blob(['\uFEFF', content], { type: 'text/csv;charset=utf-8' });
}

export function downloadCSV(
  fileName: string,
  headers: string[],
  data: (number | string)[][]
) {
  const blob = format(headers, data);
  const fileURL = window.URL.createObjectURL(blob);

  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = fileName + '.csv';
  alink.click();
}
