import { useLingui } from '@lingui/react';
import { Tooltip } from 'antd';
import { styled } from 'styled-components';
import { classic } from '~/themes/classic';

type IProps = {
  disabled?: boolean;
  onClick?: () => void;
};

export default function ButtonAddTask({ onClick, disabled }: IProps) {
  const { i18n } = useLingui();
  return (
    <Container onClick={onClick}>
      <Tooltip title={i18n._('task.add.advice')} placement="left">
        <SContent disabled={disabled}></SContent>
      </Tooltip>
    </Container>
  );
}

const Container = styled.div`
  height: 30px;
  cursor: pointer;
  left: -10px;
  bottom: -10px;
  position: absolute;
  width: 30px;
`;

const SContent = styled.button<{ $disabled?: boolean }>`
  background-color: ${classic.token?.colorPrimary};
  clip-path: polygon(0 0%, 0% 100%, 100% 50%);
  cursor: pointer;
  height: 100%;
  position: relative;
  width: 100%;
`;

/*
 <SDivTypeItem hidden={!openDialog}>
            <SItem>MySG</SItem>
            <SItem>Client</SItem>
          </SDivTypeItem>
*/
// const SDivTypeItem = styled.div<{ hidden?: boolean }>`
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   left: 30px;
//   bottom: 0;
//   width: ${({ hidden }) => (hidden ? '0' : '60px')};
//   opacity: ${({ hidden }) => (hidden ? '0' : '1')};
//   visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
//   border: 1px solid red;
// `;

// const SItem = styled.div`
//   cursor: pointer;
//   width: 100%;
// `;
