import { useLingui } from '@lingui/react';
import { StoredSuppRentaInfo } from '~/gql/project/project';

import {
  SDivRentaItem,
  SRentaSpan,
  SRentaTable,
  SRentaTd,
  SRentaTr,
} from '~/pages/components/styles/styles';

import { getHoursAndMinutesStrByDurationForTimes } from '~/utils/utils/utils';

type IProps = {
  suppRentaInfo: StoredSuppRentaInfo;
};

function SuppRentaDetails({ suppRentaInfo }: IProps) {
  const { i18n } = useLingui();
  return (
    <>
      {(suppRentaInfo.amountSpendByTasks > 0 ||
        suppRentaInfo.timesByTasks > 0) && (
        <>
          <SRentaTable width="57.5%">
            <tbody>
              <SRentaTr $firstBorderTop>
                <SRentaTd
                  $borderLeft
                  $align="left"
                  $borderRight
                  $width="46.875%"
                >
                  {i18n._('common.tasks.supp')}
                </SRentaTd>

                <SRentaTd $bold $align="right" $width="10.625%">
                  <SDivRentaItem>
                    <SRentaSpan color="#ff7700">
                      {getHoursAndMinutesStrByDurationForTimes(
                        suppRentaInfo.timesByTasks
                      ) || '-'}
                    </SRentaSpan>

                    {suppRentaInfo.amountSpendByTasks !== 0 && (
                      <SRentaSpan color="#ff7700">
                        {suppRentaInfo.amountSpendByTasks
                          ? `${suppRentaInfo.amountSpendByTasks.toFixed(2)} €`
                          : ''}
                      </SRentaSpan>
                    )}
                  </SDivRentaItem>
                </SRentaTd>
              </SRentaTr>
            </tbody>
          </SRentaTable>
        </>
      )}
    </>
  );
}

export default SuppRentaDetails;
