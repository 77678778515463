import { useLingui } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import type { CollapseProps } from 'antd';
import {
  Collapse,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from 'antd';

import type { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { StoredDevitInit, StoredDevitProd } from '~/gql/devis/devis';
import { StoredFunction } from '~/gql/function/function';
import { StoredPoste } from '~/gql/poste/poste';
import useDataUserStore from '~/helpers/store/data-user/data-user';

import useCalculetedDevisInit from '~/pages/components/common/utils-table/hooks/useCalculetedDevisInit';
import {
  SDevisTable,
  SDevisTableHead,
  SDevisTd,
  SDevisTh,
} from '~/pages/components/styles/styles';

import DurationItem from '~/pages/components/ui/DurationItem';
import TasksGrouped from '~/pages/components/ui/TasksGrouped';

import {
  DevisProdRow,
  TPosteOption,
  TTaskInDevisTemplate,
} from '~/pages/types/types';
import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  postes: StoredPoste[];
  devisInit: StoredDevitInit | null;
  devisProd: StoredDevitProd | null;
  functionTypes: StoredFunction[];
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

function ViewDevisProdP({
  functionTypes,
  devisInit,
  devisProd,
  postes,
}: IProps) {
  const { i18n } = useLingui();
  const { Text } = Typography;
  const formRef = React.useRef<FormInstance>(null);
  const { currentProject } = useDataUserStore();
  const [tasksRows, setTasksRows] = useState<DevisProdRow[]>([]);
  const [devisRowsWithTasks, setDevisRowsWithTasks] = useState<
    TTaskInDevisTemplate[]
  >([]);

  const [postesByDevis, setPostesByDevis] = useState<TPosteOption[]>([]);
  const { devisInitDurationExpected, devisInitAmount } =
    useCalculetedDevisInit(devisInit);

  function getDevisRowsWithTasks(
    prodRows: DevisProdRow[]
  ): TTaskInDevisTemplate[] {
    let result: TTaskInDevisTemplate[] = [];
    if (devisInit?.devisInit) {
      result = devisInit.devisInit.map((dI) => {
        const newResultRow: TTaskInDevisTemplate = {
          devisInit: {
            id: dI.id,
            amount: dI.amount,
            duration: dI.duration,
            durationExpected: dI.durationExpected,
            posteName: postes.find((p) => p.id === dI.posteId)?.name ?? '',
            posteId: dI.posteId,
            functionType: dI.functionType,
            functionTypeId: functionTypes.find(
              (fT) => fT.name === dI.functionType
            )?.id,
          },
          tasks: [],
        };
        return newResultRow;
      });
    }

    const oldProdRows = [...prodRows];
    const tasksWithoutPoste: DevisProdRow[] = [];
    for (const row of oldProdRows) {
      if (row.devisInitId) {
        const indInResult = result.findIndex(
          (r) => r.devisInit && r.devisInit.id === row.devisInitId
        );
        if (indInResult !== -1) {
          if (result[indInResult]) {
            result[indInResult].tasks.push({
              id: row.id,
              devisInitId: row.devisInitId,
              taskName: row.taskName,
              duration: row.duration,
              functionType: row.functionType,
              durationDays: row.durationDays,
              durationClientDays: row.durationClientDays,
              visibleInRetro: row.visibleInRetro,
              supp: row.supp,
              calculatedAsAmount: row.calculatedAsAmount,
              posteId: row.posteId,
              amount: row.amount,
              amountSpend: row.amountSpend,
              order: row.order,
            });
          }
        }
      } else {
        tasksWithoutPoste.push({
          id: row.id,
          taskName: row.taskName,
          duration: row.duration,
          durationDays: row.durationDays,
          functionType: row.functionType,
          visibleInRetro: row.visibleInRetro,
          supp: row.supp,
          calculatedAsAmount: row.calculatedAsAmount,
          durationClientDays: row.durationClientDays,
          devisInitId: undefined,
          amount: row.amount,
          amountSpend: row.amountSpend,
          order: row.order,
        });
      }
    }
    if (tasksWithoutPoste.length > 0) {
      result.push({
        tasks: tasksWithoutPoste.filter((el) => !el.supp).sort(compareByOrder),
      });
    }

    return result;
  }

  useEffect(() => {
    //init 1
    let _postes: TPosteOption[] = [];
    if (devisInit?.devisInit) {
      _postes = devisInit.devisInit.map((el, index) => {
        const _item: TPosteOption = {
          id: index,
          posteId: el.posteId,
          label: postes.find((p) => p.id === el.posteId)?.name ?? '',
          functionType: el.functionType,
          devisInitId: el.id,
          amount: el.amount,
        };
        return _item;
      });

      setPostesByDevis(_postes);
    }

    if (!devisProd) return;
    let mappedTasks: DevisProdRow[] = [];

    if (devisProd.tasks) {
      mappedTasks = devisProd.tasks.map((t) => {
        const newEl: DevisProdRow = {
          id: t.id,
          taskName: t.name,
          amount: t.amount,
          amountSpend: t.amountSpend,
          order: t.order,
          duration: t.duration,
          devisInitId: t.devisInitId,
          functionType: t.functionType,
          durationDays: t.durationDays,
          durationClientDays: t.durationClientDays,
          visibleInRetro: t.visibleInRetro,
          supp: t.supp,
          calculatedAsAmount: t.calculatedAsAmount,
          posteId:
            t.posteId ??
            _postes.find((p) => p.functionType === t.functionType)?.posteId,
        };
        return newEl;
      });
      setTasksRows(mappedTasks.filter((el) => !el.supp).sort(compareByOrder));

      setDevisRowsWithTasks(getDevisRowsWithTasks(mappedTasks));
    }
  }, [devisInit, devisProd]);

  if (!devisInit) return null;

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: i18n._('task.groupped.type'),
      children: (
        <>
          {devisRowsWithTasks.map((el, index) => (
            <TasksGrouped
              disabled
              functionTypes={functionTypes}
              postesByDevis={postesByDevis}
              key={index}
              withoutAdd
              tasks={el.tasks}
              row={el.devisInit}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <Container>
      <Form
        {...layout}
        ref={formRef}
        name="edit-devis-prod-templ"
        initialValues={{
          dateSigned: devisProd?.signedDate
            ? dayjs(devisProd.signedDate as string)
            : null,
          dateFinish: devisProd?.finishDate
            ? dayjs(devisProd.finishDate as string)
            : null,
          marge: currentProject?.marginPercent ?? 0,
        }}
        style={{ width: '100%' }}
      >
        <SDiv>
          <Form.Item
            name="dateSigned"
            style={{ width: '100%', margin: '10px 10px' }}
            label={i18n._('form.date.prod')}
          >
            <DatePicker disabled format={dateFormat} />
          </Form.Item>
          <Form.Item
            name="dateFinish"
            style={{ width: '100%', margin: '10px 10px' }}
            label={i18n._('form.date.prod.end')}
          >
            <DatePicker disabled format={dateFormat} />
          </Form.Item>
        </SDiv>

        <SDiv>
          <Form.Item
            name="marge"
            style={{ minWidth: '350px', margin: '10px 10px' }}
            label={i18n._('form.marge.expected')}
          >
            <InputNumber
              style={{ width: '100px', marginRight: '10px' }}
              placeholder={i18n._('form.marge.placeholder')}
              controls={false}
              min={0}
              disabled
              addonAfter="%"
            />
          </Form.Item>
          <Text style={{ margin: '10px' }}>{i18n._('form.marge.prod')}</Text>
          <InputNumber
            style={{
              width: '100px',
              marginRight: '10px',
              borderRadius: `${devisInitDurationExpected === 0 ? '6px ' : 'none'}`,
              border: `${devisInitDurationExpected === 0 ? '1px solid #dd4848' : 'none'}`,
            }}
            controls={false}
            min={0}
            disabled
            status={devisInitDurationExpected === 0 ? 'warning' : ''}
            value={
              devisInitDurationExpected
                ? 100 -
                  Math.round(
                    (tasksRows
                      .filter((t) => t.functionType !== FunctionType.CLIENT)
                      .reduce(function (acc, obj) {
                        return acc + obj.duration;
                      }, 0) *
                      100) /
                      devisInitDurationExpected
                  )
                : null
            }
            addonAfter="%"
          />
        </SDiv>

        <SDivTasks>
          <SDevisTable>
            <SDevisTableHead>
              <tr>
                <SDevisTh colSpan={3} $minWidth="420px">
                  <SDivDetails>
                    Total
                    <InputNumber
                      style={{
                        width: '150px',
                        margin: '5px',
                        fontWeight: 'bold',
                      }}
                      placeholder={i18n._('form.amount.placeholder')}
                      value={devisInitAmount}
                      controls={false}
                      step="0.01"
                      disabled={true}
                      addonAfter="€"
                    />
                    <DurationItem
                      $width="120px"
                      $bold
                      duration={devisInitDurationExpected}
                    />
                  </SDivDetails>
                </SDevisTh>

                <SDevisTh $minWidth="120px" $padding="0">
                  <SDivDurationHeader>
                    <DurationItem
                      $width="100%"
                      $bold
                      duration={tasksRows
                        .filter((t) => t.functionType !== FunctionType.CLIENT)
                        .reduce(function (acc, obj) {
                          return acc + obj.duration;
                        }, 0)}
                    />
                  </SDivDurationHeader>
                </SDevisTh>
                <SDevisTh>In retro ?</SDevisTh>
                <SDevisTh $textAlign="center">somme?</SDevisTh>
                <SDevisTh $minWidth="100px">
                  {i18n._('common.amount.expected')}
                </SDevisTh>
                <SDevisTh $minWidth="100px">
                  {i18n._('common.amount.spend')}
                </SDevisTh>
              </tr>
            </SDevisTableHead>
            <tbody>
              {tasksRows.map((task, index) => (
                <tr key={index}>
                  <SDevisTd
                    $minHeight="34px"
                    $height="34px"
                    $textAlign="center"
                  >
                    <Input
                      placeholder={i18n._('form.task-name.placeholder')}
                      value={task.taskName}
                      disabled
                    />
                  </SDevisTd>
                  <SDevisTd>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: '100%' }}
                      disabled
                      value={task.devisInitId}
                      status={
                        !task.posteId &&
                        task.functionType !== FunctionType.CLIENT
                          ? 'warning'
                          : ''
                      }
                      optionFilterProp="label"
                      options={postesByDevis.map((item) => ({
                        value: item.devisInitId,
                        label: `${i18n._(item.label)} ${item.amount}`,
                      }))}
                    />
                  </SDevisTd>
                  <SDevisTd>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      value={
                        functionTypes.find(
                          (_el) => _el.name === task.functionType
                        )?.id
                      }
                      disabled
                      optionFilterProp="label"
                      options={functionTypes.map((item) => ({
                        value: item.id,
                        label: i18n._(item.name),
                      }))}
                    />
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    {task.functionType === FunctionType.CLIENT ? (
                      <>
                        <InputNumber
                          style={{
                            width: '150px',
                            margin: '5px',
                            fontWeight: 'bold',
                          }}
                          disabled
                          placeholder={i18n._('form.amount.placeholder')}
                          value={task.durationClientDays}
                          controls={false}
                          addonAfter="j"
                          min={1}
                        />
                      </>
                    ) : (
                      <DurationItem duration={task.duration} />
                    )}
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    <Checkbox disabled checked={task.visibleInRetro} />
                  </SDevisTd>
                  {task.functionType === FunctionType.CLIENT ? (
                    <SDevisTd></SDevisTd>
                  ) : (
                    <SDevisTd $textAlign="center">
                      <Checkbox disabled checked={task.calculatedAsAmount} />
                    </SDevisTd>
                  )}

                  <SDevisTd $textAlign="center">
                    {task.calculatedAsAmount && (
                      <InputNumber
                        disabled
                        style={{
                          width: '110px',
                          margin: '5px',
                          fontWeight: 'bold',
                        }}
                        placeholder={i18n._('form.amount.placeholder')}
                        value={task.amount}
                        step="0.01"
                        controls={false}
                        addonAfter="€"
                      />
                    )}
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    {task.calculatedAsAmount && (
                      <InputNumber
                        disabled
                        style={{
                          width: '110px',
                          margin: '5px',
                          fontWeight: 'bold',
                        }}
                        placeholder={i18n._('form.amount.placeholder')}
                        value={task.amountSpend}
                        step="0.01"
                        controls={false}
                        addonAfter="€"
                      />
                    )}
                  </SDevisTd>
                </tr>
              ))}
            </tbody>
          </SDevisTable>
          <Collapse items={items} />
        </SDivTasks>
      </Form>
    </Container>
  );
}

export default ViewDevisProdP;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: auto;
  width: 100%;
`;

const SDivTasks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const SDivDurationHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
`;

const SDivDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  & > div > div > div > div > input {
    font-weight: bold;
  }
`;
