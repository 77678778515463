//import { gql } from '@apollo/client';
import { Selector, $, GraphQLTypes, InputType } from '@zeus/index'; // , InputType, Selector, Int

import { typedGql } from '@zeus/typedDocumentNode';
import { taskInfoSelector } from '~/gql/task/task';
//import { scalars } from '~/gql/utils/scalars';

export const timeInfoSelector = Selector('TimeInfo')({
  id: true,
  date: true,
  duration: true,
  comment: true,
  task: taskInfoSelector,
});

export const projectUserTimesInfoSelector = Selector('ProjectUserTimesInfo')({
  projectId: true,
  projectName: true,
  projectManager: {
    firstName: true,
    lastName: true,
    id: true,
  },
  calendar: {
    date: true,
    totalTimeByDate: true,
    userTime: {
      duration: true,
      user: {
        lastName: true,
        firstName: true,
      },
    },
  },
});

export const GQL_EXPORT_USER_TIMES_FOR_PERIOD_BY_PROJECT = typedGql('query')({
  getExportUserTimesForPeriodByProjectId: [
    {
      filterTimeUserPlanningByProjectInput: {
        projectId: $('projectId', 'Int!'),
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    projectUserTimesInfoSelector,
  ],
});

export const GQL_CREATE_OR_UPDATE_TIME = typedGql('mutation')({
  createOrUpdateTime: [
    {
      createOrUpdateTimeInput: {
        id: $('id', 'Int'),
        taskId: $('taskId', 'Int!'),
        userId: $('userId', 'Int!'),
        date: $('date', 'DateTime!'),
        duration: $('duration', 'Int!'),
        comment: $('comment', 'String'),
      },
    },
    true,
  ],
});

export const GQL_DELETE_TIME = typedGql('mutation')({
  deleteTime: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_TIMES_FOR_PERIOD = typedGql('query')({
  getTimesForPeriodByUserId: [
    {
      filterTimeInput: {
        userId: $('userId', 'Int!'),
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    timeInfoSelector,
  ],
});

export type StoredTimeInfo = InputType<
  GraphQLTypes['TimeInfo'],
  typeof timeInfoSelector
>;

export type StoredProjectUserTimesInfo = InputType<
  GraphQLTypes['ProjectUserTimesInfo'],
  typeof projectUserTimesInfoSelector
>;
