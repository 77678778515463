import ProjectDetails from '~/pages/components/common/details/ProjectDetails';

import DefaultLayout from '~/pages/layouts/DefaultLayout';
import InternLayout from '~/pages/layouts/InternLayout';

function ProjectInternDetailsPage() {
  return (
    <>
      <DefaultLayout>
        <InternLayout>
          <ProjectDetails />
        </InternLayout>
      </DefaultLayout>
    </>
  );
}

export default ProjectInternDetailsPage;
