import { useLingui } from '@lingui/react';
import { useAuth } from '~/helpers/store/auth';
import { getColorForDuration } from '~/pages/components/common/utils-table/utils';
import {
  SDivRentaItem,
  SRentaSpan,
  SRentaTable,
  SRentaTableHead,
  SRentaTd,
  SRentaTh,
  SRentaTr,
} from '~/pages/components/styles/styles';
import { TDevisInitRentaInfoExt } from '~/pages/types/types';
import { getHoursAndMinutesStrByDurationForTimes } from '~/utils/utils/utils';

type IProps = {
  devisInits: TDevisInitRentaInfoExt[];
};

function DevisInitsRentaDetails({ devisInits }: IProps) {
  const { i18n } = useLingui();
  const { payload } = useAuth();

  return (
    <>
      {devisInits.length > 0 && (
        <>
          <SRentaTable>
            <SRentaTableHead $borderTop>
              <SRentaTr>
                <SRentaTh $width="15%" $borderLeft>
                  {i18n._('common.poste')}
                </SRentaTh>
                <SRentaTh $width="10.625%">
                  {i18n._('common.sold.amount')}
                </SRentaTh>
                <SRentaTh $width="10.625%"> {i18n._('common.sold')}</SRentaTh>
                <SRentaTh $width="10.625%" $borderRight>
                  {i18n._('common.duration.devis.prod')}
                </SRentaTh>
                <SRentaTh $width="10.625%"> {i18n._('common.times')}</SRentaTh>
                <SRentaTh $width="10.625%" $borderRight>
                  {i18n._('common.duration.used')}
                </SRentaTh>
                <SRentaTh $width="10.625%">
                  {i18n._('common.result')} devis prod
                </SRentaTh>
                <SRentaTh $width="10.625%">
                  {i18n._('common.result')} vendu h
                </SRentaTh>
                <SRentaTh $width="10.625%">
                  {payload?.roles === 'Admin'
                    ? `${i18n._('common.result')} vendu €`
                    : ''}
                </SRentaTh>
              </SRentaTr>
            </SRentaTableHead>
            <tbody>
              {devisInits.map((dI, index) => (
                <SRentaTr key={index}>
                  <SRentaTd $borderLeft $width="15%">
                    <span>
                      {dI.poste?.name}{' '}
                      {dI.customPosteName ? ` / ${dI.customPosteName}` : ''}
                    </span>
                  </SRentaTd>
                  <SRentaTd $bold $align="right" $width="10.625%">
                    {dI.amount.toFixed(2) + '€' || '-'}
                  </SRentaTd>
                  <SRentaTd $bold $align="right" $width="10.625%">
                    {getHoursAndMinutesStrByDurationForTimes(dI.duration) ||
                      '-'}
                  </SRentaTd>
                  <SRentaTd $bold $align="right" $borderRight $width="10.625%">
                    <SDivRentaItem>
                      {getHoursAndMinutesStrByDurationForTimes(
                        dI.durationByTasks
                      ) || '-'}
                      {dI.amountByTasks !== 0 && (
                        <span>{dI.amountByTasks.toFixed(2) + '€'}</span>
                      )}
                    </SDivRentaItem>
                  </SRentaTd>

                  <SRentaTd $bold $align="right" $width="10.625%">
                    <SDivRentaItem>
                      {getHoursAndMinutesStrByDurationForTimes(
                        dI.timesByTasks
                      ) || '-'}
                      {dI.amountSpendByTasks !== 0 && (
                        <span>
                          {dI.amountSpendByTasks
                            ? `${dI.amountSpendByTasks.toFixed(2)} €`
                            : ''}
                        </span>
                      )}
                    </SDivRentaItem>
                  </SRentaTd>

                  <SRentaTd $bold $borderRight $align="right" $width="10.625%">
                    <SDivRentaItem>
                      {getHoursAndMinutesStrByDurationForTimes(
                        dI.durationUsed ?? 0
                      ) || '-'}

                      <span>
                        {dI.amountUsed !== 0 && dI.amountUsed
                          ? dI.amountUsed.toFixed(2) + '€'
                          : ''}
                      </span>
                    </SDivRentaItem>
                  </SRentaTd>

                  <SRentaTd $bold $align="right" $width="10.625%">
                    <SDivRentaItem>
                      <SRentaSpan
                        color={getColorForDuration(dI.deltaDurationProd)}
                      >
                        {getHoursAndMinutesStrByDurationForTimes(
                          dI.deltaDurationProd
                        ) || '-'}
                      </SRentaSpan>
                      <SRentaSpan
                        color={getColorForDuration(dI.deltaAmountProd)}
                      >
                        {dI.deltaAmountProd !== 0 && dI.deltaAmountProd && (
                          <span>{dI.deltaAmountProd.toFixed(2) + '€'}</span>
                        )}
                      </SRentaSpan>
                    </SDivRentaItem>
                  </SRentaTd>
                  <SRentaTd
                    color={getColorForDuration(dI.deltaDurationSold)}
                    $bold
                    $align="right"
                    $width="10.625%"
                  >
                    {getHoursAndMinutesStrByDurationForTimes(
                      dI.deltaDurationSold
                    ) || '-'}
                  </SRentaTd>
                  <SRentaTd
                    color={getColorForDuration(dI.deltaSommeSold)}
                    $bold
                    $align="right"
                    $width="10.625%"
                    $borderRight
                  >
                    {payload?.roles === 'Admin'
                      ? `${dI.deltaSommeSold.toFixed(2) + '€'}`
                      : ''}
                  </SRentaTd>
                </SRentaTr>
              ))}
            </tbody>
          </SRentaTable>
        </>
      )}
    </>
  );
}

export default DevisInitsRentaDetails;

/*
  {devisInits.map((dI, index) => (
            <SDivTaskItemWrapper key={index}>
              <SDivTaskItem>
                <SDivTaskName $align="space-between">
                  <span> {dI.poste?.name}</span>
                </SDivTaskName>

                <SDivTaskSommeItem fontWeight="700">
                  {getHoursAndMinutesStrByDurationForTimes(dI.duration) || '-'}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700">
                  {getHoursAndMinutesStrByDurationForTimes(
                    dI.durationByTasks
                  ) || '-'}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem>
                  {getHoursAndMinutesStrByDurationForTimes(dI.timesByTasks) ||
                    '-'}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700">
                  {getHoursAndMinutesStrByDurationForTimes(
                    dI.durationUsed ?? 0
                  ) || '-'}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem
                  color={getColorForDuration(
                    dI.durationByTasks -
                      (dI.durationUsed ?? 0) -
                      dI.timesByTasks
                  )}
                  fontWeight="700"
                >
                  {getHoursAndMinutesStrByDurationForTimes(
                    dI.durationByTasks -
                      (dI.durationUsed ?? 0) -
                      dI.timesByTasks
                  ) || '-'}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem
                  color={getColorForDuration(
                    dI.duration - (dI.durationUsed ?? 0) - dI.timesByTasks
                  )}
                  fontWeight="700"
                >
                  {getHoursAndMinutesStrByDurationForTimes(
                    dI.duration - (dI.durationUsed ?? 0) - dI.timesByTasks
                  ) || '-'}
                </SDivTaskSommeItem>
              </SDivTaskItem>
            </SDivTaskItemWrapper>
          ))}
*/
