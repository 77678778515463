import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Input, Typography, Form, Tooltip } from 'antd';
import type { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  GQL_GET_ONE_PROJECT,
  GQL_UPDATE_PROJECT,
  StoredProjectFullInfo,
} from '~/gql/project/project';

import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import ProjectDetailsTable from '~/pages/components/common/details/ProjectDetailsTable';

import { classic } from '~/themes/classic';

function ProjectDetails() {
  const formRef = useRef<FormInstance>(null);
  const params = useParams();
  const { id } = params;
  const { Text } = Typography;
  const { setCurrentProject, currentDevisProd } = useDataUserStore();
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);

  const [projectName, setProjectName] = useState<string>('');
  const [project, setProject] = useState<StoredProjectFullInfo | null>(null);

  const [updateProject] = useMutation(GQL_UPDATE_PROJECT, {
    client: authClient,
  });

  const { refetch: fetchProject } = useQuery(GQL_GET_ONE_PROJECT, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProject(data.getOneProject);
      setCurrentProject(data.getOneProject);
      setProjectName(data.getOneProject.name);
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_ONE_PROJECT', e);
    },
  });

  useEffect(() => {
    void getProject();
  }, []);

  useEffect(() => {
    if (project) setProjectName(project.name);
  }, [project]);

  async function getProject() {
    setLoading(true);
    await fetchProject();
    setLoading(false);
  }

  async function handleChangeProjectName() {
    if (!project) return;
    await updateProject({
      variables: {
        id: project.id,
        name: projectName,
      },
    });
  }

  return (
    <>
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <SDivContentWrapper>
          <SDivTitle>
            <Form
              ref={formRef}
              name="retro-page"
              style={{ width: '100%' }}
              initialValues={{
                dateSigned: currentDevisProd?.signedDate
                  ? dayjs(currentDevisProd.signedDate as string)
                  : null,
              }}
            >
              <Text style={{ marginRight: '10px' }}>
                {i18n._('form.project-name')}
              </Text>
              <Input
                style={{ width: '250px', marginRight: '10px' }}
                placeholder={i18n._('form.project-name.placeholder')}
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                suffix={
                  <Tooltip title={i18n._('form.project-name.advice')}>
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
                onPressEnter={handleChangeProjectName}
              />
              <Text style={{ margin: '10px' }}>
                {i18n._('form.project.manager')}
              </Text>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: classic.token?.colorPrimary,
                }}
              >
                {project?.projectManager.firstName +
                  ' ' +
                  project?.projectManager.lastName}
              </Text>
            </Form>
          </SDivTitle>
          <SDivWrapper>
            {project?.id && <ProjectDetailsTable projectId={project.id} />}
          </SDivWrapper>
        </SDivContentWrapper>
      )}
    </>
  );
}

export default ProjectDetails;

const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SDivTitle = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const SDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
