import { useLingui } from '@lingui/react';
import { Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import TasksRentaTableDetails from '~/pages/components/common/details/TasksRentaTableDetails';

import {
  SRentaTable,
  SRentaTableHead,
  SRentaTh,
  SRentaTr,
} from '~/pages/components/styles/styles';
import { TTaskRentaInfoExt } from '~/pages/types/types';
import { classic } from '~/themes';

type IProps = {
  tasks: TTaskRentaInfoExt[];
  onTaskOpen: (id: number) => void;
};

function TasksRentaDetails({ tasks, onTaskOpen }: IProps) {
  const { Text } = Typography;
  const [tasksDevis, setTasksDevis] = useState<TTaskRentaInfoExt[]>([]);
  const [tasksSupp, setTasksSupp] = useState<TTaskRentaInfoExt[]>([]);
  const { i18n } = useLingui();

  useEffect(() => {
    setTasksDevis(tasks.filter((el) => !el.supp).sort(compareByOrder));
    setTasksSupp(tasks.filter((el) => el.supp));
  }, [tasks]);

  function compareByOrder(a: TTaskRentaInfoExt, b: TTaskRentaInfoExt) {
    if (a.order !== undefined && b.order !== undefined) {
      return a.order - b.order;
    }

    return 0;
  }

  return (
    <>
      <SRentaTable>
        <SRentaTableHead $borderTop>
          <SRentaTr>
            <Tooltip
              title={'Possibilité de voir les détails si il y a planning/temps'}
            >
              <SRentaTh $width="40%" $borderLeft>
                {i18n._('common.task.name')}
              </SRentaTh>
            </Tooltip>

            <SRentaTh $width="20%" $borderRight>
              {i18n._('common.duration')}
            </SRentaTh>
            <SRentaTh $width="20%"> {i18n._('common.times')}</SRentaTh>

            <SRentaTh $width="20%">{i18n._('common.result')}</SRentaTh>
          </SRentaTr>
        </SRentaTableHead>
      </SRentaTable>
      <Text
        style={{
          fontWeight: 'bold',
          color: classic.token?.colorPrimary,
        }}
      >
        Tâches devis
      </Text>
      <TasksRentaTableDetails
        onTaskOpen={onTaskOpen}
        tasks={tasksDevis}
        borderTop
      />

      <Text
        style={{
          fontWeight: 'bold',
          color: classic.token?.colorPrimary,
        }}
      >
        Tâches supplémentaires
      </Text>
      <TasksRentaTableDetails
        onTaskOpen={onTaskOpen}
        tasks={tasksSupp}
        borderTop
      />
    </>
  );
}

export default TasksRentaDetails;

/*
<tbody>
          {tasks.map((t, index) => (
            <SRentaTr>
              <Tooltip title={'voir les détails'}>
                <SRentaTd $borderLeft $width="28%">
                  {' '}
                  <SDivTaskName
                    onClick={() => onTaskOpen(t.id)}
                    $align="space-between"
                  >
                    <span> {t.name}</span>
                    <span>{t.isOpened ? '+' : '-'}</span>
                  </SDivTaskName>
                </SRentaTd>
              </Tooltip>
              <Tooltip title={'Durée de la vente'}>
                <SRentaTd $bold $align="right" $width="12%"></SRentaTd>
              </Tooltip>
              <Tooltip title={'Durée à partir du devis prod'}>
                <SRentaTd
                  $bold
                  $align="right"
                  $borderRight
                  $width="12%"
                ></SRentaTd>
              </Tooltip>
              <SRentaTd $bold $align="right" $width="12%"></SRentaTd>
              <SRentaTd
                $bold
                $borderRight
                $align="right"
                $width="12%"
              ></SRentaTd>
              <SRentaTd $bold $align="right" $width="12%"></SRentaTd>
              <SRentaTd $bold $align="right" $width="12%"></SRentaTd>
            </SRentaTr>
          ))}
        </tbody>
*/
