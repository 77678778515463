import { LogoutOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GQL_LOGOUT } from '~/gql/auth/auth';
import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import { classic } from '~/themes';
import { SText } from '~/utils/ui/utils';

function Logout() {
  const { i18n } = useLingui();
  const [seconds, setSeconds] = useState(3);
  const { logout } = useAuth();
  const [logoutGql] = useMutation(GQL_LOGOUT);

  useEffect(() => {
    void logoutGql({ client: authClient });
    logout();
    setTimeout(() => {
      window.location.href = '/';
    }, 3000);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <Container>
      <LogoutOutlined
        style={{ color: classic.token?.colorPrimary, fontSize: '50px' }}
      />
      <SText $color={classic.token?.colorPrimary} $fontweight="bold">
        {i18n._('logout.redirect.message')}{' '}
        <span style={{ color: classic.token?.colorFillSecondary }}>
          {seconds}s
        </span>
      </SText>
    </Container>
  );
}

export default Logout;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  gap: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
