import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import { Tooltip } from 'antd';

import { useState, useEffect, useRef } from 'react';
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
} from 'react-beautiful-dnd';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { styled } from 'styled-components';
import { GQL_UPDATE_TASK_ORDER, GQL_UPDATE_TASK_RETRO } from '~/gql/task/task';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import {
  SDevisTh,
  STaskTd,
  SDevisTableHead,
  SDevisTable,
  STaskDaysTh,
  SDivCheckRetro,
  STableTr,
} from '~/pages/components/styles/styles';
import { TDate, TPeriod, TaskInfoWithCalender } from '~/pages/types/types';
import {
  compareByOrder,
  getHoursAndMinutesStrByDuration,
} from '~/utils/utils/utils';

type IProps = {
  tasks: TaskInfoWithCalender[];
  calendar: TDate[];
  onStateCellChange: (itemId: number, indInCalender: number) => void;
  onStatePeriodChange: (
    itemId: number,
    startDateInit: number,
    durationDays: number
  ) => void;
  onOrderChanged: (newTasks: TaskInfoWithCalender[]) => void;
  isPrinting: boolean;
  changeIsPrinting: (value: boolean) => void;
};

function TasksDragAndDrop({
  tasks,
  calendar,
  onStateCellChange,
  onOrderChanged,
  onStatePeriodChange,
  isPrinting,
  changeIsPrinting,
}: IProps) {
  const { i18n } = useLingui();
  const { setCurrentDevisProd } = useDataUserStore();
  const ref = useRef(null);

  const [period, setPeriod] = useState<TPeriod>({
    beginCell: undefined,
    endCell: undefined,
  });

  const [updateTaskOrder] = useMutation(GQL_UPDATE_TASK_ORDER, {
    client: authClient,
    onCompleted: (data) => {
      setCurrentDevisProd({
        ...data.updateTaskOrder,
        tasks: data.updateTaskOrder.tasks?.sort(compareByOrder),
      });
    },
  });

  const [updateTaskRetro] = useMutation(GQL_UPDATE_TASK_RETRO, {
    client: authClient,
  });

  // function hasOrderInTasks(items: TaskInfoWithCalender[]): boolean {
  //   for (const it of items) {
  //     if (it.order) return true;
  //   }
  //   return false;
  // }

  useEffect(() => {
    // loop voir plus tard
    // if (tasks.length > 0 && !hasOrderInTasks(tasks)) {
    //   void updateOrder(tasks);
    // }
  }, [tasks]);

  async function updateOrder(_tasks: TaskInfoWithCalender[]) {
    const updateTaskOrderInput = _tasks.map((el, index) => {
      return { id: el.id, order: index };
    });

    if (updateTaskOrderInput.length > 0) {
      await updateTaskOrder({
        variables: {
          updateTaskOrderInput,
        },
      });
    }
  }

  function handlerDragEnd(result: DropResult) {
    const tmpItems = [...tasks];
    const [selectedRow] = tmpItems.splice(result.source.index, 1);
    if (result.destination) {
      tmpItems.splice(result.destination.index, 0, selectedRow);
      void updateOrder(tmpItems);

      onOrderChanged(tmpItems);
    }
  }

  useEffect(() => {
    if (isPrinting) {
      print();
      changeIsPrinting(false);
    }
  }, [isPrinting]);

  async function handleCellClick(
    e: React.MouseEvent<HTMLTableDataCellElement>,
    itemId: number,
    indInCalendar: number
  ) {
    e.stopPropagation();
    if (e.shiftKey) {
      if (!period.beginCell) {
        setPeriod({
          beginCell: {
            itemId: itemId,
            indexInCalendar: indInCalendar,
          },
          endCell: undefined,
        });
      } else {
        // click pour endPeriod
        if (
          period.beginCell.itemId === itemId &&
          period.beginCell.indexInCalendar === indInCalendar
        ) {
          setPeriod({
            beginCell: undefined,
            endCell: undefined,
          });
        } else {
          // contrôle si period est valid
          if (
            period.beginCell.itemId === itemId &&
            period.beginCell.indexInCalendar < indInCalendar
          ) {
            // puis si c'est la même que dans db => supprimer
            const indItem = tasks.findIndex((el) => el.id === itemId);
            if (indItem !== -1) {
              if (
                tasks[indItem].startDateInit ===
                  period.beginCell.indexInCalendar &&
                tasks[indItem].durationDays ===
                  indInCalendar - period.beginCell.indexInCalendar + 1
              ) {
                const { data: _data } = await updateTaskRetro({
                  variables: {
                    id: itemId,
                    durationDays: 0,
                    startDateInit: 0,
                  },
                });

                if (_data) {
                  onStatePeriodChange(itemId, 0, 0);
                  setPeriod({
                    beginCell: undefined,
                    endCell: undefined,
                  });
                }
              } else {
                // pas la même
                const { data: _data2 } = await updateTaskRetro({
                  variables: {
                    id: itemId,
                    durationDays:
                      indInCalendar - period.beginCell.indexInCalendar + 1,
                    startDateInit: period.beginCell.indexInCalendar,
                  },
                });

                if (_data2) {
                  onStatePeriodChange(
                    itemId,
                    period.beginCell.indexInCalendar,
                    indInCalendar - period.beginCell.indexInCalendar + 1
                  );
                  setPeriod({
                    beginCell: undefined,
                    endCell: undefined,
                  });
                }
              }
            }
          } else {
            setPeriod({
              beginCell: undefined,
              endCell: undefined,
            });
          }
        }
      }
    } else {
      onStateCellChange(itemId, indInCalendar);
    }
  }

  function print() {
    // void exportComponentAsPDF(ref, {
    //   fileName: 'retro-planning.pdf',
    //   pdfOptions: { orientation: 'l' },
    // });
    void exportComponentAsJPEG(ref, {
      fileName: 'retro-planning.jpg',
    });
  }

  return (
    <Container ref={ref}>
      <DragDropContext onDragEnd={(results) => handlerDragEnd(results)}>
        <STableContainer>
          <SDevisTable $margin="0 0">
            <SDevisTableHead>
              <tr>
                <SDevisTh $textAlign="center">
                  {i18n._('common.task.name')}
                </SDevisTh>
                <SDevisTh $textAlign="center">
                  {i18n._('common.function.type')}
                </SDevisTh>
                <SDevisTh $textAlign="center">
                  {i18n._('common.duration')}
                </SDevisTh>
                {calendar.map((el, index) => (
                  <STaskDaysTh $textAlign="center" key={index}>
                    {el.name}
                  </STaskDaysTh>
                ))}
              </tr>
            </SDevisTableHead>
            <Droppable droppableId="1234">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {tasks.length > 0 &&
                    tasks.map((item, indexInList) => (
                      <Draggable
                        draggableId={item.id.toString()}
                        index={indexInList}
                        key={item.id}
                      >
                        {(providedDraggable) => (
                          <STableTr
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                          >
                            <STaskTd {...providedDraggable.dragHandleProps}>
                              {item.name}
                            </STaskTd>
                            <STaskTd {...providedDraggable.dragHandleProps}>
                              {i18n._(item.functionType)}
                            </STaskTd>
                            <STaskTd
                              {...providedDraggable.dragHandleProps}
                              $textAlign="right"
                            >
                              {item.functionType === FunctionType.CLIENT
                                ? item.durationClientDays + 'j'
                                : getHoursAndMinutesStrByDuration(
                                    item.duration
                                  )}
                            </STaskTd>
                            {tasks[indexInList].calendar.map((itC, ind) => (
                              <Tooltip key={ind} title={itC.name}>
                                <STaskTd
                                  onClick={(e) => {
                                    void handleCellClick(e, item.id, ind);
                                  }}
                                  key={ind}
                                  className={
                                    period.beginCell?.itemId === item.id &&
                                    period.beginCell.indexInCalendar === ind
                                      ? 'period'
                                      : period.endCell?.itemId === item.id &&
                                          period.endCell.indexInCalendar === ind
                                        ? 'end'
                                        : ''
                                  }
                                >
                                  {itC.checked ? (
                                    <SDivCheckRetro
                                      className={
                                        tasks[indexInList].functionType ===
                                        FunctionType.CLIENT
                                          ? 'client'
                                          : ''
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </STaskTd>
                              </Tooltip>
                            ))}
                          </STableTr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </SDevisTable>
        </STableContainer>
      </DragDropContext>
    </Container>
  );
}

export default TasksDragAndDrop;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const STableContainer = styled.div`
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;
