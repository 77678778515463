import { useMutation, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Select, Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  GQL_PROJECTS_BY_MANAGER,
  StoredProjectForSuperProject,
} from '~/gql/project/project';
import {
  GQL_GET_ONE_SUPER_PROJECT,
  GQL_UPDATE_SUPER_PROJECT,
  StoredSuperProject,
} from '~/gql/super-project/super-project';
import { authClient } from '~/helpers/apollo';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import SuperProjectLayout from '~/pages/layouts/SuperProjectLayout';

function SuperProjectInfoPage() {
  const formRef = useRef<FormInstance>(null);
  //const { Text } = Typography;
  const params = useParams();
  const { id } = params;
  const { i18n } = useLingui();
  const [project, setProject] = useState<StoredSuperProject | undefined>(
    undefined
  );
  const [allProjects, setAllProjects] = useState<
    StoredProjectForSuperProject[]
  >([]);
  const [projectName, setProjectName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { refetch: fetchProjects } = useQuery(GQL_PROJECTS_BY_MANAGER, {
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAllProjects(data.getAllProjectByManager);
    },
  });

  const { refetch: fetchSuperProject } = useQuery(GQL_GET_ONE_SUPER_PROJECT, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProject(data.getOneSuperProject);
      setProjectName(data.getOneSuperProject.name);
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_ONE_PROJECT', e);
    },
  });

  const [updateSuperProject] = useMutation(GQL_UPDATE_SUPER_PROJECT, {
    client: authClient,
  });

  async function getSuperProject() {
    setLoading(true);
    await fetchSuperProject();
    setLoading(false);
  }

  async function getAllProjects() {
    setLoading(true);
    await fetchProjects();
    setLoading(false);
  }

  useEffect(() => {
    void getSuperProject();
    void getAllProjects();
  }, []);

  async function handleChangeSelected(value: number[]) {
    if (!project) return;
    await updateSuperProject({
      variables: {
        id: project.id,
        name: projectName,
        projectIds: value,
      },
    });
  }

  return (
    <>
      <DefaultLayout>
        <SuperProjectLayout>
          <SDivContentWrapper>
            <>
              {loading ? (
                <p>{i18n._('common.loading')} ...</p>
              ) : (
                <>
                  <Form
                    ref={formRef}
                    style={{ width: '100%' }}
                    name="planning-page"
                    initialValues={{
                      projectName: projectName,
                      projects: project?.projects.map((el) => el.id) ?? [],
                    }}
                  >
                    <Form.Item
                      name="projectName"
                      label={i18n._('common.name')}
                      rules={[
                        {
                          required: true,
                          message: i18n._('form.project-name.placeholder'),
                        },
                      ]}
                    >
                      <Input
                        value={projectName}
                        placeholder={i18n._('form.project-name.placeholder')}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item
                      name="projects"
                      label={i18n._('common.projects')}
                      rules={[
                        {
                          required: true,
                          message: i18n._('form.project-name.placeholder'),
                        },
                      ]}
                    >
                      <Select
                        placeholder={i18n._('form.project.placeholder')}
                        mode="multiple"
                        showSearch
                        value={project?.projects.map((el) => el.id)}
                        onChange={handleChangeSelected}
                        style={{ width: '100%', padding: '2px' }}
                        optionFilterProp="label"
                        options={allProjects.map((p) => ({
                          value: p.id,
                          label: p.name,
                        }))}
                      />
                    </Form.Item>
                  </Form>
                </>
              )}
            </>
          </SDivContentWrapper>
        </SuperProjectLayout>
      </DefaultLayout>
    </>
  );
}

export default SuperProjectInfoPage;

const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
