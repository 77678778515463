import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {
  StoredCalendar,
  StoredTaskInfoForPlanning,
} from '~/gql/task-planning/task-planning';
import {
  TaskInfoWithPlanningCalender,
  TDatePlanning,
} from '~/pages/types/types';

type IProps = {
  tasks: StoredTaskInfoForPlanning[] | TaskInfoWithPlanningCalender[];
  tasksSupp: StoredTaskInfoForPlanning[] | TaskInfoWithPlanningCalender[];
  calendar: StoredCalendar[] | TDatePlanning[];
  fileName: string;
};

export const exportExcel = async ({
  tasks,
  calendar,
  fileName,
  tasksSupp,
}: IProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Task Planning');
  //   const calendarHeaders: any[] = calendar.map((el) => {
  //     return { header: el.date.toLocaleDateString(), key: el.date, width: 10 };
  //   });

  const calendarHeaders: any[] = calendar.map((el) => {
    return new Date(el.date as Date).toLocaleDateString();
  });

  //   const headers = [];
  //   headers.push({ header: 'Name', key: 'name', width: 20 });
  //   headers.push({ header: 'FunctionType', key: 'ftype', width: 10 });
  //   for (const c of calendarHeaders) headers.push(c);
  //   worksheet.columns = headers;

  const headers = ['Name', 'FunctionType'];
  worksheet.addRow(headers.concat(calendarHeaders));

  // tasks.concat(tasksSupp)
  tasks.forEach((task) => {
    const taskData: any[] = [task.name, task.functionType];

    const calendarData: any[] = task.calendar.map((day) => {
      if (day.checked) {
        return {
          value: day.label ? 'X' : '',
          functionType: 'CLIENT',
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: task.functionType.includes('CLIENT')
                  ? 'FFFF00'
                  : '00FFFF',
              },
            },
          },
        };
      }
      return '';
    });

    const row = worksheet.addRow(taskData.concat(calendarData));

    calendarData.forEach((cell, index) => {
      if (typeof cell === 'object') {
        const excelCell = row.getCell(headers.length + 1 + index);

        excelCell.fill = cell.style.fill;
        excelCell.value = '';
      }
    });
  });

  worksheet.addRow([]);

  tasksSupp.forEach((task) => {
    const taskData: any[] = [task.name, task.functionType];

    const calendarData: any[] = task.calendar.map((day) => {
      if (day.checked) {
        return {
          value: day.label ? 'X' : '',
          functionType: 'CLIENT',
          style: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: task.functionType.includes('CLIENT')
                  ? 'FFFF00'
                  : '00FFFF',
              },
            },
          },
        };
      }
      return '';
    });

    const row = worksheet.addRow(taskData.concat(calendarData));

    calendarData.forEach((cell, index) => {
      if (typeof cell === 'object') {
        const excelCell = row.getCell(headers.length + 1 + index);

        excelCell.fill = cell.style.fill;
        excelCell.value = '';
      }
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};
