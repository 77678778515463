import { Layout } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import InternNavBar from '~/pages/layouts/InternNavBar';

const { Content } = Layout;

type IProps = {
  children: ReactNode;
};

function InternLayout({ children }: IProps) {
  return (
    <>
      <Layout style={{ minHeight: '100%', width: 'auto' }}>
        <InternNavBar />
        <Layout>
          <SContent>{children}</SContent>
        </Layout>
      </Layout>
    </>
  );
}

export default InternLayout;

const SContent = styled(Content)`
  padding: 10px;
`;
