import { Selector, $, GraphQLTypes, InputType } from '@zeus/index';

import { typedGql } from '@zeus/typedDocumentNode';
import { taskInfoSelector, taskShortInfoSelector } from '~/gql/task/task';

const userPlanningInfoSelector = Selector('UserPlanningInfo')({
  id: true,
  date: true,
  duration: true,
  user: {
    firstName: true,
    lastName: true,
    id: true,
  },
  task: taskInfoSelector,
});

const userPlanningShortInfoSelector = Selector('UserPlanningShortInfo')({
  id: true,
  date: true,
  duration: true,
  user: {
    firstName: true,
    lastName: true,
    id: true,
  },
  task: taskShortInfoSelector,
});

export const timeInfoForAdminSelector = Selector('TimeInfo')({
  id: true,
  date: true,
  duration: true,
  comment: true,
  user: {
    firstName: true,
    lastName: true,
    id: true,
  },
  task: taskInfoSelector,
});

export const timeShortInfoSelector = Selector('TimeShortInfo')({
  id: true,
  date: true,
  duration: true,
  comment: true,
  user: {
    firstName: true,
    lastName: true,
    id: true,
  },
  task: taskShortInfoSelector,
});

const timeUserPlanningSelector = Selector('TimeUserPlanningInfo')({
  time: timeInfoForAdminSelector,
  userPlanning: userPlanningInfoSelector,
});

const userTimePlanningSelector = Selector('UserTimePlanningInfo')({
  userId: true,
  userFirstName: true,
  userLastName: true,
  userPlanning: userPlanningShortInfoSelector,
  time: timeShortInfoSelector,
});

export const GQL_USER_PLANNING_FOR_PERIOD = typedGql('query')({
  getPlanningForPeriodByUserId: [
    {
      filterUserPlanningInput: {
        userId: $('userId', 'Int!'),
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    userPlanningInfoSelector,
  ],
});

export const GQL_TIMES_USER_PLANNING_FOR_PERIOD = typedGql('query')({
  getTimesUserPlanningsForPeriodByUserId: [
    {
      filterUserPlanningInput: {
        userId: $('userId', 'Int!'),
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    timeUserPlanningSelector,
  ],
});

export const GQL_TIMES_USER_PLANNING_FOR_PERIOD_BY_PROJECT = typedGql('query')({
  getAllTimesUserPlanningsForPeriodByProjectId: [
    {
      filterTimeUserPlanningByProjectInput: {
        projectId: $('projectId', 'Int!'),
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    timeUserPlanningSelector,
  ],
});

export const GQL_USER_TIMES_PLANNING_BY_PROJECT = typedGql('query')({
  getTimesUserPlanningsForPeriodByProjectId: [
    {
      filterTimeUserPlanningByProjectInput: {
        projectId: $('projectId', 'Int!'),
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    userTimePlanningSelector,
  ],
});

export type StoredUserPlanningInfo = InputType<
  GraphQLTypes['UserPlanningInfo'],
  typeof userPlanningInfoSelector
>;

export type StoredTimeUserPlanningInfo = InputType<
  GraphQLTypes['TimeUserPlanningInfo'],
  typeof timeUserPlanningSelector
>;

export type StoredUserTimePlanningInfo = InputType<
  GraphQLTypes['UserTimePlanningInfo'],
  typeof userTimePlanningSelector
>;

export type StoredTimeInfoForAdmin = InputType<
  GraphQLTypes['TimeInfo'],
  typeof timeInfoForAdminSelector
>;
