import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { Tooltip } from 'antd';
import styled from 'styled-components';

import { getColorForDuration } from '~/pages/components/common/utils-table/utils';

import {
  SDivTaskItem,
  SDivTaskItemWrapper,
  SDivTaskName,
  SDivTaskSommeItem,
  SRentaSpan,
} from '~/pages/components/styles/styles';
import { TTaskRentaInfoExt } from '~/pages/types/types';
import { classic } from '~/themes';
import { getHoursAndMinutesStrByDurationForTimes } from '~/utils/utils/utils';

type IProps = {
  tasks: TTaskRentaInfoExt[];
  onTaskOpen: (id: number) => void;
  borderTop?: boolean;
};

function TasksRentaTableDetails({ tasks, onTaskOpen, borderTop }: IProps) {
  return (
    <Container $firstBorderTop={borderTop}>
      {tasks.map((t, index) => (
        <SDivTaskItemWrapper key={index}>
          <SDivTaskItem>
            <Tooltip
              title={
                t.userRentaInfo.length === 0
                  ? "il n'y a pas de détails pour cette tâche"
                  : t.isOpened
                    ? 'fermer les détails pour cette tâche'
                    : 'voir les détails pour cette tâche'
              }
            >
              <SDivTaskName
                onClick={() => onTaskOpen(t.id)}
                $align="space-between"
              >
                <span> {t.name}</span>
                {t.userRentaInfo.length > 0 && (
                  <span style={{ fontWeight: 'bold' }}>
                    {t.isOpened ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  </span>
                )}
              </SDivTaskName>
            </Tooltip>

            <SDivTaskSommeItem $borderRight fontWeight="700">
              {!t.calculatedAsAmount &&
                (getHoursAndMinutesStrByDurationForTimes(t.duration) || '-')}
              {t.calculatedAsAmount && (t.amount.toFixed(2) + '€' || '-')}
            </SDivTaskSommeItem>
            <SDivTaskSommeItem $borderLeft fontWeight="700" $borderRight>
              {!t.calculatedAsAmount && (
                <>
                  {getHoursAndMinutesStrByDurationForTimes(t.sommeTime) || '-'}
                </>
              )}
              {t.calculatedAsAmount && (
                <>{(t.amountSpend && t.amountSpend.toFixed(2) + '€') ?? '-'}</>
              )}
            </SDivTaskSommeItem>

            <SDivTaskSommeItem fontWeight="700" $borderLeft>
              <SRentaSpan color={getColorForDuration(t.deltaDuration)}>
                {!t.calculatedAsAmount && (
                  <>
                    {getHoursAndMinutesStrByDurationForTimes(t.deltaDuration) ||
                      '-'}
                  </>
                )}
              </SRentaSpan>
              <SRentaSpan
                color={getColorForDuration(t.amount - (t.amountSpend ?? 0))}
              >
                <>
                  {t.calculatedAsAmount &&
                    (t.amountSpend ?? t.amount) &&
                    ((t.amount - (t.amountSpend ?? 0)).toFixed(2) + '€' || '-')}
                </>
              </SRentaSpan>
            </SDivTaskSommeItem>
          </SDivTaskItem>
          {t.isOpened &&
            t.userRentaInfo.map((userInfo, ind) => (
              <SDivTaskItem key={ind}>
                <SDivTaskName $bg="#e8f2f7" $align="flex-end">
                  {userInfo.userFirstName} {userInfo.userLastName}
                </SDivTaskName>

                <SDivTaskSommeItem>
                  {getHoursAndMinutesStrByDurationForTimes(
                    userInfo.planningDuration
                  ) || '-'}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem>
                  {getHoursAndMinutesStrByDurationForTimes(
                    userInfo.sommeTime
                  ) || '-'}
                </SDivTaskSommeItem>

                <SDivTaskSommeItem
                  color={getColorForDuration(userInfo.deltaDuration)}
                >
                  {getHoursAndMinutesStrByDurationForTimes(
                    userInfo.deltaDuration
                  ) || '-'}
                </SDivTaskSommeItem>
              </SDivTaskItem>
            ))}
        </SDivTaskItemWrapper>
      ))}
    </Container>
  );
}

export default TasksRentaTableDetails;

const Container = styled.div<{ $firstBorderTop?: boolean }>`
  width: 100%;
  & > div:first-child {
    border-top: ${({ $firstBorderTop }) =>
      $firstBorderTop
        ? `1px solid ${classic.token?.colorFillSecondary}`
        : 'none'};
  }
`;
