import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useLingui, Trans } from '@lingui/react';
import { DevisType, FunctionType } from '@zeus/index';
import type { CollapseProps, DatePickerProps } from 'antd';
import {
  Collapse,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Typography,
  ConfigProvider,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { FormInstance } from 'antd/es/form';
import frFR from 'antd/es/locale/fr_FR';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import {
  GQL_CREATE_DEVIS_PROD,
  GQL_UPDATE_DEVIS_INITIAL_DURATION_EXPECTED,
  StoredDevitInit,
} from '~/gql/devis/devis';
import { StoredFunction } from '~/gql/function/function';
import { StoredPoste } from '~/gql/poste/poste';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import useCalculetedDevisInit from '~/pages/components/common/utils-table/hooks/useCalculetedDevisInit';
import { addElementAfterIndex } from '~/pages/components/common/utils-table/utils';
import {
  SDevisTable,
  SDevisTableHead,
  SDevisTd,
  SDevisTh,
} from '~/pages/components/styles/styles';
import ButtonAddTask from '~/pages/components/ui/button-add-task';
import DurationItem from '~/pages/components/ui/DurationItem';
import TasksGrouped from '~/pages/components/ui/TasksGrouped';

import { tasksData } from '~/pages/fixtures/data-tasks';
import {
  DevisProdRow,
  TPosteOption,
  TTaskInDevisTemplate,
} from '~/pages/types/types';

type IProps = {
  postes: StoredPoste[];
  devisInit: StoredDevitInit | null;
  functionTypes: StoredFunction[];
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

function AddDevisProdTemplate({
  functionTypes,
  devisInit,
  postes,
  confirm,
  close,
}: IProps) {
  const { i18n } = useLingui();
  const { Text } = Typography;
  const [dateSigned, setDateSigned] = useState<Date | undefined>(undefined);
  const [dateFinish, setDateFinish] = useState<Date | undefined>(undefined);
  const formRef = React.useRef<FormInstance>(null);
  const { currentProject } = useDataUserStore();
  const [tasksByTemplate, setTasksByTemplate] = useState<DevisProdRow[]>([]);
  const [devisRowsWithTasks, setDevisRowsWithTasks] = useState<
    TTaskInDevisTemplate[]
  >([]);
  const [
    changedDevisInitDurationExpected,
    setChangedDevisInitDurationExpected,
  ] = useState<{ devisInitId: number; durationExpected: number }[]>([]);
  const [postesByDevis, setPostesByDevis] = useState<TPosteOption[]>([]);
  const [addedCount, setAddedCount] = useState<number>(-1);

  const { devisInitDurationExpected, devisInitAmount } =
    useCalculetedDevisInit(devisInit);

  const [createDevisProd] = useMutation(GQL_CREATE_DEVIS_PROD, {
    client: authClient,
  });
  const [updateDevisInitDurationExpected] = useMutation(
    GQL_UPDATE_DEVIS_INITIAL_DURATION_EXPECTED,
    {
      client: authClient,
    }
  );

  function getDevisRowsWithTasks(
    prodRows: DevisProdRow[]
  ): TTaskInDevisTemplate[] {
    let result: TTaskInDevisTemplate[] = [];
    if (devisInit?.devisInit) {
      result = devisInit.devisInit.map((dI) => {
        const newResultRow: TTaskInDevisTemplate = {
          devisInit: {
            id: dI.id,
            amount: dI.amount,
            duration: dI.duration,
            durationExpected: dI.durationExpected,
            durationUsed: dI.durationUsed,
            posteId: dI.posteId,
            posteName: postes.find((p) => p.id === dI.posteId)?.name ?? '',
            functionType: dI.functionType,
            functionTypeId: functionTypes.find(
              (fT) => fT.name === dI.functionType
            )?.id,
          },
          tasks: [],
        };
        return newResultRow;
      });
    }

    const oldProdRows = [...prodRows];
    const tasksWithoutPoste: DevisProdRow[] = [];
    for (const row of oldProdRows) {
      if (row.devisInitId) {
        const indInResult = result.findIndex(
          (r) => r.devisInit && r.devisInit.id === row.devisInitId
        );
        if (indInResult !== -1) {
          if (result[indInResult]) {
            result[indInResult].tasks.push({
              id: row.id,
              devisInitId: row.devisInitId,
              taskName: row.taskName,
              duration: row.duration,
              functionType: row.functionType,
              durationDays: row.durationDays,
              durationClientDays: row.durationClientDays,
              visibleInRetro: row.visibleInRetro,
              supp: row.supp,
              calculatedAsAmount: row.calculatedAsAmount,
              posteId: row.posteId,
              amount: row.amount,
            });
          }
        }
      } else {
        tasksWithoutPoste.push({
          id: row.id,
          taskName: row.taskName,
          duration: row.duration,
          durationDays: row.durationDays,
          durationClientDays: row.durationClientDays,
          functionType: row.functionType,
          visibleInRetro: row.visibleInRetro,
          supp: row.supp,
          calculatedAsAmount: row.calculatedAsAmount,
          devisInitId: undefined,
          amount: row.amount,
        });
      }
    }
    if (tasksWithoutPoste.length > 0) {
      result.push({
        tasks: tasksWithoutPoste,
      });
    }

    return result;
  }

  useEffect(() => {
    //init 1
    let _postes: TPosteOption[] = [];
    if (devisInit?.devisInit) {
      _postes = devisInit.devisInit.map((el, index) => {
        const _item: TPosteOption = {
          id: index,
          posteId: el.posteId,
          label: postes.find((p) => p.id === el.posteId)?.name ?? '',
          functionType: el.functionType,
          devisInitId: el.id,
          amount: el.amount ? el.amount : 0,
        };
        return _item;
      });

      setPostesByDevis(_postes);
    }

    const mappedTasks: DevisProdRow[] = tasksData.map((t, index) => {
      const newEl: DevisProdRow = {
        id: index + 1,
        taskName: t.taskName,
        amount: 0, // dans template il n'y a pas de amount
        duration: t.duration,
        devisInitId: devisInit?.devisInit?.find(
          (dI) => dI.functionType === t.functionType
        )?.id,
        functionType: t.functionType,
        durationClientDays: t.durationClientDays,
        visibleInRetro: t.visibleInRetro,
        supp: t.supp,
        calculatedAsAmount: t.calculatedAsAmount,
        posteId: _postes.find((p) => p.functionType === t.functionType)
          ?.posteId,
      };
      return newEl;
    });
    setTasksByTemplate(mappedTasks);

    setDevisRowsWithTasks(getDevisRowsWithTasks(mappedTasks));
  }, [devisInit]);

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0);
    setDateSigned(new Date(d));
  };

  const onChangeDateFinish: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0);
    setDateFinish(new Date(d));
  };

  async function submit() {
    if (!devisInit?.id || !currentProject?.id) return;

    const taskRows = [];
    for (let i = 0; i < tasksByTemplate.length; i++) {
      taskRows.push({
        functionType:
          postesByDevis.find((p) => p.posteId === tasksByTemplate[i].posteId)
            ?.functionType ?? (tasksByTemplate[i].functionType as FunctionType),
        amount: tasksByTemplate[i].amount,
        duration: tasksByTemplate[i].duration,
        ...(tasksByTemplate[i].functionType === FunctionType.CLIENT && {
          durationClientDays: tasksByTemplate[i].durationClientDays,
        }),
        name: tasksByTemplate[i].taskName,
        projectId: currentProject.id,
        ...(tasksByTemplate[i].functionType !== FunctionType.CLIENT && {
          devisInitId: tasksByTemplate[i].devisInitId,
        }),
        visibleInRetro: tasksByTemplate[i].visibleInRetro,
        calculatedAsAmount: tasksByTemplate[i].calculatedAsAmount,
        order: i,
      });
    }

    const { data: _data } = await createDevisProd({
      variables: {
        type: DevisType.PROD,
        projectId: currentProject.id,
        ...(dateSigned && { signedDate: new Date(dateSigned) }),
        ...(dateFinish && { finishDate: new Date(dateFinish) }),
        createTaskInput: taskRows,
      },
    });
    if (changedDevisInitDurationExpected.length > 0) {
      //   const { data: _dataInit } =
      await updateDevisInitDurationExpected({
        variables: {
          updateDevisInitDurationExpectedInput:
            changedDevisInitDurationExpected.map((el) => {
              return {
                id: el.devisInitId,
                durationExpected: el.durationExpected,
              };
            }),
        },
      });
      //  if (_dataInit) console.log('done'); //TODO
    }

    if (_data) {
      confirm();
      close();
    } else {
      close();
    }
  }

  const onChangeTaskName = (name: string, index: number) => {
    const _tasks = [...tasksByTemplate];
    if (_tasks[index]) {
      _tasks[index].taskName = name;
    }
    setTasksByTemplate(_tasks);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
  };

  const onChangePoste = async (value: number, index: number) => {
    const _tasks = [...tasksByTemplate];
    if (_tasks[index]) {
      // _tasks[index].posteId = value;
      // _tasks[index].devisInitId = postesByDevis.find(
      //   (p) => p.value === value
      // )?.devisInitId;

      _tasks[index].posteId = postesByDevis.find(
        (p) => p.devisInitId === value
      )?.posteId;
      _tasks[index].devisInitId = value;

      _tasks[index].functionType = postesByDevis.find(
        (p) => p.devisInitId === value
      )?.functionType;
      setTasksByTemplate(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeFunctionType = async (value: number, index: number) => {
    const functionType = functionTypes.find((p) => p.id === value);
    if (!functionType) return;

    if (functionType.name === FunctionType.CLIENT) {
      const _tasks = [...tasksByTemplate];
      if (_tasks[index]) {
        _tasks[index].functionType = FunctionType.CLIENT;

        setTasksByTemplate(_tasks);
        setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
      }
    }
  };

  const onChangeTaskDuration = (duration: number, index: number) => {
    const _tasks = [...tasksByTemplate];
    if (_tasks[index]) {
      _tasks[index].duration = duration;
      setTasksByTemplate(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskDurationDays = (
    durationClientDays: number,
    index: number
  ) => {
    const _tasks = [...tasksByTemplate];
    if (_tasks[index]) {
      _tasks[index].durationClientDays = durationClientDays;
      setTasksByTemplate(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  function onAddTaskInRow(devisInitId: number | undefined) {
    const fT = devisInit?.devisInit?.find((dI) => dI.id === devisInitId);
    const _rows = [...tasksByTemplate];

    _rows.push({
      id: tasksByTemplate.length + 1,
      functionType: fT?.functionType ?? FunctionType.GP,
      posteId: fT?.posteId,
      amount: 0,
      duration: 0,
      devisInitId: devisInitId ?? 0,
      taskName: '',
      visibleInRetro: true,
      supp: false,
      calculatedAsAmount: false,
    });
    setTasksByTemplate(_rows);
    setAddedCount((old) => old - 1);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_rows));
  }

  function onDeleteTasksInRow(
    tasks: DevisProdRow[],
    devisInitId: number | undefined,
    taskId: number | undefined
  ) {
    const _rows = [...devisRowsWithTasks];
    if (devisInitId) {
      const ind = _rows.findIndex((el) => el.devisInit?.id === devisInitId);
      if (ind !== -1) {
        _rows[ind].tasks = [...tasks];
        setDevisRowsWithTasks(_rows);
      }
      // delete de tasks avec taskId
      if (taskId) {
        const _tasks = [...tasksByTemplate];

        const indToDelete = _tasks.findIndex((el) => el.id === taskId);
        _tasks.splice(indToDelete, 1);
        setTasksByTemplate(_tasks);
      }
    } else {
      const indWithoutPoste = _rows.findIndex((el) => !el.devisInit);
      _rows[indWithoutPoste].tasks = [...tasks];
    }
  }

  function onChangeOneTaskInRow(task: DevisProdRow) {
    const _tasks = [...tasksByTemplate];
    if (task.id) {
      const ind = _tasks.findIndex((el) => el.id === task.id);
      _tasks[ind] = task;
      setTasksByTemplate(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  }

  const onChangeTaskVisibleInRetro = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...tasksByTemplate];
    if (_tasks[index]) {
      _tasks[index].visibleInRetro = e.target.checked;
      setTasksByTemplate(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskCalculatedAsAmount = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...tasksByTemplate];
    if (_tasks[index]) {
      _tasks[index].calculatedAsAmount = e.target.checked;
      if (!e.target.checked) {
        _tasks[index].amount = 0;
      }
      setTasksByTemplate(_tasks);
      setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
    }
  };

  const onChangeTaskAmount = (amount: number, index: number) => {
    const _tasks = [...tasksByTemplate];
    if (_tasks[index]) {
      _tasks[index].amount = amount;
      setTasksByTemplate([..._tasks]);
      setDevisRowsWithTasks(getDevisRowsWithTasks([..._tasks]));
    }
  };

  function onDeleteClick(index: number) {
    const _tasks = [...tasksByTemplate];
    _tasks.splice(index, 1);
    setTasksByTemplate(_tasks);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
  }

  function onDeleteAllTasksClick() {
    const _tasks: DevisProdRow[] = [];
    setTasksByTemplate(_tasks);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
  }

  if (!devisInit) return null;

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: i18n._('task.groupped.type'),
      children: (
        <>
          {devisRowsWithTasks.map((el, index) => (
            <TasksGrouped
              functionTypes={functionTypes}
              postesByDevis={postesByDevis}
              key={index}
              tasks={el.tasks}
              onAddTaskInRow={onAddTaskInRow}
              onDeleteTask={onDeleteTasksInRow}
              onOneTaskChange={onChangeOneTaskInRow}
              onChangeDurationExpected={(value) =>
                handleChangeRowDevisInitDurationExpected(
                  value,
                  el.devisInit?.id
                )
              }
              row={el.devisInit}
            />
          ))}
        </>
      ),
    },
  ];

  function handleChangeRowDevisInitDurationExpected(
    durationExpected: number,
    rowDevisInitId?: number
  ) {
    if (!rowDevisInitId) return;

    const changedDevisInit = [...changedDevisInitDurationExpected];
    const indInChanged = changedDevisInit.findIndex(
      (el) => el.devisInitId === rowDevisInitId
    );
    if (indInChanged !== -1) {
      changedDevisInit[indInChanged].durationExpected = durationExpected;
    } else {
      changedDevisInit.push({
        devisInitId: rowDevisInitId,
        durationExpected: durationExpected,
      });
    }
    setChangedDevisInitDurationExpected(changedDevisInit);
  }

  function handleAddTaskClick(index: number) {
    const newElement: DevisProdRow = {
      id: addedCount,
      functionType: undefined,
      amount: 0,
      duration: 0,
      durationDays: 0,
      durationClientDays: 0,
      devisInitId: undefined,
      taskName: '',
      visibleInRetro: true,
      supp: false,
      calculatedAsAmount: false,
      order: tasksByTemplate.length,
    };
    const _tasks = addElementAfterIndex(tasksByTemplate, index, newElement);

    for (let i = 0; i < _tasks.length; i++) {
      _tasks[i].order = i + 1;
    }
    setTasksByTemplate(_tasks);
    setAddedCount((old) => old - 1);
    setDevisRowsWithTasks(getDevisRowsWithTasks(_tasks));
  }

  function devisCanBeSave(rows: TTaskInDevisTemplate[]): boolean {
    let result = true;
    const _devisRows = [...rows];
    const rowsWithoutPoste = _devisRows.filter((el) => !el.devisInit);
    for (const row of rowsWithoutPoste) {
      const existeTasks = row.tasks.filter(
        (t) => t.functionType && t.functionType !== FunctionType.CLIENT
      );
      if (existeTasks.length > 0) {
        result = false;
      }
    }

    return result;
  }
  //  disabled={task.functionType !== undefined}
  return (
    <Container>
      <Form
        {...layout}
        ref={formRef}
        name="add-devis-prod-templ"
        initialValues={{
          dateSigned: null,
          dateFinish: null,
          marge: currentProject?.marginPercent ?? 0,
        }}
        style={{ width: '100%' }}
      >
        <SDivBtn>
          <Button
            style={{ marginRight: '10px' }}
            htmlType="submit"
            onClick={close}
            type="default"
          >
            <Trans id="common.actions.cancel" />
          </Button>
          <Tooltip
            title={
              !devisCanBeSave(devisRowsWithTasks)
                ? i18n._('form.btn.cant.save')
                : i18n._('form.btn.save')
            }
          >
            <Button
              htmlType="submit"
              disabled={!devisCanBeSave(devisRowsWithTasks)}
              style={{ width: '200px' }}
              onClick={submit}
              type="primary"
            >
              <Trans id="devis.prod.save" />
            </Button>
          </Tooltip>
        </SDivBtn>

        <SDiv>
          <Form.Item
            name="dateSigned"
            style={{ width: '100%', margin: '10px 10px' }}
            label={i18n._('form.date.prod')}
          >
            <ConfigProvider locale={frFR}>
              <DatePicker
                format={dateFormat}
                onChange={onChangeDate}
                minDate={dayjs().subtract(2, 'year')}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item
            name="dateFinish"
            style={{ width: '100%', margin: '10px 10px' }}
            label={i18n._('form.date.prod.end')}
          >
            <ConfigProvider locale={frFR}>
              <DatePicker
                format={dateFormat}
                onChange={onChangeDateFinish}
                minDate={dayjs().subtract(2, 'year')}
              />
            </ConfigProvider>
          </Form.Item>
        </SDiv>

        <SDiv>
          <Tooltip title={i18n._('form.marge.expected.tipp')}>
            <Form.Item
              name="marge"
              style={{ minWidth: '350px', margin: '10px 10px' }}
              label={i18n._('form.marge.expected')}
            >
              <InputNumber
                style={{ width: '100px', marginRight: '10px' }}
                placeholder={i18n._('form.marge.placeholder')}
                controls={false}
                min={0}
                disabled
                addonAfter="%"
              />
            </Form.Item>
          </Tooltip>
          <Tooltip title={i18n._('form.marge.prod.tipp')}>
            <Text style={{ margin: '10px' }}>
              {i18n._('form.marge.prod') + ' :'}
            </Text>
            <InputNumber
              style={{ width: '100px', marginRight: '10px' }}
              controls={false}
              min={0}
              disabled
              value={
                100 -
                Math.round(
                  (tasksByTemplate
                    .filter((t) => t.functionType !== FunctionType.CLIENT)
                    .reduce(function (acc, obj) {
                      return acc + obj.duration;
                    }, 0) *
                    100) /
                    devisInitDurationExpected
                )
              }
              addonAfter="%"
            />
          </Tooltip>
          <Button
            disabled={devisRowsWithTasks.length === 0}
            onClick={onDeleteAllTasksClick}
            type="primary"
          >
            <Trans id="devis.prod.delete.all" />
          </Button>
        </SDiv>

        <SDivTasks>
          <SDevisTable>
            <SDevisTableHead>
              <tr>
                <SDevisTh colSpan={3} $minWidth="420px">
                  <SDivDetails>
                    Total
                    <InputNumber
                      style={{
                        width: '150px',
                        margin: '5px',
                        fontWeight: 'bold',
                      }}
                      placeholder={i18n._('form.amount.placeholder')}
                      value={devisInitAmount}
                      controls={false}
                      step="0.01"
                      disabled={true}
                      addonAfter="€"
                    />
                    <DurationItem
                      $width="120px"
                      $bold
                      duration={devisInitDurationExpected}
                    />
                  </SDivDetails>
                  <ButtonAddTask onClick={() => handleAddTaskClick(-1)} />
                </SDevisTh>

                <SDevisTh $minWidth="120px" $padding="0">
                  <SDivDurationHeader>
                    <DurationItem
                      $width="100%"
                      $bold
                      duration={tasksByTemplate
                        .filter((t) => t.functionType !== FunctionType.CLIENT)
                        .reduce(function (acc, obj) {
                          return acc + obj.duration;
                        }, 0)}
                    />
                  </SDivDurationHeader>
                </SDevisTh>
                <Tooltip title={i18n._('table.tipp.retro')}>
                  <SDevisTh>In retro ?</SDevisTh>
                </Tooltip>
                <Tooltip title={i18n._('table.tipp.calc-as-somme')}>
                  <SDevisTh $textAlign="center">somme?</SDevisTh>
                </Tooltip>
                <Tooltip title={i18n._('table.tipp.somme')}>
                  <SDevisTh $minWidth="130px">Somme</SDevisTh>
                </Tooltip>
              </tr>
            </SDevisTableHead>

            <tbody>
              {tasksByTemplate.map((task, index) => (
                <tr key={index}>
                  <SDevisTd
                    $minHeight="34px"
                    $height="34px"
                    $textAlign="center"
                  >
                    <Input
                      placeholder={i18n._('form.task-name.placeholder')}
                      onChange={(e) => onChangeTaskName(e.target.value, index)}
                      value={task.taskName}
                    />
                    <ButtonAddTask onClick={() => handleAddTaskClick(index)} />
                  </SDevisTd>
                  <SDevisTd>
                    <Select
                      showSearch
                      allowClear
                      style={{ width: '100%' }}
                      disabled={task.functionType === FunctionType.CLIENT}
                      onChange={async (value) => onChangePoste(value, index)}
                      value={task.devisInitId}
                      status={
                        !task.posteId &&
                        task.functionType !== FunctionType.CLIENT
                          ? 'warning'
                          : ''
                      }
                      optionFilterProp="label"
                      options={postesByDevis.map((item) => ({
                        value: item.devisInitId,
                        label: `${i18n._(item.label)} ${item.amount}`,
                      }))}
                    />
                  </SDevisTd>
                  <SDevisTd>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      value={
                        functionTypes.find(
                          (_el) => _el.name === task.functionType
                        )?.id
                      }
                      disabled={task.functionType !== undefined}
                      optionFilterProp="label"
                      options={
                        task.functionType !== undefined
                          ? functionTypes.map((item) => ({
                              value: item.id,
                              label: i18n._(item.name),
                            }))
                          : functionTypes
                              .filter((f) => f.name === 'CLIENT')
                              .map((item) => ({
                                value: item.id,
                                label: i18n._(item.name),
                              }))
                      }
                      onChange={async (value) =>
                        onChangeFunctionType(value, index)
                      }
                    />
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    {task.functionType === FunctionType.CLIENT ? (
                      <>
                        <InputNumber
                          style={{
                            width: '150px',
                            margin: '5px',
                            fontWeight: 'bold',
                          }}
                          placeholder={i18n._('form.amount.placeholder')}
                          value={task.durationClientDays}
                          controls={false}
                          addonAfter="j"
                          min={1}
                          onChange={(value) =>
                            onChangeTaskDurationDays(value ?? 1, index)
                          }
                        />
                      </>
                    ) : (
                      <DurationItem
                        $allowCorrection
                        duration={task.duration}
                        onChange={(value) => onChangeTaskDuration(value, index)}
                      />
                    )}
                  </SDevisTd>
                  <SDevisTd $textAlign="center">
                    <Checkbox
                      checked={task.visibleInRetro}
                      onChange={(e) => onChangeTaskVisibleInRetro(e, index)}
                    />
                  </SDevisTd>

                  {task.functionType === FunctionType.CLIENT ? (
                    <SDevisTd></SDevisTd>
                  ) : (
                    <SDevisTd $textAlign="center">
                      <Checkbox
                        checked={task.calculatedAsAmount}
                        onChange={(e) => {
                          onChangeTaskCalculatedAsAmount(e, index);
                        }}
                      />
                    </SDevisTd>
                  )}

                  <SDevisTd $textAlign="center">
                    {task.calculatedAsAmount && (
                      <InputNumber
                        style={{
                          width: '120px',
                          margin: '5px',
                          fontWeight: 'bold',
                        }}
                        placeholder={i18n._('form.amount.placeholder')}
                        value={task.amount}
                        step="0.01"
                        controls={false}
                        min={0}
                        onChange={(value) => {
                          onChangeTaskAmount(value ?? 0, index);
                        }}
                        addonAfter="€"
                      />
                    )}
                  </SDevisTd>
                  <SDevisTd>
                    <Tooltip title="supprimer">
                      <Button
                        type="default"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => onDeleteClick(index)}
                      />
                    </Tooltip>
                  </SDevisTd>
                </tr>
              ))}
            </tbody>
          </SDevisTable>
          <Collapse items={items} />
        </SDivTasks>
      </Form>
    </Container>
  );
}

export default AddDevisProdTemplate;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: auto;
  width: 100%;
`;

const SDivBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const SDivTasks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const SDivDurationHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
`;

const SDivDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  & > div > div > div > div > input {
    font-weight: bold;
  }
`;
