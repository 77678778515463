import { useLingui } from '@lingui/react';
import { ConfigProvider, Empty, Table } from 'antd';
import type { TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Key } from 'react';
import styled from 'styled-components';
import { classic } from '~/themes';
import HeaderTable from './HeaderTable';

type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

type IProps = {
  data: any[];
  loading: boolean;
  total: number;
  columns: any[];
  xScroll?: string;
  yScroll: boolean;
  showHeader: boolean;
  headerTitle: string;
  showFooter: boolean;
  footerTitle?: string;
  selectionType: 'checkbox' | 'radio';
  top: TablePaginationPosition | 'none';
  bottom: TablePaginationPosition;
  addButton?: boolean;
  deleteButton?: boolean;
  editButton?: boolean;
  importButton?: boolean;
  exportButton?: boolean;
  search?: boolean;
  page: number;
  pageSize: number;
  setSortedInfo: (sortedInfo: SorterResult<any> | SorterResult<any>[]) => void;
  setFilterInfo?: (filterInfo: Record<string, FilterValue | null>) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setOpenAdd?: (openAdd: boolean) => void;
  setOpenEdit?: (openEdit: boolean) => void;
  setOpenImport?: (openImport: boolean) => void;
  setOpenDelete?: (openDelete: boolean) => void;
  setSearchText?: (searchText: string) => void;
  exportCsv?: () => void;
  setRowSelect?: (rowSelect: any[]) => void;
  refresh: () => void;
  onDblClick?: (id: number) => void;
  setRecordId?: (id: number) => void;
};

function CommonTable({
  data,
  columns,
  xScroll,
  yScroll,
  showHeader,
  showFooter,
  footerTitle,
  selectionType,
  headerTitle,
  top,
  bottom,
  addButton,
  deleteButton,
  editButton,
  importButton,
  exportButton,
  search,
  setPage,
  setPageSize,
  setOpenAdd,
  setOpenEdit,
  setOpenDelete,
  setOpenImport,
  exportCsv,
  setRowSelect,
  setSearchText,
  loading,
  total,
  page,
  pageSize,
  setSortedInfo,
  setFilterInfo,
  refresh,
  onDblClick,
  setRecordId,
}: IProps) {
  const scroll: { x?: number | string; y?: number | string } = {};
  const { i18n } = useLingui();

  if (yScroll) {
    scroll.y = '58vh';
  }
  if (xScroll) {
    scroll.x = '100vw';
  }

  const tableColumns = columns.map((item) => ({ ...item }));

  if (xScroll === 'fixed') {
    tableColumns[0].fixed = true;
    tableColumns[tableColumns.length - 1].fixed = 'right';
  }
  const defaultFooter = () => (footerTitle ? footerTitle : 'Here is footer');

  const tableProps: TableProps = {
    showHeader,
    footer: showFooter ? defaultFooter : undefined,
    scroll,
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (_selectedRowKeys: Key[], selectedRows: any[]) => {
      setRowSelect && setRowSelect(selectedRows);
    },
  };

  const handleChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setSortedInfo(sorter);
    setFilterInfo && setFilterInfo(_filters);
  };

  return (
    <div style={{ height: '100%' }}>
      <HeaderTable
        title={headerTitle}
        addButton={addButton}
        deleteButton={deleteButton}
        editButton={editButton}
        importButton={importButton}
        exportButton={exportButton}
        search={search}
        setOpenAdd={setOpenAdd}
        setOpenEdit={setOpenEdit}
        setOpenDelete={setOpenDelete}
        setOpenImport={setOpenImport}
        setSearchText={setSearchText}
        exportCsv={exportCsv}
      />
      <ConfigProvider
        renderEmpty={() => <Empty description={i18n._('no.content')} />}
      >
        <STable
          {...tableProps}
          size="small"
          rowSelection={
            setRowSelect && {
              type: selectionType,
              ...rowSelection,
            }
          }
          locale={{
            filterConfirm: i18n._('common.confirm'),
            filterReset: i18n._('common.reset'),
            emptyText: i18n._('common.empty'),
            triggerDesc: i18n._('desc.sort'),
            triggerAsc: i18n._('asc.sort'),
            cancelSort: i18n._('cancel.sort'),
          }}
          columns={tableColumns}
          rowCursorPointer={onDblClick ? true : false}
          onRow={(record) => {
            return {
              onClick: () => {
                setRecordId?.(record.id);
                onDblClick && onDblClick(record.id);
              },
              onDoubleClick: () => {
                setRecordId?.(record.id);
                onDblClick && onDblClick(record.id);
              },
            };
          }}
          pagination={{
            position: [top as TablePaginationPosition, bottom],
            pageSize: pageSize,
            current: page,
            total: total,
            onChange: (_page, _pageSize) => {
              setPage(_page);
              setPageSize(_pageSize);
              refresh();
            },
          }}
          dataSource={data.map((e) => {
            return {
              ...e,
              key: e.uuid ?? e.id,
            };
          })}
          scroll={scroll}
          loading={loading}
          onChange={handleChange}
        />
      </ConfigProvider>
    </div>
  );
}

export default CommonTable;

const STable = styled(Table)<{ rowCursorPointer?: boolean }>`
  &&& .ant-table-title {
    background: ${classic.token?.colorPrimary};
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }
  &&& .ant-table-column-sort {
    background: none;
  }
  &&& .ant-table-column-has-sorters {
    background: #f0f0f0;
  }

  &&& th.ant-table-column-has-sorters:hover {
    background: unset;
  }
  &&& .ant-table-cell {
    cursor: ${({ rowCursorPointer }) =>
      rowCursorPointer ? 'pointer' : 'auto'};
  }
`;
