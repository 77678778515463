import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { scalars } from '~/gql/utils/scalars';

export const usersSelector = Selector('Users')({
  email: true,
  id: true,
  updatedAt: true,
  uuid: true,
  firstName: true,
  lastName: true,
  role: {
    name: true,
    id: true,
  },
});

export const usersInfoForDevisSelector = Selector('UserInfoForDevis')({
  email: true,
  id: true,
  updatedAt: true,
  uuid: true,
  firstName: true,
  lastName: true,
  hasTimes: true,
  role: {
    name: true,
    id: true,
  },
});

const usersPaginationSelector = Selector('UserPagination')({
  users: usersSelector,
  total: true,
});

export const GQL_ADD_USER = typedGql('mutation')({
  createUser: [
    {
      createUserInput: $('createUserInput', 'CreateUserInput!'),
    },
    usersSelector,
  ],
});

export const GQL_UPDATE_USER = typedGql('mutation')({
  updateUser: [
    { updateUserInput: $('updateUserInput', 'UpdateUserInput!') },
    usersSelector,
  ],
});

export const GQL_DELETE_USER = typedGql('mutation')({
  deleteUser: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_USER = typedGql('query')({
  getUser: [{ id: $('id', 'Int!') }, usersSelector],
});

export const GQL_USERS_WITH_FILTERS = typedGql('query')({
  getFilteredUsers: [
    {
      findManyUserWithFiltersInput: $('input', 'FindManyUserWithFiltersInput!'),
    },
    usersPaginationSelector,
  ],
});

export const GQL_USERS = typedGql('query', { scalars })({
  getUsers: {
    ...usersSelector,
  },
});

export type StoredUsers = InputType<
  GraphQLTypes['Users'],
  typeof usersSelector
>;

export type StoredUsersInfoForDevis = InputType<
  GraphQLTypes['UserInfoForDevis'],
  typeof usersInfoForDevisSelector
>;
