import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';

type IProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  onClick?: () => void;
  minWidth?: string;
};

export default function Frame({ children, isOpen, onClick, minWidth }: IProps) {
  return (
    <SDivFrame $isOpen={isOpen} $minWidth={minWidth}>
      <Wrapper $isOpen={isOpen}>
        <Container $isOpen={isOpen}>{children}</Container>
        <SDivSlider onClick={onClick}>
          {isOpen ? <LeftOutlined /> : <RightOutlined />}
        </SDivSlider>
      </Wrapper>
    </SDivFrame>
  );
}

const SDivFrame = styled.div<{ $isOpen?: boolean; $minWidth?: string }>`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #4a4a4a80;
  min-width: ${({ $isOpen, $minWidth }) =>
    !$isOpen ? '20px' : $minWidth ? $minWidth : '760px'};
  margin-right: 10px;
  width: ${({ $isOpen }) => ($isOpen ? 'auto' : '20px')};
  transition: all 0.3s linear;
`;
const Wrapper = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  min-height: 100%;
  overflow: hidden;
  width: ${({ $isOpen }) => ($isOpen ? 'auto' : '20px')};
`;

const SDivSlider = styled.div`
  align-items: center;
  background-color: #0b446f17;
  color: black;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 20px;
`;

const Container = styled.div<{ $isOpen?: boolean }>`
  flex-grow: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  overflow-y: auto;
  overflow-x: auto;
  padding: ${({ $isOpen }) => ($isOpen ? '10px' : '0px')};

  width: ${({ $isOpen }) => ($isOpen ? 'auto' : '0px')};
`;
