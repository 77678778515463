import { FunctionType } from '@zeus/index';
import { StoredHolidays } from '~/gql/holidays/holidays';
import { StoredTaskInfo } from '~/gql/task/task';
import { StoredTaskInfoForPlanning } from '~/gql/task-planning/task-planning';
import { StoredUsers } from '~/gql/users/users';
import { TDevisInitRowExtendedCurrentOrder } from '~/pages/components/common/devis/EditDevisInitP';
import {
  DevisInitRow,
  DevisProdRow,
  TaskInfoWithPlanningCalender,
  TDate,
  TDatePlanning,
  TUsersByFunctionType,
} from '~/pages/types/types';
import { classic } from '~/themes';
import { getHoursStrByDuration } from '~/utils/utils/utils';

export function getUsersListWithoutUsersWithTimeByFunctionType(
  userList: StoredUsers[],
  usersWithTimeByFunctionType: TUsersByFunctionType[],
  functionType: FunctionType
): StoredUsers[] {
  const _userList = [...userList];
  const _users = usersWithTimeByFunctionType.find(
    (el) => el.functionType === functionType
  );
  if (_users) {
    for (const uId of _users.usersIds) {
      const ind = _userList.findIndex((el) => el.id === uId);
      if (ind !== -1) {
        _userList.splice(ind, 1);
      }
    }
  }

  return _userList;
}
export function getUsersListWithoutUsersWithTime(
  userList: StoredUsers[],
  userWithTimeIds: number[]
): StoredUsers[] {
  const _userList = [...userList];

  if (userWithTimeIds.length > 0) {
    for (const uId of userWithTimeIds) {
      const ind = _userList.findIndex((el) => el.id === uId);
      if (ind !== -1) {
        _userList.splice(ind, 1);
      }
    }
  }

  return _userList;
}

export function getNewDates(
  item: StoredTaskInfoForPlanning,
  count: number
): { date: Date; duration: number; id?: number }[] {
  const average = item.duration / count;
  for (let j = 0; j < item.calendar.length; j++) {
    if (item.calendar[j].checked)
      item.calendar[j].label = getHoursStrByDuration(Math.round(average));
  }
  const _dates: { date: Date; duration: number; id?: number }[] = item.calendar
    .filter((el) => el.checked)
    .map((d) => {
      return {
        date: d.date as Date,
        duration: Math.round(average),
        ...(d.id && { id: d.id }),
      };
    });

  const calcDuration = _dates.reduce(function (acc, obj) {
    return acc + obj.duration;
  }, 0);
  const deltaDuration = calcDuration - item.duration;

  if (deltaDuration !== 0 && _dates.length > 0) {
    _dates[_dates.length - 1].duration =
      _dates[_dates.length - 1].duration - deltaDuration;
  }

  return _dates;
}
export function getColorForDuration(duration: number): string {
  if (duration >= 0) {
    return `${classic.token?.colorSuccess}`;
  } else return `${classic.token?.colorWarning}`;
}
//
export function fillRetroCalendar(
  _startDate: Date | undefined,
  _finishDate: Date | undefined,
  _tasks: StoredTaskInfo[],
  _holidays: StoredHolidays[]
): TDate[] {
  // _days sera le nombre de jours à partir de la durée de tâches dans le devis prod + 60 jours supplémentaires
  // ou la diff entre startDate et endDate
  const _days =
    !_startDate || !_finishDate
      ? getDaysByDuration(_tasks) + 60
      : getWorkingDays(_startDate, _finishDate, _holidays) ?? 60;
  const _calendar: TDate[] = [];
  for (let i = 0; i < _days; i++) {
    _calendar.push({
      name: `j${i + 1}`,
    });
  }
  return _calendar;
}

export function fillPlanningCalendar(
  _startDate: Date,
  _finishDate: Date | undefined,
  daysCount: number,
  _holidays: StoredHolidays[]
): TDatePlanning[] {
  // daysCount sera le nombre de jours à partir de la durée de tâches dans le devis prod + 60 jours supplémentaires
  // ou la diff entre startDate et endDate
  const workingDays = !_finishDate
    ? daysCount + 60
    : getWorkingDays(_startDate, _finishDate, _holidays) ?? 60;

  const _calendar: TDatePlanning[] = [];
  let addedWorkingDays = 0;
  let addedDays = 0;
  // à partir la date de Production
  while (addedWorkingDays < workingDays) {
    const date = new Date(_startDate);

    date.setDate(new Date(_startDate).getDate() + addedDays);
    const isHolidays = isDateHolidays(date, _holidays);
    const jourSemaine = date.getDay();

    if (jourSemaine !== 0 && jourSemaine !== 6 && !isHolidays) {
      _calendar.push({
        date: date,
        selectible: true,
        retroChecked: false,
        checked: false,
        duration: 0,
        label: '',
      });
      addedWorkingDays++;
    } else {
      _calendar.push({
        date: date,
        selectible: false,
        retroChecked: false,
        checked: false,
        duration: 0,
        label: '',
      });
    }

    addedDays++;
  }

  return _calendar;
}

export function getDayStatus(
  date: Date,
  holidays: StoredHolidays[]
): 'normal' | 'week-end' | 'free' {
  const isHolidays = isDateHolidays(date, holidays);
  const jourSemaine = new Date(date).getDay();

  if (jourSemaine === 0 || jourSemaine === 6) return 'week-end';
  if (isHolidays) return 'free';
  return 'normal';
}

export function isDateHolidays(
  date: Date,
  holidays: StoredHolidays[]
): boolean {
  const mDate = new Date(date).setHours(8, 0, 0, 0);
  const mHolidays: number[] = holidays.map((el) => {
    return new Date(el.date as Date).setHours(8, 0, 0, 0);
  });

  return mHolidays.includes(mDate);
}

export function getWorkingDays(
  _startDate: Date,
  _endDate: Date,
  _holidays: StoredHolidays[]
): number | undefined {
  const startDate = new Date(_startDate);
  const endDate = new Date(_endDate);

  if (startDate > endDate) {
    return undefined;
  }

  let count = 0;
  const currentDate = new Date(startDate);
  const isHolidays = isDateHolidays(currentDate, _holidays);

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6 && !isHolidays) {
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return count;
}

export function addElementAfterIndex(
  array: DevisProdRow[],
  index: number,
  newElement: DevisProdRow
): DevisProdRow[] {
  // si index === -1 on ajoute au début de la liste
  if (index >= array.length) {
    return [];
  }
  const newArray = array.slice(0, index + 1);
  newArray.push(newElement);
  newArray.push(...array.slice(index + 1));

  return newArray;
}

export function getDaysByDuration(_tasks: StoredTaskInfo[]): number {
  const _duration = _tasks.reduce(function (acc, obj) {
    return acc + obj.duration;
  }, 0);

  return Math.round(_duration / 60 / 7);
}

export function getUsersByFunctionTypeForTasks(
  tasks: TaskInfoWithPlanningCalender[]
): TUsersByFunctionType[] {
  const result: TUsersByFunctionType[] = [];

  const _tasks = tasks.filter((el) => el.functionType !== FunctionType.CLIENT);
  for (const task of _tasks) {
    const ind = result.findIndex((el) => el.functionType === task.functionType);
    if (ind === -1) {
      const newItem: TUsersByFunctionType = {
        functionType: task.functionType,
        usersIds: task.user?.map((el) => el.id) ?? [],
      };
      result.push(newItem);
    } else {
      if (task.user) {
        for (const user of task.user) {
          if (!result[ind].usersIds.includes(user.id))
            result[ind].usersIds = [...result[ind].usersIds, user.id];
        }
      }
    }
  }
  return result;
}

export function getUsersByFunctionTypeForTasksExt(
  tasks: StoredTaskInfoForPlanning[]
): {
  usersWithoutTime: TUsersByFunctionType[];
  usersWithTime: TUsersByFunctionType[];
} {
  const resultWithoutTime: TUsersByFunctionType[] = [];
  const resultWithTime: TUsersByFunctionType[] = [];

  const _tasks = tasks.filter((el) => el.functionType !== FunctionType.CLIENT);
  for (const task of _tasks) {
    const indWithout = resultWithoutTime.findIndex(
      (el) => el.functionType === task.functionType
    );

    if (indWithout === -1) {
      const newItem: TUsersByFunctionType = {
        functionType: task.functionType,
        usersIds:
          task.user?.filter((el) => !el.hasTimes).map((el) => el.id) ?? [], // ajouter uniquement ce qui n'ont pas
      };
      resultWithoutTime.push(newItem);
    } else {
      if (task.user) {
        for (const user of task.user) {
          if (!user.hasTimes) {
            if (!resultWithoutTime[indWithout].usersIds.includes(user.id)) {
              if (
                !resultWithTime
                  .find((el) => el.functionType === task.functionType)
                  ?.usersIds.includes(user.id)
              )
                resultWithoutTime[indWithout].usersIds.push(user.id);
            }
          }
        }
      }
    }

    //avec Times
    const usersWithTime = task.user?.filter((el) => el.hasTimes);
    if (usersWithTime && usersWithTime.length > 0) {
      const indWith = resultWithTime.findIndex(
        (el) => el.functionType === task.functionType
      );
      if (indWith === -1) {
        const newItem: TUsersByFunctionType = {
          functionType: task.functionType,
          usersIds:
            task.user?.filter((el) => el.hasTimes).map((el) => el.id) ?? [],
        };
        resultWithTime.push(newItem);
      } else {
        if (task.user) {
          for (const user of task.user) {
            if (user.hasTimes) {
              if (!resultWithTime[indWith].usersIds.includes(user.id))
                resultWithTime[indWith].usersIds = [
                  ...resultWithTime[indWith].usersIds,
                  user.id,
                ];
            }
          }
        }
      }
    }
  }

  return { usersWithoutTime: resultWithoutTime, usersWithTime: resultWithTime };
}

export function isCanSave(
  rows: TDevisInitRowExtendedCurrentOrder[] | DevisInitRow[]
): boolean {
  const anotherDI: TDevisInitRowExtendedCurrentOrder[] | DevisInitRow[] = [
    ...rows,
  ].filter((el) => el.posteName === 'Autre');
  let isCanSaved = true;
  if (anotherDI.length === 0) true;
  for (const dI of anotherDI) {
    if (dI.customPosteName === '') isCanSaved = false;
  }
  return isCanSaved;
}

/*
  function compareByOrder(
    a: DevisInitRowExtended | TaskInfoWithCalender,
    b: DevisInitRowExtended | TaskInfoWithCalender
  ) {
    if (a.order !== undefined && b.order !== undefined) {
      return a.order - b.order;
    }

    return 0;
  }
*/
