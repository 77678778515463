import { FunctionType } from '@zeus/index';

type TDataTasks = {
  taskName: string;
  visibleInRetro: boolean;
  calculatedAsAmount: boolean;
  duration: number;
  supp: boolean;
  durationClientDays?: number;
  functionType: FunctionType;
};
export const tasksData: TDataTasks[] = [
  // Démarrage projet
  {
    taskName: 'Kick off',
    duration: 30,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Présentation note de cadrage + rétroplanning',
    duration: 70,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Envoi note de cadrage + rétroplanning',
    duration: 10,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Retours ',
    duration: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    durationClientDays: 2,
    supp: false,
  },
  {
    taskName: 'Ajustements & retours éventuels',
    duration: 120,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Réunion interne Equipe projet',
    duration: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    durationClientDays: 1,
    supp: false,
  },
  {
    taskName: 'Validation NDC + RP',
    duration: 0,
    durationClientDays: 3,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
  {
    taskName: 'Envoi et préparation des contenus',
    duration: 0,
    durationClientDays: 4,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
  // Ingénérie formation
  {
    taskName: 'Rédaction synopsis V1',
    duration: 20,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Relecture interne',
    duration: 30,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName:
      'Atelier de présentation du synopsis V1 + transmission des retours',
    duration: 30,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Envoi synopsis V2',
    duration: 20,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Relecture Synopsis V2',
    duration: 30,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Traitement des retours',
    duration: 300,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'Relecture interne',
    duration: 30,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Relecture interne',
    duration: 30,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'Relecture interne',
    duration: 30,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Ajustements éventuels',
    duration: 60,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'Envoi synopsis V2 + intentions graphiques',
    duration: 20,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Validation synopsis vdef',
    duration: 0,
    durationClientDays: 1,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },

  // GD
  {
    taskName: 'GD',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'GD Relecture interne',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },

  // Ingénérie pédagogique
  {
    taskName: 'Atelier Métier Storyboard',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },

  {
    taskName: 'Rédaction storyboard V1',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'Relecture interne',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Atelier présentation storyboard V1',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Rédaction storyboard V2',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'Envoi storyboard V2',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Relecture SB V2',
    duration: 0,
    durationClientDays: 1,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
  {
    taskName: 'Traitement des retours SB V2',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'Envoi SB VDEF',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IP_GAME,
    supp: false,
  },
  {
    taskName: 'Validation SB VDEF',
    duration: 0,
    durationClientDays: 1,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },

  // Graphisme
  {
    taskName: 'Réunion de prés des intentions graphiques',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.ARTI,
    supp: false,
  },
  {
    taskName: 'Validation (intentions graphique)',
    duration: 0,
    durationClientDays: 1,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
  {
    taskName: 'Prodiction UI UX',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.ARTI,
    supp: false,
  },
  {
    taskName: 'Envoi des assets et maquettes',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.GP,
    supp: false,
  },
  {
    taskName: 'Retouches éventuelles',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.ARTI,
    supp: false,
  },
  {
    taskName: 'Validation (assets et maquettes)',
    duration: 0,
    durationClientDays: 1,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
  {
    taskName: 'Dev back',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Dev front',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Tests MySG',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Ajustements back',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Ajustements front',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Tests client bêta',
    duration: 0,
    durationClientDays: 2,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
  {
    taskName: 'Ajustements back après test bêta',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Ajustements front après test bêta',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Tests client',
    duration: 0,
    durationClientDays: 2,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
  {
    taskName: 'Ajustements MySG',
    duration: 0,
    durationClientDays: 0,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.IT,
    supp: false,
  },
  {
    taskName: 'Livraison',
    duration: 0,
    durationClientDays: 2,
    visibleInRetro: true,
    calculatedAsAmount: false,
    functionType: FunctionType.CLIENT,
    supp: false,
  },
];
