import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ManagerLayout from '~/pages/layouts/ManagerLayout';

function ProjectPage() {
  return (
    <>
      <DefaultLayout>
        <ManagerLayout>La page n'est encore réalisée</ManagerLayout>
      </DefaultLayout>
    </>
  );
}

export default ProjectPage;
