import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import {
  GQL_EXPORT_USER_TIMES_FOR_PERIOD_BY_PROJECT,
  StoredProjectUserTimesInfo,
} from '~/gql/time/time';

import {
  GQL_TIMES_USER_PLANNING_FOR_PERIOD_BY_PROJECT,
  StoredTimeUserPlanningInfo,
} from '~/gql/user-planning/user-planning';

import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import { exportProjectDetails } from '~/pages/components/common/details/exportProjectDetails';
import {
  getMappedDataTasksTimesTable,
  fillCalendarDetailsTable,
  calcTotalPlanningDurationByDateAdmin,
  calcTotalTimeByDateAdmin,
  calcTotalPlanningDurationAdmin,
  calcTotalTimesAdmin,
} from '~/pages/components/common/utils-table/utils-project-details-table';

import {
  SDevisTh,
  STimeTd,
  SDevisTableHead,
  SDevisTableFoot,
  STr,
  SAdminTimeTd,
  SSpanPlanning,
  SSpanTime,
  STable,
} from '~/pages/components/styles/styles';

import TimesPeriodActions from '~/pages/components/ui/TimesPeriodActions';
import UserDetailsTimesItem from '~/pages/components/ui/UserTimesItem';

import {
  PeriodEnum,
  TCellTaskTimeAdmin,
  TDateTimes,
  TPeriodDates,
} from '~/pages/types/types';
import {
  getPrevWeek,
  getNextWeek,
  getPrevMonth,
  getNextMonth,
  getStartAndEndForMonth,
  getStartAndEndForWeek,
  getHoursAndMinutesStrByDuration,
} from '~/utils/utils/utils';

type IProps = {
  projectId: number;
};

//const dateFormat = 'wo-YYYY';

function ProjectDetailsTable({ projectId }: IProps) {
  const { i18n } = useLingui();
  const { currentProject } = useDataUserStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(Date.now()));
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.WEEK);
  const [calendar, setCalendar] = useState<TDateTimes[]>([]);
  const [withHoursPlanning, setWithHoursPlanning] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<Date>(
    getStartAndEndForMonth(new Date(Date.now())).start
  );
  const [endDate, setEndDate] = useState<Date>(
    getStartAndEndForMonth(new Date(Date.now())).end
  );
  const [mappedData, setMappedData] = useState<TCellTaskTimeAdmin[]>([]);
  const [exportData, setExportData] = useState<
    StoredProjectUserTimesInfo | undefined
  >(undefined);

  const { refetch: fetchExportUserTimes } = useQuery(
    GQL_EXPORT_USER_TIMES_FOR_PERIOD_BY_PROJECT,
    {
      variables: {
        projectId: projectId,
        startDate,
        endDate,
      },
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setExportData(data.getExportUserTimesForPeriodByProjectId);
      },
      onError: (e) => {
        console.log(
          'erreur dans GQL_EXPORT_USER_TIMES_FOR_PERIOD_BY_PROJECT',
          e
        );
      },
    }
  );

  const { refetch: fetchUserTimePlannings } = useQuery(
    GQL_TIMES_USER_PLANNING_FOR_PERIOD_BY_PROJECT,
    {
      variables: {
        projectId: projectId,
        startDate,
        endDate,
      },
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const _data: StoredTimeUserPlanningInfo = {
          userPlanning:
            data.getAllTimesUserPlanningsForPeriodByProjectId.userPlanning,
          time: data.getAllTimesUserPlanningsForPeriodByProjectId.time,
        };

        const _mapped = getMappedDataTasksTimesTable(_data, calendar);

        setMappedData(_mapped);
      },
      onError: (e) => {
        console.log(
          'erreur dans GQL_TIMES_USER_PLANNING_FOR_PERIOD_BY_PROJECT',
          e
        );
      },
    }
  );

  async function getUserTimePlannings() {
    setLoading(true);
    await fetchUserTimePlannings();
    setLoading(false);
  }

  async function getExportUserTimes() {
    setLoading(true);

    await fetchExportUserTimes();
    setLoading(false);
  }

  useEffect(() => {
    // init
    setSelectedDate(new Date(Date.now()));
    void getUserTimePlannings();
    void getExportUserTimes();
  }, []);

  useEffect(() => {
    const dates = getStartAndEndForWeek(new Date(selectedDate));
    setStartDate(dates.start);
    setEndDate(dates.end);
    setCalendar(fillCalendarDetailsTable(dates.start, dates.end));
  }, [selectedDate]);

  function handleRefresh() {
    void getUserTimePlannings();
  }

  function setPeriodAndFillDataByDates(_dates: TPeriodDates) {
    setStartDate(_dates.start);
    setEndDate(_dates.end);
    setCalendar(fillCalendarDetailsTable(_dates.start, _dates.end));
    handleRefresh();
  }

  function handlePrevClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getPrevWeek(startDate, endDate)
        : getPrevMonth(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handleNextClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getNextWeek(startDate, endDate)
        : getNextMonth(startDate);

    setPeriodAndFillDataByDates(dates);
  }

  function handleTodayClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getStartAndEndForWeek(new Date(Date.now()))
        : getStartAndEndForMonth(new Date(Date.now()));

    setPeriodAndFillDataByDates(dates);
  }

  function handleMonthClick() {
    setPeriod(PeriodEnum.MONTH);
    const dates = getStartAndEndForMonth(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handleWeekClick() {
    setPeriod(PeriodEnum.WEEK);
    const dates = getStartAndEndForWeek(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handlePrint() {
    const projectName = currentProject?.name ?? 'p';
    const shortName = currentProject?.name
      ? currentProject.name.length > 10
        ? currentProject.name.slice(0, 10)
        : currentProject.name
      : '';
    const fileName = currentProject
      ? `${shortName}-${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`
      : `p-${new Date().toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`;

    if (exportData)
      void exportProjectDetails({
        data: exportData,
        projectName,
        startDate,
        endDate,
        fileName,
      });
  }

  return (
    <>
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <>
          <TimesPeriodActions
            onPrintClick={handlePrint}
            onPrevClick={handlePrevClick}
            onTodayClick={handleTodayClick}
            onNextClick={handleNextClick}
            onWeekClick={handleWeekClick}
            onMonthClick={handleMonthClick}
            period={period}
            withHoursPlanning={withHoursPlanning}
            onChangeWithHoursPlanning={setWithHoursPlanning}
          />
          <STable>
            <SDevisTableHead>
              <tr>
                {calendar.map((el, index) => (
                  <SDevisTh $fontSize="12px" $textAlign="center" key={index}>
                    {el.date.toLocaleDateString()}
                  </SDevisTh>
                ))}
                <SDevisTh $textAlign="center" $minWidth="90px">
                  {i18n._('common.task.total')}
                </SDevisTh>
              </tr>
            </SDevisTableHead>

            <tbody>
              {mappedData.map((item, ind) => (
                <STr key={ind}>
                  {item.calendar.map((el, indInCalender) => (
                    <SAdminTimeTd $width="160px" key={indInCalender}>
                      {(el.planningDuration && el.planningDuration > 0) ??
                      (el.time && el.time > 0) ? (
                        <UserDetailsTimesItem
                          userFirstName={item.userFirstName}
                          userLastName={item.userLastName}
                          planningDuration={el.planningDuration ?? 0}
                          time={el.time ?? 0}
                          taskName={item.taskName}
                          withHoursPlanning={withHoursPlanning}
                        />
                      ) : (
                        ''
                      )}
                    </SAdminTimeTd>
                  ))}
                  <STimeTd $textAlign="center">
                    <SDivTimeTotal>
                      {withHoursPlanning && (
                        <>
                          <SSpanPlanning $fontWeight="bold">
                            {getHoursAndMinutesStrByDuration(
                              item.calendar.reduce(function (
                                acc: any,
                                obj: TDateTimes
                              ) {
                                return obj.planningDuration !== undefined
                                  ? acc + obj.planningDuration
                                  : acc;
                              }, 0)
                            )}
                          </SSpanPlanning>
                          {' / '}
                        </>
                      )}

                      <SSpanTime
                        $fontWeight="bold"
                        $opaque={
                          item.calendar.reduce(function (
                            acc: any,
                            obj: TDateTimes
                          ) {
                            return obj.time !== undefined
                              ? acc + obj.time
                              : acc;
                          }, 0) === 0
                        }
                      >
                        {getHoursAndMinutesStrByDuration(
                          item.calendar.reduce(function (
                            acc: any,
                            obj: TDateTimes
                          ) {
                            return obj.time !== undefined
                              ? acc + obj.time
                              : acc;
                          }, 0)
                        )}
                      </SSpanTime>
                    </SDivTimeTotal>
                  </STimeTd>
                </STr>
              ))}
            </tbody>
            <SDevisTableFoot>
              <tr>
                {calendar.map((c, index) => (
                  <STimeTd $textAlign="center" key={index}>
                    <SDivTimeTotalDate>
                      {withHoursPlanning && (
                        <>
                          <SSpanPlanning $fontWeight="bold">
                            {getHoursAndMinutesStrByDuration(
                              calcTotalPlanningDurationByDateAdmin(
                                c.date,
                                mappedData
                              )
                            )}
                          </SSpanPlanning>
                          {' / '}
                        </>
                      )}

                      <SSpanTime $fontWeight="bold">
                        {getHoursAndMinutesStrByDuration(
                          calcTotalTimeByDateAdmin(c.date, mappedData)
                        )}
                      </SSpanTime>
                    </SDivTimeTotalDate>
                  </STimeTd>
                ))}
                <STimeTd $textAlign="center">
                  <SDivTimeTotal>
                    {withHoursPlanning && (
                      <>
                        <SSpanPlanning $fontWeight="bold">
                          {getHoursAndMinutesStrByDuration(
                            calcTotalPlanningDurationAdmin(mappedData)
                          )}
                        </SSpanPlanning>
                        {' / '}
                      </>
                    )}

                    <SSpanTime $fontWeight="bold">
                      {getHoursAndMinutesStrByDuration(
                        calcTotalTimesAdmin(mappedData)
                      )}
                    </SSpanTime>
                  </SDivTimeTotal>
                </STimeTd>
              </tr>
            </SDevisTableFoot>
          </STable>
        </>
      )}
    </>
  );
}

export default ProjectDetailsTable;

const SDivTimeTotal = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SDivTimeTotalDate = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
