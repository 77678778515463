import SuperProjectRenta from '~/pages/components/common/details/SuperProjectRenta';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import SuperProjectLayout from '~/pages/layouts/SuperProjectLayout';

function SuperProjectRentaPage() {
  return (
    <>
      <DefaultLayout>
        <SuperProjectLayout>
          <SuperProjectRenta />
        </SuperProjectLayout>
      </DefaultLayout>
    </>
  );
}

export default SuperProjectRentaPage;
