/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { DeleteOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { Trans, useLingui } from '@lingui/react';

import type { DatePickerProps, FormInstance } from 'antd';
import { Button, DatePicker, Form, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import {
  GQL_GET_HOLIDAYS_FOR_PERIOD,
  GQL_DELETE_HOLIDAYS,
  StoredHolidays,
} from '~/gql/holidays/holidays';
import { authClient } from '~/helpers/apollo';
import AddHolidays from '~/pages/components/common/settings/AddHolidays';
import EditHolidays from '~/pages/components/common/settings/EditHolidays';
import { TPeriodDates } from '~/pages/types/types';
import { classic } from '~/themes/classic';
import { getStartAndEndForYear } from '~/utils/utils/utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function Holidays() {
  const { Text } = Typography;
  const { i18n } = useLingui();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const [holidays, setHolidays] = useState<StoredHolidays[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    getStartAndEndForYear(
      dayjs(new Date(Date.now()) as unknown as string).year()
    ).start
  );
  const [endDate, setEndDate] = useState<Date>(
    getStartAndEndForYear(
      dayjs(new Date(Date.now()) as unknown as string).year()
    ).end
  );
  const formRef = React.useRef<FormInstance>(null);

  const [deleteHolidays] = useMutation(GQL_DELETE_HOLIDAYS, {
    client: authClient,
  });

  function compareDate(obj1: StoredHolidays, obj2: StoredHolidays) {
    if (obj1.date !== undefined && obj2.date !== undefined) {
      return (
        new Date(obj1.date as Date).getTime() -
        new Date(obj2.date as Date).getTime()
      );
    }

    return 0;
  }

  const { refetch: fetchHolidays } = useQuery(GQL_GET_HOLIDAYS_FOR_PERIOD, {
    variables: {
      startDate,
      endDate,
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setHolidays([...data.getHolidaysForPeriod].sort(compareDate));
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_HOLIDAYS_FOR_PERIOD', e);
    },
  });

  async function getHolidays() {
    setLoading(true);
    await fetchHolidays();
    setLoading(false);
  }

  useEffect(() => {
    const date = new Date(Date.now());
    setSelectedDate(date);
    setPeriodByDate(date);

    void getHolidays();
  }, []);

  function setPeriodByDate(date: Date) {
    const year = dayjs(date as unknown as string).year();
    const period: TPeriodDates = getStartAndEndForYear(year);
    setStartDate(period.start);
    setEndDate(period.end);
  }

  const onChange: DatePickerProps['onChange'] = (date) => {
    if (date !== null) {
      setSelectedDate(date.toDate());
      setPeriodByDate(date.toDate());
    }
  };

  function handleAddHolidaysClick() {
    setOpenAdd(true);
  }
  function handleUpdateHolidaysClick() {
    setOpenEdit(true);
  }

  async function handleDeleteClick(_id: number) {
    await deleteHolidays({
      variables: {
        id: _id,
      },
    });
  }

  return (
    <>
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <Container>
          <Form
            {...layout}
            ref={formRef}
            name="holidays"
            initialValues={{ year: dayjs(selectedDate as unknown as string) }}
            style={{ width: '100%' }}
          >
            <SDivActions>
              <Form.Item
                name="year"
                label={i18n._('form.year')}
                style={{ marginBottom: '0' }}
                rules={[
                  {
                    required: true,
                    message: i18n._('form.year.placeholder'),
                  },
                ]}
              >
                <DatePicker onChange={onChange} picker="year" />
              </Form.Item>

              <SDiv>
                <Button
                  style={{ width: '200px' }}
                  onClick={handleAddHolidaysClick}
                  type="primary"
                >
                  <Trans id="common.actions.add.holidays" />
                </Button>
                <Button
                  style={{ width: '200px' }}
                  onClick={handleUpdateHolidaysClick}
                  type="primary"
                >
                  <Trans id="common.actions.update.holidays" />
                </Button>
              </SDiv>
            </SDivActions>

            <SDivDates>
              {holidays.map((el, ind) => (
                <SDivDateItem key={ind}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: classic.token?.colorPrimary,
                    }}
                  >
                    {new Date(el.date as Date).toLocaleDateString()}
                  </Text>

                  <Tooltip title="supprimer">
                    <Button
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        void handleDeleteClick(el.id);
                        setTimeout(() => {
                          void getHolidays();
                        }, 100);
                      }}
                    />
                  </Tooltip>
                </SDivDateItem>
              ))}
            </SDivDates>
          </Form>

          <AddHolidays
            holidays={holidays}
            title={i18n._('holidays.add')}
            isOpen={openAdd}
            close={() => setOpenAdd(false)}
            confirm={() => {
              setOpenAdd(false);
              void getHolidays();
            }}
          />

          <EditHolidays
            startDate={startDate}
            endDate={endDate}
            holidays={holidays}
            title={i18n._('holidays.edit')}
            isOpen={openEdit}
            close={() => setOpenEdit(false)}
            confirm={() => {
              setOpenEdit(false);
              void getHolidays();
            }}
          />
        </Container>
      )}
    </>
  );
}

export default Holidays;

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
`;

const SDivActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  & > button:first-of-type {
    margin-right: 5px;
  }
`;

const SDivDates = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SDivDateItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 5px;
  width: 300px;
`;
