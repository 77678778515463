import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { Form, DatePicker, DatePickerProps } from 'antd';

import type { FormInstance } from 'antd/es/form';

import React, { useEffect, useState } from 'react';
import { StoredHolidays, GQL_CREATE_HOLIDAYS } from '~/gql/holidays/holidays';

import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import ModalCustom from '~/pages/components/ui/ModalCustom';

type IProps = {
  holidays: StoredHolidays[];
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function AddHolidays({ title, isOpen, confirm, close, holidays }: IProps) {
  const { i18n } = useLingui();
  const { payload } = useAuth();

  const formRef = React.useRef<FormInstance>(null);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const [createHolidays] = useMutation(GQL_CREATE_HOLIDAYS, {
    client: authClient,
  });

  useEffect(() => {
    formRef.current?.resetFields();
  }, [holidays]);

  async function submit() {
    if (!payload?.sub || !selectedDate) return;
    const indInHolidays = holidays.findIndex(
      (el) =>
        new Date(el.date as Date).setHours(8, 0, 0, 0) ===
        new Date(selectedDate).setHours(8, 0, 0, 0)
    );
    if (indInHolidays !== -1) close;

    const { data: _data } = await createHolidays({
      variables: {
        date: new Date(selectedDate.setHours(8, 0, 0, 0)),
      },
    });

    if (_data?.createHolidays) {
      confirm();
    } else {
      close();
    }
  }

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    setSelectedDate(date.toDate());
  };

  return (
    <ModalCustom title={title} isOpen={isOpen} confirm={submit} close={close}>
      <Form
        {...layout}
        ref={formRef}
        name="holidays-add"
        initialValues={{
          dates: [],
        }}
        style={{ maxWidth: 600 }}
      >
        <Form.Item name="date" label={i18n._('form.holiday')}>
          <DatePicker onChange={onChangeDate} />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
}

export default AddHolidays;
