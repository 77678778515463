/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { InfoCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import type { DatePickerProps } from 'antd';
import {
  Input,
  Typography,
  Form,
  DatePicker,
  Tooltip,
  Button,
  ConfigProvider,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import frFR from 'antd/es/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  GQL_DEVIS_BY_PROJECTID,
  GQL_UPDATE_DEVIS_PROD_DATE,
} from '~/gql/devis/devis';
import {
  GQL_GET_ONE_PROJECT,
  GQL_UPDATE_PROJECT,
  StoredProjectFullInfo,
} from '~/gql/project/project';

import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import TasksPlanningExtTable from '~/pages/components/common/planning/TaskPlanningExtTable';
//import TasksPlanningExtTable from '~/pages/components/common/planning/TaskPlanningExtTable';

import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ManagerLayout from '~/pages/layouts/ManagerLayout';
import { classic } from '~/themes/classic';

const dateFormat = 'DD-MM-YYYY';

dayjs.extend(updateLocale);
dayjs.updateLocale('fr', {
  weekStart: 1,
});

function PlanningExtPage() {
  const formRef = useRef<FormInstance>(null);
  const params = useParams();
  const { id } = params;
  const { Text } = Typography;
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    currentProject,
    setCurrentProject,
    currentDevisProd,
    setCurrentDevisProd,
  } = useDataUserStore();

  const [projectName, setProjectName] = useState<string>('');
  const [dateSigned, setDateSigned] = useState<Date | undefined>(undefined);
  const [dateFinish, setDateFinish] = useState<Date | undefined>(undefined);
  const [project, setProject] = useState<StoredProjectFullInfo | null>(null);

  const [updateDevisProdDate] = useMutation(GQL_UPDATE_DEVIS_PROD_DATE, {
    client: authClient,
  });

  const [updateProject] = useMutation(GQL_UPDATE_PROJECT, {
    client: authClient,
  });

  const { refetch: fetchDevis } = useQuery(GQL_DEVIS_BY_PROJECTID, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDevisByProjectId.devisProd) {
        setCurrentDevisProd(data.getDevisByProjectId.devisProd);

        setDateSigned(data.getDevisByProjectId.devisProd.signedDate);
        setDateFinish(data.getDevisByProjectId.devisProd.finishDate);
      }
    },
    onError: (e) => {
      console.log('erreur dans GQL_DEVIS_BY_PROJECTID', e);
    },
  });

  const { refetch: fetchProject } = useQuery(GQL_GET_ONE_PROJECT, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProject(data.getOneProject);
      setCurrentProject(data.getOneProject);
      setProjectName(data.getOneProject.name);
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_ONE_PROJECT', e);
    },
  });

  useEffect(() => {
    void getDevis();
    void getProject();
  }, []);

  useEffect(() => {
    setDateSigned(
      currentDevisProd?.signedDate
        ? (currentDevisProd.signedDate as Date)
        : undefined
    );
    setDateFinish(
      currentDevisProd?.finishDate
        ? (currentDevisProd.finishDate as Date)
        : undefined
    );
  }, [currentDevisProd]);

  useEffect(() => {
    if (project) setProjectName(project.name);
  }, [project]);

  async function getDevis() {
    setLoading(true);
    await fetchDevis();
    setLoading(false);
  }

  async function getProject() {
    setLoading(true);
    await fetchProject();
    setLoading(false);
  }

  const onChangeDate: DatePickerProps['onChange'] = async (date) => {
    if (!currentDevisProd) return;
    const { data: _data } = await updateDevisProdDate({
      variables: {
        id: currentDevisProd.id,
        signedDate: date ? new Date(date.toDate().setHours(8, 0, 0, 0)) : null,
      },
    });
    if (_data) {
      if (date) {
        setDateSigned(new Date(date.toDate().setHours(8, 0, 0, 0)));
        setCurrentDevisProd({
          ...currentDevisProd,
          signedDate: new Date(date.toDate().setHours(8, 0, 0, 0)),
        });
      }
    }
  };

  const onChangeDateFinish: DatePickerProps['onChange'] = async (date) => {
    if (!currentDevisProd) return;

    const { data: _data } = await updateDevisProdDate({
      variables: {
        id: currentDevisProd.id,
        finishDate: date ? new Date(date.toDate().setHours(8, 0, 0, 0)) : null,
      },
    });
    if (_data) {
      if (date) {
        setDateFinish(new Date(date.toDate().setHours(8, 0, 0, 0)));
        setCurrentDevisProd({
          ...currentDevisProd,
          finishDate: new Date(date.toDate().setHours(8, 0, 0, 0)),
        });
      }
    }
  };

  async function handleChangeProjectName() {
    if (!project) return;
    await updateProject({
      variables: {
        id: project.id,
        name: projectName,
      },
    });
  }

  return (
    <>
      <DefaultLayout>
        <ManagerLayout>
          {loading ? (
            <p>{i18n._('common.loading')} ...</p>
          ) : (
            <SDivContentWrapper>
              <SDivTitle>
                <Form
                  ref={formRef}
                  style={{ width: '100%' }}
                  name="planning-page"
                  initialValues={{
                    dateSigned: currentDevisProd?.signedDate
                      ? dayjs(currentDevisProd.signedDate as string)
                      : null,
                    dateFinish: currentDevisProd?.finishDate
                      ? dayjs(currentDevisProd.finishDate as string)
                      : null,
                  }}
                >
                  <Text style={{ marginRight: '10px' }}>
                    {i18n._('form.project-name')}
                  </Text>
                  <Input
                    style={{ width: '250px', marginRight: '10px' }}
                    placeholder={i18n._('form.project-name.placeholder')}
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    suffix={
                      <Tooltip title={i18n._('form.project-name.advice')}>
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                    onPressEnter={handleChangeProjectName}
                  />
                  <Text style={{ margin: '10px' }}>
                    {i18n._('form.project.manager')}
                  </Text>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: classic.token?.colorPrimary,
                    }}
                  >
                    {project?.projectManager.firstName +
                      ' ' +
                      project?.projectManager.lastName}
                  </Text>

                  <SDiv>
                    <Form.Item
                      name="dateSigned"
                      style={{
                        width: '300px',
                        margin: '10px 10px',
                      }}
                      label={i18n._('form.date.prod')}
                      rules={[
                        { required: true, message: i18n._('errors.required') },
                      ]}
                    >
                      <ConfigProvider locale={frFR}>
                        <DatePicker
                          format={dateFormat}
                          onChange={onChangeDate}
                          value={dayjs(dateSigned as Date)}
                        />
                      </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                      name="dateFinish"
                      style={{
                        width: '300px',
                        margin: '10px 10px',
                      }}
                      label={i18n._('form.date.prod.end')}
                      rules={[
                        { required: true, message: i18n._('errors.required') },
                      ]}
                    >
                      <ConfigProvider locale={frFR}>
                        <DatePicker
                          format={dateFormat}
                          onChange={onChangeDateFinish}
                          value={dayjs(dateFinish as Date)}
                        />
                      </ConfigProvider>
                    </Form.Item>
                    <SDivActions>
                      <Tooltip title={i18n._('print.planning')}>
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={() => setIsPrinting(true)}
                          icon={<PrinterOutlined />}
                          type="primary"
                          shape="circle"
                        />
                      </Tooltip>

                      {!currentProject?.retroPlanningDone && (
                        <Text type="danger" style={{ marginRight: '10px' }}>
                          {i18n._('form.warning.retro.not.finish')}
                        </Text>
                      )}
                    </SDivActions>
                  </SDiv>
                </Form>
              </SDivTitle>
              <SDivWrapper>
                <TasksPlanningExtTable
                  isPrinting={isPrinting}
                  changeIsPrinting={setIsPrinting}
                />
              </SDivWrapper>
            </SDivContentWrapper>
          )}
        </ManagerLayout>
      </DefaultLayout>
    </>
  );
}

export default PlanningExtPage;

const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SDivTitle = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const SDivWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const SDivActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
