import DefaultLayout from '~/pages/layouts/DefaultLayout';
import EmployeesTable from '~/pages/tables/EmployeesTable';

function EmployeesPage() {
  return (
    <>
      <DefaultLayout>
        <EmployeesTable />
      </DefaultLayout>
    </>
  );
}

export default EmployeesPage;
