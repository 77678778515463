import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';

import { useLingui, Trans } from '@lingui/react';
import { DevisType, FunctionType } from '@zeus/index';
import type { DatePickerProps } from 'antd';
import {
  InputNumber,
  Button,
  DatePicker,
  Select,
  Form,
  Tooltip,
  ConfigProvider,
  Space,
  Typography,
  Input,
} from 'antd';

import type { FormInstance } from 'antd/es/form';

import frFR from 'antd/es/locale/fr_FR';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { GQL_CREATE_DEVIS_INITIAL } from '~/gql/devis/devis';
import { StoredFunction } from '~/gql/function/function';
import { StoredPoste } from '~/gql/poste/poste';
import { GQL_UPDATE_PROJECT } from '~/gql/project/project';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';

import { isCanSave } from '~/pages/components/common/utils-table/utils';
import {
  SDevisTh,
  SDevisTd,
  SDevisTableHead,
  SDevisTable,
  SDevisTableFoot,
  SDivBtnAddRow,
  SDivBtn,
} from '~/pages/components/styles/styles';
import DurationItem from '~/pages/components/ui/DurationItem';
import { DevisInitRow } from '~/pages/types/types';
import 'dayjs/locale/fr';

type IProps = {
  functionTypes: StoredFunction[];
  postes: StoredPoste[];
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

dayjs.extend(updateLocale);
dayjs.updateLocale('fr', {
  weekStart: 1,
});

function AddDevisInitP({
  functionTypes,
  postes,
  confirm,
  title,
  isOpen,
  close,
}: IProps) {
  const { i18n } = useLingui();
  const { currentProject, setCurrentProject } = useDataUserStore();
  const { Title } = Typography;
  const [rows, setRows] = useState<DevisInitRow[]>([]);
  const [dateSigned, setDateSigned] = useState<Date | undefined>(undefined);
  const [marge, setMarge] = useState<number>(0);
  const formRef = React.useRef<FormInstance>(null);
  const [canBeSaved, setCanBeSaved] = useState<boolean>(true);

  const [createDevis] = useMutation(GQL_CREATE_DEVIS_INITIAL, {
    client: authClient,
  });

  const [updateProject] = useMutation(GQL_UPDATE_PROJECT, {
    client: authClient,
  });

  useEffect(() => {
    formRef.current?.resetFields();
  }, []);

  useEffect(() => {
    if (rows.length !== 0) {
      setCanBeSaved(isCanSave(rows));
    }
  }, [rows]);

  function onChangeAmount(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].amount = value;

    setRows(_rows);
  }

  function onChangeDuration(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].duration = value;
    _rows[ind].durationExpected = value;

    setRows(_rows);
  }

  function onChangeDurationExpected(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].durationExpected = value;

    setRows(_rows);
  }

  function onChangeDurationUsed(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].durationUsed = value;

    setRows(_rows);
  }

  function onChangeAmountUsed(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].amountUsed = value;

    setRows(_rows);
  }

  function onChangeFunctionType(value: number, ind: number) {
    const _rows = [...rows];

    const _functionType = functionTypes.find((el) => el.id === value);
    if (_functionType) _rows[ind].functionType = _functionType.name;
    setRows(_rows);
  }

  function onChangePoste(value: number, ind: number) {
    const _rows = [...rows];

    const _poste = postes.find((el) => el.id === value);
    if (_poste) _rows[ind].posteId = value;
    _rows[ind].posteName = _poste?.name ?? '';
    _rows[ind].customPosteName = '';

    setRows(_rows);
  }

  const onChangeCustomPosteName = (value: string, ind: number) => {
    const _rows = [...rows];
    _rows[ind].customPosteName = value;

    setRows(_rows);
  };

  function onDeleteClick(ind: number) {
    const _rows = [...rows];
    _rows.splice(ind, 1);
    setRows(_rows);
  }

  function addElement() {
    const _rows = [...rows];

    const posteEl = postes.find((el) => el.name === FunctionType.GP);
    _rows.push({
      functionType: FunctionType.GP,
      amount: 0,
      duration: 0,
      durationExpected: 0,
      durationUsed: 0,
      amountUsed: 0,
      posteId: posteEl?.id ?? postes[0].id,
      posteName: posteEl?.name ?? postes[0].name,
    });
    setRows(_rows);
  }

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0);
    setDateSigned(new Date(d));
  };

  async function submit() {
    const _rows = [...rows];
    if (!currentProject) return;

    const { data: _data } = await createDevis({
      variables: {
        ...(dateSigned && { signedDate: dateSigned }),
        projectId: currentProject.id,
        type: DevisType.INITIAL,
        createDevisInitInput: _rows
          .filter((r) => r.amount !== 0 || r.duration !== 0)
          .map((el, index) => {
            return {
              ...(el.id && { id: el.id }),
              functionType: el.functionType as FunctionType,
              amount: el.amount,
              duration: el.duration,
              durationExpected: el.durationExpected,
              durationUsed: el.durationUsed,
              amountUsed: el.amountUsed,
              posteId: el.posteId,
              order: index,
              ...(el.customPosteName && {
                customPosteName: el.customPosteName,
              }),
            };
          }),
      },
    });
    if (marge !== currentProject.marginPercent && marge) {
      void handleChangeMarge();
    }

    if (_data) {
      confirm();
    }
    handleClose();
  }

  async function handleChangeMarge() {
    if (!currentProject?.id) return;
    const { data: _data } = await updateProject({
      variables: {
        id: currentProject.id,
        marginPercent: marge,
      },
    });

    if (_data) {
      setCurrentProject({ ...currentProject, marginPercent: marge });
    }
  }

  function handleClose() {
    setRows([]);
    setDateSigned(undefined);
    setMarge(0);
    formRef.current?.resetFields();
    close();
  }
  return (
    <>
      <Container hidden={!isOpen} onClick={handleClose}>
        <SDivContentWrapper>
          <SDivContent width={'85%'} onClick={(e) => e.stopPropagation()}>
            <Space
              direction="vertical"
              size="small"
              style={{ display: 'flex', width: '100%', overflowY: 'auto' }}
            >
              <Title level={5}>{title}</Title>
              <Form
                {...layout}
                ref={formRef}
                name="add-devis-initp"
                initialValues={{
                  dateSigned: null,
                  marge: currentProject?.marginPercent ?? 0,
                }}
              >
                <SDivBtn>
                  <Button
                    style={{ marginRight: '10px' }}
                    htmlType="submit"
                    onClick={handleClose}
                    type="default"
                  >
                    <Trans id="common.actions.cancel" />
                  </Button>
                  <Button
                    htmlType="submit"
                    style={{ width: '200px' }}
                    onClick={submit}
                    type="primary"
                    disabled={!canBeSaved}
                  >
                    <Trans id="devis.init.save" />
                  </Button>
                </SDivBtn>

                <SDiv>
                  <Form.Item
                    name="dateSigned"
                    style={{ width: '100%', margin: '10px 10px' }}
                    label={i18n._('form.date.signed')}
                  >
                    <ConfigProvider locale={frFR}>
                      <DatePicker
                        style={{ width: '150px' }}
                        format={dateFormat}
                        onChange={onChangeDate}
                        minDate={dayjs().subtract(2, 'year')}
                      />
                    </ConfigProvider>
                  </Form.Item>
                  <Form.Item
                    name="marge"
                    style={{ width: '100%', margin: '10px 10px' }}
                    label={i18n._('form.marge.expected')}
                  >
                    <InputNumber
                      style={{ width: '150px', marginRight: '10px' }}
                      placeholder={i18n._('form.marge.placeholder')}
                      onChange={(value) => setMarge(value ?? 0)}
                      controls={false}
                      min={0}
                      value={marge}
                      addonAfter="%"
                    />
                  </Form.Item>
                </SDiv>
                <SDevisTable>
                  <SDevisTableHead>
                    <tr>
                      <SDevisTh $minWidth="150px">
                        {i18n._('common.function.type')}
                      </SDevisTh>
                      <SDevisTh $minWidth="150px">
                        {i18n._('common.poste')}
                      </SDevisTh>
                      <SDevisTh $minWidth="150px">
                        {i18n._('common.amount')}
                      </SDevisTh>
                      <SDevisTh $minWidth="120px">
                        {i18n._('common.duration')}
                      </SDevisTh>
                      <Tooltip title={i18n._('table.duration.expected.tipp')}>
                        <SDevisTh $minWidth="120px">
                          {i18n._('common.duration.expected')}
                        </SDevisTh>
                      </Tooltip>
                      <Tooltip title={i18n._('table.duration.used.tipp')}>
                        <SDevisTh $minWidth="120px">
                          {i18n._('common.duration.used')}
                        </SDevisTh>
                      </Tooltip>
                      <Tooltip title={i18n._('table.amount.used.tipp')}>
                        <SDevisTh $minWidth="120px">
                          {i18n._('common.amount.used')}
                        </SDevisTh>
                      </Tooltip>
                    </tr>
                  </SDevisTableHead>
                  <tbody>
                    {rows.map((el, index) => (
                      <tr key={index}>
                        <SDevisTd $minWidth="150px">
                          <SDivTableItem>
                            <Select
                              showSearch
                              style={{ width: '150px' }}
                              value={
                                functionTypes.find(
                                  (fT) => el.functionType === fT.name
                                )?.id
                              }
                              onChange={(value) =>
                                onChangeFunctionType(value, index)
                              }
                              optionFilterProp="label"
                              options={functionTypes.map((item) => ({
                                value: item.id,
                                label: i18n._(item.name),
                              }))}
                            />
                          </SDivTableItem>
                        </SDevisTd>
                        <SDevisTd>
                          <SDivTableItem>
                            <Select
                              showSearch
                              style={{ width: '150px' }}
                              value={el.posteId}
                              onChange={(value) => onChangePoste(value, index)}
                              optionFilterProp="label"
                              options={postes.map((item) => ({
                                value: item.id,
                                label: i18n._(item.name),
                              }))}
                            />
                            {el.posteName === 'Autre' && (
                              <Input
                                value={el.customPosteName}
                                style={{
                                  width: '150px',
                                  marginRight: '10px',
                                  marginTop: '5px',
                                }}
                                placeholder={i18n._('form.poste.cutom.name')}
                                onChange={(e) =>
                                  onChangeCustomPosteName(e.target.value, index)
                                }
                              />
                            )}
                          </SDivTableItem>
                        </SDevisTd>
                        <SDevisTd $minWidth="150px" $textAlign="right">
                          <SDivTableItem>
                            <InputNumber
                              style={{ width: '150px' }}
                              placeholder={i18n._('form.amount.placeholder')}
                              onChange={(value) =>
                                onChangeAmount(value ?? 0, index)
                              }
                              controls={false}
                              value={el.amount}
                              step="0.01"
                              addonAfter="€"
                            />
                          </SDivTableItem>
                        </SDevisTd>
                        <SDevisTd $minWidth="120px">
                          <SDivTableItem>
                            <DurationItem
                              $allowCorrection
                              duration={el.duration}
                              onChange={(value) =>
                                onChangeDuration(value, index)
                              }
                            />
                          </SDivTableItem>
                        </SDevisTd>
                        <SDevisTd $minWidth="120px">
                          <SDivTableItem>
                            <DurationItem
                              $allowCorrection
                              duration={el.durationExpected}
                              onChange={(value) =>
                                onChangeDurationExpected(value, index)
                              }
                            />
                          </SDivTableItem>
                        </SDevisTd>
                        <SDevisTd $minWidth="120px">
                          <SDivTableItem>
                            <DurationItem
                              $allowCorrection
                              duration={el.durationUsed ?? 0}
                              onChange={(value) =>
                                onChangeDurationUsed(value, index)
                              }
                            />
                          </SDivTableItem>
                        </SDevisTd>
                        <SDevisTd $minWidth="120px">
                          <SDivTableItem>
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder={i18n._(
                                'form.amount.used.placeholder'
                              )}
                              onChange={(value) =>
                                onChangeAmountUsed(value ?? 0, index)
                              }
                              controls={false}
                              value={el.amountUsed}
                              step="0.01"
                              addonAfter="€"
                            />
                          </SDivTableItem>
                        </SDevisTd>
                        <SDevisTd>
                          <SDivTableItem>
                            <Tooltip title="supprimer">
                              <Button
                                type="default"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => onDeleteClick(index)}
                              />
                            </Tooltip>
                          </SDivTableItem>
                        </SDevisTd>
                      </tr>
                    ))}
                    <tr>
                      <th colSpan={3}>
                        <SDivBtnAddRow>
                          <Button
                            onClick={() => addElement()}
                            type="default"
                            style={{
                              backgroundColor: '#0B446F4D',
                              color: '#0B446F',
                            }}
                          >
                            <Trans id="common.add.row" />
                          </Button>
                        </SDivBtnAddRow>
                      </th>
                    </tr>
                  </tbody>
                  <SDevisTableFoot>
                    <tr>
                      <SDevisTh colSpan={2}>{i18n._('common.total')}</SDevisTh>
                      <SDevisTd $minWidth="150px" $textAlign="right">
                        <InputNumber
                          style={{ width: '150px' }}
                          placeholder={i18n._('form.amount.placeholder')}
                          controls={false}
                          disabled
                          value={rows.reduce(function (acc: any, obj: any) {
                            return acc + obj.amount;
                          }, 0)}
                          step="0.01"
                          addonAfter="€"
                        />
                      </SDevisTd>
                      <SDevisTd>
                        <DurationItem
                          duration={rows.reduce(function (acc: any, obj: any) {
                            return acc + obj.duration;
                          }, 0)}
                        />
                      </SDevisTd>
                      <SDevisTd>
                        <DurationItem
                          duration={rows.reduce(function (acc: any, obj: any) {
                            return acc + obj.durationExpected;
                          }, 0)}
                        />
                      </SDevisTd>
                      <SDevisTd>
                        <DurationItem
                          duration={rows.reduce(function (acc: any, obj: any) {
                            return acc + obj.durationUsed;
                          }, 0)}
                        />
                      </SDevisTd>
                      <SDevisTd $minWidth="120px" $textAlign="left">
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder={i18n._('form.amount.used.placeholder')}
                          controls={false}
                          disabled
                          value={rows.reduce(function (acc: any, obj: any) {
                            return acc + obj.amountUsed;
                          }, 0)}
                          step="0.01"
                          addonAfter="€"
                        />
                      </SDevisTd>
                    </tr>
                  </SDevisTableFoot>
                </SDevisTable>
              </Form>
            </Space>
          </SDivContent>
        </SDivContentWrapper>
      </Container>
    </>
  );
}
/*
 <Flex
                style={{
                  marginTop: '10px',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
                gap="small"
                wrap="wrap"
              >
                <Button onClick={close} style={{ margin: '5px' }}>
                  {i18n._('common.actions.cancel')}
                </Button>

                <Button
                  onClick={confirm}
                  type="primary"
                  style={{ margin: '5px' }}
                >
                  <Trans id="common.actions.confirm" />
                </Button>
              </Flex>
*/
/*
 <ModalCustom
      title={title}
      isOpen={isOpen}
      confirm={submit}
      width="900px"
      close={close}
    >
      </ModalCustom>
*/

export default AddDevisInitP;

const SDivTableItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SDiv = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const Container = styled.div<{ hidden?: boolean }>`
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  overflow: hidden;
  height: 100%;
  top: 0;
  left: 0;
  height: ${({ hidden }) => (hidden ? '0' : '100%')};
  transition: ${({ hidden }) => (hidden ? 'none' : 'opacity .2s linear')};
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  width: ${({ hidden }) => (hidden ? '0' : '100%')};
  z-index: 40;
  max-height: 100%;
`;
const SDivContentWrapper = styled.div`
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const SDivContent = styled.div<{ width?: string }>`
  align-items: center;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: ${({ width }) => (width ? width : '450px')};
  padding: 20px;
  overflow-y: auto;
`;
