import { useLingui, Trans } from '@lingui/react';
import { Button, Flex, Typography, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

type IProps = {
  isOpen?: boolean;
  title: string;
  confirmDisabled?: boolean;
  children?: React.ReactNode;
  confirm: () => void;
  close: () => void;
  width?: string;
  withoutBtnConfirm?: boolean;
  customBtnCancelText?: string;
};

function ModalCustom({
  title,
  confirmDisabled,
  isOpen,
  children,
  confirm,
  close,
  width,
  withoutBtnConfirm,
  customBtnCancelText,
}: IProps) {
  const { Title } = Typography;
  const { i18n } = useLingui();

  return (
    <Container hidden={!isOpen} onClick={() => close()}>
      <SDivContent width={width} onClick={(e) => e.stopPropagation()}>
        <Space
          direction="vertical"
          size="small"
          style={{ display: 'flex', width: '100%', overflowY: 'auto' }}
        >
          <Title level={5}>{title}</Title>
          {children}
          <Flex
            style={{
              marginTop: '10px',
              width: '100%',
              justifyContent: 'flex-end',
            }}
            gap="small"
            wrap="wrap"
          >
            <Button onClick={close} style={{ margin: '5px' }}>
              {customBtnCancelText
                ? customBtnCancelText
                : i18n._('common.actions.cancel')}
            </Button>
            {!withoutBtnConfirm && (
              <Button
                disabled={confirmDisabled}
                onClick={confirm}
                type="primary"
                style={{ margin: '5px' }}
              >
                <Trans id="common.actions.confirm" />
              </Button>
            )}
          </Flex>
        </Space>
      </SDivContent>
    </Container>
  );
}

/*
<Button disabled={confirmDisabled} onClick={confirm} type="primary">
              <Trans id="common.actions.confirm" />
            </Button>
*/
export default ModalCustom;

const Container = styled.div<{ hidden?: boolean }>`
  align-items: center;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  overflow: hidden;
  height: 100%;
  top: 0;
  left: 0;
  height: ${({ hidden }) => (hidden ? '0' : '100%')};
  transition: ${({ hidden }) => (hidden ? 'none' : 'opacity .2s linear')};
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  width: ${({ hidden }) => (hidden ? '0' : '100%')};
  z-index: 40;
  max-height: 100%;
`;

const SDivContent = styled.div<{ width?: string }>`
  align-items: center;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: ${({ width }) => (width ? width : '450px')};
  padding: 20px;
`;
