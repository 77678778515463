import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GQL_ALL_HOLIDAYS } from '~/gql/holidays/holidays';
import { GQL_UPDATE_TASK_RETRO, StoredTaskInfo } from '~/gql/task/task';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import TasksDragAndDrop from '~/pages/components/common/planning/TasksRetroTable';
import { fillRetroCalendar } from '~/pages/components/common/utils-table/utils';
import { TDate, TaskInfoWithCalender } from '~/pages/types/types';
import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  isPrinting: boolean;
  changeIsPrinting: (value: boolean) => void;
};

function TasksRetroPlanning({ isPrinting, changeIsPrinting }: IProps) {
  const [calendar, setCalendar] = useState<TDate[]>([]);
  const [mappedTasks, setMappedTasks] = useState<TaskInfoWithCalender[]>([]);
  const { currentDevisProd, setCurrentDevisProd } = useDataUserStore();

  const [updateTaskRetro] = useMutation(GQL_UPDATE_TASK_RETRO, {
    client: authClient,
  });

  const { refetch: getAllHolidays } = useQuery(GQL_ALL_HOLIDAYS, {
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  function mapTaskRetroPlanning(
    _tasks: StoredTaskInfo[],
    _calendar: TDate[]
  ): TaskInfoWithCalender[] {
    const itemsMapped = JSON.parse(
      JSON.stringify(_tasks.filter((t) => t.visibleInRetro && !t.supp))
    );
    const items: StoredTaskInfo[] = itemsMapped.map(
      (el: any) => el as StoredTaskInfo
    );

    const res: TaskInfoWithCalender[] = items.map((task) => {
      const taskCalendar: TDate[] = JSON.parse(JSON.stringify(_calendar)).map(
        (el: any) => el as TDate
      );

      if (task.startDateInit !== undefined && task.durationDays !== undefined) {
        for (let i = 0; i < task.durationDays; i++) {
          taskCalendar[task.startDateInit + i].checked = true;
        }
      }
      // const addInName =
      //   task.functionType === FunctionType.CLIENT
      //     ? `-${task.durationDays}j`
      //     : '';
      return {
        id: task.id,
        name: task.name,
        amount: task.amount,
        duration: task.duration,
        functionType: task.functionType,
        devisInitId: task.devisInitId,
        startDateInit: task.startDateInit,
        durationDays: task.durationDays,
        durationClientDays: task.durationClientDays,
        visibleInRetro: task.visibleInRetro,
        supp: task.supp,
        calculatedAsAmount: task.calculatedAsAmount,
        order: task.order,
        taskPlanning: task.taskPlanning,
        projectId: task.projectId,
        projectName: task.projectName,
        user: task.user,
        calendar: [...taskCalendar],
      };
    });
    return res.sort(compareByOrder);
  }

  async function getData() {
    const data = await getAllHolidays();
    if (currentDevisProd?.tasks && currentDevisProd.tasks.length > 0) {
      const _calendar = fillRetroCalendar(
        currentDevisProd.signedDate as Date,
        currentDevisProd.finishDate as Date,
        currentDevisProd.tasks,
        data.data.getAllHolidays
      );
      setCalendar(_calendar);
      const res = mapTaskRetroPlanning(currentDevisProd.tasks, _calendar);
      setMappedTasks(res);
    }
  }

  useEffect(() => {
    void getData();
  }, [currentDevisProd?.tasks]);

  async function changeCell(itemId: number, calendarIndex: number) {
    const itemsMapped = JSON.parse(JSON.stringify(mappedTasks));
    const items: TaskInfoWithCalender[] = itemsMapped.map(
      (el: any) => el as TaskInfoWithCalender
    );

    const itemIndex = items.findIndex((item) => item.id === itemId);
    if (itemIndex !== -1) {
      if (!hasCheckedinRow(items, itemId)) {
        // rien n'est choisi

        items[itemIndex].calendar[calendarIndex].checked =
          !items[itemIndex].calendar[calendarIndex].checked;
        items[itemIndex].startDateInit = calendarIndex;
        items[itemIndex].durationDays = 1;

        const { data: _data } = await updateTaskRetro({
          variables: {
            id: itemId,
            durationDays: 1,
            startDateInit: calendarIndex,
          },
        });

        if (_data) {
          setCurrentDevisProd({
            ..._data.updateTaskRetro,
            tasks: _data.updateTaskRetro.tasks?.sort(compareByOrder),
          });
          setMappedTasks(
            JSON.parse(JSON.stringify(items)).map(
              (el: any) => el as TaskInfoWithCalender
            )
          );
          //   refreshDevis();
        }

        return;
      } else {
        // il y a déjà les éléments choisi
        if (lastIndexCheckedInRow(items[itemIndex]) === calendarIndex - 1) {
          // click sur libre après checked

          items[itemIndex].calendar[calendarIndex].checked =
            !items[itemIndex].calendar[calendarIndex].checked;
          items[itemIndex].durationDays =
            items[itemIndex].durationDays ?? 0 + 1;

          const _startDate: number = items[itemIndex].startDateInit ?? 0;
          const _duration: number = items[itemIndex].durationDays ?? 0;

          const { data: _data } = await updateTaskRetro({
            variables: {
              id: itemId,
              durationDays: _duration + 1,
              startDateInit: _startDate,
            },
          });

          if (_data) {
            setCurrentDevisProd({
              ..._data.updateTaskRetro,
              tasks: _data.updateTaskRetro.tasks?.sort(compareByOrder),
            });

            setMappedTasks(
              JSON.parse(JSON.stringify(items)).map(
                (el: any) => el as TaskInfoWithCalender
              )
            );
          }
          return;
        }
        if (
          lastIndexCheckedInRow(items[itemIndex]) === calendarIndex &&
          items[itemIndex].calendar[calendarIndex].checked === true
        ) {
          // click sur un dernier checked
          items[itemIndex].calendar[calendarIndex].checked =
            !items[itemIndex].calendar[calendarIndex].checked;
          items[itemIndex].durationDays =
            items[itemIndex].durationDays ?? 0 + -1;
          const _startDate: number = items[itemIndex].startDateInit ?? 0;
          const _duration: number = items[itemIndex].durationDays ?? 0;

          const { data: _data } = await updateTaskRetro({
            variables: {
              id: itemId,
              durationDays: _duration - 1,
              startDateInit: _duration - 1 === 0 ? 0 : _startDate,
            },
          });

          if (_data) {
            setCurrentDevisProd({
              ..._data.updateTaskRetro,
              tasks: _data.updateTaskRetro.tasks?.sort(compareByOrder),
            });

            setMappedTasks(
              JSON.parse(JSON.stringify(items)).map(
                (el: any) => el as TaskInfoWithCalender
              )
            );
          }
          return;
        }
      }
    }
  }

  function lastIndexCheckedInRow(item: TaskInfoWithCalender): number {
    let lastInd = -1;
    for (let i = 0; i < item.calendar.length; i++) {
      if (item.calendar[i].checked) {
        lastInd = i;
      }
    }
    return lastInd;
  }

  function hasCheckedinRow(items: TaskInfoWithCalender[], itemId: number) {
    let checked = false;
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const item: TaskInfoWithCalender = JSON.parse(
      JSON.stringify(mappedTasks[itemIndex])
    );

    for (const itC of item.calendar) {
      if (itC.checked) {
        checked = true;
        return true;
      }
    }

    return checked;
  }

  function onChangeStatePeriod(
    itemId: number,
    startDateInit: number,
    durationDays: number
  ) {
    const itemsMapped = JSON.parse(JSON.stringify(mappedTasks));
    const items: TaskInfoWithCalender[] = itemsMapped.map(
      (el: any) => el as TaskInfoWithCalender
    );
    const itemIndex = items.findIndex((item) => item.id === itemId);
    if (itemIndex !== -1) {
      //
      items[itemIndex].startDateInit = startDateInit;
      items[itemIndex].durationDays = durationDays;

      //
      const taskCalendar: TDate[] = JSON.parse(
        JSON.stringify(items[itemIndex].calendar)
      ).map((el: any) => el as TDate);

      if (durationDays === 0 && startDateInit === 0) {
        for (let i = 0; i < taskCalendar.length; i++) {
          taskCalendar[i].checked = false;
        }
      }

      for (let i = 0; i < durationDays; i++) {
        taskCalendar[startDateInit + i].checked = true;
      }
      items[itemIndex].calendar = [...taskCalendar];
      setMappedTasks(
        JSON.parse(JSON.stringify(items)).map(
          (el: any) => el as TaskInfoWithCalender
        )
      );
    }
  }

  return (
    <>
      {mappedTasks.length > 0 && calendar.length > 0 && (
        <TasksDragAndDrop
          onOrderChanged={setMappedTasks}
          onStatePeriodChange={onChangeStatePeriod}
          onStateCellChange={changeCell}
          calendar={calendar}
          tasks={mappedTasks}
          isPrinting={isPrinting}
          changeIsPrinting={changeIsPrinting}
        />
      )}
    </>
  );
}

export default TasksRetroPlanning;
