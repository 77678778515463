import { CaretDownOutlined } from '@ant-design/icons';
import Logo from '@assets/images/MySG.jpeg';
import { useLingui } from '@lingui/react';
import { Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { useAuth } from '~/helpers/store/auth';
import { classic } from '~/themes/classic';

function Header() {
  const { payload } = useAuth();
  const { i18n } = useLingui();
  const navigate = useNavigate();

  if (payload === null) {
    navigate('/logout');
  }

  return (
    <SHeader>
      {payload && (
        <>
          <img src={Logo} alt="Logo MySG" width="350px" />
          <Popover
            placement="bottomLeft"
            title={
              <h3 style={{ color: '#333', fontWeight: '600' }}>
                {i18n._('your.informations')}
              </h3>
            }
            content={
              <>
                <UserInformation>
                  {i18n._('you.are')}{' '}
                  <IntensiveText>{payload.roles}</IntensiveText>
                </UserInformation>
                <UserInformation>
                  {i18n._('your.email')} :{' '}
                  <IntensiveText>{payload.email}</IntensiveText>
                </UserInformation>
                <UserInformation>
                  {i18n._('token.creation.message') + ' '}
                  <IntensiveText>
                    {new Date(Number(`${payload.iat}000`)).toLocaleString()}
                  </IntensiveText>
                </UserInformation>
                <UserInformation>
                  {i18n._('token.expiration.message') + ' '}
                  <IntensiveText>
                    {new Date(Number(`${payload.exp}000`)).toLocaleString()}
                  </IntensiveText>
                </UserInformation>
              </>
            }
            trigger="click"
          >
            <UserName>
              {i18n._('common.hello')}{' '}
              <IntensiveText style={{ color: classic.token?.colorPrimary }}>
                {payload.firstName + ' ' + payload.lastName + ' '}
              </IntensiveText>
              <CaretDownOutlined color="#333" />
            </UserName>
          </Popover>
        </>
      )}
    </SHeader>
  );
}

export default Header;

const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  & > img {
    margin: 10px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    padding: 5%;

    & > img {
      width: 250px;
      margin-bottom: 5%;
    }
  }
`;

const UserName = styled.p`
  font-size: 24px;
  color: #333;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

const UserInformation = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
`;

const IntensiveText = styled.span`
  color: ${classic.token?.colorPrimary};
  font-weight: 600;
`;
