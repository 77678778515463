import { useQuery, useMutation } from '@apollo/client';
import { Trans, useLingui } from '@lingui/react';
import { ProjectType } from '@zeus/index';
import { Button } from 'antd';
import { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { GQL_ALL_HOLIDAYS, StoredHolidays } from '~/gql/holidays/holidays';
import {
  GQL_PROJECTS_WITH_TASKS,
  StoredProjectShortInfo,
} from '~/gql/project/project';
import { GQL_CREATE_OR_UPDATE_TIME } from '~/gql/time/time';
import {
  StoredTimeUserPlanningInfo,
  GQL_TIMES_USER_PLANNING_FOR_PERIOD,
} from '~/gql/user-planning/user-planning';

import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import AddTime from '~/pages/components/common/time/AddTime';
import EditTime from '~/pages/components/common/time/EditTime';
import { getDayStatus } from '~/pages/components/common/utils-table/utils';

import {
  calcTotalPlanningDuration,
  calcTotalPlanningDurationByDate,
  calcTotalTimeByDate,
  calcTotalTimes,
  fillCalendarTasksTimesTable,
  getMappedDataTasksTimesTable,
  isDateNowInPeriod,
} from '~/pages/components/common/utils-table/utils-task-times-table';

import {
  SDevisTh,
  STimeTd,
  SDevisTableHead,
  SDevisTable,
  SDevisTableFoot,
  SDivBtnAddRow,
  STr,
  SSpanTime,
  SSpanPlanning,
  STableContainer,
  SDivTableItem,
  SDivStickyItem,
} from '~/pages/components/styles/styles';
import DurationItemSmall from '~/pages/components/ui/DurationItemSmall';
import TimesPeriodActions from '~/pages/components/ui/TimesPeriodActions';
import { withPopover } from '~/pages/components/ui/withPopover';

import {
  PeriodEnum,
  TCellTaskTime,
  TDateTimes,
  TPeriodDates,
} from '~/pages/types/types';
import {
  getHoursAndMinutesStrByDuration,
  getPrevWeek,
  getNextWeek,
  getPrevMonth,
  getNextMonth,
  getStartAndEndForMonth,
  getStartAndEndForWeek,
} from '~/utils/utils/utils';

type TSelectedItem = {
  projectId: number;
  taskId: number;
  itemInCalendar: TDateTimes;
};

function TasksTimesTable() {
  const { i18n } = useLingui();
  const { payload } = useAuth();
  const [projects, setProjects] = useState<StoredProjectShortInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(Date.now()));
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.WEEK);
  const [calendar, setCalendar] = useState<TDateTimes[]>([]);
  const [withHoursPlanning, setWithHoursPlanning] = useState<boolean>(true);
  const [holidays, setHolidays] = useState<StoredHolidays[]>([]);
  const [messageAlert, setMessageAlert] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(
    getStartAndEndForMonth(new Date(Date.now())).start
  );
  const [endDate, setEndDate] = useState<Date>(
    getStartAndEndForMonth(new Date(Date.now())).end
  );
  const [mappedData, setMappedData] = useState<TCellTaskTime[]>([]);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [createOrUpdateTime] = useMutation(GQL_CREATE_OR_UPDATE_TIME, {
    client: authClient,
  });
  const [selectedItem, setSelectedItem] = useState<TSelectedItem | undefined>(
    undefined
  );

  const { refetch: getAllHolidays } = useQuery(GQL_ALL_HOLIDAYS, {
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setHolidays(data.getAllHolidays);
    },
  });

  const { refetch: fetchTimesUserPlannings } = useQuery(
    GQL_TIMES_USER_PLANNING_FOR_PERIOD,
    {
      variables: {
        userId: Number(payload?.sub ?? 0),
        startDate,
        endDate,
      },
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const _data: StoredTimeUserPlanningInfo = {
          userPlanning:
            data.getTimesUserPlanningsForPeriodByUserId.userPlanning,
          time: data.getTimesUserPlanningsForPeriodByUserId.time,
        };

        const _mapped = getMappedDataTasksTimesTable(_data, calendar);

        setMappedData(_mapped);
      },
      onError: (e) => {
        console.log('erreur dans GQL_TIMES_USER_PLANNING_FOR_PERIOD', e);
      },
    }
  );

  const { refetch: getFilteredProjects } = useQuery(GQL_PROJECTS_WITH_TASKS, {
    variables: {
      takeAll: false,
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProjects(data.getAllProjectsWithTasks);
    },
  });

  async function getProjects() {
    setLoading(true);
    await getFilteredProjects();
    setLoading(false);
  }

  async function getTimesUserPlannings() {
    setLoading(true);
    await fetchTimesUserPlannings();
    setLoading(false);
  }

  useEffect(() => {
    // init
    setSelectedDate(new Date(Date.now()));
    void getProjects();
    void getTimesUserPlannings();
    setMessageAlert('');
    void getHolydays();
  }, []);

  async function getHolydays() {
    setLoading(true);
    await getAllHolidays();
    setLoading(false);
  }

  useEffect(() => {
    const dates = getStartAndEndForWeek(new Date(selectedDate));
    setStartDate(dates.start);
    setEndDate(dates.end);
    setCalendar(fillCalendarTasksTimesTable(dates.start, dates.end));
  }, [selectedDate]);

  function handleRefresh() {
    void getTimesUserPlannings();
    setMessageAlert('');
  }

  const debouncedChangeDuration = useDebouncedCallback(
    handleChangeDuration,
    1500
  );

  async function handleChangeDuration(
    value: number,
    projectId: number,
    taskId: number,
    itemInCalender: TDateTimes
  ) {
    if (!payload?.sub || !projectId || !taskId) return;

    await createOrUpdateTime({
      variables: {
        taskId: taskId,
        date: new Date(itemInCalender.date),
        duration: value,
        userId: payload.sub,
        ...(itemInCalender.timeId && { id: itemInCalender.timeId }),
        comment: itemInCalender.timeComment ? itemInCalender.timeComment : '',
      },
    });

    handleRefresh();
  }

  function setPeriodAndFillDataByDates(_dates: TPeriodDates) {
    setStartDate(_dates.start);
    setEndDate(_dates.end);
    setCalendar(fillCalendarTasksTimesTable(_dates.start, _dates.end));
    handleRefresh();
  }

  function handlePrevClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getPrevWeek(startDate, endDate)
        : getPrevMonth(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handleNextClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getNextWeek(startDate, endDate)
        : getNextMonth(startDate);

    setPeriodAndFillDataByDates(dates);
  }

  function handleTodayClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getStartAndEndForWeek(new Date(Date.now()))
        : getStartAndEndForMonth(new Date(Date.now()));

    setPeriodAndFillDataByDates(dates);
  }

  function handleMonthClick() {
    setPeriod(PeriodEnum.MONTH);
    const dates = getStartAndEndForMonth(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handleWeekClick() {
    setPeriod(PeriodEnum.WEEK);
    const dates = getStartAndEndForWeek(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  const DurationItemSmallWithPopover = withPopover<{
    duration: number;
    onChange?: (duration: number) => void;
    $allowCorrection?: boolean;
    $opaque?: boolean;
    $align?: string;
    $color?: string;
    $backgroundColor?: string;
    onIconClick?: () => void;
    projectType?: ProjectType;
    onFocus?: () => void;
    $borderLeftColor?: string;
  }>(DurationItemSmall);

  return (
    <Container>
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <>
          <TimesPeriodActions
            onPrevClick={handlePrevClick}
            onTodayClick={handleTodayClick}
            onNextClick={handleNextClick}
            onWeekClick={handleWeekClick}
            onMonthClick={handleMonthClick}
            period={period}
            withHoursPlanning={withHoursPlanning}
            onChangeWithHoursPlanning={setWithHoursPlanning}
            messageAlert={messageAlert}
          />
          <STableContainer>
            <SDevisTable style={{ width: '100%' }}>
              <SDevisTableHead>
                <tr>
                  <SDevisTh $textAlign="center" $minWidth="180px">
                    <SDivTableItem>
                      <SDivStickyItem $margin="0 5px 0 0">
                        {i18n._('common.task.project')}
                      </SDivStickyItem>
                      <SDivStickyItem>
                        {i18n._('common.task.name')}
                      </SDivStickyItem>
                    </SDivTableItem>
                  </SDevisTh>

                  {calendar.map((el, index) => (
                    <SDevisTh
                      $fontSize="12px"
                      $textAlign="center"
                      key={index}
                      $bg={
                        el.date.setHours(8, 0, 0, 0) ===
                        new Date(Date.now()).setHours(8, 0, 0, 0)
                          ? '#B1BFCD'
                          : getDayStatus(el.date, holidays) === 'normal'
                            ? 'transparent'
                            : '#cdb1b8'
                      }
                    >
                      {el.date.toLocaleDateString()}
                    </SDevisTh>
                  ))}
                  <SDevisTh $textAlign="center" $minWidth="90px">
                    {i18n._('common.task.total')}
                  </SDevisTh>
                </tr>
              </SDevisTableHead>

              <tbody>
                {mappedData.map((item, indexInList) => (
                  <STr key={indexInList}>
                    <STimeTd $padding="0px 10px 0px 0px" $bg="#f5f5f5">
                      <SDivTableItem>
                        <SDivStickyItem>{item.projectName}</SDivStickyItem>
                        <SDivStickyItem> {item.taskName}</SDivStickyItem>
                      </SDivTableItem>
                    </STimeTd>

                    {item.calendar.map((itC, index) => (
                      <STimeTd $textAlign="center" key={index}>
                        <SDivCellTime>
                          {withHoursPlanning && (
                            <SSpanPlanning $opaque={!itC.planningDuration}>
                              {getHoursAndMinutesStrByDuration(
                                itC.planningDuration ?? 0
                              )}
                            </SSpanPlanning>
                          )}

                          <DurationItemSmallWithPopover
                            duration={itC.time ?? 0}
                            comment={itC.timeComment ?? ''}
                            $backgroundColor="#fff"
                            $allowCorrection
                            $borderLeftColor={
                              itC.timeComment ? '#0b446f80' : 'transparent'
                            }
                            $color={itC.time ? '#0b446f' : '#0b446f80'}
                            onIconClick={() => {
                              setSelectedItem({
                                projectId: item.projectId,
                                taskId: item.taskId,
                                itemInCalendar: itC,
                              });
                              setOpenEdit(true);
                            }}
                            onFocus={() => {
                              const message =
                                getDayStatus(itC.date, holidays) === 'week-end'
                                  ? i18n._('common.day.alert.we')
                                  : getDayStatus(itC.date, holidays) === 'free'
                                    ? i18n._('common.day.alert.free')
                                    : '';

                              setMessageAlert(message);
                            }}
                            onChange={(value) => {
                              void debouncedChangeDuration(
                                value,
                                item.projectId,
                                item.taskId,
                                itC
                              );
                            }}
                          />
                        </SDivCellTime>
                      </STimeTd>
                    ))}
                    <STimeTd $textAlign="center">
                      <SDivTimeTotal>
                        {withHoursPlanning && (
                          <>
                            <SSpanPlanning $fontWeight="bold">
                              {getHoursAndMinutesStrByDuration(
                                item.calendar.reduce(function (
                                  acc: any,
                                  obj: TDateTimes
                                ) {
                                  return obj.planningDuration !== undefined
                                    ? acc + obj.planningDuration
                                    : acc;
                                }, 0)
                              )}
                            </SSpanPlanning>
                            {' / '}
                          </>
                        )}

                        <SSpanTime
                          $fontWeight="bold"
                          $opaque={
                            item.calendar.reduce(function (
                              acc: any,
                              obj: TDateTimes
                            ) {
                              return obj.time !== undefined
                                ? acc + obj.time
                                : acc;
                            }, 0) === 0
                          }
                        >
                          {getHoursAndMinutesStrByDuration(
                            item.calendar.reduce(function (
                              acc: any,
                              obj: TDateTimes
                            ) {
                              return obj.time !== undefined
                                ? acc + obj.time
                                : acc;
                            }, 0)
                          )}
                        </SSpanTime>
                      </SDivTimeTotal>
                    </STimeTd>
                  </STr>
                ))}
                <tr>
                  <td>
                    <SDivBtnAddRow>
                      <Button
                        onClick={() => setOpenAdd(true)}
                        type="default"
                        style={{
                          backgroundColor: '#0B446F4D',
                          color: '#0B446F',
                        }}
                      >
                        <Trans id="common.add.row" />
                      </Button>
                    </SDivBtnAddRow>
                  </td>
                </tr>
              </tbody>
              <SDevisTableFoot>
                <tr>
                  <SDevisTh $textAlign={'right'}>
                    <SDivTableItem>
                      <SDivStickyItem> {i18n._('common.total')}</SDivStickyItem>
                    </SDivTableItem>
                  </SDevisTh>
                  {calendar.map((c, index) => (
                    <STimeTd $textAlign="center" key={index}>
                      <SDivTimeTotal>
                        {withHoursPlanning && (
                          <>
                            <SSpanPlanning $fontWeight="bold">
                              {getHoursAndMinutesStrByDuration(
                                calcTotalPlanningDurationByDate(
                                  c.date,
                                  mappedData
                                )
                              )}
                            </SSpanPlanning>
                            {' / '}
                          </>
                        )}

                        <SSpanTime $fontWeight="bold">
                          {getHoursAndMinutesStrByDuration(
                            calcTotalTimeByDate(c.date, mappedData)
                          )}
                        </SSpanTime>
                      </SDivTimeTotal>
                    </STimeTd>
                  ))}
                  <STimeTd $textAlign="center">
                    <SDivTimeTotal>
                      {withHoursPlanning && (
                        <>
                          <SSpanPlanning $fontWeight="bold">
                            {getHoursAndMinutesStrByDuration(
                              calcTotalPlanningDuration(mappedData)
                            )}
                          </SSpanPlanning>
                          {' / '}
                        </>
                      )}

                      <SSpanTime $fontWeight="bold">
                        {getHoursAndMinutesStrByDuration(
                          calcTotalTimes(mappedData)
                        )}
                      </SSpanTime>
                    </SDivTimeTotal>
                  </STimeTd>
                </tr>
              </SDevisTableFoot>
            </SDevisTable>
          </STableContainer>
          <AddTime
            projects={projects}
            title={i18n._('time.add')}
            holydays={[]}
            isOpen={openAdd}
            dateDefault={
              !isDateNowInPeriod(startDate, endDate) ? startDate : undefined
            }
            close={() => setOpenAdd(false)}
            confirm={() => {
              setOpenAdd(false);
              handleRefresh();
            }}
          />
          {selectedItem && (
            <EditTime
              projects={projects}
              holydays={holidays}
              projectId={selectedItem.projectId}
              taskId={selectedItem.taskId}
              item={selectedItem.itemInCalendar}
              title={i18n._('time.edit')}
              isOpen={openEdit}
              close={() => {
                setOpenEdit(false);
              }}
              confirm={() => {
                setOpenEdit(false);
                setSelectedItem(undefined);
                handleRefresh();
              }}
            />
          )}
        </>
      )}
    </Container>
  );
}

export default TasksTimesTable;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SDivCellTime = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  & > div:first-of-type {
    margin-right: 2px;
  }
`;

const SDivTimeTotal = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
