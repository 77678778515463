import TasksTimesTable from '~/pages/components/common/time/TasksTimesTable';
import DefaultLayout from '~/pages/layouts/DefaultLayout';

function TimesPage() {
  return (
    <>
      <DefaultLayout>
        <TasksTimesTable />
      </DefaultLayout>
    </>
  );
}

export default TimesPage;
