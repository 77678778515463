import {
  StoredDevisInitRentaInfo,
  StoredTaskRentaInfo,
} from '~/gql/project/project';
import { StoredTaskForUserInfo } from '~/gql/task/task';
import { StoredTimeUserPlanningInfo } from '~/gql/user-planning/user-planning';
import {
  TCellTaskTimeAdmin,
  TDateTimes,
  TDevisInitRentaInfoExt,
  TTaskForUserInfo,
  TTaskRentaInfoExt,
} from '~/pages/types/types';

export function getMappedDataTasksTimesTable(
  data: StoredTimeUserPlanningInfo,
  calendar: TDateTimes[]
): TCellTaskTimeAdmin[] {
  const result: TCellTaskTimeAdmin[] = [];

  for (const uP of data.userPlanning) {
    const indTask = result.findIndex(
      (el) => el.taskId === uP.task?.id && el.useId === uP.user.id
    );
    if (indTask !== -1) {
      // is la tâche existe dans la liste
      const _existCalender: TDateTimes[] = JSON.parse(
        JSON.stringify(result[indTask].calendar)
      ).map((el: any) => el as TDateTimes);

      // trouver la date
      const indDate = _existCalender.findIndex(
        (el) =>
          new Date(el.date).setHours(8, 0, 0, 0) ===
          new Date(uP.date as Date).setHours(8, 0, 0, 0)
      );
      if (indDate !== -1) {
        _existCalender[indDate].planningDuration = uP.duration;
      }
      result[indTask].calendar = [..._existCalender];
    } else {
      const taskCalendar: TDateTimes[] = JSON.parse(
        JSON.stringify(calendar)
      ).map((el: any) => el as TDateTimes);

      // trouver la date
      const indDate = taskCalendar.findIndex(
        (el) =>
          new Date(el.date).setHours(8, 0, 0, 0) ===
          new Date(uP.date as Date).setHours(8, 0, 0, 0)
      );
      if (indDate !== -1) {
        taskCalendar[indDate].planningDuration = uP.duration;
      }

      if (uP.task)
        result.push({
          taskId: uP.task.id,
          taskName: uP.task.name,
          taskOrder: uP.task.order ?? 0,
          useId: uP.user.id,
          userFirstName: uP.user.firstName,
          userLastName: uP.user.lastName,
          calendar: [...taskCalendar],
        });
    }
  }

  for (const t of data.time) {
    if (t.task) {
      const indTask = result.findIndex(
        (el) => el.taskId === t.task?.id && el.useId === t.user?.id
      );
      if (indTask !== -1) {
        // task userPlannning existe pour cette tâche

        const _existCalender: TDateTimes[] = JSON.parse(
          JSON.stringify(result[indTask].calendar)
        ).map((el: any) => el as TDateTimes);

        // trouver la date
        const indDate = _existCalender.findIndex(
          (el) =>
            new Date(el.date).setHours(8, 0, 0, 0) ===
            new Date(t.date as Date).setHours(8, 0, 0, 0)
        );
        if (indDate !== -1) {
          _existCalender[indDate].time =
            (_existCalender[indDate].time ?? 0) + t.duration;
        }
        result[indTask].calendar = [..._existCalender];
      } else {
        // task userPlannning n'existe pas pour cette tâche
        const taskCalendar: TDateTimes[] = JSON.parse(
          JSON.stringify(calendar)
        ).map((el: any) => el as TDateTimes);

        // trouver la date
        const indDate = taskCalendar.findIndex(
          (el) =>
            new Date(el.date).setHours(8, 0, 0, 0) ===
            new Date(t.date as Date).setHours(8, 0, 0, 0)
        );
        if (indDate !== -1) {
          taskCalendar[indDate].time = t.duration;
          taskCalendar[indDate].timeId = t.id;
          taskCalendar[indDate].timeComment = t.comment;
        } else {
          taskCalendar[indDate].time =
            (taskCalendar[indDate]?.time ?? 0) + t.duration;
          taskCalendar[indDate].timeId = t.id; // TODO
          taskCalendar[indDate].timeComment =
            taskCalendar[indDate].timeComment + t.comment;
        }

        if (t.user)
          result.push({
            taskId: t.task.id,
            taskName: t.task.name,
            taskOrder: t.task.order ?? 0,
            useId: t.user.id,
            userFirstName: t.user.firstName,
            userLastName: t.user.lastName,
            calendar: [...taskCalendar],
          });
      }
    }
  }

  return result.sort(compareByUserAndTaskOrder);

  //
}

export function compareByUserAndTaskOrder(
  a: TCellTaskTimeAdmin,
  b: TCellTaskTimeAdmin
) {
  if (a.userFirstName + a.userLastName === b.userFirstName + a.userLastName) {
    return a.taskOrder > b.taskOrder ? 1 : -1;
  }
  return a.userFirstName + a.userLastName > b.userFirstName + a.userLastName
    ? 1
    : -1;
}

export function fillCalendarDetailsTable(
  startDate: Date,
  endDate: Date
): TDateTimes[] {
  const dateStart = new Date(startDate);
  const dateEnd = new Date(endDate);
  let currentDate = dateStart;
  const calendar: TDateTimes[] = [];
  if (dateStart > dateEnd) return [];

  while (currentDate < dateEnd) {
    const date = currentDate;
    const jourSemaine = date.getDay();
    if (jourSemaine !== 0 && jourSemaine !== 6) {
      // Jours travaillés sont du lundi au vendredi
      const elem = {
        date: date,
        selectible: true,
        label: '',
      };
      calendar.push(elem);
    } else {
      const elem = {
        date: date,
        selectible: false,
        label: '',
      };
      calendar.push(elem);
    }
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    currentDate = new Date(nextDate.setHours(8, 0, 0, 0));
  }

  return calendar;
}

export function calcTotalPlanningDurationAdmin(
  mappedData: TCellTaskTimeAdmin[]
): number {
  let result = 0;
  for (const mT of mappedData) {
    const itRes = mT.calendar.reduce(function (acc: any, obj: TDateTimes) {
      return obj.planningDuration !== undefined
        ? acc + obj.planningDuration
        : acc;
    }, 0);

    result = result + itRes;
  }
  return result;
}

export function calcTotalTimesAdmin(mappedData: TCellTaskTimeAdmin[]): number {
  let result = 0;
  for (const mT of mappedData) {
    const itRes = mT.calendar.reduce(function (acc: any, obj: TDateTimes) {
      return obj.time !== undefined ? acc + obj.time : acc;
    }, 0);

    result = result + itRes;
  }
  return result;
}

export function calcTotalTimeByDateAdmin(
  date: Date,
  mappedData: TCellTaskTimeAdmin[]
): number {
  const initDate = date.setHours(8, 0, 0, 0);
  let result = 0;
  for (const mT of mappedData) {
    const calenderItem = mT.calendar.find(
      (el) => new Date(el.date).setHours(8, 0, 0, 0) === initDate
    );
    if (calenderItem?.time !== undefined) result = result + calenderItem.time;
  }
  return result;
}

export function calcTotalPlanningDurationByDateAdmin(
  date: Date,
  mappedData: TCellTaskTimeAdmin[]
): number {
  const initDate = date.setHours(8, 0, 0, 0);
  let result = 0;
  for (const mT of mappedData) {
    const calenderItem = mT.calendar.find(
      (el) => new Date(el.date).setHours(8, 0, 0, 0) === initDate
    );
    if (calenderItem?.planningDuration !== undefined)
      result = result + calenderItem.planningDuration;
  }
  return result;
}

export function mappedTasksForUserInfo(
  tasks: StoredTaskForUserInfo[]
): TTaskForUserInfo[] {
  const mappedTasks: TTaskForUserInfo[] = tasks.map((el) => {
    const mTask: TTaskForUserInfo = {
      id: el.id,
      allTime: el.allTime,
      allUserPlanning: el.allUserPlanning,
      isOpened: false,
      name: el.name,
      projectManagerFirstName: el.projectManagerFirstName,
      projectManagerLastName: el.projectManagerLastName,
      time: el.time,
      userPlanning: el.userPlanning,
      timeAndUserPlanning: el.timeAndUserPlanning,
      projectName: el.projectName,
      order: el.order,
      projectType: el.projectType,
    };
    return mTask;
  });
  return mappedTasks;
}

export function getMappedDevisInitsRenta(
  _devisInits: StoredDevisInitRentaInfo[]
): TDevisInitRentaInfoExt[] {
  const mappedDevisInits: TDevisInitRentaInfoExt[] = _devisInits.map((el) => {
    const devisInit: TDevisInitRentaInfoExt = {
      id: el.id,
      amount: el.amount,
      duration: el.duration,
      durationExpected: el.durationExpected,
      deltaDurationProd: el.deltaDurationProd,
      deltaAmountProd: el.deltaAmountProd,
      deltaDurationSold: el.deltaDurationSold,
      customPosteName: el.customPosteName,
      amountUsed: el.amountUsed,
      deltaSommeSold: el.deltaSommeSold,
      functionType: el.functionType,
      durationUsed: el.durationUsed,
      durationByTasks: el.durationByTasks,
      amountByTasks: el.amountByTasks,
      amountSpendByTasks: el.amountSpendByTasks,
      timesByTasks: el.timesByTasks,
      poste: el.poste,
      isOpened: false,
    };
    return devisInit;
  });
  return mappedDevisInits;
}

export function getMappedTaskRenta(
  _tasks: StoredTaskRentaInfo[]
): TTaskRentaInfoExt[] {
  const mappedTasks: TTaskRentaInfoExt[] = _tasks.map((t) => {
    const task: TTaskRentaInfoExt = {
      id: t.id,
      amount: t.amount,
      amountSpend: t.amountSpend,
      calculatedAsAmount: t.calculatedAsAmount,
      deltaDuration: t.deltaDuration,
      duration: t.duration,
      isOpened: false,
      name: t.name,
      sommeTime: t.sommeTime,
      supp: t.supp,
      time: t.time,
      userRentaInfo: t.userRentaInfo,
      order: t.order,
    };
    return task;
  });
  return mappedTasks;
}
