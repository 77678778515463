import { GraphQLTypes, InputType, Selector } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

const pingSelector = Selector('Health')({
  env: true,
  status: true,
  time: true,
});

export const GQL_PING = typedGql('query')({
  getHealthGql: {
    ...pingSelector,
  },
});

export type StoredPing = InputType<GraphQLTypes['Health'], typeof pingSelector>;
