import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ProjectInternTable from '~/pages/tables/ProjectInternTable';

function ManagerInternPage() {
  return (
    <>
      <DefaultLayout>
        <ProjectInternTable />
      </DefaultLayout>
    </>
  );
}

export default ManagerInternPage;
