import { Layout } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import SuperProjectNavBar from '~/pages/layouts/SuperProjectNavBar';

const { Content } = Layout;

type IProps = {
  children: ReactNode;
};

function SuperProjectLayout({ children }: IProps) {
  return (
    <>
      <Layout style={{ minHeight: '100%', width: 'auto' }}>
        <SuperProjectNavBar />
        <Layout>
          <SContent>{children}</SContent>
        </Layout>
      </Layout>
    </>
  );
}

export default SuperProjectLayout;

const SContent = styled(Content)`
  padding: 10px;
`;
