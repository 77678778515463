import { Selector, $, GraphQLTypes, InputType } from '@zeus/index';

import { typedGql } from '@zeus/typedDocumentNode';
import { projectRentaInfoBaseSelector } from '~/gql/project/project';

const superProjectRentaSelector = Selector('SuperProjectRentaDetailsInfo')({
  id: true,
  name: true,
  renta: projectRentaInfoBaseSelector,

  projects: {
    id: true,
    name: true,
  },
  projectsRenta: {
    id: true,
    renta: projectRentaInfoBaseSelector,

    name: true,
    projectManager: {
      id: true,
      firstName: true,
      lastName: true,
    },
  },
});

const superProjectSelector = Selector('SuperProject')({
  id: true,
  name: true,
  projects: {
    id: true,
    name: true,
  },
});

export const GQL_GET_RENTA_BY_SUPER_PROJECT_ID = typedGql('query')({
  getRentaBySuperProjectId: [
    {
      id: $('id', 'Int!'),
    },
    superProjectRentaSelector,
  ],
});

export const GQL_CREATE_SUPER_PROJECT = typedGql('mutation')({
  createSuperProject: [
    {
      createSuperProjectInput: {
        name: $('name', 'String!'),
        projectIds: $('projectIds', '[Int!]'),
      },
    },
    true,
  ],
});

export const GQL_GET_ONE_SUPER_PROJECT = typedGql('query')({
  getOneSuperProject: [
    {
      id: $('id', 'Int!'),
    },
    superProjectSelector,
  ],
});

export const GQL_GET_FILTERED_SUPER_PROJECTS = typedGql('query')({
  getFilteredSuperProjectsInfo: [
    {
      filterProjectInput: {
        skip: $('skip', 'Int'),
        take: $('take', 'Int'),
        searchInSuperProjectName: $('searchInSuperProjectName', 'String'),
      },
    },
    {
      projects: superProjectSelector,
      total: true,
    },
  ],
});

export const GQL_DELETE_SUPER_PROJECT = typedGql('mutation')({
  deleteSuperProject: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_UPDATE_SUPER_PROJECT = typedGql('mutation')({
  updateSuperProject: [
    {
      updateProjectInput: {
        id: $('id', 'Int!'),
        name: $('name', 'String'),
        projectIds: $('projectIds', '[Int!]'),
      },
    },
    true,
  ],
});

export type StoredSuperProject = InputType<
  GraphQLTypes['SuperProject'],
  typeof superProjectSelector
>;

export type StoredSuperProjectRenta = InputType<
  GraphQLTypes['SuperProjectRentaDetailsInfo'],
  typeof superProjectRentaSelector
>;
