import { styled } from 'styled-components';
import { SSpanPlanning, SSpanTime } from '~/pages/components/styles/styles';

import { getHoursAndMinutesStrByDuration } from '~/utils/utils/utils';

type IProps = {
  userFirstName: string;
  userLastName: string;
  taskName: string;
  planningDuration: number;
  time: number;
  withHoursPlanning?: boolean;
};

function UserDetailsTimesItem({
  userFirstName,
  userLastName,
  planningDuration,
  taskName,
  time,
  withHoursPlanning,
}: IProps) {
  return (
    <Container>
      {(planningDuration || time) && (
        <>
          {userFirstName} {userLastName} {taskName}
        </>
      )}

      <SDivTimes $withHoursPlanning={withHoursPlanning}>
        {withHoursPlanning && (
          <SSpanPlanning>
            {planningDuration > 0 && (
              <>{getHoursAndMinutesStrByDuration(planningDuration)}</>
            )}
          </SSpanPlanning>
        )}

        <SSpanTime>
          {time > 0 && <> {getHoursAndMinutesStrByDuration(time)}</>}
        </SSpanTime>
      </SDivTimes>
    </Container>
  );
}

export default UserDetailsTimesItem;

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;

  width: 100%;
`;

const SDivTimes = styled.div<{ $withHoursPlanning?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: ${({ $withHoursPlanning }) =>
    $withHoursPlanning ? '115px' : '60px'};
`;
