import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { Trans, useLingui } from '@lingui/react';
import { Button, Checkbox, CheckboxProps, Tooltip, Typography } from 'antd';

import { styled } from 'styled-components';
import { PeriodEnum } from '~/pages/types/types';

type IProps = {
  onPrevClick: () => void;
  onTodayClick: () => void;
  onNextClick: () => void;
  onWeekClick: () => void;
  onMonthClick: () => void;
  onPrintClick?: () => void;
  period: PeriodEnum;
  withHoursPlanning: boolean;
  onChangeWithHoursPlanning: (value: boolean) => void;
  messageAlert?: string;
};

function TimesPeriodActions({
  onPrevClick,
  onTodayClick,
  onNextClick,
  onWeekClick,
  messageAlert,
  onMonthClick,
  onPrintClick,
  withHoursPlanning,
  onChangeWithHoursPlanning,
  period,
}: IProps) {
  const { Text } = Typography;
  const { i18n } = useLingui();

  const onChange: CheckboxProps['onChange'] = (e) => {
    onChangeWithHoursPlanning(e.target.checked);
  };

  return (
    <Container>
      <SDivActions>
        <Button
          type="primary"
          style={{ margin: '5px 2px' }}
          icon={<ArrowLeftOutlined />}
          onClick={onPrevClick}
        />
        <Button
          type="primary"
          style={{ margin: '5px 2px' }}
          onClick={onTodayClick}
        >
          <Trans id="common.now" />
        </Button>
        <Button
          type="primary"
          style={{ margin: '5px 22px 5px 2px' }}
          icon={<ArrowRightOutlined />}
          onClick={onNextClick}
        />

        <Button
          onClick={onWeekClick}
          type={period === PeriodEnum.WEEK ? 'default' : 'text'}
          style={{
            margin: '5px 2px',
          }}
        >
          <Trans id={PeriodEnum.WEEK} />
        </Button>
        <Button
          onClick={onMonthClick}
          type={period === PeriodEnum.MONTH ? 'default' : 'text'}
          style={{
            margin: '5px 2px',
          }}
        >
          <Trans id={PeriodEnum.MONTH} />
        </Button>
        <Checkbox
          style={{ marginLeft: '30px' }}
          checked={withHoursPlanning}
          onChange={onChange}
        >
          <Trans id="common.hours.planning" />
        </Checkbox>
      </SDivActions>
      <SDivAlert>
        {messageAlert !== '' && (
          <Text
            type="danger"
            style={{ marginRight: '10px', fontWeight: 'bold' }}
          >
            {messageAlert}
          </Text>
        )}
        {onPrintClick && (
          <Tooltip title={i18n._('print.period.users.times')}>
            <Button
              style={{ marginRight: '10px' }}
              onClick={onPrintClick}
              icon={<PrinterOutlined />}
              type="primary"
              shape="circle"
            />
          </Tooltip>
        )}
      </SDivAlert>
    </Container>
  );
}

export default TimesPeriodActions;

const SDivActions = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;
const SDivAlert = styled.div`
  align-items: center;
  display: flex;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  & > label > span > span {
    &.ant-checkbox-checked {
      background-color: #9a4655;
      border-color: #9a4655;
    }
    &.ant-checkbox-inner {
      background-color: #9a4655;
      border-color: #9a4655;
    }
  }
`;
