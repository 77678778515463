import { PrinterOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { ProjectType } from '@zeus/index';
import { Button, Select, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { GQL_ALL_HOLIDAYS, StoredHolidays } from '~/gql/holidays/holidays';
import {
  GQL_PROJECTS_WITH_TASKS,
  StoredProjectShortInfo,
} from '~/gql/project/project';
import {
  StoredTimeUserPlanningInfo,
  GQL_TIMES_USER_PLANNING_FOR_PERIOD,
} from '~/gql/user-planning/user-planning';
import { StoredUsers, GQL_USERS } from '~/gql/users/users';
import { authClient } from '~/helpers/apollo';
import { exportUserTasksTimes } from '~/pages/components/common/time/ExportUserTasksTimes';
import { getDayStatus } from '~/pages/components/common/utils-table/utils';
import {
  calcTotalPlanningDuration,
  calcTotalPlanningDurationByDate,
  calcTotalTimeByDate,
  calcTotalTimeByDateByProjectType,
  calcTotalTimes,
  calcTotalTimesByProjectType,
  fillCalendarTasksTimesTable,
  getMappedDataTasksTimesTable,
} from '~/pages/components/common/utils-table/utils-task-times-table';

import {
  SDevisTh,
  STimeTd,
  SDevisTableHead,
  SDevisTable,
  SDevisTableFoot,
  STr,
  SSpanTime,
  SSpanPlanning,
  STableContainer,
  SDivTableItem,
  SDivStickyItem,
} from '~/pages/components/styles/styles';
import DurationItemSmall from '~/pages/components/ui/DurationItemSmall';
import TimesPeriodActions from '~/pages/components/ui/TimesPeriodActions';
import { withPopover } from '~/pages/components/ui/withPopover';
import {
  PeriodEnum,
  TCellTaskTime,
  TDateTimes,
  TPeriodDates,
} from '~/pages/types/types';

import {
  getHoursAndMinutesStrByDuration,
  getPrevWeek,
  getNextWeek,
  getPrevMonth,
  getNextMonth,
  getStartAndEndForMonth,
  getStartAndEndForWeek,
} from '~/utils/utils/utils';

type IProps = {
  ids: number[];
};

function UserTasksTimesTable({ ids }: IProps) {
  const { i18n } = useLingui();
  const [projects, setProjects] = useState<StoredProjectShortInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(Date.now()));
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum.WEEK);
  const [calendar, setCalendar] = useState<TDateTimes[]>([]);
  const [withHoursPlanning, setWithHoursPlanning] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date>(
    getStartAndEndForMonth(new Date(Date.now())).start
  );
  const [endDate, setEndDate] = useState<Date>(
    getStartAndEndForMonth(new Date(Date.now())).end
  );
  const [mappedData, setMappedData] = useState<TCellTaskTime[]>([]);
  const [userList, setUserList] = useState<StoredUsers[]>([]);
  const [holidays, setHolidays] = useState<StoredHolidays[]>([]);

  const { refetch: fetchTimesUserPlannings } = useQuery(
    GQL_TIMES_USER_PLANNING_FOR_PERIOD,
    {
      variables: {
        userId: Number(ids[0]),
        startDate,
        endDate,
      },
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const _data: StoredTimeUserPlanningInfo = {
          userPlanning:
            data.getTimesUserPlanningsForPeriodByUserId.userPlanning,
          time: data.getTimesUserPlanningsForPeriodByUserId.time,
        };

        const _mapped = getMappedDataTasksTimesTable(_data, calendar);

        setMappedData(_mapped);
      },
      onError: (e) => {
        console.log('erreur dans GQL_TIMES_USER_PLANNING_FOR_PERIOD', e);
      },
    }
  );

  const { refetch: getAllHolidays } = useQuery(GQL_ALL_HOLIDAYS, {
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setHolidays(data.getAllHolidays);
    },
  });

  const { refetch: getFilteredProjects } = useQuery(GQL_PROJECTS_WITH_TASKS, {
    client: authClient,
    variables: {
      takeAll: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProjects(data.getAllProjectsWithTasks);
    },
  });

  async function getProjects() {
    setLoading(true);
    await getFilteredProjects();
    setLoading(false);
  }

  async function getTimesUserPlannings() {
    setLoading(true);
    await fetchTimesUserPlannings();
    setLoading(false);
  }
  const [getAllUsers] = useLazyQuery(GQL_USERS, {
    client: authClient,
    onCompleted: (data) => {
      setUserList(data.getUsers);
    },
  });

  async function getUserList() {
    await getAllUsers();
  }

  useEffect(() => {
    // formRef.current?.resetFields();
    void getUserList();
  }, []);

  useEffect(() => {
    setSelectedDate(new Date(Date.now()));
    void getProjects();
    void getTimesUserPlannings();
    void getHolydays();
  }, []);

  async function getHolydays() {
    setLoading(true);
    await getAllHolidays();
    setLoading(false);
  }

  useEffect(() => {
    const dates = getStartAndEndForWeek(new Date(selectedDate));
    setStartDate(dates.start);
    setEndDate(dates.end);
    setCalendar(fillCalendarTasksTimesTable(dates.start, dates.end));
  }, [selectedDate]);

  function handleRefresh() {
    void getTimesUserPlannings();
  }

  function setPeriodAndFillDataByDates(_dates: TPeriodDates) {
    setStartDate(_dates.start);
    setEndDate(_dates.end);
    setCalendar(fillCalendarTasksTimesTable(_dates.start, _dates.end));
    handleRefresh();
  }

  function handlePrevClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getPrevWeek(startDate, endDate)
        : getPrevMonth(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handleNextClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getNextWeek(startDate, endDate)
        : getNextMonth(startDate);

    setPeriodAndFillDataByDates(dates);
  }

  function handleTodayClick() {
    const dates =
      period === PeriodEnum.WEEK
        ? getStartAndEndForWeek(new Date(Date.now()))
        : getStartAndEndForMonth(new Date(Date.now()));

    setPeriodAndFillDataByDates(dates);
  }

  function handleMonthClick() {
    setPeriod(PeriodEnum.MONTH);
    const dates = getStartAndEndForMonth(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handleWeekClick() {
    setPeriod(PeriodEnum.WEEK);
    const dates = getStartAndEndForWeek(startDate);
    setPeriodAndFillDataByDates(dates);
  }

  function handlePrint() {
    const user = userList.find((el) => el.id === ids[0]);
    const shortName = user ? user.lastName + '-' + user.firstName : '';
    const fileName = user
      ? `${shortName}-${new Date().toLocaleDateString()}`
      : `${new Date().toLocaleDateString()}`;

    void exportUserTasksTimes({
      tasks: mappedData,
      calendar: calendar,
      fileName: fileName,
    });
  }

  const DurationItemSmallWithPopover = withPopover<{
    duration: number;
    onChange?: (duration: number) => void;
    $allowCorrection?: boolean;
    $opaque?: boolean;
    $align?: string;
    $color?: string;
    $backgroundColor?: string;
    onIconClick?: () => void;
    projectType?: ProjectType;
    onFocus?: () => void;
    $borderLeftColor?: string;
  }>(DurationItemSmall);

  return (
    <Container>
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <>
          <SDivUsers>
            <SDivUserName>
              <Select
                mode="multiple"
                disabled
                placeholder={i18n._('form.edit.users.placeholder')}
                value={ids}
                style={{ width: 'auto' }}
                optionFilterProp="label"
                options={userList.map((item) => ({
                  value: item.id,
                  label: item.firstName + ' ' + item.lastName,
                }))}
              />
            </SDivUserName>

            <Tooltip title={i18n._('print.user.data')}>
              <Button
                style={{ marginRight: '10px' }}
                onClick={handlePrint}
                icon={<PrinterOutlined />}
                type="primary"
                shape="circle"
              />
            </Tooltip>
          </SDivUsers>
          <TimesPeriodActions
            onPrevClick={handlePrevClick}
            onTodayClick={handleTodayClick}
            onNextClick={handleNextClick}
            onWeekClick={handleWeekClick}
            onMonthClick={handleMonthClick}
            period={period}
            withHoursPlanning={withHoursPlanning}
            onChangeWithHoursPlanning={setWithHoursPlanning}
          />
          <STableContainer>
            <SDevisTable style={{ width: '100%' }}>
              <SDevisTableHead>
                <tr>
                  <SDevisTh $minWidth="180px" $textAlign="center">
                    <SDivTableItem>
                      <SDivStickyItem $margin="0 5px 0 0">
                        {i18n._('common.task.project')}
                      </SDivStickyItem>
                      <SDivStickyItem>
                        {' '}
                        {i18n._('common.task.name')}
                      </SDivStickyItem>
                    </SDivTableItem>
                  </SDevisTh>

                  {calendar.map((el, index) => (
                    <SDevisTh
                      $fontSize="12px"
                      $textAlign="center"
                      key={index}
                      $bg={
                        el.date.setHours(8, 0, 0, 0) ===
                        new Date(Date.now()).setHours(8, 0, 0, 0)
                          ? '#B1BFCD'
                          : getDayStatus(el.date, holidays) === 'normal'
                            ? 'transparent'
                            : '#cdb1b8'
                      }
                    >
                      {el.date.toLocaleDateString()}
                    </SDevisTh>
                  ))}
                  <SDevisTh $textAlign="center" $minWidth="90px">
                    {i18n._('common.task.total')}
                  </SDevisTh>
                </tr>
              </SDevisTableHead>

              <tbody>
                {mappedData.map((item, indexInList) => (
                  <STr key={indexInList}>
                    <STimeTd>
                      <Tooltip
                        title={
                          projects.find((el) => el.id === item.projectId)
                            ?.name +
                          ' / ' +
                          projects
                            .find((el) => el.id === item.projectId)
                            ?.tasks.find((t) => t.id === item.taskId)?.name
                        }
                      >
                        <SDivTableItem>
                          <SDivStickyItem $margin="0 0px 0 0">
                            {item.projectName}
                          </SDivStickyItem>
                          <SDivStickyItem>{item.taskName}</SDivStickyItem>
                        </SDivTableItem>
                      </Tooltip>
                    </STimeTd>

                    {item.calendar.map((itC, index) => (
                      <STimeTd $textAlign="center" key={index}>
                        <SDivCellTime>
                          {withHoursPlanning && (
                            <SSpanPlanning $opaque={!itC.planningDuration}>
                              {getHoursAndMinutesStrByDuration(
                                itC.planningDuration ?? 0
                              )}
                            </SSpanPlanning>
                          )}

                          <DurationItemSmallWithPopover
                            comment={itC.timeComment ?? ''}
                            duration={itC.time ?? 0}
                            $backgroundColor="#fff"
                            $borderLeftColor={
                              itC.timeComment ? '#0b446f80' : 'transparent'
                            }
                            $color={itC.time ? '#0b446f' : '#0b446f80'}
                            projectType={item.projectType}
                          />
                        </SDivCellTime>
                      </STimeTd>
                    ))}
                    <STimeTd $textAlign="center">
                      <SDivTimeTotal>
                        {withHoursPlanning && (
                          <>
                            <SSpanPlanning $fontWeight="bold">
                              {getHoursAndMinutesStrByDuration(
                                item.calendar.reduce(function (
                                  acc: any,
                                  obj: TDateTimes
                                ) {
                                  return obj.planningDuration !== undefined
                                    ? acc + obj.planningDuration
                                    : acc;
                                }, 0)
                              )}
                            </SSpanPlanning>
                            {' / '}
                          </>
                        )}

                        <SSpanTime
                          $fontWeight="bold"
                          $opaque={
                            item.calendar.reduce(function (
                              acc: any,
                              obj: TDateTimes
                            ) {
                              return obj.time !== undefined
                                ? acc + obj.time
                                : acc;
                            }, 0) === 0
                          }
                        >
                          {getHoursAndMinutesStrByDuration(
                            item.calendar.reduce(function (
                              acc: any,
                              obj: TDateTimes
                            ) {
                              return obj.time !== undefined
                                ? acc + obj.time
                                : acc;
                            }, 0)
                          )}
                        </SSpanTime>
                      </SDivTimeTotal>
                    </STimeTd>
                  </STr>
                ))}
              </tbody>
              <SDevisTableFoot>
                <tr>
                  <SDevisTh $textAlign={'right'}>
                    {i18n._('common.total')}
                  </SDevisTh>
                  {calendar.map((c, index) => (
                    <STimeTd $textAlign="center" key={index}>
                      <SDivTimeTotal>
                        {withHoursPlanning && (
                          <>
                            <SSpanPlanning $fontWeight="bold">
                              {getHoursAndMinutesStrByDuration(
                                calcTotalPlanningDurationByDate(
                                  c.date,
                                  mappedData
                                )
                              )}
                            </SSpanPlanning>
                            {' / '}
                          </>
                        )}

                        <SSpanTime $fontWeight="bold">
                          {getHoursAndMinutesStrByDuration(
                            calcTotalTimeByDate(c.date, mappedData)
                          )}
                        </SSpanTime>
                      </SDivTimeTotal>
                    </STimeTd>
                  ))}
                  <STimeTd $textAlign="center">
                    <SDivTimeTotal>
                      {withHoursPlanning && (
                        <>
                          <SSpanPlanning $fontWeight="bold">
                            {getHoursAndMinutesStrByDuration(
                              calcTotalPlanningDuration(mappedData)
                            )}
                          </SSpanPlanning>
                          {' / '}
                        </>
                      )}

                      <SSpanTime $fontWeight="bold">
                        {getHoursAndMinutesStrByDuration(
                          calcTotalTimes(mappedData)
                        )}
                      </SSpanTime>
                    </SDivTimeTotal>
                  </STimeTd>
                </tr>

                <tr>
                  <SDevisTh $textAlign={'right'}>
                    {i18n._('common.total')} Interne
                  </SDevisTh>
                  {calendar.map((c, index) => (
                    <STimeTd $textAlign="center" key={index}>
                      <SDivTimeTotal $align="flex-end">
                        <SSpanTime $fontStyle="italic">
                          {getHoursAndMinutesStrByDuration(
                            calcTotalTimeByDateByProjectType(
                              c.date,
                              mappedData,
                              ProjectType.INTERN
                            )
                          )}
                        </SSpanTime>
                      </SDivTimeTotal>
                    </STimeTd>
                  ))}
                  <STimeTd $textAlign="center">
                    <SDivTimeTotal $align="flex-end">
                      <SSpanTime $fontStyle="italic">
                        {getHoursAndMinutesStrByDuration(
                          calcTotalTimesByProjectType(
                            mappedData,
                            ProjectType.INTERN
                          )
                        )}
                      </SSpanTime>
                    </SDivTimeTotal>
                  </STimeTd>
                </tr>
                <tr>
                  <SDevisTh $textAlign={'right'}>
                    {i18n._('common.total')} Externe
                  </SDevisTh>
                  {calendar.map((c, index) => (
                    <STimeTd $textAlign="center" key={index}>
                      <SDivTimeTotal $align="flex-end">
                        <SSpanTime>
                          {getHoursAndMinutesStrByDuration(
                            calcTotalTimeByDateByProjectType(
                              c.date,
                              mappedData,
                              ProjectType.EXTERN
                            )
                          )}
                        </SSpanTime>
                      </SDivTimeTotal>
                    </STimeTd>
                  ))}
                  <STimeTd $textAlign="center">
                    <SDivTimeTotal $align="flex-end">
                      <SSpanTime>
                        {getHoursAndMinutesStrByDuration(
                          calcTotalTimesByProjectType(
                            mappedData,
                            ProjectType.EXTERN
                          )
                        )}
                      </SSpanTime>
                    </SDivTimeTotal>
                  </STimeTd>
                </tr>
              </SDevisTableFoot>
            </SDevisTable>
          </STableContainer>
        </>
      )}
    </Container>
  );
}

export default UserTasksTimesTable;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SDivCellTime = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  & > div:first-of-type {
    margin-right: 2px;
  }
`;

const SDivTimeTotal = styled.div<{ $align?: string }>`
  align-items: center;
  display: flex;
  justify-content: ${({ $align }) => ($align ? $align : 'space-between;')};
`;

const SDivUsers = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const SDivUserName = styled.div`
  flex: 0 0 80%;
`;
