import { useLingui } from '@lingui/react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import Holidays from '~/pages/components/common/settings/Holidays';
import DefaultLayout from '~/pages/layouts/DefaultLayout';

function SettingsPage() {
  const { i18n } = useLingui();

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: i18n._('common.holidays'),
      children: <Holidays />,
    },
  ];

  return (
    <>
      <DefaultLayout>
        <Collapse items={items} defaultActiveKey={['1']} />
      </DefaultLayout>
    </>
  );
}

export default SettingsPage;
