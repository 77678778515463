import { RedoOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { ProjectType } from '@zeus/index';
import { Button, Space, FloatButton } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  GQL_FILTERED_PROJECTS,
  StoredProjectInfo,
} from '~/gql/project/project';

import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import AddProject from '~/pages/components/common/project/AddProject';
import DeleteProject from '~/pages/components/common/project/DeleteProject';

import { TableElement } from '~/pages/components/styles/styles';
import { downloadCSV } from '~/utils/exportCsv/utils';
import CommonTable from '~/utils/table/CommonTable';

function ProjectTable() {
  const navigate = useNavigate();
  const { payload } = useAuth();
  const { setCurrentProject, setCurrentDevisProd } = useDataUserStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [sortedInfo, setSortedInfo] = useState<any>();

  const [filteredProjects, setFilteredProjects] = useState<StoredProjectInfo[]>(
    []
  );

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [, setOpenImport] = useState<boolean>(false);

  const [rowSelect, setRowSelect] = useState<StoredProjectInfo>();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');

  const [yScroll] = useState(true);
  const [xScroll] = useState<string>();
  const { i18n } = useLingui();

  const { refetch: getFilteredProjects } = useQuery(GQL_FILTERED_PROJECTS, {
    variables: {
      skip: page <= 1 ? 0 : (page - 1) * pageSize,
      take: pageSize,
      ...(searchValue && {
        searchInProjectName: searchValue,
      }),
      projectType: ProjectType.EXTERN,
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFilteredProjects(data.getFilteredProjectsInfo.projects);
      setTotal(data.getFilteredProjectsInfo.total);
    },
  });

  const columns: ColumnsType<StoredProjectInfo> = [
    {
      title: 'Nom du projet',
      dataIndex: 'name',
      key: 'name',
      // width: 150,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo.order,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (_, { name }) => <TableElement>{name}</TableElement>,
    },

    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      // width: 150,

      render: () => <TableElement>{''}</TableElement>,
    },

    {
      title: 'Action',
      key: 'action',
      //    width: 150,
      render: (_, record) => (
        <Space size="middle">
          {(payload?.roles === 'Admin' || payload?.roles === 'Manager') && (
            <a
              onClick={(e) => {
                e.stopPropagation();
                setRowSelect(record);
                setOpenEdit(true);
              }}
            >
              <Button icon={<EditTwoTone />} />
            </a>
          )}
          {payload?.roles === 'Admin' && (
            <a
              onClick={(e) => {
                e.stopPropagation();
                setRowSelect(record);
                setOpenDelete(true);
              }}
            >
              <Button icon={<DeleteTwoTone />} />
            </a>
          )}
        </Space>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item }));
  if (xScroll === 'fixed') {
    tableColumns[0].fixed = true;
    tableColumns[tableColumns.length - 1].fixed = 'right';
  }

  async function getFilteredData() {
    setLoading(true);
    await getFilteredProjects();
    setLoading(false);
  }

  useEffect(() => {
    setSearchValue('');

    setCurrentProject(null);
    setCurrentDevisProd(null);
  }, []);

  function exportCsv() {
    downloadCSV(
      'Projets',
      ['Nom du projet'],
      filteredProjects.map((project) => {
        return [project.name];
      })
    );
  }

  function handleRefresh() {
    void getFilteredData();
  }

  useEffect(() => {
    void handleRefresh();
  }, [page, pageSize, searchValue, sortedInfo]);

  useEffect(() => {
    if (openEdit) navigate(`/manager-projects/devis/${rowSelect?.id}`);
  }, [openEdit]);

  function handleDblClick(id: number) {
    navigate(`/manager-projects/devis/${id}`);
  }

  return (
    <Container>
      <CommonTable
        loading={loading}
        data={filteredProjects}
        columns={columns}
        xScroll={xScroll}
        yScroll={yScroll}
        showFooter={false}
        showHeader={true}
        headerTitle={i18n._('table.projects.title')}
        selectionType="checkbox"
        top="none"
        bottom="bottomRight"
        addButton
        exportButton
        setOpenAdd={setOpenAdd}
        setOpenImport={setOpenImport}
        exportCsv={exportCsv}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        refresh={handleRefresh}
        total={total}
        setSortedInfo={setSortedInfo}
        onDblClick={handleDblClick}
        setSearchText={setSearchValue}
        search={true}
      />

      <AddProject
        title={i18n._('table.projects.add')}
        isOpen={openAdd}
        close={() => setOpenAdd(false)}
        confirm={() => {
          setOpenAdd(false);
          handleRefresh();
        }}
      />

      {rowSelect && (
        <DeleteProject
          project={rowSelect}
          title={i18n._('table.projects.delete')}
          isOpen={openDelete}
          close={() => setOpenDelete(false)}
          confirm={() => {
            setOpenDelete(false);
            handleRefresh();
          }}
        />
      )}
      <FloatButton
        onClick={handleRefresh}
        icon={<RedoOutlined style={{ fontSize: '20px' }} />}
        type="primary"
        style={{ right: 24, width: '50px', height: '50px', bottom: 24 }}
      />
    </Container>
  );
}

export default ProjectTable;

const Container = styled.div`
  padding-top: 25px;

  flex-grow: 1;
  height: 100%;
`;
