import { useLingui } from '@lingui/react';

import {
  DatePicker,
  InputNumber,
  Select,
  Form,
  Typography,
  Tooltip,
  Input,
} from 'antd';

import type { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import { StoredDevitInit } from '~/gql/devis/devis';
import { StoredFunction } from '~/gql/function/function';
import { StoredPoste } from '~/gql/poste/poste';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import useCalculetedDevisInit from '~/pages/components/common/utils-table/hooks/useCalculetedDevisInit';

import {
  SDevisTh,
  SDevisTd,
  SDevisTableHead,
  SDevisTable,
} from '~/pages/components/styles/styles';
import DurationItem from '~/pages/components/ui/DurationItem';

type IProps = {
  devisInit: StoredDevitInit;
  functionTypes: StoredFunction[];
  postes: StoredPoste[];
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

function ViewDevisInitP({ devisInit, functionTypes, postes }: IProps) {
  const { i18n } = useLingui();
  const { Text } = Typography;
  const formRef = React.useRef<FormInstance>(null);
  const {
    devisInitAmount,
    devisInitDuration,
    devisInitDurationExpected,
    devisInitDurationUsed,
    devisInitAmountUsed,
  } = useCalculetedDevisInit(devisInit);
  const currentProject = useDataUserStore((state) => state.currentProject);

  useEffect(() => {
    formRef.current?.resetFields();
  }, [devisInit]);

  return (
    <Container>
      <Form
        {...layout}
        ref={formRef}
        name="view-devis-init"
        initialValues={{
          dateSigned: devisInit.signedDate
            ? dayjs(devisInit.signedDate as string)
            : null,
          marge: currentProject?.marginPercent ?? 0,
        }}
        style={{ maxWidth: '100%' }}
      >
        <SDiv>
          <SDivItem>
            <Text style={{ marginRight: '10px' }}>
              {i18n._('form.date.signed')} :
            </Text>
            <Form.Item name="dateSigned" style={{ margin: '10px 10px' }}>
              <DatePicker
                style={{ width: '150px' }}
                format={dateFormat}
                disabled
              />
            </Form.Item>
          </SDivItem>

          <SDivItem>
            <Text style={{ marginRight: '10px' }}>
              {i18n._('form.marge.expected')} :
            </Text>
            <Form.Item name="marge" style={{ margin: '10px 10px' }}>
              <InputNumber
                style={{ width: '150px', marginRight: '10px' }}
                placeholder={i18n._('form.marge.placeholder')}
                controls={false}
                min={0}
                disabled
                addonAfter="%"
              />
            </Form.Item>
          </SDivItem>
        </SDiv>

        <SDevisTable>
          <SDevisTableHead>
            <tr>
              <SDevisTh $minWidth="80px">
                {i18n._('common.function.type')}
              </SDevisTh>
              <SDevisTh $minWidth="150px">{i18n._('common.poste')}</SDevisTh>
              <SDevisTh $minWidth="150px">{i18n._('common.amount')}</SDevisTh>
              <SDevisTh $minWidth="120px">{i18n._('common.duration')}</SDevisTh>
              <Tooltip title={i18n._('table.duration.expected.tipp')}>
                <SDevisTh $minWidth="120px">
                  {i18n._('common.duration.expected')}
                </SDevisTh>
              </Tooltip>
              <Tooltip title={i18n._('table.duration.used.tipp')}>
                <SDevisTh $minWidth="120px">
                  {i18n._('common.duration.used')}
                </SDevisTh>
              </Tooltip>
              <Tooltip title={i18n._('table.amount.used.tipp')}>
                <SDevisTh $minWidth="120px">
                  {i18n._('common.amount.used')}
                </SDevisTh>
              </Tooltip>
            </tr>
          </SDevisTableHead>
          <tbody>
            {devisInit.devisInit?.map((el, index) => (
              <tr key={index}>
                <SDevisTd>
                  <SDivTableItem>
                    <Select
                      showSearch
                      style={{ width: '80px' }}
                      placeholder={i18n._('form.project.manager.placeholder')}
                      value={
                        functionTypes.find(
                          (_el) => _el.name === el.functionType
                        )?.id ?? el.functionType
                      }
                      disabled={true}
                      optionFilterProp="label"
                      options={functionTypes.map((item) => ({
                        value: item.id,
                        label: i18n._(item.name),
                      }))}
                    />
                  </SDivTableItem>
                </SDevisTd>
                <SDevisTd>
                  <SDivTableItem>
                    <Select
                      showSearch
                      disabled
                      style={{ width: '150px' }}
                      value={el.posteId}
                      optionFilterProp="label"
                      options={postes.map((item) => ({
                        value: item.id,
                        label: i18n._(item.name),
                      }))}
                    />
                    {postes.find((p) => p.id === el.posteId)?.name ===
                      'Autre' && (
                      <Input
                        disabled
                        value={el.customPosteName}
                        style={{
                          width: '150px',
                          marginRight: '10px',
                          marginTop: '5px',
                        }}
                        placeholder={i18n._('form.poste.cutom.name')}
                      />
                    )}
                  </SDivTableItem>
                </SDevisTd>
                <SDevisTd $textAlign="right">
                  <SDivTableItem>
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={i18n._('form.amount.placeholder')}
                      controls={false}
                      value={el.amount}
                      step="0.01"
                      addonAfter="€"
                      disabled={true}
                    />
                  </SDivTableItem>
                </SDevisTd>

                <SDevisTd>
                  <SDivTableItem>
                    <DurationItem
                      duration={el.duration}
                      $allowCorrection={false}
                    />
                  </SDivTableItem>
                </SDevisTd>
                <SDevisTd $minWidth="120px">
                  <SDivTableItem>
                    <DurationItem
                      $allowCorrection={false}
                      duration={el.durationExpected}
                    />
                  </SDivTableItem>
                </SDevisTd>
                <SDevisTd $minWidth="120px">
                  <SDivTableItem>
                    <DurationItem
                      $allowCorrection={false}
                      duration={el.durationUsed ?? 0}
                    />
                  </SDivTableItem>
                </SDevisTd>

                <SDevisTd $textAlign="right">
                  <SDivTableItem>
                    <InputNumber
                      style={{ width: '120px' }}
                      placeholder={i18n._('form.amount.used.placeholder')}
                      controls={false}
                      value={el.amountUsed}
                      step="0.01"
                      addonAfter="€"
                      disabled={true}
                    />
                  </SDivTableItem>
                </SDevisTd>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <SDevisTh colSpan={2}>Total</SDevisTh>
              <SDevisTd $textAlign="right">
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={i18n._('form.amount.placeholder')}
                  controls={false}
                  value={devisInitAmount.toFixed(2)}
                  step="0.01"
                  addonAfter="€"
                  disabled={true}
                />
              </SDevisTd>

              <SDevisTd>
                <DurationItem
                  duration={devisInitDuration}
                  $allowCorrection={false}
                />
              </SDevisTd>
              <SDevisTd>
                <DurationItem duration={devisInitDurationExpected} />
              </SDevisTd>
              <SDevisTd>
                <DurationItem duration={devisInitDurationUsed} />
              </SDevisTd>

              <SDevisTd $textAlign="right">
                <InputNumber
                  style={{ width: '120px' }}
                  placeholder={i18n._('form.amount.used.placeholder')}
                  controls={false}
                  value={devisInitAmountUsed.toFixed(2)}
                  step="0.01"
                  addonAfter="€"
                  disabled={true}
                />
              </SDevisTd>
            </tr>
          </tfoot>
        </SDevisTable>
      </Form>
    </Container>
  );
}

export default ViewDevisInitP;

const SDivTableItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const SDivItem = styled.div`
  align-items: center;
  display: flex;
`;
