import { Layout } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import ManagerNavBar from '~/pages/layouts/ManagerNavBar';

const { Content } = Layout;

type IProps = {
  children: ReactNode;
};

function ManagerLayout({ children }: IProps) {
  return (
    <>
      <Layout style={{ minHeight: '100%', width: 'auto' }}>
        <ManagerNavBar />
        <Layout style={{ maxHeight: '100%' }}>
          <SContent>{children}</SContent>
        </Layout>
      </Layout>
    </>
  );
}

export default ManagerLayout;

const SContent = styled(Content)`
  padding: 10px;
  max-height: 100%;
`;
