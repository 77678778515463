import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Trans, useLingui } from '@lingui/react';
import { Button, Tooltip, Modal } from 'antd';
// import { Form, Input } from 'antd';
// import type { FormInstance } from 'antd/es/form';
import { useState } from 'react';
import styled from 'styled-components';
import { GQL_DELETE_TASK_INTERN, StoredTaskShortInfo } from '~/gql/task/task';
import { authClient } from '~/helpers/apollo';
import EditUsersInTask from '~/pages/components/common/planning/EditUsersInTask';
import AddTaskIntern from '~/pages/components/common/project/AddTaskIntern';
import AvatarList from '~/pages/components/ui/AvatarList';
import { classic } from '~/themes';

type IProps = {
  tasks: StoredTaskShortInfo[];
  projectId: number | undefined;
  refresh: () => void;
};

function TasksProjectIntern({ tasks, projectId, refresh }: IProps) {
  const { i18n } = useLingui();
  //   const { payload } = useAuth();
  //const { Title } = Typography;
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openEditUsers, setOpenEditUsers] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<StoredTaskShortInfo | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteTaskIntern] = useMutation(GQL_DELETE_TASK_INTERN, {
    client: authClient,
  });

  function addTaskIntern() {
    setOpenAdd(true);
  }

  async function onDeleteClick(ind: number) {
    await deleteTaskIntern({ variables: { id: ind } });
    refresh();
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (taskId: number) => {
    void onDeleteClick(taskId);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <SDivHeader>
        <SP>
          <Trans id="common.tasks.intern" />
        </SP>
      </SDivHeader>
      <SDivTasks>
        {tasks.map((task, index) => (
          <SDivTaskItem key={index}>
            <SDivTaskName>{task.name}</SDivTaskName>
            <SDivTaskUsers>
              <AvatarList maxCount={10} users={task.user}></AvatarList>
              <Tooltip title={i18n._('common.edit.users')}>
                <Button
                  shape="circle"
                  onClick={() => {
                    setSelectedTask(task);
                    setOpenEditUsers(true);
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            </SDivTaskUsers>
            <SDivActions>
              <Tooltip
                title={
                  task.user.filter((el) => el.hasTimes).length > 0
                    ? 'Ne peut pas être supprimée, car les intervenats ont déjà indiqué leurs temps pour cette tâche.'
                    : 'Supprimer'
                }
              >
                <Button
                  disabled={task.user.filter((el) => el.hasTimes).length > 0}
                  type="default"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setSelectedTask(task);
                    showModal();
                  }}
                />
              </Tooltip>
            </SDivActions>
          </SDivTaskItem>
        ))}
        <SDivBtn>
          <Button
            onClick={addTaskIntern}
            type="default"
            style={{ backgroundColor: '#0B446F4D', color: '#0B446F' }}
          >
            <Trans id="common.add.row" />
          </Button>
        </SDivBtn>
      </SDivTasks>
      {projectId && (
        <AddTaskIntern
          title={i18n._('table.task.intern.add')}
          isOpen={openAdd}
          close={() => setOpenAdd(false)}
          projectId={projectId}
          confirm={() => {
            setOpenAdd(false);
            refresh();
          }}
        />
      )}
      {selectedTask?.id && (
        <EditUsersInTask
          taskId={selectedTask.id}
          users={selectedTask.user}
          title={i18n._('task.edit.users')}
          isOpen={openEditUsers}
          taskType="INTERN"
          close={() => setOpenEditUsers(false)}
          confirm={() => {
            setOpenEditUsers(false);
            refresh();
          }}
        />
      )}
      {selectedTask?.id && (
        <Modal
          title={i18n._('common.task.intern.delete')}
          open={isModalOpen}
          onOk={() => handleOk(selectedTask.id)}
          onCancel={handleCancel}
        >
          <p>{i18n._('common.task.intern.delete.confirm')}</p>

          <br />
          <p>{i18n._('common.task.intern.delete.warning')}</p>
        </Modal>
      )}
    </Container>
  );
}

export default TasksProjectIntern;

const Container = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const SDivHeader = styled.div`
  background-color: ${classic.token?.colorPrimary};
  border-radius: 8px 8px 0 0;
  padding: 14px;
  width: 100%;
`;

const SP = styled.p`
  color: ${classic.token?.colorWhite};
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const SDivTasks = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  width: 80%;
`;

const SDivTaskItem = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${classic.token?.colorFillSecondary};
  border-radius: 4px;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
  &:hover {
    background-color: ${classic.token?.colorFillAlter};
  }
`;

const SDivTaskName = styled.div`
  flex: 0 0 40%;
  border-right: 1px solid ${classic.token?.colorFillSecondary};
`;

const SDivTaskUsers = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding: 0 10px;
  border-right: 1px solid ${classic.token?.colorFillSecondary};
`;

const SDivActions = styled.div`
  align-items: center;
  display: flex;

  padding: 0 10px;
`;

const SDivBtn = styled.div`
  padding: 10px;
  width: 100%;
`;
