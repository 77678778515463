import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { Form, Input, Select } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useState } from 'react';
import { StoredProjectForSuperProject } from '~/gql/project/project';

import { GQL_CREATE_SUPER_PROJECT } from '~/gql/super-project/super-project';

import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';

import ModalCustom from '~/pages/components/ui/ModalCustom';

type IProps = {
  title: string;
  isOpen: boolean;
  projects: StoredProjectForSuperProject[];
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function AddSuperProject({ title, isOpen, confirm, close, projects }: IProps) {
  const { i18n } = useLingui();
  const { payload } = useAuth();

  const [projectName, setProjectName] = useState<string>('');
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const formRef = React.useRef<FormInstance>(null);

  const [createProject] = useMutation(GQL_CREATE_SUPER_PROJECT, {
    client: authClient,
  });

  async function submit() {
    if (!payload?.sub || !projectName) return;

    const { data: _data } = await createProject({
      variables: {
        name: projectName,
        ...(selectedProjects.length > 0 && { projectIds: selectedProjects }),
      },
    });

    if (_data) {
      confirm();
    } else {
      close();
    }
  }

  return (
    <ModalCustom
      confirmDisabled={!projectName}
      title={title}
      isOpen={isOpen}
      confirm={submit}
      close={close}
    >
      <Form
        {...layout}
        ref={formRef}
        name="project"
        initialValues={{ projectName: '' }}
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          name="projectName"
          label={i18n._('common.name')}
          rules={[
            {
              required: true,
              message: i18n._('form.project-name.placeholder'),
            },
          ]}
        >
          <Input
            placeholder={i18n._('form.project-name.placeholder')}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="projects"
          label={i18n._('common.projects')}
          rules={[
            {
              required: true,
              message: i18n._('form.project-name.placeholder'),
            },
          ]}
        >
          <Select
            placeholder={i18n._('form.project.placeholder')}
            mode="multiple"
            showSearch
            value={selectedProjects}
            onChange={setSelectedProjects}
            style={{ width: '100%', padding: '2px' }}
            optionFilterProp="label"
            options={
              projects.length > 0
                ? projects.map((p) => ({
                    value: p.id,
                    label: p.name,
                  }))
                : []
            }
          />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
}

export default AddSuperProject;
