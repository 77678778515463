import { useLazyQuery, useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import { Form, Input, InputNumber, Select } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useState, useEffect } from 'react';
import { GQL_FUNCTIONS, StoredFunction } from '~/gql/function/function';
import { GQL_UPDATE_TASK_SUPP } from '~/gql/task/task';
import {
  GQL_CREATE_OR_UPDATE_TASK_PLANNING,
  StoredTaskInfoForPlanning,
} from '~/gql/task-planning/task-planning';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import DurationItem from '~/pages/components/ui/DurationItem';
import ModalCustom from '~/pages/components/ui/ModalCustom';
import { TaskInfoWithPlanningCalender } from '~/pages/types/types';
import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  task: StoredTaskInfoForPlanning | TaskInfoWithPlanningCalender;
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function EditTaskSupp({ title, isOpen, confirm, close, task }: IProps) {
  const { i18n } = useLingui();

  const { setCurrentDevisProd } = useDataUserStore();
  const formRef = React.useRef<FormInstance>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [functionTypes, setFunctionTypes] = useState<StoredFunction[]>([]);
  const [selectedFunctionType, setSelectedFunctionType] = useState<
    number | null
  >(null);
  const [taskName, setTaskName] = useState<string>('');

  const [amount, setAmount] = useState<number>(0);
  const [amountSpend, setAmountSpend] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  const [updateTaskSupp] = useMutation(GQL_UPDATE_TASK_SUPP, {
    client: authClient,
  });

  const [createOrUpdateTaskPlanning] = useMutation(
    GQL_CREATE_OR_UPDATE_TASK_PLANNING,
    {
      client: authClient,
    }
  );

  const [getFunctions] = useLazyQuery(GQL_FUNCTIONS, {
    client: authClient,
    onCompleted: (data) => {
      setFunctionTypes(data.getFunctions);
    },
  });

  useEffect(() => {
    formRef.current?.resetFields();
    void getFunctionTypes();

    setTaskName(task.name);
    setAmount(task.amount);
    setAmountSpend(task.amountSpend ?? 0);
    setDuration(task.duration);
  }, []);

  useEffect(() => {
    if (functionTypes.length > 0) {
      const fType = functionTypes.find((el) => el.name === task.functionType);
      setSelectedFunctionType(fType ? fType.id : null);
    }
  }, [functionTypes]);

  async function getFunctionTypes() {
    setLoading(true);
    await getFunctions();
    setLoading(false);
  }

  async function submit() {
    const fType = functionTypes.find((el) => el.id === selectedFunctionType);
    if (!fType || !taskName) return;

    const updateTaskSuppInput = {
      id: task.id,
      functionType: fType.name as FunctionType,
      name: taskName,
      duration: duration,
      amount: amount,
      amountSpend: amountSpend,
    };
    const { data: _data } = await updateTaskSupp({
      variables: {
        updateTaskSuppInput,
      },
    });

    // si changement de durée => recalc les planning
    if (duration !== task.duration) {
      const count = task.calendar.filter((el) => el.checked).length;
      const average = duration / count;

      const _dates: { date: Date; duration: number; id?: number }[] =
        task.calendar
          .filter((el) => el.checked)
          .map((d) => {
            return {
              date: d.date as Date,
              duration: Math.round(average),
              ...(d.id && { id: d.id }),
            };
          });

      const calcDuration = _dates.reduce(function (acc, obj) {
        return acc + obj.duration;
      }, 0);
      const deltaDuration = calcDuration - duration;

      if (deltaDuration !== 0 && _dates.length > 0) {
        _dates[_dates.length - 1].duration =
          _dates[_dates.length - 1].duration - deltaDuration;
      }

      const { data: _dataPlanning } = await createOrUpdateTaskPlanning({
        variables: {
          projectId: task.projectId,
          taskId: task.id,
          dates: _dates,
        },
      });
      if (_dataPlanning) {
        setCurrentDevisProd({
          ..._dataPlanning.createOrUpdateTaskPlanning,
          tasks:
            _dataPlanning.createOrUpdateTaskPlanning.tasks?.sort(
              compareByOrder
            ),
        });
      }
    }

    if (_data?.updateTaskSupp) {
      setCurrentDevisProd({
        ..._data.updateTaskSupp,
        tasks: _data.updateTaskSupp.tasks?.sort(compareByOrder),
      });
      confirm();
    } else {
      close();
    }
    formRef.current?.resetFields();
  }

  const onChangeDuration = (_duration: number) => {
    setDuration(_duration);
  };

  const onChangeAmount = (value: number | null) => {
    if (value !== null) setAmount(value);
  };

  const onChangeAmountSpend = (value: number | null) => {
    if (value !== null) setAmountSpend(value);
  };

  return (
    <ModalCustom
      confirmDisabled={!taskName || !selectedFunctionType}
      title={title}
      isOpen={isOpen}
      confirm={submit}
      close={close}
    >
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <>
          <Form
            {...layout}
            ref={formRef}
            name="task-edit"
            initialValues={{
              taskName: task.name,
              functionType: task.functionType,
              duration: task.duration,
              amount: task.amount,
              amountSpend: task.amountSpend,
            }}
            style={{ maxWidth: 600 }}
          >
            <Form.Item
              name="taskName"
              label={i18n._('common.task.name')}
              rules={[
                {
                  required: true,
                  message: i18n._('form.task-name.placeholder'),
                },
              ]}
            >
              <Input
                placeholder={i18n._('form.task-name.placeholder')}
                onChange={(e) => setTaskName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="functionType"
              label={i18n._('common.function.type')}
              rules={[
                {
                  required: true,
                  message: i18n._('form.task-name.placeholder'),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={i18n._('common.function.type')}
                value={selectedFunctionType}
                onChange={setSelectedFunctionType}
                optionFilterProp="label"
                options={functionTypes.map((item) => ({
                  value: item.id,
                  label: i18n._(item.name),
                }))}
              />
            </Form.Item>
            {task.duration > 0 && (
              <>
                <Form.Item
                  name="duration"
                  style={{ width: '100%' }}
                  label={i18n._('common.duration')}
                  rules={[
                    {
                      required: true,
                      message: i18n._('form.project-name.placeholder'),
                    },
                  ]}
                >
                  <DurationItem
                    $allowCorrection
                    duration={duration}
                    onChange={onChangeDuration}
                    $align="flex-start"
                  />
                </Form.Item>
              </>
            )}

            {task.duration === 0 && (
              <>
                <Form.Item
                  name="amount"
                  style={{ width: '100%' }}
                  label={i18n._('common.amount')}
                  rules={[
                    {
                      required: true,
                      message: i18n._('form.project-name.placeholder'),
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '150px', marginRight: '10px' }}
                    placeholder={i18n._('form.amount.placeholder')}
                    onChange={onChangeAmount}
                    value={amount}
                    controls={false}
                    step="0.01"
                    addonAfter="€"
                  />
                </Form.Item>

                <Form.Item
                  name="amountSpend"
                  style={{ width: '100%' }}
                  label={i18n._('common.amount.spend')}
                  rules={[
                    {
                      required: true,
                      message: i18n._('form.project-name.placeholder'),
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '150px', marginRight: '10px' }}
                    placeholder={i18n._('form.amount.placeholder')}
                    onChange={onChangeAmountSpend}
                    value={amountSpend}
                    controls={false}
                    step="0.01"
                    addonAfter="€"
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </>
      )}
    </ModalCustom>
  );
}

export default EditTaskSupp;

/*
pour rajouter plus tard si besoin

 <Form.Item
              name="duration"
              style={{ width: '100%' }}
              label={i18n._('common.duration')}
              rules={[
                {
                  required: true,
                  message: i18n._('form.project-name.placeholder'),
                },
              ]}
            >
              <DurationItem
                $allowCorrection
                duration={duration}
                onChange={onChangeDuration}
                $align="flex-start"
              />
            </Form.Item>

            <Form.Item
              name="amount"
              style={{ width: '100%' }}
              label={i18n._('common.amount')}
              rules={[
                {
                  required: true,
                  message: i18n._('form.project-name.placeholder'),
                },
              ]}
            >
              <InputNumber
                style={{ width: '150px', marginRight: '10px' }}
                placeholder={i18n._('form.amount.placeholder')}
                onChange={onChangeAmount}
                value={amount}
                controls={false}
                step="0.01"
                addonAfter="€"
              />
            </Form.Item>
*/
