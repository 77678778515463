import { OrderByEnum } from '@zeus/index';

export const returnOrderBy = (
  field: string | undefined,
  order: string | undefined
) => {
  if (field !== undefined && order !== undefined && typeof order === 'string') {
    return {
      [field]: order === 'ascend' ? OrderByEnum.ASC : OrderByEnum.DESC,
    };
  } else {
    return null;
  }
};
