/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { ProjectType } from '@zeus/index';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  GQL_GET_RENTA_BY_PROJECT_ID,
  StoredProjectRentaInfo,
  StoredProjectRentaInfoBase,
} from '~/gql/project/project';

import { authClient } from '~/helpers/apollo';
import DevisInitsRentaDetails from '~/pages/components/common/details/DevisInitsRentaDetails';
import RentaDetails from '~/pages/components/common/details/RentaDetails';
import SuppRentaDetails from '~/pages/components/common/details/SuppRentaDetails';
import TasksRentaDetails from '~/pages/components/common/details/TasksRentaDetails';

import {
  getMappedDevisInitsRenta,
  getMappedTaskRenta,
} from '~/pages/components/common/utils-table/utils-project-details-table';

import { TDevisInitRentaInfoExt, TTaskRentaInfoExt } from '~/pages/types/types';

import { classic } from '~/themes/classic';

function ProjectRenta() {
  const params = useParams();
  const { id } = params;
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>('');
  const [project, setProject] = useState<StoredProjectRentaInfo | null>(null);
  const [tasks, setTasks] = useState<TTaskRentaInfoExt[]>([]);
  const [rentaProject, setRentaProject] = useState<
    StoredProjectRentaInfoBase | undefined
  >(undefined);
  const [devisInits, setDevisInits] = useState<TDevisInitRentaInfoExt[]>([]);

  const { refetch: fetchProject } = useQuery(GQL_GET_RENTA_BY_PROJECT_ID, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProject(data.getRentaByProjectId);
      setRentaProject(data.getRentaByProjectId.renta);
      setTasks(getMappedTaskRenta(data.getRentaByProjectId.tasks));
      setDevisInits(
        getMappedDevisInitsRenta(data.getRentaByProjectId.devisInit)
      );
      setProjectName(data.getRentaByProjectId.name);
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_ONE_PROJECT', e);
    },
  });

  useEffect(() => {
    void getProject();
  }, []);

  useEffect(() => {
    if (project) setProjectName(project.name);
  }, [project]);

  async function getProject() {
    setLoading(true);
    await fetchProject();
    setLoading(false);
  }

  function handleSetTaskOpen(taskId: number) {
    const _tasks = [...tasks];
    const ind = _tasks.findIndex((el) => el.id === taskId);
    if (ind === -1) return;
    _tasks[ind].isOpened = !_tasks[ind].isOpened;
    setTasks(_tasks);
  }

  return (
    <>
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <SDivContentWrapper>
          {project &&
            project.projectType === ProjectType.EXTERN &&
            !project.renta.projectDurationSold && (
              <>
                <SSpan color={`${classic.token?.colorWarning}`}>
                  {i18n._('common.warning')}
                </SSpan>{' '}
                <SSpan
                  color={`${classic.token?.colorWarning}`}
                  $textTransform="normal"
                >
                  {i18n._('common.renta.warning')}
                </SSpan>
              </>
            )}

          <SDivWrapper>
            <SDivHeader>
              <SSpan>
                {i18n._('common.project')} : {projectName}
              </SSpan>
              <SSpan>
                {i18n._('common.intern.renta')} :{' '}
                {project?.renta.rentaPercent.toFixed(2) + '%'}
              </SSpan>
            </SDivHeader>
            {rentaProject && (
              <RentaDetails
                renta={rentaProject}
                projectName={project?.name ?? ''}
              />
            )}
            <br />
            <br />
            <SDivTasks>
              <DevisInitsRentaDetails devisInits={devisInits} />
              <br />
              {project?.supp.timesByTasks ||
              project?.supp.amountSpendByTasks ? (
                <SuppRentaDetails suppRentaInfo={project.supp} />
              ) : (
                <></>
              )}
              <br />
              <br />
              <TasksRentaDetails tasks={tasks} onTaskOpen={handleSetTaskOpen} />
            </SDivTasks>
          </SDivWrapper>
        </SDivContentWrapper>
      )}
    </>
  );
}

export default ProjectRenta;

const SDivHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: ${classic.token?.colorPrimary};
  border-radius: 8px 8px 0 0;
  padding: 14px;
  width: 100%;
`;
const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SDivTasks = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const SDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSpan = styled.span<{ color?: string; $textTransform?: string }>`
  color: ${({ color }) => (color ? color : ` ${classic.token?.colorWhite}`)};
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: ${({ $textTransform }) =>
    $textTransform ? $textTransform : 'uppercase'};
`;

/*
  <SDivTasks>
              <SDivTaskItem $bg="#0B446F0A">
                <SDivTaskName fontWeight="700" $align="center"></SDivTaskName>
                <SDivTaskSommeItem fontWeight="700" $align="center">
                  {i18n._('common.sold')}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700" $align="center">
                  {i18n._('common.duration.devis.prod')}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700" $align="center">
                  {i18n._('common.times')}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700" $align="center">
                  {i18n._('common.duration.used')}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700" $align="center">
                  {i18n._('common.result')} devis prod
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700" $align="center">
                  {i18n._('common.result')} vendu
                </SDivTaskSommeItem>
              </SDivTaskItem>
              <SDivTaskItem>
                <SDivTaskName fontWeight="700" $align="flex-start">
                  Projet: {projectName}
                </SDivTaskName>
                <Tooltip title={'Durée de la vente'}>
                  <SDivTaskSommeItem fontWeight="700">
                    {getHoursAndMinutesStrByDurationForTimes(
                      project?.projectDurationSold ?? 0
                    )}
                  </SDivTaskSommeItem>
                </Tooltip>
                <Tooltip title={'Durée à partir du devis prod'}>
                  <SDivTaskSommeItem fontWeight="700">
                    {getHoursAndMinutesStrByDurationForTimes(
                      project?.projectDuration ?? 0
                    )}
                  </SDivTaskSommeItem>
                </Tooltip>
                <SDivTaskSommeItem fontWeight="700">
                  {getHoursAndMinutesStrByDurationForTimes(
                    project?.projectSommeTime ?? 0
                  )}
                </SDivTaskSommeItem>
                <SDivTaskSommeItem fontWeight="700">
                  {getHoursAndMinutesStrByDurationForTimes(
                    project?.projectDurationUsed ?? 0
                  )}
                </SDivTaskSommeItem>
                <Tooltip title={'Résultat à partir du devis prod'}>
                  <SDivTaskSommeItem
                    color={getColorForDuration(
                      project?.projectDeltaDuration ?? 0
                    )}
                    fontWeight="700"
                  >
                    {getHoursAndMinutesStrByDurationForTimes(
                      (project?.projectDeltaDuration ?? 0) -
                        (project?.projectDurationUsed ?? 0)
                    )}
                  </SDivTaskSommeItem>
                </Tooltip>
                <Tooltip title={'Résultat à partir  de vendu'}>
                  <SDivTaskSommeItem
                    color={getColorForDuration(
                      project?.projectDeltaDuration ?? 0
                    )}
                    fontWeight="700"
                  >
                    {getHoursAndMinutesStrByDurationForTimes(
                      (project?.projectDeltaDurationSold ?? 0) -
                        (project?.projectDurationUsed ?? 0)
                    )}
                  </SDivTaskSommeItem>
                </Tooltip>
              </SDivTaskItem>
              <br />
              <TasksRentaDetails tasks={tasks} onTaskOpen={handleSetTaskOpen} />
              <br />
              <DevisInitsRentaDetails devisInits={devisInits} />
            </SDivTasks>
*/
