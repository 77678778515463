import { ThemeConfig } from 'antd';
// https://ant.design/docs/react/customize-theme
export const classic: ThemeConfig = {
  token: {
    colorPrimary: '#0B446F', //'#188',
    colorFillSecondary: '#0B446F4D', //'#10888880',
    colorText: '#4A4A4A',
    colorTextSecondary: '#4A4A4A',
    colorFillAlter: '#f0f0f0',
    colorIcon: '#0B446F', //'#188',
    colorIconHover: '#0B446F', //'#188',
    colorLink: '#0B446F', // '#188',
    colorError: '#E7340E ',
    colorTextDisabled: '#0B446F',
    colorWhite: '#ffffff',
    colorWarning: '#dd4848',
    colorSuccess: '#14b15c',
  },
  components: {
    Input: {
      colorText: '#000000',
      colorTextPlaceholder: '#918f8a',
    },
    Modal: {
      colorText: '#4A4A4A',
    },
    Drawer: {
      colorText: '#000000',
    },
    Table: {
      colorText: '#000000',
    },
  },
};
