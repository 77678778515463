import DefaultLayout from '~/pages/layouts/DefaultLayout';

function SettingsUserPage() {
  return (
    <>
      <DefaultLayout>SettingsUserPage</DefaultLayout>
    </>
  );
}

export default SettingsUserPage;
