import { Popover } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { ComponentType } from 'react';

type WithPopoverProps = {
  comment: string;
};

export function withPopover<P extends object>(
  WrappedComponent: ComponentType<P>
) {
  return (props: P & WithPopoverProps) => {
    const { comment, ...restProps } = props; // Extraire `comment` et le reste des props
    const content = (
      <TextArea style={{ border: 'none' }} autoSize rows={4} value={comment} />
    );

    if (!comment) return <WrappedComponent {...(restProps as P)} />;

    return (
      <Popover content={content} trigger={'hover'}>
        <div>
          <WrappedComponent {...(restProps as P)} />
        </div>
      </Popover>
    );
  };
}
