import ProjectDetails from '~/pages/components/common/details/ProjectDetails';

import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ManagerLayout from '~/pages/layouts/ManagerLayout';

function ProjectDetailsPage() {
  return (
    <>
      <DefaultLayout>
        <ManagerLayout>
          <ProjectDetails />
        </ManagerLayout>
      </DefaultLayout>
    </>
  );
}

export default ProjectDetailsPage;
