import { CloseOutlined, MailOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import {
  ConfigProvider,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  notification,
} from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { GQL_ASK_REGISTER } from '~/gql/auth/auth';
import { GQL_ROLES, StoredRoles } from '~/gql/role/role';
import { authClient } from '~/helpers/apollo';
import { Role } from '~/pages/utils/router';
import { classic } from '~/themes';
import { FormGroup, SH2, StyledButton } from '~/utils/ui/utils';

type IProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: () => void;
};

function UserAddModal({ open, setOpen, refresh }: IProps) {
  const [roles, setRoles] = useState<StoredRoles[]>([]);
  const [roleId, setRoleId] = useState<number>(3);
  const [email, setEmail] = useState<string>('');
  const [form] = Form.useForm();
  const { i18n } = useLingui();

  useQuery(GQL_ROLES, {
    client: authClient,
    onCompleted: (data) => {
      setRoles(data.getRoles);
    },
  });

  const [askRegister] = useMutation(GQL_ASK_REGISTER, {
    client: authClient,
    onCompleted: () => {
      notification.success({
        message: i18n._('add.user'),
        description: i18n._('good.add.user'),
        placement: 'topRight',
      });
      refresh();
      setOpen(false);
    },
    onError: () => {
      notification.error({
        message: i18n._('add.user'),
        description: i18n._('bad.add.user'),
        placement: 'topRight',
      });
      setOpen(false);
    },
  });

  const handleChangeRole = (value: number) => {
    setRoleId(value);
  };

  const onFinish = async () => {
    await askRegister({
      variables: {
        registerUserInput: {
          email: email,
          roleId: roleId,
        },
      },
    });
  };

  return (
    <Modal
      open={open}
      centered
      closeIcon={false}
      style={{ textAlign: 'center' }}
      footer={false}
      onCancel={() => setOpen(false)}
    >
      <SForm
        initialValues={{
          roles: { value: 2, label: Role.User },
        }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item>
          <SH2 $color={classic.token?.colorPrimary}>{i18n._('add.user')}</SH2>
        </Form.Item>

        <Form.Item
          name="email"
          className="customInput"
          rules={[
            {
              required: true,
              message: i18n._('input.required.message'),
            },
            {
              pattern: new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
              message: i18n._('input.email.message'),
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder={i18n._('common.email')}
            onChange={(e) => setEmail(e.target.value)}
            size="large"
            type="text"
          />
        </Form.Item>

        <FormGroup $direction="column" $gap="5px">
          <Form.Item
            name="roles"
            className="customInput"
            rules={[
              {
                required: true,
                message: i18n._('input.required.message'),
              },
            ]}
          >
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description={i18n._('no.content')}
                  imageStyle={{ height: 60 }}
                />
              )}
            >
              <Select
                options={roles.map((_role) => ({
                  value: _role.id,
                  label: _role.name,
                }))}
                defaultValue={2}
                size="large"
                placeholder={i18n._('common.role')}
                onChange={handleChangeRole}
              />
            </ConfigProvider>
          </Form.Item>
        </FormGroup>

        <StyledButton $margin="15px 0 0" type="submit">
          {i18n._('common.add')}
        </StyledButton>
      </SForm>
      <CloseOutlined
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fontSize: '25px',
          cursor: 'pointer',
          color: classic.token?.colorFillSecondary,
        }}
        onClick={() => setOpen(false)}
      />
    </Modal>
  );
}

export default UserAddModal;

const SForm = styled(Form)`
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .customInput {
    width: 100%;
    margin-bottom: 10px;
    & span:nth-child(1),
    & input,
    & .ant-select-selector {
      text-align: left;
    }
  }

  & .ant-form-item-explain-error {
    text-align: left;
  }
`;
