import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useLingui, Trans } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import { Button, Tooltip } from 'antd';

import { useEffect, useRef, useState } from 'react';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { GQL_CREATE_OR_UPDATE_USERS_BY_FUNCTION_TYPE } from '~/gql/task/task';
import {
  GQL_CREATE_OR_UPDATE_TASK_PLANNING,
  GQL_GET_PLANNING_BY_PROJECT_ID,
  StoredPlanning,
  StoredTaskInfoForPlanning,
} from '~/gql/task-planning/task-planning';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import AddTaskSupp from '~/pages/components/common/planning/AddTaskSupp';
import DeleteTaskSupp from '~/pages/components/common/planning/DeleteTaskSupp';
import EditAllUsers from '~/pages/components/common/planning/EditAllUsers';
import EditTaskSupp from '~/pages/components/common/planning/EditTaskSupp';
import EditUsersInTask from '~/pages/components/common/planning/EditUsersInTask';
//import { printExcel } from '~/pages/components/common/planning/PrintExcel';
import { exportExcel } from '~/pages/components/common/planning/exportExcel';
import {
  getNewDates,
  getUsersByFunctionTypeForTasksExt,
} from '~/pages/components/common/utils-table/utils';
import {
  SDevisTh,
  STaskTd,
  SDevisTableHead,
  SDevisTable,
  STaskDaysTh,
  SDivCheckRetroInPl,
  SDivCheckPlanning,
  STableTr,
} from '~/pages/components/styles/styles';
import AvatarList from '~/pages/components/ui/AvatarList';

import { TPeriod, TUsersByFunctionType } from '~/pages/types/types';
import {
  calcRetroPlanningByPlanning,
  compareByOrder,
  getHoursAndMinutesStrByDuration,
  getHoursStrByDuration,
  hasPeriodsCross,
} from '~/utils/utils/utils';

type IProps = {
  isPrinting: boolean;
  changeIsPrinting: (value: boolean) => void;
};

function TasksPlanningExtTable({ isPrinting, changeIsPrinting }: IProps) {
  const params = useParams();
  const { id } = params;
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);
  const { currentProject, setCurrentDevisProd, currentDevisProd } =
    useDataUserStore();
  const ref = useRef(null);
  const [openEditUsers, setOpenEditUsers] = useState<boolean>(false);
  const [openEditAllUsers, setOpenEditAllUsers] = useState<boolean>(false);
  const [openDeleteTaskSupp, setOpenDeleteTaskSupp] = useState<boolean>(false);
  const [openEditTaskSupp, setOpenEditTaskSupp] = useState<boolean>(false);
  const [usersByFunctionType, setUsersByFunctionType] = useState<
    TUsersByFunctionType[]
  >([]);
  const [usersWithTimeByFunctionType, setUsersWithTimesByFunctionType] =
    useState<TUsersByFunctionType[]>([]);
  const [planning, setPlanning] = useState<StoredPlanning>({
    tasks: [],
    tasksSupp: [],
    calendar: [],
  });
  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const [selectedTask, setSelectedTask] = useState<
    StoredTaskInfoForPlanning | undefined
  >(undefined);

  const [period, setPeriod] = useState<TPeriod>({
    beginCell: undefined,
    endCell: undefined,
  });

  const { refetch: fetchPlanningByProjectId } = useQuery(
    GQL_GET_PLANNING_BY_PROJECT_ID,
    {
      variables: {
        id: Number(id),
      },
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setPlanning(data.getPlanningByProjectId);

        const users: {
          usersWithoutTime: TUsersByFunctionType[];
          usersWithTime: TUsersByFunctionType[];
        } = getUsersByFunctionTypeForTasksExt(
          data.getPlanningByProjectId.tasks
        );

        setUsersByFunctionType(users.usersWithoutTime);
        setUsersWithTimesByFunctionType(users.usersWithTime);
      },
      onError: (e) => {
        console.log('erreur dans GQL_GET_ONE_PROJECT', e);
      },
    }
  );

  const [createOrUpdateUsersByFunctionType] = useMutation(
    GQL_CREATE_OR_UPDATE_USERS_BY_FUNCTION_TYPE,
    {
      client: authClient,
      fetchPolicy: 'no-cache',
    }
  );

  const [createOrUpdateTaskPlanning] = useMutation(
    GQL_CREATE_OR_UPDATE_TASK_PLANNING,
    {
      client: authClient,
      fetchPolicy: 'no-cache',
    }
  );

  async function getPlanningByProjectId() {
    setLoading(true);
    await fetchPlanningByProjectId();
    setLoading(false);
  }

  useEffect(() => {
    void getPlanningByProjectId();
  }, []);

  useEffect(() => {
    if (isPrinting) {
      const shortName = currentProject?.name
        ? currentProject.name.length > 10
          ? currentProject.name.slice(0, 10)
          : currentProject.name
        : '';
      const fileName = currentProject
        ? `${shortName}-planning-${new Date().toLocaleDateString()}`
        : `planning-${new Date().toLocaleDateString()}`;

      print(fileName);
      const mappedPlanning = calcRetroPlanningByPlanning(planning);

      void exportExcel({
        tasks: mappedPlanning.tasks,
        tasksSupp: mappedPlanning.tasksSupp,
        calendar: mappedPlanning.calendar,
        fileName: fileName,
      });
      //printExcel({ tasks: tasks, tasksSupp: tasksSupp, calendar: calendar });
      changeIsPrinting(false);
    }
  }, [isPrinting]);

  function print(fileName: string) {
    // void exportComponentAsPDF(ref, {
    //   fileName: 'retro-planning.pdf',
    //   pdfOptions: { orientation: 'l' },
    // });
    void exportComponentAsJPEG(ref, {
      fileName: `${fileName}.jpg`,
    });
  }

  async function handleCellClick(
    e: React.MouseEvent<HTMLTableDataCellElement>,
    itemId: number,
    indInCalendar: number,
    dateInCalendar: Date,
    supp: boolean
  ) {
    e.stopPropagation();
    if (!currentProject) return;
    if (e.shiftKey) {
      if (!period.beginCell) {
        // click pour beginPeriod
        setPeriod({
          beginCell: {
            itemId: itemId,
            indexInCalendar: indInCalendar,
            dateInCalendar: dateInCalendar,
          },
          endCell: undefined,
        });
      } else {
        // click pour endPeriod
        if (!period.beginCell.dateInCalendar) return;
        const start: Date = period.beginCell.dateInCalendar;
        if (
          period.beginCell.itemId === itemId &&
          period.beginCell.indexInCalendar === indInCalendar
        ) {
          // click sur la même case pour enlever la selection de beginCell
          setPeriod({
            beginCell: undefined,
            endCell: undefined,
          });
        } else {
          // contrôle si period est valid
          if (
            period.beginCell.itemId === itemId &&
            period.beginCell.indexInCalendar < indInCalendar
          ) {
            //period est valid
            // puis si c'est la même que dans db => supprimer
            const indItem = (
              supp ? planning.tasksSupp : planning.tasks
            ).findIndex((el) => el.id === itemId);

            if (indItem !== -1) {
              const datesChecked = supp
                ? planning.tasksSupp[indItem].calendar.filter((c) => c.checked)
                : planning.tasks[indItem].calendar.filter((c) => c.checked);
              if (
                datesChecked.length > 0 &&
                hasPeriodsCross(
                  datesChecked[0].date as Date,
                  datesChecked[datesChecked.length - 1].date as Date,
                  period.beginCell.dateInCalendar,
                  dateInCalendar
                ) === -1
              ) {
                // si le periode choisi correspond au taskPlanning dans db => supprimer les anciens taskPlanning

                const { data: _data } = await createOrUpdateTaskPlanning({
                  variables: {
                    projectId: currentProject.id,
                    taskId: itemId,
                    dates: [],
                  },
                });
                if (_data) {
                  setCurrentDevisProd({
                    ..._data.createOrUpdateTaskPlanning,
                    tasks:
                      _data.createOrUpdateTaskPlanning.tasks?.sort(
                        compareByOrder
                      ),
                  });
                  setPeriod({
                    beginCell: undefined,
                    endCell: undefined,
                  });
                }
              } else {
                // period correct mais ne correspond pas au period dans la db
                const _dates = (
                  supp
                    ? planning.tasks[indItem].calendar
                    : planning.tasks[indItem].calendar
                )
                  .filter(
                    (element) =>
                      element.selectible &&
                      new Date(element.date as Date).setHours(8, 0, 0, 0) >=
                        new Date(start).setHours(8, 0, 0, 0) &&
                      new Date(element.date as Date).setHours(8, 0, 0, 0) <=
                        new Date(dateInCalendar).setHours(8, 0, 0, 0)
                  )
                  .map((element) => element.date);

                void handleStatePeriodChange(
                  itemId,
                  _dates.map((d) => d as Date)
                );
                setPeriod({
                  beginCell: undefined,
                  endCell: undefined,
                });
              }
            }
          } else {
            setPeriod({
              beginCell: undefined,
              endCell: undefined,
            });
          }
        }
      }
    } else {
      void changeCell(itemId, indInCalendar);
    }
  }

  async function handleStatePeriodChange(itemId: number, dates: Date[]) {
    const items: StoredTaskInfoForPlanning[] = JSON.parse(
      JSON.stringify(planning.tasks.concat(planning.tasksSupp))
    ).map((el: any) => el as StoredTaskInfoForPlanning);

    const itemIndex = items.findIndex((item) => item.id === itemId);
    if (itemId === -1) return;

    const checkedDates: { date: Date; id?: number | undefined }[] = items[
      itemIndex
    ].taskPlanning
      //.filter((el) => el.duration > 0)
      .map((d) => {
        return {
          date: d.date as Date,
          id: d.id,
        };
      });

    for (const nD of dates) {
      const ind = checkedDates.findIndex(
        (el) =>
          new Date(el.date).setHours(8, 0, 0, 0) ===
          new Date(nD).setHours(8, 0, 0, 0)
      );
      if (ind !== -1) {
        checkedDates.splice(ind, 1);
      } else checkedDates.push({ date: nD });
    }

    const average = items[itemIndex].duration / checkedDates.length;
    const _dates: { date: Date; duration: number; id?: number }[] =
      checkedDates.map((d) => {
        return {
          date: d.date,
          duration: Math.round(average),
          ...(d.id && { id: d.id }),
        };
      });

    // TODO
    const calcDuration = _dates.reduce(function (acc, obj) {
      return acc + obj.duration;
    }, 0);
    const deltaDuration = calcDuration - items[itemIndex].duration;

    if (deltaDuration !== 0 && _dates.length > 0) {
      _dates[_dates.length - 1].duration =
        _dates[_dates.length - 1].duration - deltaDuration;
    }

    if (!currentProject?.id) return;
    const { data: _data } = await createOrUpdateTaskPlanning({
      variables: {
        projectId: currentProject.id,
        taskId: items[itemIndex].id,
        dates: _dates,
      },
    });
    if (_data) {
      setCurrentDevisProd({
        ..._data.createOrUpdateTaskPlanning,
        tasks: _data.createOrUpdateTaskPlanning.tasks?.sort(compareByOrder),
      });
      void getPlanningByProjectId();
    }
  }

  async function handleChangeAllUsers(_users: TUsersByFunctionType[]) {
    if (!currentProject?.id) return;

    const { data: _data } = await createOrUpdateUsersByFunctionType({
      variables: {
        projectId: currentProject.id,
        usersByFunctionType: _users,
      },
    });

    if (_data) {
      setCurrentDevisProd({
        ..._data.createOrUpdateUsersInTaskByFunctionType,
        tasks:
          _data.createOrUpdateUsersInTaskByFunctionType.tasks?.sort(
            compareByOrder
          ),
      });
      void getPlanningByProjectId();
    }
  }

  async function changeCell(itemId: number, calendarIndex: number) {
    const itemsMapped = JSON.parse(
      JSON.stringify(planning.tasks.concat(planning.tasksSupp))
    );
    const items: StoredTaskInfoForPlanning[] = itemsMapped.map(
      (el: any) => el as StoredTaskInfoForPlanning
    );

    const itemIndex = items.findIndex((item) => item.id === itemId);

    if (itemIndex !== -1) {
      items[itemIndex].calendar[calendarIndex].checked =
        !items[itemIndex].calendar[calendarIndex].checked;

      const count = items[itemIndex].calendar.filter((el) => el.checked).length;
      const average = items[itemIndex].duration / count;
      for (let j = 0; j < items[itemIndex].calendar.length; j++) {
        if (items[itemIndex].calendar[j].checked)
          items[itemIndex].calendar[j].label = getHoursStrByDuration(
            Math.round(average)
          );
      }

      const _dates = getNewDates(items[itemIndex], count);

      if (!currentProject?.id) return;
      const { data: _data } = await createOrUpdateTaskPlanning({
        variables: {
          projectId: currentProject.id,
          taskId: items[itemIndex].id,
          dates: _dates,
        },
      });
      if (_data) {
        setCurrentDevisProd({
          ..._data.createOrUpdateTaskPlanning,
          tasks: _data.createOrUpdateTaskPlanning.tasks?.sort(compareByOrder),
        });
        void getPlanningByProjectId();
      }
    }
  }

  function handleAddSuppTask() {
    setOpenAdd(true);
  }

  return (
    <Container>
      <STableContainer>
        {loading ? (
          <p>{i18n._('common.loading')} ...</p>
        ) : (
          <>
            <SDevisTable ref={ref}>
              <SDevisTableHead>
                <tr>
                  <SDevisTh $textAlign="center" $minWidth="180px">
                    {i18n._('common.task.name')}
                  </SDevisTh>
                  <SDevisTh $textAlign="center">
                    {i18n._('common.function.type')}
                  </SDevisTh>
                  <SDevisTh $textAlign="center">
                    {i18n._('common.duration')}
                  </SDevisTh>
                  <SDevisTh $textAlign="center">
                    {i18n._('form.edit.users')}
                    <Tooltip
                      title={i18n._('common.edit.users.by.function.type')}
                    >
                      <Button
                        shape="circle"
                        onClick={() => {
                          setOpenEditAllUsers(true);
                        }}
                        icon={<UsergroupAddOutlined />}
                      />
                    </Tooltip>
                  </SDevisTh>
                  {planning.calendar.map((el, index) => (
                    <STaskDaysTh
                      $height="95px"
                      $width="45px"
                      $textAlign="center"
                      key={index}
                    >
                      <SDivRotate>
                        {new Date(el.date as Date).toLocaleDateString()}
                      </SDivRotate>
                    </STaskDaysTh>
                  ))}
                </tr>
              </SDevisTableHead>

              <tbody>
                {planning.tasks.length > 0 &&
                  planning.tasks.map((item, indexInList) => (
                    <STableTr key={indexInList}>
                      <STaskTd $padding="0 3px">{item.name}</STaskTd>
                      <STaskTd $padding="0 3px">
                        {i18n._(item.functionType)}
                      </STaskTd>
                      <STaskTd $padding="0 3px" $textAlign="right">
                        {item.functionType === FunctionType.CLIENT
                          ? item.durationClientDays + 'j'
                          : getHoursAndMinutesStrByDuration(item.duration)}
                      </STaskTd>
                      <STaskTd $textAlign="right">
                        <SDiv>
                          <AvatarList users={item.user}></AvatarList>
                          {item.functionType !== FunctionType.CLIENT &&
                            !item.calculatedAsAmount && (
                              <Tooltip title={i18n._('common.edit.users')}>
                                <Button
                                  shape="circle"
                                  onClick={() => {
                                    setSelectedTask(item);
                                    setOpenEditUsers(true);
                                  }}
                                  icon={<EditOutlined />}
                                />
                              </Tooltip>
                            )}
                        </SDiv>
                      </STaskTd>

                      {planning.tasks[indexInList]?.calendar?.map(
                        (itC, ind) => (
                          <Tooltip
                            key={ind}
                            title={
                              item.name +
                              ' / ' +
                              new Date(itC.date as Date).toLocaleDateString()
                            }
                          >
                            <STaskTd
                              onClick={(e) => {
                                void handleCellClick(
                                  e,
                                  item.id,
                                  ind,
                                  itC.date as Date,
                                  false
                                );
                              }}
                              key={ind}
                              className={
                                !itC.selectible
                                  ? 'notselectible'
                                  : period.beginCell?.itemId === item.id &&
                                      period.beginCell.indexInCalendar === ind
                                    ? 'period'
                                    : period.endCell?.itemId === item.id &&
                                        period.endCell.indexInCalendar === ind
                                      ? 'end'
                                      : ''
                              }
                            >
                              {itC.retroChecked ? (
                                <SDivCheckRetroInPl></SDivCheckRetroInPl>
                              ) : (
                                <></>
                              )}
                              {itC.checked && (
                                <SDivCheckPlanning
                                  className={
                                    item.functionType === FunctionType.CLIENT
                                      ? 'client'
                                      : ''
                                  }
                                >
                                  <SSpanLabel>
                                    {item.functionType !== FunctionType.CLIENT
                                      ? itC.label
                                      : ''}
                                  </SSpanLabel>
                                </SDivCheckPlanning>
                              )}
                            </STaskTd>
                          </Tooltip>
                        )
                      )}
                    </STableTr>
                  ))}
                <tr style={{ height: '20px' }}></tr>
                {planning.tasksSupp.length > 0 &&
                  planning.tasksSupp.map((item, indexInList) => (
                    <STableTr key={indexInList}>
                      <STaskTd $padding="0 3px">
                        <SDiv>
                          {item.name}
                          <Tooltip title={i18n._('table.task.edit')}>
                            <Button
                              shape="circle"
                              onClick={() => {
                                setSelectedTask(item);
                                setOpenEditTaskSupp(true);
                              }}
                              icon={<EditOutlined />}
                            />
                          </Tooltip>
                          {item.taskPlanning.length === 0 && (
                            <>
                              <Tooltip title={i18n._('table.task.delete')}>
                                <Button
                                  shape="circle"
                                  onClick={() => {
                                    setSelectedTask(item);
                                    setOpenDeleteTaskSupp(true);
                                  }}
                                  icon={<DeleteOutlined />}
                                />
                              </Tooltip>
                            </>
                          )}
                        </SDiv>
                      </STaskTd>
                      <STaskTd $padding="0 3px">
                        {i18n._(item.functionType)}
                      </STaskTd>
                      <STaskTd $padding="0 3px" $textAlign="right">
                        {item.duration > 0
                          ? getHoursAndMinutesStrByDuration(item.duration)
                          : item.amount.toFixed(2) + '€'}
                      </STaskTd>
                      <STaskTd $textAlign="right">
                        <SDiv>
                          <AvatarList users={item.user}></AvatarList>
                          {(item.functionType !== FunctionType.CLIENT ||
                            !item.calculatedAsAmount) && (
                            <Tooltip title={i18n._('common.edit.users')}>
                              <Button
                                shape="circle"
                                onClick={() => {
                                  setSelectedTask(item);
                                  setOpenEditUsers(true);
                                }}
                                icon={<EditOutlined />}
                              />
                            </Tooltip>
                          )}
                        </SDiv>
                      </STaskTd>

                      {planning.tasksSupp[indexInList]?.calendar?.map(
                        (itC, ind) => (
                          <Tooltip
                            key={ind}
                            title={
                              item.name +
                              ' / ' +
                              new Date(itC.date as Date).toLocaleDateString()
                            }
                          >
                            <STaskTd
                              onClick={(e) => {
                                if (item.duration > 0)
                                  void handleCellClick(
                                    e,
                                    item.id,
                                    ind,
                                    itC.date as Date,
                                    true
                                  );
                              }}
                              key={ind}
                              className={
                                !itC.selectible
                                  ? 'notselectible'
                                  : period.beginCell?.itemId === item.id &&
                                      period.beginCell.indexInCalendar === ind
                                    ? 'period'
                                    : period.endCell?.itemId === item.id &&
                                        period.endCell.indexInCalendar === ind
                                      ? 'end'
                                      : ''
                              }
                            >
                              {itC.retroChecked ? (
                                <SDivCheckRetroInPl></SDivCheckRetroInPl>
                              ) : (
                                <></>
                              )}
                              {itC.checked && (
                                <SDivCheckPlanning
                                  className={
                                    item.functionType === FunctionType.CLIENT
                                      ? 'client'
                                      : ''
                                  }
                                >
                                  <SSpanLabel>
                                    {item.functionType !== FunctionType.CLIENT
                                      ? itC.label
                                      : ''}
                                  </SSpanLabel>
                                </SDivCheckPlanning>
                              )}
                            </STaskTd>
                          </Tooltip>
                        )
                      )}
                    </STableTr>
                  ))}
              </tbody>
            </SDevisTable>
          </>
        )}
      </STableContainer>
      <>
        <Button
          style={{ width: '300px' }}
          disabled={!currentDevisProd}
          onClick={handleAddSuppTask}
          type="primary"
        >
          <Trans id="common.add.task.supp" />
        </Button>
      </>

      {openEditAllUsers && (
        <EditAllUsers
          onChangeUsers={handleChangeAllUsers}
          usersByFunctionType={usersByFunctionType}
          usersWithTimeByFunctionType={usersWithTimeByFunctionType}
          title={i18n._('task.edit.all.users')}
          isOpen={openEditAllUsers}
          close={() => setOpenEditAllUsers(false)}
          confirm={() => {
            setOpenEditAllUsers(false);
          }}
        />
      )}

      {selectedTask?.id && (
        <EditUsersInTask
          taskId={selectedTask.id}
          users={selectedTask.user}
          title={i18n._('task.edit.users')}
          isOpen={openEditUsers}
          close={() => setOpenEditUsers(false)}
          confirm={() => {
            setOpenEditUsers(false);
            void getPlanningByProjectId();
          }}
        />
      )}
      {selectedTask && (
        <DeleteTaskSupp
          task={selectedTask}
          title={i18n._('table.task.delete')}
          isOpen={openDeleteTaskSupp}
          close={() => setOpenDeleteTaskSupp(false)}
          confirm={() => {
            setOpenDeleteTaskSupp(false);
            void getPlanningByProjectId();
          }}
        />
      )}
      {selectedTask && (
        <EditTaskSupp
          task={selectedTask}
          title={i18n._('table.task.edit')}
          isOpen={openEditTaskSupp}
          close={() => setOpenEditTaskSupp(false)}
          confirm={() => {
            setOpenEditTaskSupp(false);
            void getPlanningByProjectId();
          }}
        />
      )}
      {currentProject && (
        <AddTaskSupp
          projectId={currentProject.id}
          title={i18n._('task.supp.add')}
          isOpen={openAdd}
          close={() => setOpenAdd(false)}
          confirm={() => {
            setOpenAdd(false);
            void getPlanningByProjectId();
          }}
        />
      )}
    </Container>
  );
}

export default TasksPlanningExtTable;

const STableContainer = styled.div`
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;
const Container = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SDivRotate = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  left: -22px;
  bottom: 35px;
`;

const SSpanLabel = styled.span`
  font-size: 10px;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
`;
