import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';

import { useLingui } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import { Button, Tooltip } from 'antd';

import { useEffect, useRef, useState } from 'react';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { styled } from 'styled-components';
import { GQL_CREATE_OR_UPDATE_USERS_BY_FUNCTION_TYPE } from '~/gql/task/task';
import { GQL_CREATE_OR_UPDATE_TASK_PLANNING } from '~/gql/task-planning/task-planning';

import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import DeleteTaskSupp from '~/pages/components/common/planning/DeleteTaskSupp';
import EditAllUsers from '~/pages/components/common/planning/EditAllUsers';
import EditTaskSupp from '~/pages/components/common/planning/EditTaskSupp';
import EditUsersInTask from '~/pages/components/common/planning/EditUsersInTask';
//import { printExcel } from '~/pages/components/common/planning/PrintExcel';
import { exportExcel } from '~/pages/components/common/planning/exportExcel';
import { getUsersByFunctionTypeForTasks } from '~/pages/components/common/utils-table/utils';
import {
  SDevisTh,
  STaskTd,
  SDevisTableHead,
  SDevisTable,
  STaskDaysTh,
  SDivCheckRetroInPl,
  SDivCheckPlanning,
  STableTr,
} from '~/pages/components/styles/styles';
import AvatarList from '~/pages/components/ui/AvatarList';

import {
  TDatePlanning,
  TPeriod,
  TUsersByFunctionType,
  TaskInfoWithPlanningCalender,
} from '~/pages/types/types';
import {
  compareByOrder,
  getHoursAndMinutesStrByDuration,
  hasPeriodsCross,
} from '~/utils/utils/utils';

type IProps = {
  tasks: TaskInfoWithPlanningCalender[];
  tasksSupp: TaskInfoWithPlanningCalender[];
  calendar: TDatePlanning[];
  onStateCellChange: (itemId: number, indInCalender: number) => void;
  onStatePeriodChange: (
    itemId: number,
    startDate: Date,
    endDate: Date,
    dates: Date[]
  ) => void;
  refresh: () => void;
  isPrinting: boolean;
  changeIsPrinting: (value: boolean) => void;
};

function TasksPlanningTable({
  tasks,
  calendar,
  onStateCellChange,
  refresh,
  tasksSupp,
  onStatePeriodChange,
  isPrinting,
  changeIsPrinting,
}: IProps) {
  const { i18n } = useLingui();
  const { currentProject, setCurrentDevisProd } = useDataUserStore();
  const ref = useRef(null);
  const [openEditUsers, setOpenEditUsers] = useState<boolean>(false);
  const [openEditAllUsers, setOpenEditAllUsers] = useState<boolean>(false);
  const [openDeleteTaskSupp, setOpenDeleteTaskSupp] = useState<boolean>(false);
  const [openEditTaskSupp, setOpenEditTaskSupp] = useState<boolean>(false);
  const [usersByFunctionType, setUsersByFunctionType] = useState<
    TUsersByFunctionType[]
  >([]);

  const [selectedTask, setSelectedTask] = useState<
    TaskInfoWithPlanningCalender | undefined
  >(undefined);

  const [period, setPeriod] = useState<TPeriod>({
    beginCell: undefined,
    endCell: undefined,
  });

  const [createOrUpdateUsersByFunctionType] = useMutation(
    GQL_CREATE_OR_UPDATE_USERS_BY_FUNCTION_TYPE,
    {
      client: authClient,
    }
  );

  const [createOrUpdateTaskPlanning] = useMutation(
    GQL_CREATE_OR_UPDATE_TASK_PLANNING,
    {
      client: authClient,
    }
  );

  useEffect(() => {
    setUsersByFunctionType(getUsersByFunctionTypeForTasks(tasks));
  }, [tasks]);

  useEffect(() => {
    if (isPrinting) {
      const shortName = currentProject?.name
        ? currentProject.name.length > 10
          ? currentProject.name.slice(0, 10)
          : currentProject.name
        : '';
      const fileName = currentProject
        ? `${shortName}-planning-${new Date().toLocaleDateString()}`
        : `planning-${new Date().toLocaleDateString()}`;

      print(fileName);
      void exportExcel({
        tasks: tasks,
        tasksSupp: tasksSupp,
        calendar: calendar,
        fileName: fileName,
      });
      //printExcel({ tasks: tasks, tasksSupp: tasksSupp, calendar: calendar });
      changeIsPrinting(false);
    }
  }, [isPrinting]);

  function print(fileName: string) {
    // void exportComponentAsPDF(ref, {
    //   fileName: 'retro-planning.pdf',
    //   pdfOptions: { orientation: 'l' },
    // });
    void exportComponentAsJPEG(ref, {
      fileName: `${fileName}.jpg`,
    });
  }

  async function handleCellClick(
    e: React.MouseEvent<HTMLTableDataCellElement>,
    itemId: number,
    indInCalendar: number,
    dateInCalendar: Date,
    supp: boolean
  ) {
    e.stopPropagation();
    if (!currentProject) return;
    if (e.shiftKey) {
      if (!period.beginCell) {
        // click pour beginPeriod
        setPeriod({
          beginCell: {
            itemId: itemId,
            indexInCalendar: indInCalendar,
            dateInCalendar: dateInCalendar,
          },
          endCell: undefined,
        });
      } else {
        // click pour endPeriod
        if (!period.beginCell.dateInCalendar) return;
        const start: Date = period.beginCell.dateInCalendar;
        if (
          period.beginCell.itemId === itemId &&
          period.beginCell.indexInCalendar === indInCalendar
        ) {
          // click sur la même case pour enlever la selection de beginCell
          setPeriod({
            beginCell: undefined,
            endCell: undefined,
          });
        } else {
          // contrôle si period est valid
          if (
            period.beginCell.itemId === itemId &&
            period.beginCell.indexInCalendar < indInCalendar
          ) {
            //period est valid
            // puis si c'est la même que dans db => supprimer
            const indItem = (supp ? tasksSupp : tasks).findIndex(
              (el) => el.id === itemId
            );

            if (indItem !== -1) {
              const datesChecked = supp
                ? tasksSupp[indItem].calendar.filter((c) => c.checked)
                : tasks[indItem].calendar.filter((c) => c.checked);
              if (
                datesChecked.length > 0 &&
                hasPeriodsCross(
                  datesChecked[0].date,
                  datesChecked[datesChecked.length - 1].date,
                  period.beginCell.dateInCalendar,
                  dateInCalendar
                ) === -1
              ) {
                // si le periode choisi correspond au taskPlanning dans db => supprimer les anciens taskPlanning

                const { data: _data } = await createOrUpdateTaskPlanning({
                  variables: {
                    projectId: currentProject.id,
                    taskId: itemId,
                    dates: [],
                  },
                });
                if (_data) {
                  setCurrentDevisProd({
                    ..._data.createOrUpdateTaskPlanning,
                    tasks:
                      _data.createOrUpdateTaskPlanning.tasks?.sort(
                        compareByOrder
                      ),
                  });
                  setPeriod({
                    beginCell: undefined,
                    endCell: undefined,
                  });
                }
              } else {
                // period correct mais ne correspond pas au period dans la db
                const _dates = (
                  supp ? tasks[indItem].calendar : tasks[indItem].calendar
                )
                  .filter(
                    (element) =>
                      element.selectible &&
                      new Date(element.date).setHours(8, 0, 0, 0) >=
                        new Date(start).setHours(8, 0, 0, 0) &&
                      new Date(element.date).setHours(8, 0, 0, 0) <=
                        new Date(dateInCalendar).setHours(8, 0, 0, 0)
                  )
                  .map((element) => element.date);

                onStatePeriodChange(
                  itemId,
                  period.beginCell.dateInCalendar,
                  dateInCalendar,
                  _dates
                );
                setPeriod({
                  beginCell: undefined,
                  endCell: undefined,
                });
              }
            }
          } else {
            setPeriod({
              beginCell: undefined,
              endCell: undefined,
            });
          }
        }
      }
    } else {
      onStateCellChange(itemId, indInCalendar);
    }
  }

  async function handleChangeAllUsers(_users: TUsersByFunctionType[]) {
    if (!currentProject?.id) return;

    const { data: _data } = await createOrUpdateUsersByFunctionType({
      variables: {
        projectId: currentProject.id,
        usersByFunctionType: _users,
      },
    });
    if (_data) {
      setCurrentDevisProd({
        ..._data.createOrUpdateUsersInTaskByFunctionType,
        tasks:
          _data.createOrUpdateUsersInTaskByFunctionType.tasks?.sort(
            compareByOrder
          ),
      });
    }
  }

  return (
    <Container>
      <STableContainer>
        <SDevisTable ref={ref}>
          <SDevisTableHead>
            <tr>
              <SDevisTh $textAlign="center" $minWidth="180px">
                {i18n._('common.task.name')}
              </SDevisTh>
              <SDevisTh $textAlign="center">
                {i18n._('common.function.type')}
              </SDevisTh>
              <SDevisTh $textAlign="center">
                {i18n._('common.duration')}
              </SDevisTh>
              <SDevisTh $textAlign="center">
                {i18n._('form.edit.users')}
                <Tooltip title={i18n._('common.edit.users.by.function.type')}>
                  <Button
                    shape="circle"
                    disabled
                    onClick={() => {
                      setOpenEditAllUsers(true);
                    }}
                    icon={<UsergroupAddOutlined />}
                  />
                </Tooltip>
              </SDevisTh>
              {calendar.map((el, index) => (
                <STaskDaysTh
                  $height="95px"
                  $width="45px"
                  $textAlign="center"
                  key={index}
                >
                  <SDivRotate>{el.date.toLocaleDateString()}</SDivRotate>
                </STaskDaysTh>
              ))}
            </tr>
          </SDevisTableHead>

          <tbody>
            {tasks.length > 0 &&
              tasks.map((item, indexInList) => (
                <STableTr key={indexInList}>
                  <STaskTd $padding="0 3px">{item.name}</STaskTd>
                  <STaskTd $padding="0 3px">
                    {i18n._(item.functionType)}
                  </STaskTd>
                  <STaskTd $padding="0 3px" $textAlign="right">
                    {item.functionType === FunctionType.CLIENT
                      ? item.durationClientDays + 'j'
                      : getHoursAndMinutesStrByDuration(item.duration)}
                  </STaskTd>
                  <STaskTd $textAlign="right">
                    <SDiv>
                      <AvatarList users={item.user}></AvatarList>
                      {item.functionType !== FunctionType.CLIENT &&
                        !item.calculatedAsAmount && (
                          <Tooltip title={i18n._('common.edit.users')}>
                            <Button
                              shape="circle"
                              onClick={() => {
                                setSelectedTask(item);
                                setOpenEditUsers(true);
                              }}
                              icon={<EditOutlined />}
                            />
                          </Tooltip>
                        )}
                    </SDiv>
                  </STaskTd>

                  {tasks[indexInList]?.calendar?.map((itC, ind) => (
                    <Tooltip
                      key={ind}
                      title={
                        item.name +
                        ' / ' +
                        new Date(itC.date).toLocaleDateString()
                      }
                    >
                      <STaskTd
                        onClick={(e) => {
                          void handleCellClick(
                            e,
                            item.id,
                            ind,
                            itC.date,
                            false
                          );
                        }}
                        key={ind}
                        className={
                          !itC.selectible
                            ? 'notselectible'
                            : period.beginCell?.itemId === item.id &&
                                period.beginCell.indexInCalendar === ind
                              ? 'period'
                              : period.endCell?.itemId === item.id &&
                                  period.endCell.indexInCalendar === ind
                                ? 'end'
                                : ''
                        }
                      >
                        {itC.retroChecked ? (
                          <SDivCheckRetroInPl></SDivCheckRetroInPl>
                        ) : (
                          <></>
                        )}
                        {itC.checked && (
                          <SDivCheckPlanning
                            className={
                              item.functionType === FunctionType.CLIENT
                                ? 'client'
                                : ''
                            }
                          >
                            <SSpanLabel>
                              {item.functionType !== FunctionType.CLIENT
                                ? itC.label
                                : ''}
                            </SSpanLabel>
                          </SDivCheckPlanning>
                        )}
                      </STaskTd>
                    </Tooltip>
                  ))}
                </STableTr>
              ))}
            <tr style={{ height: '20px' }}></tr>
            {tasksSupp.length > 0 &&
              tasksSupp.map((item, indexInList) => (
                <STableTr key={indexInList}>
                  <STaskTd $padding="0 3px">
                    <SDiv>
                      {item.name}
                      <Tooltip title={i18n._('table.task.edit')}>
                        <Button
                          shape="circle"
                          onClick={() => {
                            setSelectedTask(item);
                            setOpenEditTaskSupp(true);
                          }}
                          icon={<EditOutlined />}
                        />
                      </Tooltip>
                      {item.taskPlanning.length === 0 && (
                        <>
                          <Tooltip title={i18n._('table.task.delete')}>
                            <Button
                              shape="circle"
                              onClick={() => {
                                setSelectedTask(item);
                                setOpenDeleteTaskSupp(true);
                              }}
                              icon={<DeleteOutlined />}
                            />
                          </Tooltip>
                        </>
                      )}
                    </SDiv>
                  </STaskTd>
                  <STaskTd $padding="0 3px">
                    {i18n._(item.functionType)}
                  </STaskTd>
                  <STaskTd $padding="0 3px" $textAlign="right">
                    {getHoursAndMinutesStrByDuration(item.duration)}
                  </STaskTd>
                  <STaskTd $textAlign="right">
                    <SDiv>
                      <AvatarList users={item.user}></AvatarList>
                      {(item.functionType !== FunctionType.CLIENT ||
                        !item.calculatedAsAmount) && (
                        <Tooltip title={i18n._('common.edit.users')}>
                          <Button
                            shape="circle"
                            onClick={() => {
                              setSelectedTask(item);
                              setOpenEditUsers(true);
                            }}
                            icon={<EditOutlined />}
                          />
                        </Tooltip>
                      )}
                    </SDiv>
                  </STaskTd>

                  {tasksSupp[indexInList]?.calendar?.map((itC, ind) => (
                    <Tooltip
                      key={ind}
                      title={
                        item.name +
                        ' / ' +
                        new Date(itC.date).toLocaleDateString()
                      }
                    >
                      <STaskTd
                        onClick={(e) => {
                          if (item.duration > 0)
                            void handleCellClick(
                              e,
                              item.id,
                              ind,
                              itC.date,
                              true
                            );
                        }}
                        key={ind}
                        className={
                          !itC.selectible
                            ? 'notselectible'
                            : period.beginCell?.itemId === item.id &&
                                period.beginCell.indexInCalendar === ind
                              ? 'period'
                              : period.endCell?.itemId === item.id &&
                                  period.endCell.indexInCalendar === ind
                                ? 'end'
                                : ''
                        }
                      >
                        {itC.retroChecked ? (
                          <SDivCheckRetroInPl></SDivCheckRetroInPl>
                        ) : (
                          <></>
                        )}
                        {itC.checked && (
                          <SDivCheckPlanning
                            className={
                              item.functionType === FunctionType.CLIENT
                                ? 'client'
                                : ''
                            }
                          >
                            <SSpanLabel>
                              {item.functionType !== FunctionType.CLIENT
                                ? itC.label
                                : ''}
                            </SSpanLabel>
                          </SDivCheckPlanning>
                        )}
                      </STaskTd>
                    </Tooltip>
                  ))}
                </STableTr>
              ))}
          </tbody>
        </SDevisTable>
      </STableContainer>
      <EditAllUsers
        onChangeUsers={handleChangeAllUsers}
        usersByFunctionType={usersByFunctionType}
        usersWithTimeByFunctionType={[]}
        title={i18n._('task.edit.all.users')}
        isOpen={openEditAllUsers}
        close={() => setOpenEditAllUsers(false)}
        confirm={() => {
          setOpenEditAllUsers(false);
        }}
      />
      {selectedTask?.id && (
        <EditUsersInTask
          taskId={selectedTask.id}
          users={selectedTask.user}
          title={i18n._('task.edit.users')}
          isOpen={openEditUsers}
          close={() => setOpenEditUsers(false)}
          confirm={() => {
            setOpenEditUsers(false);
          }}
        />
      )}
      {selectedTask && (
        <DeleteTaskSupp
          task={selectedTask}
          title={i18n._('table.task.delete')}
          isOpen={openDeleteTaskSupp}
          close={() => setOpenDeleteTaskSupp(false)}
          confirm={() => {
            setOpenDeleteTaskSupp(false);
            refresh();
          }}
        />
      )}
      {selectedTask && (
        <EditTaskSupp
          task={selectedTask}
          title={i18n._('table.task.edit')}
          isOpen={openEditTaskSupp}
          close={() => setOpenEditTaskSupp(false)}
          confirm={() => {
            setOpenEditTaskSupp(false);
            refresh();
          }}
        />
      )}
    </Container>
  );
}

export default TasksPlanningTable;

const STableContainer = styled.div`
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;
const Container = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SDivRotate = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  left: -22px;
  bottom: 35px;
`;

const SSpanLabel = styled.span`
  font-size: 10px;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
`;
