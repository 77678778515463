import { GraphQLTypes, InputType, Selector } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { scalars } from '~/gql/utils/scalars';

const posteSelector = Selector('Poste')({
  id: true,
  name: true,
});

export const GQL_POSTES = typedGql('query', { scalars })({
  getPostes: {
    ...posteSelector,
  },
});

export type StoredPoste = InputType<
  GraphQLTypes['Poste'],
  typeof posteSelector
>;
