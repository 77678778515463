import { FunctionType, ProjectType } from '@zeus/index';
import {
  StoredDevisInitRentaInfo,
  StoredProjectInfo,
  StoredTaskRentaInfo,
} from '~/gql/project/project';
import {
  StoredTaskForUserInfo,
  StoredTaskInfo,
  StoredTaskShortInfo,
} from '~/gql/task/task';
//import { StoredUsers } from '~/gql/users/users';

export type TPosteOption = {
  id: number;
  posteId: number;
  label: string;
  functionType: FunctionType;
  devisInitId: number;
  amount?: number;
};

export type DevisInitRow = {
  id?: number;
  functionType?: FunctionType;
  amount: number;
  duration: number;
  durationExpected: number;
  durationUsed?: number;
  amountUsed?: number;
  order?: number;
  posteId: number;
  posteName: string;
  customPosteName?: string;
  functionTypeId?: number | undefined;
};

export type DevisProdRow = {
  id?: number;
  devisInitId: number | undefined;
  functionType?: FunctionType;
  taskName: string;
  amount: number;
  amountSpend?: number;
  duration: number;
  visibleInRetro: boolean;
  supp: boolean;
  calculatedAsAmount: boolean;
  order?: number;
  posteId?: number;
  durationDays?: number;
  durationClientDays?: number;
  hasTimes?: boolean;
};

export type TTaskInDevisTemplate = {
  devisInit?: DevisInitRow;
  tasks: DevisProdRow[];
};

export type TTaskInDevis = {
  functionTypeId: number | undefined;
  tasks?: DevisProdRow[];
};
export type DevisInitRowExtended = TTaskInDevis & DevisInitRow;

export type TUsersByFunctionType = {
  functionType: FunctionType;
  usersIds: number[];
};

export type TDate = {
  name: string;
  date?: Date;
  checked?: boolean;
};

export type TOpenedInfoAdd = {
  isOpened: boolean;
};
export type TTaskRentaInfoExt = TOpenedInfoAdd & StoredTaskRentaInfo;

export type TTaskForUserInfo = TOpenedInfoAdd & StoredTaskForUserInfo;

export type TDatePlanning = {
  id?: number;
  date: Date;
  label: string;
  selectible: boolean;
  retroChecked: boolean;
  checked: boolean;
  duration: number;
};

export type TDevisInitRentaInfoExt = TOpenedInfoAdd & StoredDevisInitRentaInfo;

export type TaskInfoWithCalender = {
  calendar: TDate[];
} & StoredTaskInfo;

export type TaskInfoWithPlanningCalender = {
  calendar: TDatePlanning[];
} & StoredTaskInfo;

export type TCellCoord = {
  itemId: number;
  indexInCalendar: number;
  dateInCalendar?: Date;
};

export type TPeriod = {
  beginCell: TCellCoord | undefined;
  endCell: TCellCoord | undefined;
};

export type TDateTimes = {
  label?: string;
  date: Date;
  selectible: boolean;
  planningDuration?: number; // optionnel pour calendrier dans head
  time?: number; // optionnel pour calendrier dans head
  timeId?: number;
  timeComment?: string;
};

export type TCellTaskTime = {
  timeId?: number;
  taskId: number;
  taskName: string;
  projectId: number;
  projectName?: string;
  projectType?: ProjectType;
  allowModify?: boolean;
  calendar: TDateTimes[];
};

export enum PeriodEnum {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export type TProjectWithAllTasks = {
  tasks: StoredTaskShortInfo[];
} & StoredProjectInfo;

export type TPeriodDates = {
  start: Date;
  end: Date;
};

// ------- pour la table UserDetailsTable -----
export type TTaskUserTimePlanningDetails = {
  taskId: number;
  taskName: number;
  projectId: number;
  projectName: string;
  planningDuration?: number;
  time?: number;
  timeId?: number;
  timeComment?: string;
};

export type TDateUserAdmin = {
  date: Date;
  times?: TTaskUserTimePlanningDetails[];
};

export type TUserDetails = {
  userId: number;
  userFirstName: string;
  userLastName: string;
  calendar: TDateUserAdmin[];
};

// ------- pour la table ProjectDetailsTable -----
export type TTaskProjectTimePlanningDetails = {
  taskId: number;
  taskName: string;
  userId: number;
  userFirstName: string;
  userLastName: string;
  planningDuration?: number;
  time?: number;
  timeId?: number;
  timeComment?: string;
};

export type TDateProjectAdmin = {
  date: Date;
  times?: TTaskProjectTimePlanningDetails[];
};

export type TProjectDetails = {
  projectId: number;
  projectName: string;

  calendar: TDateProjectAdmin[];
};

export type TCellTaskTimeAdmin = {
  taskId: number;
  taskName: string;
  taskOrder: number;
  useId: number;
  userFirstName: string;
  userLastName: string;
  calendar: TDateTimes[];
};
