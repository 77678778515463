import { $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

export const GQL_LOGIN = typedGql('query')({
  login: [
    { email: $('email', 'String!'), password: $('password', 'String!') },
    {
      __typename: true,
      '...on Tokens': {
        access_token: true,
        refresh_token: true,
      },
      '...on CustomError': {
        error: true,
        message: true,
      },
    },
  ],
});

export const GQL_SEND_RESET_PASSWORD = typedGql('mutation')({
  askForResetPassword: [{ email: $('email', 'String!') }, true],
});

export const GQL_RESET_PASSWORD = typedGql('mutation')({
  resetPassword: [
    {
      email: $('email', 'String!'),
      password: $('password', 'String!'),
      token: $('token', 'String!'),
    },
    true,
  ],
});

export const GQL_REFRESH = typedGql('mutation')({
  refresh: {
    __typename: true,
    '...on Tokens': {
      access_token: true,
      refresh_token: true,
    },
    '...on CustomError': {
      error: true,
      message: true,
    },
  },
});

export const GQL_LOGOUT = typedGql('mutation')({
  logout: true,
});

export const GQL_ASK_REGISTER = typedGql('mutation')({
  askForRegister: [
    {
      registerUserInput: $('registerUserInput', 'RegisterUserInput!'),
    },
    true,
  ],
});

export const GQL_LOGIN_UNIQUE = typedGql('query')({
  uniqueAuthFromSso: [
    {
      code: $('code', 'String!'),
    },
    {
      access_token: true,
      firstName: true,
      lastName: true,
      role: true,
      email: true,
      sub: true,
    },
  ],
});

export const GQL_FINISH_REGISTER = typedGql('mutation')({
  finishRegister: [
    {
      email: $('email', 'String!'),
      password: $('password', 'String!'),
      firstName: $('firstName', 'String!'),
      lastName: $('lastName', 'String!'),
      token: $('token', 'String!'),
    },
    true,
  ],
});
