/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { Form, DatePicker, DatePickerProps } from 'antd';

import type { FormInstance } from 'antd/es/form';
import dayjs, { Dayjs } from 'dayjs';

import React, { useEffect, useState } from 'react';
import {
  StoredHolidays,
  GQL_UPDATE_HOLIDAYS_FOR_PERIOD,
} from '~/gql/holidays/holidays';

import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import ModalCustom from '~/pages/components/ui/ModalCustom';

type IProps = {
  holidays: StoredHolidays[];
  startDate: Date;
  endDate: Date;
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function EditHolidays({
  title,
  isOpen,
  confirm,
  close,
  holidays,
  startDate,
  endDate,
}: IProps) {
  const { i18n } = useLingui();
  const { payload } = useAuth();

  const formRef = React.useRef<FormInstance>(null);
  const [selectedDatesD, setSelectedDatesD] = useState<Dayjs[]>([]);

  const [updateHolidays] = useMutation(GQL_UPDATE_HOLIDAYS_FOR_PERIOD, {
    client: authClient,
  });

  useEffect(() => {
    formRef.current?.resetFields();

    // setSelectedDates(holidays.map((el) => el.date as unknown as Date));
    setSelectedDatesD(
      holidays.map((el) => dayjs(new Date(el.date as unknown as string)))
    );
  }, [holidays]);

  async function submit() {
    if (!payload?.sub) return;

    const { data: _data } = await updateHolidays({
      variables: {
        dates: selectedDatesD,
        startDate: startDate,
        endDate: endDate,
      },
    });

    if (_data?.updateHolidaysForPeriod) {
      confirm();
    } else {
      close();
    }
  }

  const onChange: DatePickerProps<Dayjs[]>['onChange'] = (date) => {
    setSelectedDatesD(date);
    if (date !== undefined) setSelectedDatesD(date);
  };

  return (
    <ModalCustom title={title} isOpen={isOpen} confirm={submit} close={close}>
      <Form
        {...layout}
        ref={formRef}
        name="holidays-edit"
        initialValues={{
          dates:
            holidays.length > 0
              ? holidays.map((el) =>
                  dayjs(new Date(el.date as unknown as string))
                )
              : [],
        }}
        style={{ maxWidth: 600 }}
      >
        <Form.Item name="dates" label={i18n._('form.holidays')}>
          <DatePicker
            minDate={dayjs(new Date(startDate as unknown as string))}
            maxDate={dayjs(new Date(endDate as unknown as string))}
            multiple
            value={selectedDatesD}
            onChange={onChange}
          />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
}

export default EditHolidays;
