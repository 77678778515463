import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { GQL_DELETE_TASK } from '~/gql/task/task';
import { StoredTaskInfoForPlanning } from '~/gql/task-planning/task-planning';

import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import ModalCustom from '~/pages/components/ui/ModalCustom';
import { TaskInfoWithPlanningCalender } from '~/pages/types/types';
import { SPText } from '~/utils/ui/utils';
import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  task: StoredTaskInfoForPlanning | TaskInfoWithPlanningCalender;
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

function DeleteTaskSupp({ title, isOpen, confirm, close, task }: IProps) {
  const { i18n } = useLingui();
  const { setCurrentDevisProd } = useDataUserStore();

  const [deleteTask] = useMutation(GQL_DELETE_TASK, {
    client: authClient,
  });

  async function submit() {
    const { data: _data } = await deleteTask({
      variables: {
        id: task.id,
      },
    });
    if (_data) {
      confirm();
    } else {
      close();
    }

    if (_data?.deleteTask) {
      setCurrentDevisProd({
        ..._data.deleteTask,
        tasks: _data.deleteTask.tasks?.sort(compareByOrder),
      });
      confirm();
    } else {
      close();
    }
  }

  return (
    <ModalCustom title={title} isOpen={isOpen} confirm={submit} close={close}>
      <SPText $textalign="left">
        {i18n._('table.task.delete.text')} {task.name} ?
      </SPText>
    </ModalCustom>
  );
}

export default DeleteTaskSupp;
