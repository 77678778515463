import { styled } from 'styled-components';
import { classic } from '~/themes';
import { SH3 } from '~/utils/ui/utils';
import {
  DeleteFilled,
  EditOutlined,
  ExportOutlined,
  ImportOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useLingui } from '@lingui/react';

type IProps = {
  title: string;
  addButton?: boolean;
  deleteButton?: boolean;
  editButton?: boolean;
  importButton?: boolean;
  exportButton?: boolean;
  search?: boolean;
  setOpenAdd?: (openAdd: boolean) => void;
  setOpenEdit?: (openEdit: boolean) => void;
  setOpenDelete?: (openDelete: boolean) => void;
  setOpenImport?: (openImport: boolean) => void;
  setSearchText?: (searchText: string) => void;
  exportCsv?: () => void;
};

function HeaderTable({
  title,
  addButton,
  deleteButton,
  editButton,
  importButton,
  exportButton,
  search,
  setOpenAdd,
  setOpenEdit,
  setOpenDelete,
  setOpenImport,
  exportCsv,
  setSearchText,
}: IProps) {
  const { i18n } = useLingui();

  return (
    <Container>
      <SH3 $color="#fff" $texttransform="uppercase">
        {title}
      </SH3>
      <SFlex>
        {search && setSearchText && (
          <Input.Search
            enterButton={
              <Button
                style={{
                  backgroundColor: classic.token?.colorFillSecondary,
                  color: '#fff',
                  border: 'none',
                }}
              >
                {i18n._('common.search')}
              </Button>
            }
            onSearch={(value) => setSearchText(value)}
          />
        )}
        <Actions>
          {addButton && setOpenAdd && (
            <PlusCircleFilled
              style={{ fontSize: '25px', cursor: 'pointer', color: '#fff' }}
              onClick={() => setOpenAdd(true)}
            />
          )}
          {deleteButton && setOpenDelete && (
            <DeleteFilled
              style={{ fontSize: '25px', cursor: 'pointer', color: '#fff' }}
              onClick={() => setOpenDelete(true)}
            />
          )}
          {editButton && setOpenEdit && (
            <EditOutlined
              style={{ fontSize: '25px', cursor: 'pointer', color: '#fff' }}
              onClick={() => setOpenEdit(true)}
            />
          )}
          {importButton && setOpenImport && (
            <ImportOutlined
              style={{ fontSize: '25px', cursor: 'pointer', color: '#fff' }}
              onClick={() => setOpenImport(true)}
            />
          )}
          {exportButton && (
            <ExportOutlined
              style={{ fontSize: '25px', cursor: 'pointer', color: '#fff' }}
              onClick={exportCsv}
            />
          )}
        </Actions>
      </SFlex>
    </Container>
  );
}

export default HeaderTable;

const Container = styled.div`
  position: relative;
  width: 100%;
  background: ${classic.token?.colorPrimary};
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  padding: 16px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 767px) {
    margin-top: 5%;

    &&&& svg {
      font-size: 20px;
    }
  }
`;

const SFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 767px) {
    margin-top: 5%;
    gap: 5px;
    align-items: baseline;
    flex-direction: column;
  }
`;
