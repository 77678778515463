import { useEffect, useState } from 'react';
import { StoredDevitInit } from '~/gql/devis/devis';

export default function useCalculetedDevisInit(
  devisInit: StoredDevitInit | null
) {
  const [devisInitAmount, setDevisInitAmount] = useState<number>(0);
  const [devisInitDuration, setDevisInitDuration] = useState<number>(0);
  const [devisInitDurationExpected, setDevisInitDurationExpected] =
    useState<number>(0);
  const [devisInitDurationUsed, setDevisInitDurationUsed] = useState<number>(0);
  const [devisInitAmountUsed, setDevisInitAmountUsed] = useState<number>(0);

  useEffect(() => {
    let _devisInitAmount = 0;
    let _devisInitAmountUsed = 0;
    let _devisInitDuration = 0;
    let _devisInitDurationExpected = 0;
    let _devisInitDurationUsed = 0;
    if (devisInit) {
      const _devisInits = devisInit.devisInit ?? [];
      _devisInitAmount = _devisInits.reduce(function (acc, obj) {
        return acc + obj.amount;
      }, 0);
      _devisInitAmountUsed = _devisInits.reduce(function (acc, obj) {
        return acc + (obj.amountUsed ?? 0);
      }, 0);
      _devisInitDuration = _devisInits.reduce(function (acc, obj) {
        return acc + obj.duration;
      }, 0);
      _devisInitDurationExpected = _devisInits.reduce(function (acc, obj) {
        return acc + obj.durationExpected;
      }, 0);
      _devisInitDurationUsed = _devisInits.reduce(function (acc, obj) {
        return acc + (obj.durationUsed ?? 0);
      }, 0);
    }
    setDevisInitAmount(_devisInitAmount);
    setDevisInitDuration(_devisInitDuration);
    setDevisInitDurationExpected(_devisInitDurationExpected);
    setDevisInitDurationUsed(_devisInitDurationUsed);
    setDevisInitAmountUsed(_devisInitAmountUsed);
  }, [devisInit]);

  return {
    devisInitAmount,
    devisInitDuration,
    devisInitDurationExpected,
    devisInitDurationUsed,
    devisInitAmountUsed,
  };
}
