// @ts-nocheck
/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: (Z & ValueTypes[R]) | ValueTypes[R],
    ops?: OperationOptions & { variables?: Record<string, unknown> },
  ) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: (Z & ValueTypes[R]) | ValueTypes[R],
    ops?: OperationOptions & { variables?: ExtractVariables<Z> },
  ) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: (Z & ValueTypes[R]) | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : Record<string, never>, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariablesDeep<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariablesDeep<Query[K]>> }[keyof Query]>;

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariablesDeep<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	DateTime?: ScalarResolver;
	Upload?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["AuthType"]

export type ValueTypes = {
    ["Health"]: AliasType<{
	env?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Permissions"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	roles?:ValueTypes["Roles"],
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:unknown;
	["Users"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	passwordResetToken?:boolean | `@${string}`,
	passwordResetExpiryDate?:boolean | `@${string}`,
	activateAccountToken?:boolean | `@${string}`,
	activateAccountExpiryDate?:boolean | `@${string}`,
	emailVerified?:boolean | `@${string}`,
	verifyEmailToken?:boolean | `@${string}`,
	verifyEmailExpiryDate?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	role?:ValueTypes["Roles"],
	roleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountStatus"]:AccountStatus;
	["UserInfoForDevis"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	passwordResetToken?:boolean | `@${string}`,
	passwordResetExpiryDate?:boolean | `@${string}`,
	activateAccountToken?:boolean | `@${string}`,
	activateAccountExpiryDate?:boolean | `@${string}`,
	emailVerified?:boolean | `@${string}`,
	verifyEmailToken?:boolean | `@${string}`,
	verifyEmailExpiryDate?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	role?:ValueTypes["Roles"],
	roleId?:boolean | `@${string}`,
	hasTimes?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPagination"]: AliasType<{
	users?:ValueTypes["Users"],
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Roles"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:ValueTypes["Permissions"],
	users?:ValueTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["UniqueAuth"]: AliasType<{
	access_token?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	sub?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Configs"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Poste"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	task?:ValueTypes["Task"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationState"]:NotificationState;
	["Project"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManager?:ValueTypes["Users"],
	projectManagerId?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	startDatePlanning?:boolean | `@${string}`,
	marginPercent?:boolean | `@${string}`,
	retroPlanningDone?:boolean | `@${string}`,
	planningDone?:boolean | `@${string}`,
	superProject?:ValueTypes["Project"],
	superProjectId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectType"]:ProjectType;
	["ProjectInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManagerId?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	startDatePlanning?:boolean | `@${string}`,
	marginPercent?:boolean | `@${string}`,
	retroPlanningDone?:boolean | `@${string}`,
	planningDone?:boolean | `@${string}`,
	superProjectId?:boolean | `@${string}`,
	projectManagerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectInfoPagination"]: AliasType<{
	projects?:ValueTypes["ProjectInfo"],
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectFullInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManagerId?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	startDatePlanning?:boolean | `@${string}`,
	marginPercent?:boolean | `@${string}`,
	retroPlanningDone?:boolean | `@${string}`,
	planningDone?:boolean | `@${string}`,
	superProjectId?:boolean | `@${string}`,
	projectManager?:ValueTypes["UserInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	superProjectId?:boolean | `@${string}`,
	tasks?:ValueTypes["TaskShortInfo"],
		__typename?: boolean | `@${string}`
}>;
	["SuppRentaInfo"]: AliasType<{
	amountByTasks?:boolean | `@${string}`,
	amountSpendByTasks?:boolean | `@${string}`,
	durationByTasks?:boolean | `@${string}`,
	timesByTasks?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectRentaInfoBase"]: AliasType<{
	projectId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	rentaPercent?:boolean | `@${string}`,
	projectDurationSold?:boolean | `@${string}`,
	projectAmountSold?:boolean | `@${string}`,
	projectDuration?:boolean | `@${string}`,
	projectDeltaDurationProd?:boolean | `@${string}`,
	projectDeltaAmountProd?:boolean | `@${string}`,
	projectSommeTime?:boolean | `@${string}`,
	projectSommeAmount?:boolean | `@${string}`,
	projectSommeAmountSpend?:boolean | `@${string}`,
	projectDurationUsed?:boolean | `@${string}`,
	projectAmountUsed?:boolean | `@${string}`,
	projectDeltaDurationSold?:boolean | `@${string}`,
	projectDeltaSommeSold?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManager?:ValueTypes["Users"],
	superProjectId?:boolean | `@${string}`,
	renta?:ValueTypes["ProjectRentaInfoBase"],
	tasks?:ValueTypes["TaskRentaInfo"],
	devisInit?:ValueTypes["DevisInitRentaInfo"],
	supp?:ValueTypes["SuppRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectInfoForSuperProject"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskPlanning"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	task?:ValueTypes["Task"],
	taskId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskPlanningInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimeForUserInfo"]: AliasType<{
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Time"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	userId?:boolean | `@${string}`,
	task?:ValueTypes["Task"],
	taskId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimeInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	userId?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
	task?:ValueTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TimeShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	userId?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
	task?:ValueTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TimeRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	userId?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserTime"]: AliasType<{
	user?:ValueTypes["Users"],
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateUserTime"]: AliasType<{
	date?:boolean | `@${string}`,
	totalTimeByDate?:boolean | `@${string}`,
	userTime?:ValueTypes["UserTime"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectUserTimesInfo"]: AliasType<{
	projectId?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	projectManager?:ValueTypes["Users"],
	calendar?:ValueTypes["DateUserTime"],
		__typename?: boolean | `@${string}`
}>;
	["DatePlanningTimes"]: AliasType<{
	label?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	selectible?:boolean | `@${string}`,
	planningDuration?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	timeId?:boolean | `@${string}`,
	timeComment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningForUserInfo"]: AliasType<{
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningTimeForUserInfo"]: AliasType<{
	date?:boolean | `@${string}`,
	userPlanningDuration?:boolean | `@${string}`,
	timeDuration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	taskPlanningId?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	userId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	task?:ValueTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TimeUserPlanningInfo"]: AliasType<{
	userPlanning?:ValueTypes["UserPlanningInfo"],
	time?:ValueTypes["TimeInfo"],
		__typename?: boolean | `@${string}`
}>;
	["UserTimePlanningInfo"]: AliasType<{
	userId?:boolean | `@${string}`,
	userFirstName?:boolean | `@${string}`,
	userLastName?:boolean | `@${string}`,
	userPlanning?:ValueTypes["UserPlanningShortInfo"],
	time?:ValueTypes["TimeShortInfo"],
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	taskPlanningId?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	userId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	task?:ValueTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["Calendar"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	selectible?:boolean | `@${string}`,
	retroChecked?:boolean | `@${string}`,
	checked?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Task"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	durationDays?:boolean | `@${string}`,
	durationClientDays?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	stateType?:boolean | `@${string}`,
	statePercent?:boolean | `@${string}`,
	devis?:ValueTypes["Devis"],
	devisId?:boolean | `@${string}`,
	devisInit?:ValueTypes["DevisInit"],
	devisInitId?:boolean | `@${string}`,
	project?:ValueTypes["Project"],
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	user?:ValueTypes["Users"],
	notifications?:ValueTypes["Notification"],
	time?:ValueTypes["Time"],
	taskPlanning?:ValueTypes["TaskPlanning"],
		__typename?: boolean | `@${string}`
}>;
	["FunctionType"]:FunctionType;
	["StateType"]:StateType;
	["TaskInfoForDevis"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	durationDays?:boolean | `@${string}`,
	durationClientDays?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	devisInitId?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	taskPlanning?:ValueTypes["TaskPlanningInfo"],
	posteId?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	user?:ValueTypes["UserInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TaskInfoForPlanning"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	durationDays?:boolean | `@${string}`,
	durationClientDays?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	devisInitId?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	taskPlanning?:ValueTypes["TaskPlanningInfo"],
	posteId?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	user?:ValueTypes["UserInfoForDevis"],
	calendar?:ValueTypes["Calendar"],
		__typename?: boolean | `@${string}`
}>;
	["Planning"]: AliasType<{
	calendar?:ValueTypes["Calendar"],
	tasks?:ValueTypes["TaskInfoForPlanning"],
	tasksSupp?:ValueTypes["TaskInfoForPlanning"],
		__typename?: boolean | `@${string}`
}>;
	["TaskShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	devisInitId?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	user?:ValueTypes["UserInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["UserRentaInfo"]: AliasType<{
	userId?:boolean | `@${string}`,
	userFirstName?:boolean | `@${string}`,
	userLastName?:boolean | `@${string}`,
	planningDuration?:boolean | `@${string}`,
	sommeTime?:boolean | `@${string}`,
	deltaDuration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	deltaDuration?:boolean | `@${string}`,
	sommeTime?:boolean | `@${string}`,
	time?:ValueTypes["TimeRentaInfo"],
	userRentaInfo?:ValueTypes["UserRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["TaskForUserInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManagerFirstName?:boolean | `@${string}`,
	projectManagerLastName?:boolean | `@${string}`,
	allUserPlanning?:boolean | `@${string}`,
	userPlanning?:ValueTypes["UserPlanningForUserInfo"],
	allTime?:boolean | `@${string}`,
	time?:ValueTypes["TimeForUserInfo"],
	timeAndUserPlanning?:ValueTypes["UserPlanningTimeForUserInfo"],
		__typename?: boolean | `@${string}`
}>;
	["DevisInit"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	durationExpected?:boolean | `@${string}`,
	durationUsed?:boolean | `@${string}`,
	amountUsed?:boolean | `@${string}`,
	devis?:ValueTypes["Devis"],
	devisId?:boolean | `@${string}`,
	poste?:ValueTypes["Poste"],
	posteId?:boolean | `@${string}`,
	customPosteName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevisInitInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	durationExpected?:boolean | `@${string}`,
	durationUsed?:boolean | `@${string}`,
	amountUsed?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	poste?:ValueTypes["Poste"],
	posteId?:boolean | `@${string}`,
	customPosteName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevisInitRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	durationExpected?:boolean | `@${string}`,
	durationUsed?:boolean | `@${string}`,
	amountUsed?:boolean | `@${string}`,
	customPosteName?:boolean | `@${string}`,
	durationByTasks?:boolean | `@${string}`,
	timesByTasks?:boolean | `@${string}`,
	amountByTasks?:boolean | `@${string}`,
	amountSpendByTasks?:boolean | `@${string}`,
	deltaDurationProd?:boolean | `@${string}`,
	deltaAmountProd?:boolean | `@${string}`,
	deltaDurationSold?:boolean | `@${string}`,
	deltaSommeSold?:boolean | `@${string}`,
	poste?:ValueTypes["Poste"],
	tasks?:ValueTypes["TaskRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["Devis"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	project?:ValueTypes["Project"],
	projectId?:boolean | `@${string}`,
	signedDate?:boolean | `@${string}`,
	finishDate?:boolean | `@${string}`,
	tasks?:ValueTypes["Task"],
	devisInit?:ValueTypes["DevisInit"],
		__typename?: boolean | `@${string}`
}>;
	["DevisType"]:DevisType;
	["DevisForProjectInit"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	signedDate?:boolean | `@${string}`,
	finishDate?:boolean | `@${string}`,
	project?:ValueTypes["ProjectInfo"],
	devisInit?:ValueTypes["DevisInitInfo"],
		__typename?: boolean | `@${string}`
}>;
	["DevisForProjectProd"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	signedDate?:boolean | `@${string}`,
	finishDate?:boolean | `@${string}`,
	project?:ValueTypes["ProjectInfo"],
	tasks?:ValueTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["DevisForProject"]: AliasType<{
	devisInit?:ValueTypes["DevisForProjectInit"],
	devisProd?:ValueTypes["DevisForProjectProd"],
		__typename?: boolean | `@${string}`
}>;
	["DownloadFile"]: AliasType<{
	fileName?:boolean | `@${string}`,
	fileMimeType?:boolean | `@${string}`,
	buffer?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MyFiles"]: AliasType<{
	eTag?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
	fileMimeType?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Function"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Holidays"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mail"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	to?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuperProject"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projects?:ValueTypes["ProjectInfoForSuperProject"],
		__typename?: boolean | `@${string}`
}>;
	["SuperProjectInfoPagination"]: AliasType<{
	projects?:ValueTypes["SuperProject"],
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuperProjectRentaDetailsInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projects?:ValueTypes["ProjectInfoForSuperProject"],
	renta?:ValueTypes["ProjectRentaInfoBase"],
	projectsRenta?:ValueTypes["ProjectRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
	getHealthGql?:ValueTypes["Health"],
getRole?: [{	id: number | Variable<any, string>},ValueTypes["Roles"]],
	getRoles?:ValueTypes["Roles"],
getPermission?: [{	id: number | Variable<any, string>},ValueTypes["Permissions"]],
	getPermissions?:ValueTypes["Permissions"],
getConfig?: [{	id: number | Variable<any, string>},ValueTypes["Configs"]],
	getConfigs?:ValueTypes["Configs"],
getMail?: [{	id: number | Variable<any, string>},ValueTypes["Mail"]],
	getMails?:ValueTypes["Mail"],
	sendMail?:boolean | `@${string}`,
loginAsGuest?: [{	email: string | Variable<any, string>},ValueTypes["AuthType"]],
login?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>},ValueTypes["AuthType"]],
uniqueAuthFromSso?: [{	code: string | Variable<any, string>},ValueTypes["UniqueAuth"]],
getUser?: [{	id: number | Variable<any, string>},ValueTypes["Users"]],
	getUsers?:ValueTypes["Users"],
getFilteredUsers?: [{	findManyUserWithFiltersInput: ValueTypes["FindManyUserWithFiltersInput"] | Variable<any, string>},ValueTypes["UserPagination"]],
downloadFile?: [{	eTag: string | Variable<any, string>},ValueTypes["DownloadFile"]],
	getAllMyFiles?:ValueTypes["MyFiles"],
getFilteredProjectsInfo?: [{	filterProjectInput: ValueTypes["FilterProjectInput"] | Variable<any, string>},ValueTypes["ProjectInfoPagination"]],
	getAllProjectByManager?:ValueTypes["ProjectInfoForSuperProject"],
getAllProjectsWithTasks?: [{	takeAll: boolean | Variable<any, string>},ValueTypes["ProjectShortInfo"]],
getOneProject?: [{	id: number | Variable<any, string>},ValueTypes["ProjectFullInfo"]],
getRentaByProjectId?: [{	id: number | Variable<any, string>},ValueTypes["ProjectRentaInfo"]],
getFilteredSuperProjectsInfo?: [{	filterProjectInput: ValueTypes["FilterSuperProjectInput"] | Variable<any, string>},ValueTypes["SuperProjectInfoPagination"]],
getOneSuperProject?: [{	id: number | Variable<any, string>},ValueTypes["SuperProject"]],
getRentaBySuperProjectId?: [{	id: number | Variable<any, string>},ValueTypes["SuperProjectRentaDetailsInfo"]],
getDevisByProjectId?: [{	id: number | Variable<any, string>},ValueTypes["DevisForProject"]],
getFunction?: [{	id: number | Variable<any, string>},ValueTypes["Function"]],
	getFunctions?:ValueTypes["Function"],
isUsersCanBeChangedInTask?: [{	createOrUpdateUsersInTaskInput: ValueTypes["CreateOrUpdateUsersInTaskInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
getTaskListByProjectId?: [{	id: number | Variable<any, string>},ValueTypes["TaskShortInfo"]],
getTasksInternByProjectId?: [{	id: number | Variable<any, string>},ValueTypes["TaskShortInfo"]],
getTasksInfoByUserId?: [{	id: number | Variable<any, string>},ValueTypes["TaskForUserInfo"]],
getPlanningByProjectId?: [{	id: number | Variable<any, string>},ValueTypes["Planning"]],
getPlanningForPeriodByUserId?: [{	filterUserPlanningInput: ValueTypes["FilterUserPlanningInput"] | Variable<any, string>},ValueTypes["UserPlanningInfo"]],
getTimesUserPlanningsForPeriodByUserId?: [{	filterUserPlanningInput: ValueTypes["FilterUserPlanningInput"] | Variable<any, string>},ValueTypes["TimeUserPlanningInfo"]],
getAllTimesUserPlanningsForPeriodByProjectId?: [{	filterTimeUserPlanningByProjectInput: ValueTypes["FilterTimeUserPlanningByProjectInput"] | Variable<any, string>},ValueTypes["TimeUserPlanningInfo"]],
getTimesUserPlanningsForPeriodByProjectId?: [{	filterTimeUserPlanningByProjectInput: ValueTypes["FilterTimeUserPlanningByProjectInput"] | Variable<any, string>},ValueTypes["UserTimePlanningInfo"]],
getTimesForPeriodByUserId?: [{	filterTimeInput: ValueTypes["FilterTimeInput"] | Variable<any, string>},ValueTypes["TimeInfo"]],
getExportUserTimesForPeriodByProjectId?: [{	filterTimeUserPlanningByProjectInput: ValueTypes["FilterTimeUserPlanningByProjectInput"] | Variable<any, string>},ValueTypes["ProjectUserTimesInfo"]],
getHolidaysForPeriod?: [{	filterHolidaysInput: ValueTypes["FilterHolidaysInput"] | Variable<any, string>},ValueTypes["Holidays"]],
	getAllHolidays?:ValueTypes["Holidays"],
getPoste?: [{	id: number | Variable<any, string>},ValueTypes["Poste"]],
	getPostes?:ValueTypes["Poste"],
		__typename?: boolean | `@${string}`
}>;
	["AuthType"]: AliasType<{		["...on Tokens"] : ValueTypes["Tokens"],
		["...on CustomError"] : ValueTypes["CustomError"]
		__typename?: boolean | `@${string}`
}>;
	["Tokens"]: AliasType<{
	access_token?:boolean | `@${string}`,
	refresh_token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomError"]: AliasType<{
	error?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EError"]:EError;
	["FindManyUserWithFiltersInput"]: {
	skip: number | Variable<any, string>,
	take: number | Variable<any, string>,
	searchInput: string | Variable<any, string>,
	orderBy?: ValueTypes["UserOrderByInput"] | undefined | null | Variable<any, string>,
	filterBy?: ValueTypes["UserFilterByInput"] | undefined | null | Variable<any, string>,
	adminIncluded?: boolean | undefined | null | Variable<any, string>
};
	["UserOrderByInput"]: {
	email?: ValueTypes["OrderByEnum"] | undefined | null | Variable<any, string>,
	firstName?: ValueTypes["OrderByEnum"] | undefined | null | Variable<any, string>,
	lastName?: ValueTypes["OrderByEnum"] | undefined | null | Variable<any, string>,
	lastConnectionAt?: ValueTypes["OrderByEnum"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["OrderByEnum"] | undefined | null | Variable<any, string>
};
	["OrderByEnum"]:OrderByEnum;
	["UserFilterByInput"]: {
	role?: Array<number> | undefined | null | Variable<any, string>
};
	["FilterProjectInput"]: {
	skip?: number | undefined | null | Variable<any, string>,
	take?: number | undefined | null | Variable<any, string>,
	userId?: number | undefined | null | Variable<any, string>,
	userRole?: string | undefined | null | Variable<any, string>,
	searchInProjectName?: string | undefined | null | Variable<any, string>,
	orderBy?: ValueTypes["ProjectOrderByInput"] | undefined | null | Variable<any, string>,
	projectType: ValueTypes["ProjectType"] | Variable<any, string>
};
	["ProjectOrderByInput"]: {
	name?: ValueTypes["OrderByEnum"] | undefined | null | Variable<any, string>
};
	["FilterSuperProjectInput"]: {
	skip?: number | undefined | null | Variable<any, string>,
	take?: number | undefined | null | Variable<any, string>,
	userId?: number | undefined | null | Variable<any, string>,
	userRole?: string | undefined | null | Variable<any, string>,
	searchInSuperProjectName?: string | undefined | null | Variable<any, string>,
	orderBy?: ValueTypes["SuperProjectOrderByInput"] | undefined | null | Variable<any, string>
};
	["SuperProjectOrderByInput"]: {
	name?: ValueTypes["OrderByEnum"] | undefined | null | Variable<any, string>
};
	["CreateOrUpdateUsersInTaskInput"]: {
	id: number | Variable<any, string>,
	users: Array<number> | Variable<any, string>
};
	["FilterUserPlanningInput"]: {
	userId: number | Variable<any, string>,
	startDate: ValueTypes["DateTime"] | Variable<any, string>,
	endDate: ValueTypes["DateTime"] | Variable<any, string>
};
	["FilterTimeUserPlanningByProjectInput"]: {
	projectId: number | Variable<any, string>,
	startDate: ValueTypes["DateTime"] | Variable<any, string>,
	endDate: ValueTypes["DateTime"] | Variable<any, string>
};
	["FilterTimeInput"]: {
	userId: number | Variable<any, string>,
	startDate: ValueTypes["DateTime"] | Variable<any, string>,
	endDate: ValueTypes["DateTime"] | Variable<any, string>
};
	["FilterHolidaysInput"]: {
	startDate: ValueTypes["DateTime"] | Variable<any, string>,
	endDate: ValueTypes["DateTime"] | Variable<any, string>
};
	["Mutation"]: AliasType<{
askForResetPassword?: [{	email: string | Variable<any, string>},boolean | `@${string}`],
resetPassword?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>,	token: string | Variable<any, string>},boolean | `@${string}`],
verifyEmail?: [{	email: string | Variable<any, string>,	token: string | Variable<any, string>},boolean | `@${string}`],
register?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>},ValueTypes["AuthType"]],
askForRegister?: [{	registerUserInput: ValueTypes["RegisterUserInput"] | Variable<any, string>},boolean | `@${string}`],
finishRegister?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>,	token: string | Variable<any, string>},boolean | `@${string}`],
finishRegisterAsGuest?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>},boolean | `@${string}`],
	refresh?:ValueTypes["AuthType"],
	logout?:boolean | `@${string}`,
createUser?: [{	createUserInput: ValueTypes["CreateUserInput"] | Variable<any, string>},ValueTypes["Users"]],
updateUser?: [{	updateUserInput: ValueTypes["UpdateUserInput"] | Variable<any, string>},ValueTypes["Users"]],
deleteUser?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
deleteFile?: [{	fileId: number | Variable<any, string>},boolean | `@${string}`],
uploadFile?: [{	file: ValueTypes["Upload"] | Variable<any, string>},boolean | `@${string}`],
createProject?: [{	createProjectInput: ValueTypes["CreateProjectInput"] | Variable<any, string>},boolean | `@${string}`],
createProjectIntern?: [{	createProjectInput: ValueTypes["CreateProjectInput"] | Variable<any, string>},boolean | `@${string}`],
updateProject?: [{	updateProjectInput: ValueTypes["UpdateProjectInput"] | Variable<any, string>},boolean | `@${string}`],
setRetroPlanningDone?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
setPlanningDone?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
updateRetroPlanningDone?: [{	id: number | Variable<any, string>,	retroPlanningDone: boolean | Variable<any, string>},boolean | `@${string}`],
updatePlanningDone?: [{	id: number | Variable<any, string>,	planningDone: boolean | Variable<any, string>},boolean | `@${string}`],
deleteProject?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
createSuperProject?: [{	createSuperProjectInput: ValueTypes["CreateSuperProjectInput"] | Variable<any, string>},boolean | `@${string}`],
updateSuperProject?: [{	updateProjectInput: ValueTypes["UpdateSuperProjectInput"] | Variable<any, string>},boolean | `@${string}`],
deleteSuperProject?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
createDevisInitial?: [{	createDevisInput: ValueTypes["CreateDevisInput"] | Variable<any, string>},boolean | `@${string}`],
createDevisProd?: [{	createDevisProdInput: ValueTypes["CreateDevisProdInput"] | Variable<any, string>},boolean | `@${string}`],
updateDevisInitial?: [{	updateDevisInput: ValueTypes["UpdateDevisInput"] | Variable<any, string>},boolean | `@${string}`],
updateDevisProd?: [{	updateDevisProdInput: ValueTypes["UpdateDevisProdInput"] | Variable<any, string>},boolean | `@${string}`],
updateDevisProdDate?: [{	updateDevisProdDateInput: ValueTypes["UpdateDevisProdDateInput"] | Variable<any, string>},boolean | `@${string}`],
updateDevisInitDurationExpected?: [{	updateDevisInitDurationExpectedInput: Array<ValueTypes["UpdateDevisInitDurationExpectedInput"]> | Variable<any, string>},boolean | `@${string}`],
createDevisInit?: [{	createDevisInitInput: ValueTypes["CreateDevisInitInput"] | Variable<any, string>},boolean | `@${string}`],
updateTaskOrder?: [{	updateTaskOrderInput: Array<ValueTypes["UpdateTaskOrderInput"]> | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
updateTaskSupp?: [{	updateTaskSuppInput: ValueTypes["UpdateTaskSuppInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
updateTaskRetro?: [{	updateTaskRetroInput: ValueTypes["UpdateTaskRetroInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
createOrUpdateUsersInTaskByFunctionType?: [{	createOrUpdateUsersByFunctionTypeInput: ValueTypes["CreateOrUpdateUsersByFunctionTypeInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
createOrUpdateUsersInTask?: [{	createOrUpdateUsersInTaskInput: ValueTypes["CreateOrUpdateUsersInTaskInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
createOrUpdateUsersInTaskIntern?: [{	createOrUpdateUsersInTaskInput: ValueTypes["CreateOrUpdateUsersInTaskInput"] | Variable<any, string>},boolean | `@${string}`],
createTaskSupp?: [{	createTaskSuppInput: ValueTypes["CreateTaskSuppInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
createTaskIntern?: [{	createTaskInternInput: ValueTypes["CreateTaskInternInput"] | Variable<any, string>},boolean | `@${string}`],
deleteTask?: [{	id: number | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
deleteTaskIntern?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
createOrUpdateTaskPlanning?: [{	updateTaskPlanningInput: ValueTypes["UpdateTaskPlanningInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
createUserPlanning?: [{	createUserPlanningInput: ValueTypes["CreateUserPlanningInput"] | Variable<any, string>},ValueTypes["DevisForProjectProd"]],
deleteUserPlanning?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
createOrUpdateTime?: [{	createOrUpdateTimeInput: ValueTypes["CreateOrUpdateTimeInput"] | Variable<any, string>},boolean | `@${string}`],
deleteTime?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
createHolidays?: [{	createHolidaysInput: ValueTypes["CreateHolidaysInput"] | Variable<any, string>},boolean | `@${string}`],
deleteHolidays?: [{	id: number | Variable<any, string>},boolean | `@${string}`],
updateHolidaysForPeriod?: [{	updateHolidaysInput: ValueTypes["UpdateHolidaysInput"] | Variable<any, string>},ValueTypes["Holidays"]],
		__typename?: boolean | `@${string}`
}>;
	["RegisterUserInput"]: {
	email: string | Variable<any, string>,
	roleId: number | Variable<any, string>
};
	["CreateUserInput"]: {
	email: string | Variable<any, string>,
	password: string | Variable<any, string>,
	firstName: string | Variable<any, string>,
	lastName: string | Variable<any, string>,
	roleId: number | Variable<any, string>
};
	["UpdateUserInput"]: {
	email?: string | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	roleId?: number | undefined | null | Variable<any, string>,
	id: number | Variable<any, string>
};
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:unknown;
	["CreateProjectInput"]: {
	name: string | Variable<any, string>,
	projectManagerId: number | Variable<any, string>,
	marginPercent?: number | undefined | null | Variable<any, string>,
	projectType?: ValueTypes["ProjectType"] | undefined | null | Variable<any, string>
};
	["UpdateProjectInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	projectManagerId?: number | undefined | null | Variable<any, string>,
	marginPercent?: number | undefined | null | Variable<any, string>,
	projectType?: ValueTypes["ProjectType"] | undefined | null | Variable<any, string>,
	id: number | Variable<any, string>
};
	["CreateSuperProjectInput"]: {
	name: string | Variable<any, string>,
	projectIds?: Array<number> | undefined | null | Variable<any, string>
};
	["UpdateSuperProjectInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	projectIds?: Array<number> | undefined | null | Variable<any, string>,
	id: number | Variable<any, string>
};
	["CreateDevisInput"]: {
	projectId: number | Variable<any, string>,
	type: ValueTypes["DevisType"] | Variable<any, string>,
	signedDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createDevisInitInput: Array<ValueTypes["CreateDevisInitInput"]> | Variable<any, string>
};
	["CreateDevisInitInput"]: {
	id?: number | undefined | null | Variable<any, string>,
	devisId?: number | undefined | null | Variable<any, string>,
	functionType: ValueTypes["FunctionType"] | Variable<any, string>,
	amount: number | Variable<any, string>,
	duration: number | Variable<any, string>,
	durationExpected: number | Variable<any, string>,
	durationUsed?: number | undefined | null | Variable<any, string>,
	amountUsed?: number | undefined | null | Variable<any, string>,
	posteId: number | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	customPosteName?: string | undefined | null | Variable<any, string>
};
	["CreateDevisProdInput"]: {
	projectId: number | Variable<any, string>,
	type: ValueTypes["DevisType"] | Variable<any, string>,
	signedDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	finishDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createTaskInput: Array<ValueTypes["CreateTaskInput"]> | Variable<any, string>
};
	["CreateTaskInput"]: {
	devisId?: number | undefined | null | Variable<any, string>,
	projectId: number | Variable<any, string>,
	name: string | Variable<any, string>,
	functionType: ValueTypes["FunctionType"] | Variable<any, string>,
	amount: number | Variable<any, string>,
	amountSpend?: number | undefined | null | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	duration: number | Variable<any, string>,
	devisInitId?: number | undefined | null | Variable<any, string>,
	durationDays?: number | undefined | null | Variable<any, string>,
	durationClientDays?: number | undefined | null | Variable<any, string>,
	visibleInRetro: boolean | Variable<any, string>,
	calculatedAsAmount: boolean | Variable<any, string>
};
	["UpdateDevisInput"]: {
	id: number | Variable<any, string>,
	signedDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	createDevisInitInput: Array<ValueTypes["CreateDevisInitInput"]> | Variable<any, string>
};
	["UpdateDevisProdInput"]: {
	id: number | Variable<any, string>,
	signedDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	finishDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	updateTaskInput: Array<ValueTypes["UpdateTaskInput"]> | Variable<any, string>
};
	["UpdateTaskInput"]: {
	devisId?: number | undefined | null | Variable<any, string>,
	projectId: number | Variable<any, string>,
	name: string | Variable<any, string>,
	functionType: ValueTypes["FunctionType"] | Variable<any, string>,
	amount: number | Variable<any, string>,
	amountSpend?: number | undefined | null | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>,
	duration: number | Variable<any, string>,
	devisInitId?: number | undefined | null | Variable<any, string>,
	durationDays?: number | undefined | null | Variable<any, string>,
	durationClientDays?: number | undefined | null | Variable<any, string>,
	visibleInRetro: boolean | Variable<any, string>,
	calculatedAsAmount: boolean | Variable<any, string>,
	id?: number | undefined | null | Variable<any, string>,
	stateType?: ValueTypes["StateType"] | undefined | null | Variable<any, string>
};
	["UpdateDevisProdDateInput"]: {
	id: number | Variable<any, string>,
	signedDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	finishDate?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["UpdateDevisInitDurationExpectedInput"]: {
	id: number | Variable<any, string>,
	durationExpected: number | Variable<any, string>
};
	["UpdateTaskOrderInput"]: {
	id: number | Variable<any, string>,
	order?: number | undefined | null | Variable<any, string>
};
	["UpdateTaskSuppInput"]: {
	id: number | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	functionType: ValueTypes["FunctionType"] | Variable<any, string>,
	amount?: number | undefined | null | Variable<any, string>,
	amountSpend?: number | undefined | null | Variable<any, string>,
	duration?: number | undefined | null | Variable<any, string>
};
	["UpdateTaskRetroInput"]: {
	id: number | Variable<any, string>,
	startDateInit: number | Variable<any, string>,
	durationDays?: number | undefined | null | Variable<any, string>
};
	["CreateOrUpdateUsersByFunctionTypeInput"]: {
	projectId: number | Variable<any, string>,
	usersByFunctionType: Array<ValueTypes["UsersByFunctionTypeInput"]> | Variable<any, string>
};
	["UsersByFunctionTypeInput"]: {
	functionType: ValueTypes["FunctionType"] | Variable<any, string>,
	usersIds: Array<number> | Variable<any, string>
};
	["CreateTaskSuppInput"]: {
	projectId: number | Variable<any, string>,
	name: string | Variable<any, string>,
	functionType: ValueTypes["FunctionType"] | Variable<any, string>,
	amount: number | Variable<any, string>,
	amountSpend?: number | undefined | null | Variable<any, string>,
	duration: number | Variable<any, string>,
	supp?: boolean | undefined | null | Variable<any, string>
};
	["CreateTaskInternInput"]: {
	projectId: number | Variable<any, string>,
	name: string | Variable<any, string>
};
	["UpdateTaskPlanningInput"]: {
	projectId: number | Variable<any, string>,
	taskId: number | Variable<any, string>,
	dates: Array<ValueTypes["UpdateDatePlanningInput"]> | Variable<any, string>
};
	["UpdateDatePlanningInput"]: {
	id?: number | undefined | null | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	duration: number | Variable<any, string>
};
	["CreateUserPlanningInput"]: {
	userId: number | Variable<any, string>,
	taskPlanningId: number | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	duration: number | Variable<any, string>
};
	["CreateOrUpdateTimeInput"]: {
	id?: number | undefined | null | Variable<any, string>,
	taskId: number | Variable<any, string>,
	userId: number | Variable<any, string>,
	date: ValueTypes["DateTime"] | Variable<any, string>,
	duration: number | Variable<any, string>,
	comment?: string | undefined | null | Variable<any, string>
};
	["CreateHolidaysInput"]: {
	date: ValueTypes["DateTime"] | Variable<any, string>
};
	["UpdateHolidaysInput"]: {
	dates: Array<ValueTypes["DateTime"]> | Variable<any, string>,
	startDate: ValueTypes["DateTime"] | Variable<any, string>,
	endDate: ValueTypes["DateTime"] | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["Health"]: AliasType<{
	env?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Permissions"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	roles?:ResolverInputTypes["Roles"],
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:unknown;
	["Users"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	passwordResetToken?:boolean | `@${string}`,
	passwordResetExpiryDate?:boolean | `@${string}`,
	activateAccountToken?:boolean | `@${string}`,
	activateAccountExpiryDate?:boolean | `@${string}`,
	emailVerified?:boolean | `@${string}`,
	verifyEmailToken?:boolean | `@${string}`,
	verifyEmailExpiryDate?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	role?:ResolverInputTypes["Roles"],
	roleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountStatus"]:AccountStatus;
	["UserInfoForDevis"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	passwordResetToken?:boolean | `@${string}`,
	passwordResetExpiryDate?:boolean | `@${string}`,
	activateAccountToken?:boolean | `@${string}`,
	activateAccountExpiryDate?:boolean | `@${string}`,
	emailVerified?:boolean | `@${string}`,
	verifyEmailToken?:boolean | `@${string}`,
	verifyEmailExpiryDate?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	role?:ResolverInputTypes["Roles"],
	roleId?:boolean | `@${string}`,
	hasTimes?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPagination"]: AliasType<{
	users?:ResolverInputTypes["Users"],
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	roleId?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Roles"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:ResolverInputTypes["Permissions"],
	users?:ResolverInputTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["UniqueAuth"]: AliasType<{
	access_token?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	sub?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Configs"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Poste"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	task?:ResolverInputTypes["Task"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationState"]:NotificationState;
	["Project"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManager?:ResolverInputTypes["Users"],
	projectManagerId?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	startDatePlanning?:boolean | `@${string}`,
	marginPercent?:boolean | `@${string}`,
	retroPlanningDone?:boolean | `@${string}`,
	planningDone?:boolean | `@${string}`,
	superProject?:ResolverInputTypes["Project"],
	superProjectId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectType"]:ProjectType;
	["ProjectInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManagerId?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	startDatePlanning?:boolean | `@${string}`,
	marginPercent?:boolean | `@${string}`,
	retroPlanningDone?:boolean | `@${string}`,
	planningDone?:boolean | `@${string}`,
	superProjectId?:boolean | `@${string}`,
	projectManagerName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectInfoPagination"]: AliasType<{
	projects?:ResolverInputTypes["ProjectInfo"],
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectFullInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManagerId?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	startDatePlanning?:boolean | `@${string}`,
	marginPercent?:boolean | `@${string}`,
	retroPlanningDone?:boolean | `@${string}`,
	planningDone?:boolean | `@${string}`,
	superProjectId?:boolean | `@${string}`,
	projectManager?:ResolverInputTypes["UserInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	superProjectId?:boolean | `@${string}`,
	tasks?:ResolverInputTypes["TaskShortInfo"],
		__typename?: boolean | `@${string}`
}>;
	["SuppRentaInfo"]: AliasType<{
	amountByTasks?:boolean | `@${string}`,
	amountSpendByTasks?:boolean | `@${string}`,
	durationByTasks?:boolean | `@${string}`,
	timesByTasks?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectRentaInfoBase"]: AliasType<{
	projectId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	rentaPercent?:boolean | `@${string}`,
	projectDurationSold?:boolean | `@${string}`,
	projectAmountSold?:boolean | `@${string}`,
	projectDuration?:boolean | `@${string}`,
	projectDeltaDurationProd?:boolean | `@${string}`,
	projectDeltaAmountProd?:boolean | `@${string}`,
	projectSommeTime?:boolean | `@${string}`,
	projectSommeAmount?:boolean | `@${string}`,
	projectSommeAmountSpend?:boolean | `@${string}`,
	projectDurationUsed?:boolean | `@${string}`,
	projectAmountUsed?:boolean | `@${string}`,
	projectDeltaDurationSold?:boolean | `@${string}`,
	projectDeltaSommeSold?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProjectRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManager?:ResolverInputTypes["Users"],
	superProjectId?:boolean | `@${string}`,
	renta?:ResolverInputTypes["ProjectRentaInfoBase"],
	tasks?:ResolverInputTypes["TaskRentaInfo"],
	devisInit?:ResolverInputTypes["DevisInitRentaInfo"],
	supp?:ResolverInputTypes["SuppRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectInfoForSuperProject"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskPlanning"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	task?:ResolverInputTypes["Task"],
	taskId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskPlanningInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimeForUserInfo"]: AliasType<{
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Time"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	userId?:boolean | `@${string}`,
	task?:ResolverInputTypes["Task"],
	taskId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimeInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	userId?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
	task?:ResolverInputTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TimeShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	userId?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
	task?:ResolverInputTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TimeRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	userId?:boolean | `@${string}`,
	taskId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserTime"]: AliasType<{
	user?:ResolverInputTypes["Users"],
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateUserTime"]: AliasType<{
	date?:boolean | `@${string}`,
	totalTimeByDate?:boolean | `@${string}`,
	userTime?:ResolverInputTypes["UserTime"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectUserTimesInfo"]: AliasType<{
	projectId?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	projectManager?:ResolverInputTypes["Users"],
	calendar?:ResolverInputTypes["DateUserTime"],
		__typename?: boolean | `@${string}`
}>;
	["DatePlanningTimes"]: AliasType<{
	label?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	selectible?:boolean | `@${string}`,
	planningDuration?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	timeId?:boolean | `@${string}`,
	timeComment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningForUserInfo"]: AliasType<{
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningTimeForUserInfo"]: AliasType<{
	date?:boolean | `@${string}`,
	userPlanningDuration?:boolean | `@${string}`,
	timeDuration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	taskPlanningId?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	userId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	task?:ResolverInputTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TimeUserPlanningInfo"]: AliasType<{
	userPlanning?:ResolverInputTypes["UserPlanningInfo"],
	time?:ResolverInputTypes["TimeInfo"],
		__typename?: boolean | `@${string}`
}>;
	["UserTimePlanningInfo"]: AliasType<{
	userId?:boolean | `@${string}`,
	userFirstName?:boolean | `@${string}`,
	userLastName?:boolean | `@${string}`,
	userPlanning?:ResolverInputTypes["UserPlanningShortInfo"],
	time?:ResolverInputTypes["TimeShortInfo"],
		__typename?: boolean | `@${string}`
}>;
	["UserPlanningShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	taskPlanningId?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	userId?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	task?:ResolverInputTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["Calendar"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	selectible?:boolean | `@${string}`,
	retroChecked?:boolean | `@${string}`,
	checked?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Task"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	durationDays?:boolean | `@${string}`,
	durationClientDays?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	stateType?:boolean | `@${string}`,
	statePercent?:boolean | `@${string}`,
	devis?:ResolverInputTypes["Devis"],
	devisId?:boolean | `@${string}`,
	devisInit?:ResolverInputTypes["DevisInit"],
	devisInitId?:boolean | `@${string}`,
	project?:ResolverInputTypes["Project"],
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	user?:ResolverInputTypes["Users"],
	notifications?:ResolverInputTypes["Notification"],
	time?:ResolverInputTypes["Time"],
	taskPlanning?:ResolverInputTypes["TaskPlanning"],
		__typename?: boolean | `@${string}`
}>;
	["FunctionType"]:FunctionType;
	["StateType"]:StateType;
	["TaskInfoForDevis"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	durationDays?:boolean | `@${string}`,
	durationClientDays?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	devisInitId?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	taskPlanning?:ResolverInputTypes["TaskPlanningInfo"],
	posteId?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	user?:ResolverInputTypes["UserInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["TaskInfoForPlanning"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	startDateInit?:boolean | `@${string}`,
	durationDays?:boolean | `@${string}`,
	durationClientDays?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	devisInitId?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	taskPlanning?:ResolverInputTypes["TaskPlanningInfo"],
	posteId?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	user?:ResolverInputTypes["UserInfoForDevis"],
	calendar?:ResolverInputTypes["Calendar"],
		__typename?: boolean | `@${string}`
}>;
	["Planning"]: AliasType<{
	calendar?:ResolverInputTypes["Calendar"],
	tasks?:ResolverInputTypes["TaskInfoForPlanning"],
	tasksSupp?:ResolverInputTypes["TaskInfoForPlanning"],
		__typename?: boolean | `@${string}`
}>;
	["TaskShortInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	devisInitId?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	user?:ResolverInputTypes["UserInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["UserRentaInfo"]: AliasType<{
	userId?:boolean | `@${string}`,
	userFirstName?:boolean | `@${string}`,
	userLastName?:boolean | `@${string}`,
	planningDuration?:boolean | `@${string}`,
	sommeTime?:boolean | `@${string}`,
	deltaDuration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TaskRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	amountSpend?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	visibleInRetro?:boolean | `@${string}`,
	calculatedAsAmount?:boolean | `@${string}`,
	supp?:boolean | `@${string}`,
	projectId?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	deltaDuration?:boolean | `@${string}`,
	sommeTime?:boolean | `@${string}`,
	time?:ResolverInputTypes["TimeRentaInfo"],
	userRentaInfo?:ResolverInputTypes["UserRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["TaskForUserInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	projectName?:boolean | `@${string}`,
	projectType?:boolean | `@${string}`,
	projectManagerFirstName?:boolean | `@${string}`,
	projectManagerLastName?:boolean | `@${string}`,
	allUserPlanning?:boolean | `@${string}`,
	userPlanning?:ResolverInputTypes["UserPlanningForUserInfo"],
	allTime?:boolean | `@${string}`,
	time?:ResolverInputTypes["TimeForUserInfo"],
	timeAndUserPlanning?:ResolverInputTypes["UserPlanningTimeForUserInfo"],
		__typename?: boolean | `@${string}`
}>;
	["DevisInit"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	durationExpected?:boolean | `@${string}`,
	durationUsed?:boolean | `@${string}`,
	amountUsed?:boolean | `@${string}`,
	devis?:ResolverInputTypes["Devis"],
	devisId?:boolean | `@${string}`,
	poste?:ResolverInputTypes["Poste"],
	posteId?:boolean | `@${string}`,
	customPosteName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevisInitInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	durationExpected?:boolean | `@${string}`,
	durationUsed?:boolean | `@${string}`,
	amountUsed?:boolean | `@${string}`,
	devisId?:boolean | `@${string}`,
	poste?:ResolverInputTypes["Poste"],
	posteId?:boolean | `@${string}`,
	customPosteName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevisInitRentaInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	functionType?:boolean | `@${string}`,
	amount?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	durationExpected?:boolean | `@${string}`,
	durationUsed?:boolean | `@${string}`,
	amountUsed?:boolean | `@${string}`,
	customPosteName?:boolean | `@${string}`,
	durationByTasks?:boolean | `@${string}`,
	timesByTasks?:boolean | `@${string}`,
	amountByTasks?:boolean | `@${string}`,
	amountSpendByTasks?:boolean | `@${string}`,
	deltaDurationProd?:boolean | `@${string}`,
	deltaAmountProd?:boolean | `@${string}`,
	deltaDurationSold?:boolean | `@${string}`,
	deltaSommeSold?:boolean | `@${string}`,
	poste?:ResolverInputTypes["Poste"],
	tasks?:ResolverInputTypes["TaskRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["Devis"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	project?:ResolverInputTypes["Project"],
	projectId?:boolean | `@${string}`,
	signedDate?:boolean | `@${string}`,
	finishDate?:boolean | `@${string}`,
	tasks?:ResolverInputTypes["Task"],
	devisInit?:ResolverInputTypes["DevisInit"],
		__typename?: boolean | `@${string}`
}>;
	["DevisType"]:DevisType;
	["DevisForProjectInit"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	signedDate?:boolean | `@${string}`,
	finishDate?:boolean | `@${string}`,
	project?:ResolverInputTypes["ProjectInfo"],
	devisInit?:ResolverInputTypes["DevisInitInfo"],
		__typename?: boolean | `@${string}`
}>;
	["DevisForProjectProd"]: AliasType<{
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	signedDate?:boolean | `@${string}`,
	finishDate?:boolean | `@${string}`,
	project?:ResolverInputTypes["ProjectInfo"],
	tasks?:ResolverInputTypes["TaskInfoForDevis"],
		__typename?: boolean | `@${string}`
}>;
	["DevisForProject"]: AliasType<{
	devisInit?:ResolverInputTypes["DevisForProjectInit"],
	devisProd?:ResolverInputTypes["DevisForProjectProd"],
		__typename?: boolean | `@${string}`
}>;
	["DownloadFile"]: AliasType<{
	fileName?:boolean | `@${string}`,
	fileMimeType?:boolean | `@${string}`,
	buffer?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MyFiles"]: AliasType<{
	eTag?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
	fileMimeType?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	ownerId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Function"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Holidays"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mail"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	to?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuperProject"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projects?:ResolverInputTypes["ProjectInfoForSuperProject"],
		__typename?: boolean | `@${string}`
}>;
	["SuperProjectInfoPagination"]: AliasType<{
	projects?:ResolverInputTypes["SuperProject"],
	total?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuperProjectRentaDetailsInfo"]: AliasType<{
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	projects?:ResolverInputTypes["ProjectInfoForSuperProject"],
	renta?:ResolverInputTypes["ProjectRentaInfoBase"],
	projectsRenta?:ResolverInputTypes["ProjectRentaInfo"],
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
	getHealthGql?:ResolverInputTypes["Health"],
getRole?: [{	id: number},ResolverInputTypes["Roles"]],
	getRoles?:ResolverInputTypes["Roles"],
getPermission?: [{	id: number},ResolverInputTypes["Permissions"]],
	getPermissions?:ResolverInputTypes["Permissions"],
getConfig?: [{	id: number},ResolverInputTypes["Configs"]],
	getConfigs?:ResolverInputTypes["Configs"],
getMail?: [{	id: number},ResolverInputTypes["Mail"]],
	getMails?:ResolverInputTypes["Mail"],
	sendMail?:boolean | `@${string}`,
loginAsGuest?: [{	email: string},ResolverInputTypes["AuthType"]],
login?: [{	email: string,	password: string},ResolverInputTypes["AuthType"]],
uniqueAuthFromSso?: [{	code: string},ResolverInputTypes["UniqueAuth"]],
getUser?: [{	id: number},ResolverInputTypes["Users"]],
	getUsers?:ResolverInputTypes["Users"],
getFilteredUsers?: [{	findManyUserWithFiltersInput: ResolverInputTypes["FindManyUserWithFiltersInput"]},ResolverInputTypes["UserPagination"]],
downloadFile?: [{	eTag: string},ResolverInputTypes["DownloadFile"]],
	getAllMyFiles?:ResolverInputTypes["MyFiles"],
getFilteredProjectsInfo?: [{	filterProjectInput: ResolverInputTypes["FilterProjectInput"]},ResolverInputTypes["ProjectInfoPagination"]],
	getAllProjectByManager?:ResolverInputTypes["ProjectInfoForSuperProject"],
getAllProjectsWithTasks?: [{	takeAll: boolean},ResolverInputTypes["ProjectShortInfo"]],
getOneProject?: [{	id: number},ResolverInputTypes["ProjectFullInfo"]],
getRentaByProjectId?: [{	id: number},ResolverInputTypes["ProjectRentaInfo"]],
getFilteredSuperProjectsInfo?: [{	filterProjectInput: ResolverInputTypes["FilterSuperProjectInput"]},ResolverInputTypes["SuperProjectInfoPagination"]],
getOneSuperProject?: [{	id: number},ResolverInputTypes["SuperProject"]],
getRentaBySuperProjectId?: [{	id: number},ResolverInputTypes["SuperProjectRentaDetailsInfo"]],
getDevisByProjectId?: [{	id: number},ResolverInputTypes["DevisForProject"]],
getFunction?: [{	id: number},ResolverInputTypes["Function"]],
	getFunctions?:ResolverInputTypes["Function"],
isUsersCanBeChangedInTask?: [{	createOrUpdateUsersInTaskInput: ResolverInputTypes["CreateOrUpdateUsersInTaskInput"]},ResolverInputTypes["DevisForProjectProd"]],
getTaskListByProjectId?: [{	id: number},ResolverInputTypes["TaskShortInfo"]],
getTasksInternByProjectId?: [{	id: number},ResolverInputTypes["TaskShortInfo"]],
getTasksInfoByUserId?: [{	id: number},ResolverInputTypes["TaskForUserInfo"]],
getPlanningByProjectId?: [{	id: number},ResolverInputTypes["Planning"]],
getPlanningForPeriodByUserId?: [{	filterUserPlanningInput: ResolverInputTypes["FilterUserPlanningInput"]},ResolverInputTypes["UserPlanningInfo"]],
getTimesUserPlanningsForPeriodByUserId?: [{	filterUserPlanningInput: ResolverInputTypes["FilterUserPlanningInput"]},ResolverInputTypes["TimeUserPlanningInfo"]],
getAllTimesUserPlanningsForPeriodByProjectId?: [{	filterTimeUserPlanningByProjectInput: ResolverInputTypes["FilterTimeUserPlanningByProjectInput"]},ResolverInputTypes["TimeUserPlanningInfo"]],
getTimesUserPlanningsForPeriodByProjectId?: [{	filterTimeUserPlanningByProjectInput: ResolverInputTypes["FilterTimeUserPlanningByProjectInput"]},ResolverInputTypes["UserTimePlanningInfo"]],
getTimesForPeriodByUserId?: [{	filterTimeInput: ResolverInputTypes["FilterTimeInput"]},ResolverInputTypes["TimeInfo"]],
getExportUserTimesForPeriodByProjectId?: [{	filterTimeUserPlanningByProjectInput: ResolverInputTypes["FilterTimeUserPlanningByProjectInput"]},ResolverInputTypes["ProjectUserTimesInfo"]],
getHolidaysForPeriod?: [{	filterHolidaysInput: ResolverInputTypes["FilterHolidaysInput"]},ResolverInputTypes["Holidays"]],
	getAllHolidays?:ResolverInputTypes["Holidays"],
getPoste?: [{	id: number},ResolverInputTypes["Poste"]],
	getPostes?:ResolverInputTypes["Poste"],
		__typename?: boolean | `@${string}`
}>;
	["AuthType"]: AliasType<{
	Tokens?:ResolverInputTypes["Tokens"],
	CustomError?:ResolverInputTypes["CustomError"],
		__typename?: boolean | `@${string}`
}>;
	["Tokens"]: AliasType<{
	access_token?:boolean | `@${string}`,
	refresh_token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomError"]: AliasType<{
	error?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EError"]:EError;
	["FindManyUserWithFiltersInput"]: {
	skip: number,
	take: number,
	searchInput: string,
	orderBy?: ResolverInputTypes["UserOrderByInput"] | undefined | null,
	filterBy?: ResolverInputTypes["UserFilterByInput"] | undefined | null,
	adminIncluded?: boolean | undefined | null
};
	["UserOrderByInput"]: {
	email?: ResolverInputTypes["OrderByEnum"] | undefined | null,
	firstName?: ResolverInputTypes["OrderByEnum"] | undefined | null,
	lastName?: ResolverInputTypes["OrderByEnum"] | undefined | null,
	lastConnectionAt?: ResolverInputTypes["OrderByEnum"] | undefined | null,
	role?: ResolverInputTypes["OrderByEnum"] | undefined | null
};
	["OrderByEnum"]:OrderByEnum;
	["UserFilterByInput"]: {
	role?: Array<number> | undefined | null
};
	["FilterProjectInput"]: {
	skip?: number | undefined | null,
	take?: number | undefined | null,
	userId?: number | undefined | null,
	userRole?: string | undefined | null,
	searchInProjectName?: string | undefined | null,
	orderBy?: ResolverInputTypes["ProjectOrderByInput"] | undefined | null,
	projectType: ResolverInputTypes["ProjectType"]
};
	["ProjectOrderByInput"]: {
	name?: ResolverInputTypes["OrderByEnum"] | undefined | null
};
	["FilterSuperProjectInput"]: {
	skip?: number | undefined | null,
	take?: number | undefined | null,
	userId?: number | undefined | null,
	userRole?: string | undefined | null,
	searchInSuperProjectName?: string | undefined | null,
	orderBy?: ResolverInputTypes["SuperProjectOrderByInput"] | undefined | null
};
	["SuperProjectOrderByInput"]: {
	name?: ResolverInputTypes["OrderByEnum"] | undefined | null
};
	["CreateOrUpdateUsersInTaskInput"]: {
	id: number,
	users: Array<number>
};
	["FilterUserPlanningInput"]: {
	userId: number,
	startDate: ResolverInputTypes["DateTime"],
	endDate: ResolverInputTypes["DateTime"]
};
	["FilterTimeUserPlanningByProjectInput"]: {
	projectId: number,
	startDate: ResolverInputTypes["DateTime"],
	endDate: ResolverInputTypes["DateTime"]
};
	["FilterTimeInput"]: {
	userId: number,
	startDate: ResolverInputTypes["DateTime"],
	endDate: ResolverInputTypes["DateTime"]
};
	["FilterHolidaysInput"]: {
	startDate: ResolverInputTypes["DateTime"],
	endDate: ResolverInputTypes["DateTime"]
};
	["Mutation"]: AliasType<{
askForResetPassword?: [{	email: string},boolean | `@${string}`],
resetPassword?: [{	email: string,	password: string,	token: string},boolean | `@${string}`],
verifyEmail?: [{	email: string,	token: string},boolean | `@${string}`],
register?: [{	email: string,	password: string,	firstName: string,	lastName: string},ResolverInputTypes["AuthType"]],
askForRegister?: [{	registerUserInput: ResolverInputTypes["RegisterUserInput"]},boolean | `@${string}`],
finishRegister?: [{	email: string,	password: string,	firstName: string,	lastName: string,	token: string},boolean | `@${string}`],
finishRegisterAsGuest?: [{	email: string,	password: string,	firstName: string,	lastName: string},boolean | `@${string}`],
	refresh?:ResolverInputTypes["AuthType"],
	logout?:boolean | `@${string}`,
createUser?: [{	createUserInput: ResolverInputTypes["CreateUserInput"]},ResolverInputTypes["Users"]],
updateUser?: [{	updateUserInput: ResolverInputTypes["UpdateUserInput"]},ResolverInputTypes["Users"]],
deleteUser?: [{	id: number},boolean | `@${string}`],
deleteFile?: [{	fileId: number},boolean | `@${string}`],
uploadFile?: [{	file: ResolverInputTypes["Upload"]},boolean | `@${string}`],
createProject?: [{	createProjectInput: ResolverInputTypes["CreateProjectInput"]},boolean | `@${string}`],
createProjectIntern?: [{	createProjectInput: ResolverInputTypes["CreateProjectInput"]},boolean | `@${string}`],
updateProject?: [{	updateProjectInput: ResolverInputTypes["UpdateProjectInput"]},boolean | `@${string}`],
setRetroPlanningDone?: [{	id: number},boolean | `@${string}`],
setPlanningDone?: [{	id: number},boolean | `@${string}`],
updateRetroPlanningDone?: [{	id: number,	retroPlanningDone: boolean},boolean | `@${string}`],
updatePlanningDone?: [{	id: number,	planningDone: boolean},boolean | `@${string}`],
deleteProject?: [{	id: number},boolean | `@${string}`],
createSuperProject?: [{	createSuperProjectInput: ResolverInputTypes["CreateSuperProjectInput"]},boolean | `@${string}`],
updateSuperProject?: [{	updateProjectInput: ResolverInputTypes["UpdateSuperProjectInput"]},boolean | `@${string}`],
deleteSuperProject?: [{	id: number},boolean | `@${string}`],
createDevisInitial?: [{	createDevisInput: ResolverInputTypes["CreateDevisInput"]},boolean | `@${string}`],
createDevisProd?: [{	createDevisProdInput: ResolverInputTypes["CreateDevisProdInput"]},boolean | `@${string}`],
updateDevisInitial?: [{	updateDevisInput: ResolverInputTypes["UpdateDevisInput"]},boolean | `@${string}`],
updateDevisProd?: [{	updateDevisProdInput: ResolverInputTypes["UpdateDevisProdInput"]},boolean | `@${string}`],
updateDevisProdDate?: [{	updateDevisProdDateInput: ResolverInputTypes["UpdateDevisProdDateInput"]},boolean | `@${string}`],
updateDevisInitDurationExpected?: [{	updateDevisInitDurationExpectedInput: Array<ResolverInputTypes["UpdateDevisInitDurationExpectedInput"]>},boolean | `@${string}`],
createDevisInit?: [{	createDevisInitInput: ResolverInputTypes["CreateDevisInitInput"]},boolean | `@${string}`],
updateTaskOrder?: [{	updateTaskOrderInput: Array<ResolverInputTypes["UpdateTaskOrderInput"]>},ResolverInputTypes["DevisForProjectProd"]],
updateTaskSupp?: [{	updateTaskSuppInput: ResolverInputTypes["UpdateTaskSuppInput"]},ResolverInputTypes["DevisForProjectProd"]],
updateTaskRetro?: [{	updateTaskRetroInput: ResolverInputTypes["UpdateTaskRetroInput"]},ResolverInputTypes["DevisForProjectProd"]],
createOrUpdateUsersInTaskByFunctionType?: [{	createOrUpdateUsersByFunctionTypeInput: ResolverInputTypes["CreateOrUpdateUsersByFunctionTypeInput"]},ResolverInputTypes["DevisForProjectProd"]],
createOrUpdateUsersInTask?: [{	createOrUpdateUsersInTaskInput: ResolverInputTypes["CreateOrUpdateUsersInTaskInput"]},ResolverInputTypes["DevisForProjectProd"]],
createOrUpdateUsersInTaskIntern?: [{	createOrUpdateUsersInTaskInput: ResolverInputTypes["CreateOrUpdateUsersInTaskInput"]},boolean | `@${string}`],
createTaskSupp?: [{	createTaskSuppInput: ResolverInputTypes["CreateTaskSuppInput"]},ResolverInputTypes["DevisForProjectProd"]],
createTaskIntern?: [{	createTaskInternInput: ResolverInputTypes["CreateTaskInternInput"]},boolean | `@${string}`],
deleteTask?: [{	id: number},ResolverInputTypes["DevisForProjectProd"]],
deleteTaskIntern?: [{	id: number},boolean | `@${string}`],
createOrUpdateTaskPlanning?: [{	updateTaskPlanningInput: ResolverInputTypes["UpdateTaskPlanningInput"]},ResolverInputTypes["DevisForProjectProd"]],
createUserPlanning?: [{	createUserPlanningInput: ResolverInputTypes["CreateUserPlanningInput"]},ResolverInputTypes["DevisForProjectProd"]],
deleteUserPlanning?: [{	id: number},boolean | `@${string}`],
createOrUpdateTime?: [{	createOrUpdateTimeInput: ResolverInputTypes["CreateOrUpdateTimeInput"]},boolean | `@${string}`],
deleteTime?: [{	id: number},boolean | `@${string}`],
createHolidays?: [{	createHolidaysInput: ResolverInputTypes["CreateHolidaysInput"]},boolean | `@${string}`],
deleteHolidays?: [{	id: number},boolean | `@${string}`],
updateHolidaysForPeriod?: [{	updateHolidaysInput: ResolverInputTypes["UpdateHolidaysInput"]},ResolverInputTypes["Holidays"]],
		__typename?: boolean | `@${string}`
}>;
	["RegisterUserInput"]: {
	email: string,
	roleId: number
};
	["CreateUserInput"]: {
	email: string,
	password: string,
	firstName: string,
	lastName: string,
	roleId: number
};
	["UpdateUserInput"]: {
	email?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	roleId?: number | undefined | null,
	id: number
};
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:unknown;
	["CreateProjectInput"]: {
	name: string,
	projectManagerId: number,
	marginPercent?: number | undefined | null,
	projectType?: ResolverInputTypes["ProjectType"] | undefined | null
};
	["UpdateProjectInput"]: {
	name?: string | undefined | null,
	projectManagerId?: number | undefined | null,
	marginPercent?: number | undefined | null,
	projectType?: ResolverInputTypes["ProjectType"] | undefined | null,
	id: number
};
	["CreateSuperProjectInput"]: {
	name: string,
	projectIds?: Array<number> | undefined | null
};
	["UpdateSuperProjectInput"]: {
	name?: string | undefined | null,
	projectIds?: Array<number> | undefined | null,
	id: number
};
	["CreateDevisInput"]: {
	projectId: number,
	type: ResolverInputTypes["DevisType"],
	signedDate?: ResolverInputTypes["DateTime"] | undefined | null,
	createDevisInitInput: Array<ResolverInputTypes["CreateDevisInitInput"]>
};
	["CreateDevisInitInput"]: {
	id?: number | undefined | null,
	devisId?: number | undefined | null,
	functionType: ResolverInputTypes["FunctionType"],
	amount: number,
	duration: number,
	durationExpected: number,
	durationUsed?: number | undefined | null,
	amountUsed?: number | undefined | null,
	posteId: number,
	order?: number | undefined | null,
	customPosteName?: string | undefined | null
};
	["CreateDevisProdInput"]: {
	projectId: number,
	type: ResolverInputTypes["DevisType"],
	signedDate?: ResolverInputTypes["DateTime"] | undefined | null,
	finishDate?: ResolverInputTypes["DateTime"] | undefined | null,
	createTaskInput: Array<ResolverInputTypes["CreateTaskInput"]>
};
	["CreateTaskInput"]: {
	devisId?: number | undefined | null,
	projectId: number,
	name: string,
	functionType: ResolverInputTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined | null,
	order?: number | undefined | null,
	duration: number,
	devisInitId?: number | undefined | null,
	durationDays?: number | undefined | null,
	durationClientDays?: number | undefined | null,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean
};
	["UpdateDevisInput"]: {
	id: number,
	signedDate?: ResolverInputTypes["DateTime"] | undefined | null,
	createDevisInitInput: Array<ResolverInputTypes["CreateDevisInitInput"]>
};
	["UpdateDevisProdInput"]: {
	id: number,
	signedDate?: ResolverInputTypes["DateTime"] | undefined | null,
	finishDate?: ResolverInputTypes["DateTime"] | undefined | null,
	updateTaskInput: Array<ResolverInputTypes["UpdateTaskInput"]>
};
	["UpdateTaskInput"]: {
	devisId?: number | undefined | null,
	projectId: number,
	name: string,
	functionType: ResolverInputTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined | null,
	order?: number | undefined | null,
	duration: number,
	devisInitId?: number | undefined | null,
	durationDays?: number | undefined | null,
	durationClientDays?: number | undefined | null,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	id?: number | undefined | null,
	stateType?: ResolverInputTypes["StateType"] | undefined | null
};
	["UpdateDevisProdDateInput"]: {
	id: number,
	signedDate?: ResolverInputTypes["DateTime"] | undefined | null,
	finishDate?: ResolverInputTypes["DateTime"] | undefined | null
};
	["UpdateDevisInitDurationExpectedInput"]: {
	id: number,
	durationExpected: number
};
	["UpdateTaskOrderInput"]: {
	id: number,
	order?: number | undefined | null
};
	["UpdateTaskSuppInput"]: {
	id: number,
	name?: string | undefined | null,
	functionType: ResolverInputTypes["FunctionType"],
	amount?: number | undefined | null,
	amountSpend?: number | undefined | null,
	duration?: number | undefined | null
};
	["UpdateTaskRetroInput"]: {
	id: number,
	startDateInit: number,
	durationDays?: number | undefined | null
};
	["CreateOrUpdateUsersByFunctionTypeInput"]: {
	projectId: number,
	usersByFunctionType: Array<ResolverInputTypes["UsersByFunctionTypeInput"]>
};
	["UsersByFunctionTypeInput"]: {
	functionType: ResolverInputTypes["FunctionType"],
	usersIds: Array<number>
};
	["CreateTaskSuppInput"]: {
	projectId: number,
	name: string,
	functionType: ResolverInputTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined | null,
	duration: number,
	supp?: boolean | undefined | null
};
	["CreateTaskInternInput"]: {
	projectId: number,
	name: string
};
	["UpdateTaskPlanningInput"]: {
	projectId: number,
	taskId: number,
	dates: Array<ResolverInputTypes["UpdateDatePlanningInput"]>
};
	["UpdateDatePlanningInput"]: {
	id?: number | undefined | null,
	date: ResolverInputTypes["DateTime"],
	duration: number
};
	["CreateUserPlanningInput"]: {
	userId: number,
	taskPlanningId: number,
	date: ResolverInputTypes["DateTime"],
	duration: number
};
	["CreateOrUpdateTimeInput"]: {
	id?: number | undefined | null,
	taskId: number,
	userId: number,
	date: ResolverInputTypes["DateTime"],
	duration: number,
	comment?: string | undefined | null
};
	["CreateHolidaysInput"]: {
	date: ResolverInputTypes["DateTime"]
};
	["UpdateHolidaysInput"]: {
	dates: Array<ResolverInputTypes["DateTime"]>,
	startDate: ResolverInputTypes["DateTime"],
	endDate: ResolverInputTypes["DateTime"]
};
	["schema"]: AliasType<{
	query?:ResolverInputTypes["Query"],
	mutation?:ResolverInputTypes["Mutation"],
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Health"]: {
		env: string,
	time: string,
	status: string
};
	["Permissions"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	roles: Array<ModelTypes["Roles"]>
};
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:any;
	["Users"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	status: ModelTypes["AccountStatus"],
	email: string,
	password: string,
	passwordResetToken?: string | undefined,
	passwordResetExpiryDate?: ModelTypes["DateTime"] | undefined,
	activateAccountToken?: string | undefined,
	activateAccountExpiryDate?: ModelTypes["DateTime"] | undefined,
	emailVerified: boolean,
	verifyEmailToken?: string | undefined,
	verifyEmailExpiryDate?: ModelTypes["DateTime"] | undefined,
	firstName: string,
	lastName: string,
	refreshToken?: string | undefined,
	role: ModelTypes["Roles"],
	roleId: number
};
	["AccountStatus"]:AccountStatus;
	["UserInfoForDevis"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	status: ModelTypes["AccountStatus"],
	email: string,
	password: string,
	passwordResetToken?: string | undefined,
	passwordResetExpiryDate?: ModelTypes["DateTime"] | undefined,
	activateAccountToken?: string | undefined,
	activateAccountExpiryDate?: ModelTypes["DateTime"] | undefined,
	emailVerified: boolean,
	verifyEmailToken?: string | undefined,
	verifyEmailExpiryDate?: ModelTypes["DateTime"] | undefined,
	firstName: string,
	lastName: string,
	refreshToken?: string | undefined,
	role: ModelTypes["Roles"],
	roleId: number,
	hasTimes: boolean
};
	["UserPagination"]: {
		users: Array<ModelTypes["Users"] | undefined>,
	total: number
};
	["UserInfo"]: {
		id: number,
	email: string,
	firstName: string,
	lastName: string,
	roleId: number,
	roleName?: string | undefined
};
	["Roles"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	permissions: Array<ModelTypes["Permissions"] | undefined>,
	users: Array<ModelTypes["Users"] | undefined>
};
	["UniqueAuth"]: {
		access_token: string,
	firstName: string,
	lastName: string,
	role: string,
	email: string,
	sub: number
};
	["Configs"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	key: string,
	value: string
};
	["Poste"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string
};
	["Notification"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	comment: string,
	state: ModelTypes["NotificationState"],
	user?: ModelTypes["Users"] | undefined,
	task?: ModelTypes["Task"] | undefined
};
	["NotificationState"]:NotificationState;
	["Project"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	projectType?: ModelTypes["ProjectType"] | undefined,
	projectManager?: ModelTypes["Users"] | undefined,
	projectManagerId: number,
	startDateInit?: ModelTypes["DateTime"] | undefined,
	startDatePlanning?: ModelTypes["DateTime"] | undefined,
	marginPercent?: number | undefined,
	retroPlanningDone: boolean,
	planningDone: boolean,
	superProject?: ModelTypes["Project"] | undefined,
	superProjectId?: number | undefined
};
	["ProjectType"]:ProjectType;
	["ProjectInfo"]: {
		id: number,
	name: string,
	projectType?: ModelTypes["ProjectType"] | undefined,
	projectManagerId: number,
	startDateInit?: ModelTypes["DateTime"] | undefined,
	startDatePlanning?: ModelTypes["DateTime"] | undefined,
	marginPercent?: number | undefined,
	retroPlanningDone: boolean,
	planningDone: boolean,
	superProjectId?: number | undefined,
	projectManagerName?: string | undefined
};
	["ProjectInfoPagination"]: {
		projects: Array<ModelTypes["ProjectInfo"] | undefined>,
	total: number
};
	["ProjectFullInfo"]: {
		id: number,
	name: string,
	projectType?: ModelTypes["ProjectType"] | undefined,
	projectManagerId: number,
	startDateInit?: ModelTypes["DateTime"] | undefined,
	startDatePlanning?: ModelTypes["DateTime"] | undefined,
	marginPercent?: number | undefined,
	retroPlanningDone: boolean,
	planningDone: boolean,
	superProjectId?: number | undefined,
	projectManager: ModelTypes["UserInfo"]
};
	["ProjectShortInfo"]: {
		id: number,
	name: string,
	projectType?: ModelTypes["ProjectType"] | undefined,
	superProjectId?: number | undefined,
	tasks: Array<ModelTypes["TaskShortInfo"]>
};
	["SuppRentaInfo"]: {
		amountByTasks: number,
	amountSpendByTasks: number,
	durationByTasks: number,
	timesByTasks: number
};
	["ProjectRentaInfoBase"]: {
		projectId: number,
	name: string,
	rentaPercent: number,
	projectDurationSold: number,
	projectAmountSold: number,
	projectDuration: number,
	projectDeltaDurationProd: number,
	projectDeltaAmountProd: number,
	projectSommeTime: number,
	projectSommeAmount: number,
	projectSommeAmountSpend: number,
	projectDurationUsed: number,
	projectAmountUsed: number,
	projectDeltaDurationSold: number,
	projectDeltaSommeSold: number
};
	["ProjectRentaInfo"]: {
		id: number,
	name: string,
	projectType?: ModelTypes["ProjectType"] | undefined,
	projectManager?: ModelTypes["Users"] | undefined,
	superProjectId?: number | undefined,
	renta: ModelTypes["ProjectRentaInfoBase"],
	tasks: Array<ModelTypes["TaskRentaInfo"]>,
	devisInit: Array<ModelTypes["DevisInitRentaInfo"]>,
	supp: ModelTypes["SuppRentaInfo"]
};
	["ProjectInfoForSuperProject"]: {
		id: number,
	name: string
};
	["TaskPlanning"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	task?: ModelTypes["Task"] | undefined,
	taskId: number,
	date: ModelTypes["DateTime"],
	duration: number
};
	["TaskPlanningInfo"]: {
		id: number,
	taskId: number,
	date: ModelTypes["DateTime"],
	duration: number
};
	["TimeForUserInfo"]: {
		date: ModelTypes["DateTime"],
	duration: number,
	comment: string
};
	["Time"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	comment: string,
	date: ModelTypes["DateTime"],
	duration: number,
	user?: ModelTypes["Users"] | undefined,
	userId: number,
	task?: ModelTypes["Task"] | undefined,
	taskId: number
};
	["TimeInfo"]: {
		id: number,
	comment: string,
	date: ModelTypes["DateTime"],
	duration: number,
	user?: ModelTypes["Users"] | undefined,
	userId: number,
	taskId: number,
	task?: ModelTypes["TaskInfoForDevis"] | undefined
};
	["TimeShortInfo"]: {
		id: number,
	comment: string,
	date: ModelTypes["DateTime"],
	duration: number,
	user?: ModelTypes["Users"] | undefined,
	userId: number,
	taskId: number,
	task?: ModelTypes["TaskInfoForDevis"] | undefined
};
	["TimeRentaInfo"]: {
		id: number,
	comment: string,
	date: ModelTypes["DateTime"],
	duration: number,
	user?: ModelTypes["Users"] | undefined,
	userId: number,
	taskId: number
};
	["UserTime"]: {
		user?: ModelTypes["Users"] | undefined,
	duration: number
};
	["DateUserTime"]: {
		date: ModelTypes["DateTime"],
	totalTimeByDate: number,
	userTime: Array<ModelTypes["UserTime"] | undefined>
};
	["ProjectUserTimesInfo"]: {
		projectId: number,
	projectName: string,
	projectManager: ModelTypes["Users"],
	calendar: Array<ModelTypes["DateUserTime"] | undefined>
};
	["DatePlanningTimes"]: {
		label?: string | undefined,
	date: ModelTypes["DateTime"],
	selectible: boolean,
	planningDuration?: number | undefined,
	time?: number | undefined,
	timeId?: number | undefined,
	timeComment?: string | undefined
};
	["UserPlanningForUserInfo"]: {
		date: ModelTypes["DateTime"],
	duration: number
};
	["UserPlanningTimeForUserInfo"]: {
		date: ModelTypes["DateTime"],
	userPlanningDuration: number,
	timeDuration: number
};
	["UserPlanningInfo"]: {
		id: number,
	taskPlanningId: number,
	user: ModelTypes["Users"],
	userId: number,
	date: ModelTypes["DateTime"],
	duration: number,
	task?: ModelTypes["TaskInfoForDevis"] | undefined
};
	["TimeUserPlanningInfo"]: {
		userPlanning: Array<ModelTypes["UserPlanningInfo"] | undefined>,
	time: Array<ModelTypes["TimeInfo"] | undefined>
};
	["UserTimePlanningInfo"]: {
		userId: number,
	userFirstName: string,
	userLastName: string,
	userPlanning: Array<ModelTypes["UserPlanningShortInfo"] | undefined>,
	time: Array<ModelTypes["TimeShortInfo"] | undefined>
};
	["UserPlanningShortInfo"]: {
		id: number,
	taskPlanningId: number,
	user: ModelTypes["Users"],
	userId: number,
	date: ModelTypes["DateTime"],
	duration: number,
	task: ModelTypes["TaskInfoForDevis"]
};
	["Calendar"]: {
		id?: number | undefined,
	date: ModelTypes["DateTime"],
	label: string,
	selectible: boolean,
	retroChecked: boolean,
	checked: boolean,
	duration: number
};
	["Task"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	startDateInit?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	stateType: ModelTypes["StateType"],
	statePercent: number,
	devis?: ModelTypes["Devis"] | undefined,
	devisId?: number | undefined,
	devisInit?: ModelTypes["DevisInit"] | undefined,
	devisInitId?: number | undefined,
	project?: ModelTypes["Project"] | undefined,
	projectId: number,
	order?: number | undefined,
	user?: Array<ModelTypes["Users"]> | undefined,
	notifications?: Array<ModelTypes["Notification"]> | undefined,
	time?: Array<ModelTypes["Time"]> | undefined,
	taskPlanning?: Array<ModelTypes["TaskPlanning"]> | undefined
};
	["FunctionType"]:FunctionType;
	["StateType"]:StateType;
	["TaskInfoForDevis"]: {
		id: number,
	name: string,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	startDateInit?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	devisId?: number | undefined,
	devisInitId?: number | undefined,
	projectId: number,
	order?: number | undefined,
	taskPlanning: Array<ModelTypes["TaskPlanningInfo"]>,
	posteId?: number | undefined,
	projectType?: ModelTypes["ProjectType"] | undefined,
	projectName: string,
	user?: Array<ModelTypes["UserInfoForDevis"]> | undefined
};
	["TaskInfoForPlanning"]: {
		id: number,
	name: string,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	startDateInit?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	devisId?: number | undefined,
	devisInitId?: number | undefined,
	projectId: number,
	order?: number | undefined,
	taskPlanning: Array<ModelTypes["TaskPlanningInfo"]>,
	posteId?: number | undefined,
	projectType?: ModelTypes["ProjectType"] | undefined,
	projectName: string,
	user?: Array<ModelTypes["UserInfoForDevis"]> | undefined,
	calendar: Array<ModelTypes["Calendar"]>
};
	["Planning"]: {
		calendar: Array<ModelTypes["Calendar"]>,
	tasks: Array<ModelTypes["TaskInfoForPlanning"]>,
	tasksSupp: Array<ModelTypes["TaskInfoForPlanning"]>
};
	["TaskShortInfo"]: {
		id: number,
	name: string,
	functionType: ModelTypes["FunctionType"],
	amountSpend?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	devisId?: number | undefined,
	devisInitId?: number | undefined,
	projectId: number,
	order?: number | undefined,
	user: Array<ModelTypes["UserInfoForDevis"]>
};
	["UserRentaInfo"]: {
		userId: number,
	userFirstName: string,
	userLastName: string,
	planningDuration: number,
	sommeTime: number,
	deltaDuration: number
};
	["TaskRentaInfo"]: {
		id: number,
	name: string,
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	projectId: number,
	order?: number | undefined,
	deltaDuration: number,
	sommeTime: number,
	time: Array<ModelTypes["TimeRentaInfo"]>,
	userRentaInfo: Array<ModelTypes["UserRentaInfo"]>
};
	["TaskForUserInfo"]: {
		id: number,
	name: string,
	order?: number | undefined,
	projectName: string,
	projectType?: ModelTypes["ProjectType"] | undefined,
	projectManagerFirstName: string,
	projectManagerLastName: string,
	allUserPlanning: number,
	userPlanning: Array<ModelTypes["UserPlanningForUserInfo"]>,
	allTime: number,
	time: Array<ModelTypes["TimeForUserInfo"]>,
	timeAndUserPlanning: Array<ModelTypes["UserPlanningTimeForUserInfo"]>
};
	["DevisInit"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	functionType: ModelTypes["FunctionType"],
	amount: number,
	duration: number,
	order?: number | undefined,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	devis?: ModelTypes["Devis"] | undefined,
	devisId: number,
	poste?: ModelTypes["Poste"] | undefined,
	posteId: number,
	customPosteName?: string | undefined
};
	["DevisInitInfo"]: {
		id: number,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	duration: number,
	order?: number | undefined,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	devisId: number,
	poste?: ModelTypes["Poste"] | undefined,
	posteId: number,
	customPosteName?: string | undefined
};
	["DevisInitRentaInfo"]: {
		id: number,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	duration: number,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	customPosteName?: string | undefined,
	durationByTasks: number,
	timesByTasks: number,
	amountByTasks: number,
	amountSpendByTasks: number,
	deltaDurationProd: number,
	deltaAmountProd: number,
	deltaDurationSold: number,
	deltaSommeSold: number,
	poste?: ModelTypes["Poste"] | undefined,
	tasks: Array<ModelTypes["TaskRentaInfo"]>
};
	["Devis"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	type: ModelTypes["DevisType"],
	project?: ModelTypes["Project"] | undefined,
	projectId: number,
	signedDate?: ModelTypes["DateTime"] | undefined,
	finishDate?: ModelTypes["DateTime"] | undefined,
	tasks?: Array<ModelTypes["Task"]> | undefined,
	devisInit?: Array<ModelTypes["DevisInit"]> | undefined
};
	["DevisType"]:DevisType;
	["DevisForProjectInit"]: {
		id: number,
	type: ModelTypes["DevisType"],
	signedDate?: ModelTypes["DateTime"] | undefined,
	finishDate?: ModelTypes["DateTime"] | undefined,
	project: ModelTypes["ProjectInfo"],
	devisInit?: Array<ModelTypes["DevisInitInfo"]> | undefined
};
	["DevisForProjectProd"]: {
		id: number,
	type: ModelTypes["DevisType"],
	signedDate?: ModelTypes["DateTime"] | undefined,
	finishDate?: ModelTypes["DateTime"] | undefined,
	project: ModelTypes["ProjectInfo"],
	tasks?: Array<ModelTypes["TaskInfoForDevis"]> | undefined
};
	["DevisForProject"]: {
		devisInit?: ModelTypes["DevisForProjectInit"] | undefined,
	devisProd?: ModelTypes["DevisForProjectProd"] | undefined
};
	["DownloadFile"]: {
		fileName: string,
	fileMimeType: string,
	buffer: string
};
	["MyFiles"]: {
		eTag: string,
	location: string,
	fileName: string,
	fileMimeType: string,
	createdAt: ModelTypes["DateTime"],
	ownerId: number
};
	["Function"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: ModelTypes["FunctionType"],
	order: number
};
	["Holidays"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	date: ModelTypes["DateTime"]
};
	["Mail"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	key: string,
	to: string,
	data: string
};
	["SuperProject"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	projects: Array<ModelTypes["ProjectInfoForSuperProject"]>
};
	["SuperProjectInfoPagination"]: {
		projects: Array<ModelTypes["SuperProject"] | undefined>,
	total: number
};
	["SuperProjectRentaDetailsInfo"]: {
		id: number,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	projects: Array<ModelTypes["ProjectInfoForSuperProject"]>,
	renta: ModelTypes["ProjectRentaInfoBase"],
	projectsRenta?: Array<ModelTypes["ProjectRentaInfo"]> | undefined
};
	["Query"]: {
		getHealthGql: ModelTypes["Health"],
	getRole: ModelTypes["Roles"],
	getRoles: Array<ModelTypes["Roles"]>,
	getPermission: ModelTypes["Permissions"],
	getPermissions: Array<ModelTypes["Permissions"]>,
	getConfig: ModelTypes["Configs"],
	getConfigs: Array<ModelTypes["Configs"]>,
	getMail: ModelTypes["Mail"],
	getMails: Array<ModelTypes["Mail"]>,
	sendMail: boolean,
	loginAsGuest: ModelTypes["AuthType"],
	login: ModelTypes["AuthType"],
	uniqueAuthFromSso: ModelTypes["UniqueAuth"],
	getUser: ModelTypes["Users"],
	getUsers: Array<ModelTypes["Users"]>,
	getFilteredUsers: ModelTypes["UserPagination"],
	downloadFile: ModelTypes["DownloadFile"],
	getAllMyFiles: Array<ModelTypes["MyFiles"]>,
	getFilteredProjectsInfo: ModelTypes["ProjectInfoPagination"],
	getAllProjectByManager: Array<ModelTypes["ProjectInfoForSuperProject"]>,
	getAllProjectsWithTasks: Array<ModelTypes["ProjectShortInfo"]>,
	getOneProject: ModelTypes["ProjectFullInfo"],
	getRentaByProjectId: ModelTypes["ProjectRentaInfo"],
	getFilteredSuperProjectsInfo: ModelTypes["SuperProjectInfoPagination"],
	getOneSuperProject: ModelTypes["SuperProject"],
	getRentaBySuperProjectId: ModelTypes["SuperProjectRentaDetailsInfo"],
	getDevisByProjectId: ModelTypes["DevisForProject"],
	getFunction: ModelTypes["Function"],
	getFunctions: Array<ModelTypes["Function"]>,
	isUsersCanBeChangedInTask: ModelTypes["DevisForProjectProd"],
	getTaskListByProjectId: Array<ModelTypes["TaskShortInfo"]>,
	getTasksInternByProjectId: Array<ModelTypes["TaskShortInfo"]>,
	getTasksInfoByUserId: Array<ModelTypes["TaskForUserInfo"]>,
	getPlanningByProjectId: ModelTypes["Planning"],
	getPlanningForPeriodByUserId: Array<ModelTypes["UserPlanningInfo"]>,
	getTimesUserPlanningsForPeriodByUserId: ModelTypes["TimeUserPlanningInfo"],
	getAllTimesUserPlanningsForPeriodByProjectId: ModelTypes["TimeUserPlanningInfo"],
	getTimesUserPlanningsForPeriodByProjectId: Array<ModelTypes["UserTimePlanningInfo"]>,
	getTimesForPeriodByUserId: Array<ModelTypes["TimeInfo"]>,
	getExportUserTimesForPeriodByProjectId: ModelTypes["ProjectUserTimesInfo"],
	getHolidaysForPeriod: Array<ModelTypes["Holidays"]>,
	getAllHolidays: Array<ModelTypes["Holidays"]>,
	getPoste: ModelTypes["Poste"],
	getPostes: Array<ModelTypes["Poste"]>
};
	["AuthType"]:ModelTypes["Tokens"] | ModelTypes["CustomError"];
	["Tokens"]: {
		access_token: string,
	refresh_token: string
};
	["CustomError"]: {
		error: ModelTypes["EError"],
	message: string
};
	["EError"]:EError;
	["FindManyUserWithFiltersInput"]: {
	skip: number,
	take: number,
	searchInput: string,
	orderBy?: ModelTypes["UserOrderByInput"] | undefined,
	filterBy?: ModelTypes["UserFilterByInput"] | undefined,
	adminIncluded?: boolean | undefined
};
	["UserOrderByInput"]: {
	email?: ModelTypes["OrderByEnum"] | undefined,
	firstName?: ModelTypes["OrderByEnum"] | undefined,
	lastName?: ModelTypes["OrderByEnum"] | undefined,
	lastConnectionAt?: ModelTypes["OrderByEnum"] | undefined,
	role?: ModelTypes["OrderByEnum"] | undefined
};
	["OrderByEnum"]:OrderByEnum;
	["UserFilterByInput"]: {
	role?: Array<number> | undefined
};
	["FilterProjectInput"]: {
	skip?: number | undefined,
	take?: number | undefined,
	userId?: number | undefined,
	userRole?: string | undefined,
	searchInProjectName?: string | undefined,
	orderBy?: ModelTypes["ProjectOrderByInput"] | undefined,
	projectType: ModelTypes["ProjectType"]
};
	["ProjectOrderByInput"]: {
	name?: ModelTypes["OrderByEnum"] | undefined
};
	["FilterSuperProjectInput"]: {
	skip?: number | undefined,
	take?: number | undefined,
	userId?: number | undefined,
	userRole?: string | undefined,
	searchInSuperProjectName?: string | undefined,
	orderBy?: ModelTypes["SuperProjectOrderByInput"] | undefined
};
	["SuperProjectOrderByInput"]: {
	name?: ModelTypes["OrderByEnum"] | undefined
};
	["CreateOrUpdateUsersInTaskInput"]: {
	id: number,
	users: Array<number>
};
	["FilterUserPlanningInput"]: {
	userId: number,
	startDate: ModelTypes["DateTime"],
	endDate: ModelTypes["DateTime"]
};
	["FilterTimeUserPlanningByProjectInput"]: {
	projectId: number,
	startDate: ModelTypes["DateTime"],
	endDate: ModelTypes["DateTime"]
};
	["FilterTimeInput"]: {
	userId: number,
	startDate: ModelTypes["DateTime"],
	endDate: ModelTypes["DateTime"]
};
	["FilterHolidaysInput"]: {
	startDate: ModelTypes["DateTime"],
	endDate: ModelTypes["DateTime"]
};
	["Mutation"]: {
		askForResetPassword: boolean,
	resetPassword: boolean,
	verifyEmail: boolean,
	register: ModelTypes["AuthType"],
	askForRegister: boolean,
	finishRegister: boolean,
	finishRegisterAsGuest: boolean,
	refresh: ModelTypes["AuthType"],
	logout: boolean,
	createUser: ModelTypes["Users"],
	updateUser: ModelTypes["Users"],
	deleteUser: boolean,
	deleteFile: boolean,
	uploadFile: boolean,
	createProject: boolean,
	createProjectIntern: boolean,
	updateProject: boolean,
	setRetroPlanningDone: boolean,
	setPlanningDone: boolean,
	updateRetroPlanningDone: boolean,
	updatePlanningDone: boolean,
	deleteProject: boolean,
	createSuperProject: boolean,
	updateSuperProject: boolean,
	deleteSuperProject: boolean,
	createDevisInitial: boolean,
	createDevisProd: boolean,
	updateDevisInitial: boolean,
	updateDevisProd: boolean,
	updateDevisProdDate: boolean,
	updateDevisInitDurationExpected: boolean,
	createDevisInit: boolean,
	updateTaskOrder: ModelTypes["DevisForProjectProd"],
	updateTaskSupp: ModelTypes["DevisForProjectProd"],
	updateTaskRetro: ModelTypes["DevisForProjectProd"],
	createOrUpdateUsersInTaskByFunctionType: ModelTypes["DevisForProjectProd"],
	createOrUpdateUsersInTask: ModelTypes["DevisForProjectProd"],
	createOrUpdateUsersInTaskIntern: boolean,
	createTaskSupp: ModelTypes["DevisForProjectProd"],
	createTaskIntern: boolean,
	deleteTask: ModelTypes["DevisForProjectProd"],
	deleteTaskIntern: boolean,
	createOrUpdateTaskPlanning: ModelTypes["DevisForProjectProd"],
	createUserPlanning: ModelTypes["DevisForProjectProd"],
	deleteUserPlanning: boolean,
	createOrUpdateTime: boolean,
	deleteTime: boolean,
	createHolidays: boolean,
	deleteHolidays: boolean,
	updateHolidaysForPeriod: Array<ModelTypes["Holidays"]>
};
	["RegisterUserInput"]: {
	email: string,
	roleId: number
};
	["CreateUserInput"]: {
	email: string,
	password: string,
	firstName: string,
	lastName: string,
	roleId: number
};
	["UpdateUserInput"]: {
	email?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	roleId?: number | undefined,
	id: number
};
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:any;
	["CreateProjectInput"]: {
	name: string,
	projectManagerId: number,
	marginPercent?: number | undefined,
	projectType?: ModelTypes["ProjectType"] | undefined
};
	["UpdateProjectInput"]: {
	name?: string | undefined,
	projectManagerId?: number | undefined,
	marginPercent?: number | undefined,
	projectType?: ModelTypes["ProjectType"] | undefined,
	id: number
};
	["CreateSuperProjectInput"]: {
	name: string,
	projectIds?: Array<number> | undefined
};
	["UpdateSuperProjectInput"]: {
	name?: string | undefined,
	projectIds?: Array<number> | undefined,
	id: number
};
	["CreateDevisInput"]: {
	projectId: number,
	type: ModelTypes["DevisType"],
	signedDate?: ModelTypes["DateTime"] | undefined,
	createDevisInitInput: Array<ModelTypes["CreateDevisInitInput"]>
};
	["CreateDevisInitInput"]: {
	id?: number | undefined,
	devisId?: number | undefined,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	duration: number,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	posteId: number,
	order?: number | undefined,
	customPosteName?: string | undefined
};
	["CreateDevisProdInput"]: {
	projectId: number,
	type: ModelTypes["DevisType"],
	signedDate?: ModelTypes["DateTime"] | undefined,
	finishDate?: ModelTypes["DateTime"] | undefined,
	createTaskInput: Array<ModelTypes["CreateTaskInput"]>
};
	["CreateTaskInput"]: {
	devisId?: number | undefined,
	projectId: number,
	name: string,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	order?: number | undefined,
	duration: number,
	devisInitId?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean
};
	["UpdateDevisInput"]: {
	id: number,
	signedDate?: ModelTypes["DateTime"] | undefined,
	createDevisInitInput: Array<ModelTypes["CreateDevisInitInput"]>
};
	["UpdateDevisProdInput"]: {
	id: number,
	signedDate?: ModelTypes["DateTime"] | undefined,
	finishDate?: ModelTypes["DateTime"] | undefined,
	updateTaskInput: Array<ModelTypes["UpdateTaskInput"]>
};
	["UpdateTaskInput"]: {
	devisId?: number | undefined,
	projectId: number,
	name: string,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	order?: number | undefined,
	duration: number,
	devisInitId?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	id?: number | undefined,
	stateType?: ModelTypes["StateType"] | undefined
};
	["UpdateDevisProdDateInput"]: {
	id: number,
	signedDate?: ModelTypes["DateTime"] | undefined,
	finishDate?: ModelTypes["DateTime"] | undefined
};
	["UpdateDevisInitDurationExpectedInput"]: {
	id: number,
	durationExpected: number
};
	["UpdateTaskOrderInput"]: {
	id: number,
	order?: number | undefined
};
	["UpdateTaskSuppInput"]: {
	id: number,
	name?: string | undefined,
	functionType: ModelTypes["FunctionType"],
	amount?: number | undefined,
	amountSpend?: number | undefined,
	duration?: number | undefined
};
	["UpdateTaskRetroInput"]: {
	id: number,
	startDateInit: number,
	durationDays?: number | undefined
};
	["CreateOrUpdateUsersByFunctionTypeInput"]: {
	projectId: number,
	usersByFunctionType: Array<ModelTypes["UsersByFunctionTypeInput"]>
};
	["UsersByFunctionTypeInput"]: {
	functionType: ModelTypes["FunctionType"],
	usersIds: Array<number>
};
	["CreateTaskSuppInput"]: {
	projectId: number,
	name: string,
	functionType: ModelTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	supp?: boolean | undefined
};
	["CreateTaskInternInput"]: {
	projectId: number,
	name: string
};
	["UpdateTaskPlanningInput"]: {
	projectId: number,
	taskId: number,
	dates: Array<ModelTypes["UpdateDatePlanningInput"]>
};
	["UpdateDatePlanningInput"]: {
	id?: number | undefined,
	date: ModelTypes["DateTime"],
	duration: number
};
	["CreateUserPlanningInput"]: {
	userId: number,
	taskPlanningId: number,
	date: ModelTypes["DateTime"],
	duration: number
};
	["CreateOrUpdateTimeInput"]: {
	id?: number | undefined,
	taskId: number,
	userId: number,
	date: ModelTypes["DateTime"],
	duration: number,
	comment?: string | undefined
};
	["CreateHolidaysInput"]: {
	date: ModelTypes["DateTime"]
};
	["UpdateHolidaysInput"]: {
	dates: Array<ModelTypes["DateTime"]>,
	startDate: ModelTypes["DateTime"],
	endDate: ModelTypes["DateTime"]
};
	["schema"]: {
	query?: ModelTypes["Query"] | undefined,
	mutation?: ModelTypes["Mutation"] | undefined
}
    }

export type GraphQLTypes = {
    // ------------------------------------------------------;
	// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY);
	// ------------------------------------------------------;
	["Health"]: {
	__typename: "Health",
	env: string,
	time: string,
	status: string
};
	["Permissions"]: {
	__typename: "Permissions",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	roles: Array<GraphQLTypes["Roles"]>
};
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]: "scalar" & { name: "DateTime" };
	["Users"]: {
	__typename: "Users",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	status: GraphQLTypes["AccountStatus"],
	email: string,
	password: string,
	passwordResetToken?: string | undefined,
	passwordResetExpiryDate?: GraphQLTypes["DateTime"] | undefined,
	activateAccountToken?: string | undefined,
	activateAccountExpiryDate?: GraphQLTypes["DateTime"] | undefined,
	emailVerified: boolean,
	verifyEmailToken?: string | undefined,
	verifyEmailExpiryDate?: GraphQLTypes["DateTime"] | undefined,
	firstName: string,
	lastName: string,
	refreshToken?: string | undefined,
	role: GraphQLTypes["Roles"],
	roleId: number
};
	["AccountStatus"]: AccountStatus;
	["UserInfoForDevis"]: {
	__typename: "UserInfoForDevis",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	status: GraphQLTypes["AccountStatus"],
	email: string,
	password: string,
	passwordResetToken?: string | undefined,
	passwordResetExpiryDate?: GraphQLTypes["DateTime"] | undefined,
	activateAccountToken?: string | undefined,
	activateAccountExpiryDate?: GraphQLTypes["DateTime"] | undefined,
	emailVerified: boolean,
	verifyEmailToken?: string | undefined,
	verifyEmailExpiryDate?: GraphQLTypes["DateTime"] | undefined,
	firstName: string,
	lastName: string,
	refreshToken?: string | undefined,
	role: GraphQLTypes["Roles"],
	roleId: number,
	hasTimes: boolean
};
	["UserPagination"]: {
	__typename: "UserPagination",
	users: Array<GraphQLTypes["Users"] | undefined>,
	total: number
};
	["UserInfo"]: {
	__typename: "UserInfo",
	id: number,
	email: string,
	firstName: string,
	lastName: string,
	roleId: number,
	roleName?: string | undefined
};
	["Roles"]: {
	__typename: "Roles",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	permissions: Array<GraphQLTypes["Permissions"] | undefined>,
	users: Array<GraphQLTypes["Users"] | undefined>
};
	["UniqueAuth"]: {
	__typename: "UniqueAuth",
	access_token: string,
	firstName: string,
	lastName: string,
	role: string,
	email: string,
	sub: number
};
	["Configs"]: {
	__typename: "Configs",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	key: string,
	value: string
};
	["Poste"]: {
	__typename: "Poste",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string
};
	["Notification"]: {
	__typename: "Notification",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	comment: string,
	state: GraphQLTypes["NotificationState"],
	user?: GraphQLTypes["Users"] | undefined,
	task?: GraphQLTypes["Task"] | undefined
};
	["NotificationState"]: NotificationState;
	["Project"]: {
	__typename: "Project",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	projectManager?: GraphQLTypes["Users"] | undefined,
	projectManagerId: number,
	startDateInit?: GraphQLTypes["DateTime"] | undefined,
	startDatePlanning?: GraphQLTypes["DateTime"] | undefined,
	marginPercent?: number | undefined,
	retroPlanningDone: boolean,
	planningDone: boolean,
	superProject?: GraphQLTypes["Project"] | undefined,
	superProjectId?: number | undefined
};
	["ProjectType"]: ProjectType;
	["ProjectInfo"]: {
	__typename: "ProjectInfo",
	id: number,
	name: string,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	projectManagerId: number,
	startDateInit?: GraphQLTypes["DateTime"] | undefined,
	startDatePlanning?: GraphQLTypes["DateTime"] | undefined,
	marginPercent?: number | undefined,
	retroPlanningDone: boolean,
	planningDone: boolean,
	superProjectId?: number | undefined,
	projectManagerName?: string | undefined
};
	["ProjectInfoPagination"]: {
	__typename: "ProjectInfoPagination",
	projects: Array<GraphQLTypes["ProjectInfo"] | undefined>,
	total: number
};
	["ProjectFullInfo"]: {
	__typename: "ProjectFullInfo",
	id: number,
	name: string,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	projectManagerId: number,
	startDateInit?: GraphQLTypes["DateTime"] | undefined,
	startDatePlanning?: GraphQLTypes["DateTime"] | undefined,
	marginPercent?: number | undefined,
	retroPlanningDone: boolean,
	planningDone: boolean,
	superProjectId?: number | undefined,
	projectManager: GraphQLTypes["UserInfo"]
};
	["ProjectShortInfo"]: {
	__typename: "ProjectShortInfo",
	id: number,
	name: string,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	superProjectId?: number | undefined,
	tasks: Array<GraphQLTypes["TaskShortInfo"]>
};
	["SuppRentaInfo"]: {
	__typename: "SuppRentaInfo",
	amountByTasks: number,
	amountSpendByTasks: number,
	durationByTasks: number,
	timesByTasks: number
};
	["ProjectRentaInfoBase"]: {
	__typename: "ProjectRentaInfoBase",
	projectId: number,
	name: string,
	rentaPercent: number,
	projectDurationSold: number,
	projectAmountSold: number,
	projectDuration: number,
	projectDeltaDurationProd: number,
	projectDeltaAmountProd: number,
	projectSommeTime: number,
	projectSommeAmount: number,
	projectSommeAmountSpend: number,
	projectDurationUsed: number,
	projectAmountUsed: number,
	projectDeltaDurationSold: number,
	projectDeltaSommeSold: number
};
	["ProjectRentaInfo"]: {
	__typename: "ProjectRentaInfo",
	id: number,
	name: string,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	projectManager?: GraphQLTypes["Users"] | undefined,
	superProjectId?: number | undefined,
	renta: GraphQLTypes["ProjectRentaInfoBase"],
	tasks: Array<GraphQLTypes["TaskRentaInfo"]>,
	devisInit: Array<GraphQLTypes["DevisInitRentaInfo"]>,
	supp: GraphQLTypes["SuppRentaInfo"]
};
	["ProjectInfoForSuperProject"]: {
	__typename: "ProjectInfoForSuperProject",
	id: number,
	name: string
};
	["TaskPlanning"]: {
	__typename: "TaskPlanning",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	task?: GraphQLTypes["Task"] | undefined,
	taskId: number,
	date: GraphQLTypes["DateTime"],
	duration: number
};
	["TaskPlanningInfo"]: {
	__typename: "TaskPlanningInfo",
	id: number,
	taskId: number,
	date: GraphQLTypes["DateTime"],
	duration: number
};
	["TimeForUserInfo"]: {
	__typename: "TimeForUserInfo",
	date: GraphQLTypes["DateTime"],
	duration: number,
	comment: string
};
	["Time"]: {
	__typename: "Time",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	comment: string,
	date: GraphQLTypes["DateTime"],
	duration: number,
	user?: GraphQLTypes["Users"] | undefined,
	userId: number,
	task?: GraphQLTypes["Task"] | undefined,
	taskId: number
};
	["TimeInfo"]: {
	__typename: "TimeInfo",
	id: number,
	comment: string,
	date: GraphQLTypes["DateTime"],
	duration: number,
	user?: GraphQLTypes["Users"] | undefined,
	userId: number,
	taskId: number,
	task?: GraphQLTypes["TaskInfoForDevis"] | undefined
};
	["TimeShortInfo"]: {
	__typename: "TimeShortInfo",
	id: number,
	comment: string,
	date: GraphQLTypes["DateTime"],
	duration: number,
	user?: GraphQLTypes["Users"] | undefined,
	userId: number,
	taskId: number,
	task?: GraphQLTypes["TaskInfoForDevis"] | undefined
};
	["TimeRentaInfo"]: {
	__typename: "TimeRentaInfo",
	id: number,
	comment: string,
	date: GraphQLTypes["DateTime"],
	duration: number,
	user?: GraphQLTypes["Users"] | undefined,
	userId: number,
	taskId: number
};
	["UserTime"]: {
	__typename: "UserTime",
	user?: GraphQLTypes["Users"] | undefined,
	duration: number
};
	["DateUserTime"]: {
	__typename: "DateUserTime",
	date: GraphQLTypes["DateTime"],
	totalTimeByDate: number,
	userTime: Array<GraphQLTypes["UserTime"] | undefined>
};
	["ProjectUserTimesInfo"]: {
	__typename: "ProjectUserTimesInfo",
	projectId: number,
	projectName: string,
	projectManager: GraphQLTypes["Users"],
	calendar: Array<GraphQLTypes["DateUserTime"] | undefined>
};
	["DatePlanningTimes"]: {
	__typename: "DatePlanningTimes",
	label?: string | undefined,
	date: GraphQLTypes["DateTime"],
	selectible: boolean,
	planningDuration?: number | undefined,
	time?: number | undefined,
	timeId?: number | undefined,
	timeComment?: string | undefined
};
	["UserPlanningForUserInfo"]: {
	__typename: "UserPlanningForUserInfo",
	date: GraphQLTypes["DateTime"],
	duration: number
};
	["UserPlanningTimeForUserInfo"]: {
	__typename: "UserPlanningTimeForUserInfo",
	date: GraphQLTypes["DateTime"],
	userPlanningDuration: number,
	timeDuration: number
};
	["UserPlanningInfo"]: {
	__typename: "UserPlanningInfo",
	id: number,
	taskPlanningId: number,
	user: GraphQLTypes["Users"],
	userId: number,
	date: GraphQLTypes["DateTime"],
	duration: number,
	task?: GraphQLTypes["TaskInfoForDevis"] | undefined
};
	["TimeUserPlanningInfo"]: {
	__typename: "TimeUserPlanningInfo",
	userPlanning: Array<GraphQLTypes["UserPlanningInfo"] | undefined>,
	time: Array<GraphQLTypes["TimeInfo"] | undefined>
};
	["UserTimePlanningInfo"]: {
	__typename: "UserTimePlanningInfo",
	userId: number,
	userFirstName: string,
	userLastName: string,
	userPlanning: Array<GraphQLTypes["UserPlanningShortInfo"] | undefined>,
	time: Array<GraphQLTypes["TimeShortInfo"] | undefined>
};
	["UserPlanningShortInfo"]: {
	__typename: "UserPlanningShortInfo",
	id: number,
	taskPlanningId: number,
	user: GraphQLTypes["Users"],
	userId: number,
	date: GraphQLTypes["DateTime"],
	duration: number,
	task: GraphQLTypes["TaskInfoForDevis"]
};
	["Calendar"]: {
	__typename: "Calendar",
	id?: number | undefined,
	date: GraphQLTypes["DateTime"],
	label: string,
	selectible: boolean,
	retroChecked: boolean,
	checked: boolean,
	duration: number
};
	["Task"]: {
	__typename: "Task",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	startDateInit?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	stateType: GraphQLTypes["StateType"],
	statePercent: number,
	devis?: GraphQLTypes["Devis"] | undefined,
	devisId?: number | undefined,
	devisInit?: GraphQLTypes["DevisInit"] | undefined,
	devisInitId?: number | undefined,
	project?: GraphQLTypes["Project"] | undefined,
	projectId: number,
	order?: number | undefined,
	user?: Array<GraphQLTypes["Users"]> | undefined,
	notifications?: Array<GraphQLTypes["Notification"]> | undefined,
	time?: Array<GraphQLTypes["Time"]> | undefined,
	taskPlanning?: Array<GraphQLTypes["TaskPlanning"]> | undefined
};
	["FunctionType"]: FunctionType;
	["StateType"]: StateType;
	["TaskInfoForDevis"]: {
	__typename: "TaskInfoForDevis",
	id: number,
	name: string,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	startDateInit?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	devisId?: number | undefined,
	devisInitId?: number | undefined,
	projectId: number,
	order?: number | undefined,
	taskPlanning: Array<GraphQLTypes["TaskPlanningInfo"]>,
	posteId?: number | undefined,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	projectName: string,
	user?: Array<GraphQLTypes["UserInfoForDevis"]> | undefined
};
	["TaskInfoForPlanning"]: {
	__typename: "TaskInfoForPlanning",
	id: number,
	name: string,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	startDateInit?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	devisId?: number | undefined,
	devisInitId?: number | undefined,
	projectId: number,
	order?: number | undefined,
	taskPlanning: Array<GraphQLTypes["TaskPlanningInfo"]>,
	posteId?: number | undefined,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	projectName: string,
	user?: Array<GraphQLTypes["UserInfoForDevis"]> | undefined,
	calendar: Array<GraphQLTypes["Calendar"]>
};
	["Planning"]: {
	__typename: "Planning",
	calendar: Array<GraphQLTypes["Calendar"]>,
	tasks: Array<GraphQLTypes["TaskInfoForPlanning"]>,
	tasksSupp: Array<GraphQLTypes["TaskInfoForPlanning"]>
};
	["TaskShortInfo"]: {
	__typename: "TaskShortInfo",
	id: number,
	name: string,
	functionType: GraphQLTypes["FunctionType"],
	amountSpend?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	devisId?: number | undefined,
	devisInitId?: number | undefined,
	projectId: number,
	order?: number | undefined,
	user: Array<GraphQLTypes["UserInfoForDevis"]>
};
	["UserRentaInfo"]: {
	__typename: "UserRentaInfo",
	userId: number,
	userFirstName: string,
	userLastName: string,
	planningDuration: number,
	sommeTime: number,
	deltaDuration: number
};
	["TaskRentaInfo"]: {
	__typename: "TaskRentaInfo",
	id: number,
	name: string,
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	supp: boolean,
	projectId: number,
	order?: number | undefined,
	deltaDuration: number,
	sommeTime: number,
	time: Array<GraphQLTypes["TimeRentaInfo"]>,
	userRentaInfo: Array<GraphQLTypes["UserRentaInfo"]>
};
	["TaskForUserInfo"]: {
	__typename: "TaskForUserInfo",
	id: number,
	name: string,
	order?: number | undefined,
	projectName: string,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	projectManagerFirstName: string,
	projectManagerLastName: string,
	allUserPlanning: number,
	userPlanning: Array<GraphQLTypes["UserPlanningForUserInfo"]>,
	allTime: number,
	time: Array<GraphQLTypes["TimeForUserInfo"]>,
	timeAndUserPlanning: Array<GraphQLTypes["UserPlanningTimeForUserInfo"]>
};
	["DevisInit"]: {
	__typename: "DevisInit",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	duration: number,
	order?: number | undefined,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	devis?: GraphQLTypes["Devis"] | undefined,
	devisId: number,
	poste?: GraphQLTypes["Poste"] | undefined,
	posteId: number,
	customPosteName?: string | undefined
};
	["DevisInitInfo"]: {
	__typename: "DevisInitInfo",
	id: number,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	duration: number,
	order?: number | undefined,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	devisId: number,
	poste?: GraphQLTypes["Poste"] | undefined,
	posteId: number,
	customPosteName?: string | undefined
};
	["DevisInitRentaInfo"]: {
	__typename: "DevisInitRentaInfo",
	id: number,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	duration: number,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	customPosteName?: string | undefined,
	durationByTasks: number,
	timesByTasks: number,
	amountByTasks: number,
	amountSpendByTasks: number,
	deltaDurationProd: number,
	deltaAmountProd: number,
	deltaDurationSold: number,
	deltaSommeSold: number,
	poste?: GraphQLTypes["Poste"] | undefined,
	tasks: Array<GraphQLTypes["TaskRentaInfo"]>
};
	["Devis"]: {
	__typename: "Devis",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	type: GraphQLTypes["DevisType"],
	project?: GraphQLTypes["Project"] | undefined,
	projectId: number,
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	finishDate?: GraphQLTypes["DateTime"] | undefined,
	tasks?: Array<GraphQLTypes["Task"]> | undefined,
	devisInit?: Array<GraphQLTypes["DevisInit"]> | undefined
};
	["DevisType"]: DevisType;
	["DevisForProjectInit"]: {
	__typename: "DevisForProjectInit",
	id: number,
	type: GraphQLTypes["DevisType"],
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	finishDate?: GraphQLTypes["DateTime"] | undefined,
	project: GraphQLTypes["ProjectInfo"],
	devisInit?: Array<GraphQLTypes["DevisInitInfo"]> | undefined
};
	["DevisForProjectProd"]: {
	__typename: "DevisForProjectProd",
	id: number,
	type: GraphQLTypes["DevisType"],
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	finishDate?: GraphQLTypes["DateTime"] | undefined,
	project: GraphQLTypes["ProjectInfo"],
	tasks?: Array<GraphQLTypes["TaskInfoForDevis"]> | undefined
};
	["DevisForProject"]: {
	__typename: "DevisForProject",
	devisInit?: GraphQLTypes["DevisForProjectInit"] | undefined,
	devisProd?: GraphQLTypes["DevisForProjectProd"] | undefined
};
	["DownloadFile"]: {
	__typename: "DownloadFile",
	fileName: string,
	fileMimeType: string,
	buffer: string
};
	["MyFiles"]: {
	__typename: "MyFiles",
	eTag: string,
	location: string,
	fileName: string,
	fileMimeType: string,
	createdAt: GraphQLTypes["DateTime"],
	ownerId: number
};
	["Function"]: {
	__typename: "Function",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: GraphQLTypes["FunctionType"],
	order: number
};
	["Holidays"]: {
	__typename: "Holidays",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	date: GraphQLTypes["DateTime"]
};
	["Mail"]: {
	__typename: "Mail",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	key: string,
	to: string,
	data: string
};
	["SuperProject"]: {
	__typename: "SuperProject",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	projects: Array<GraphQLTypes["ProjectInfoForSuperProject"]>
};
	["SuperProjectInfoPagination"]: {
	__typename: "SuperProjectInfoPagination",
	projects: Array<GraphQLTypes["SuperProject"] | undefined>,
	total: number
};
	["SuperProjectRentaDetailsInfo"]: {
	__typename: "SuperProjectRentaDetailsInfo",
	id: number,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	projects: Array<GraphQLTypes["ProjectInfoForSuperProject"]>,
	renta: GraphQLTypes["ProjectRentaInfoBase"],
	projectsRenta?: Array<GraphQLTypes["ProjectRentaInfo"]> | undefined
};
	["Query"]: {
	__typename: "Query",
	getHealthGql: GraphQLTypes["Health"],
	getRole: GraphQLTypes["Roles"],
	getRoles: Array<GraphQLTypes["Roles"]>,
	getPermission: GraphQLTypes["Permissions"],
	getPermissions: Array<GraphQLTypes["Permissions"]>,
	getConfig: GraphQLTypes["Configs"],
	getConfigs: Array<GraphQLTypes["Configs"]>,
	getMail: GraphQLTypes["Mail"],
	getMails: Array<GraphQLTypes["Mail"]>,
	sendMail: boolean,
	loginAsGuest: GraphQLTypes["AuthType"],
	login: GraphQLTypes["AuthType"],
	uniqueAuthFromSso: GraphQLTypes["UniqueAuth"],
	getUser: GraphQLTypes["Users"],
	getUsers: Array<GraphQLTypes["Users"]>,
	getFilteredUsers: GraphQLTypes["UserPagination"],
	downloadFile: GraphQLTypes["DownloadFile"],
	getAllMyFiles: Array<GraphQLTypes["MyFiles"]>,
	getFilteredProjectsInfo: GraphQLTypes["ProjectInfoPagination"],
	getAllProjectByManager: Array<GraphQLTypes["ProjectInfoForSuperProject"]>,
	getAllProjectsWithTasks: Array<GraphQLTypes["ProjectShortInfo"]>,
	getOneProject: GraphQLTypes["ProjectFullInfo"],
	getRentaByProjectId: GraphQLTypes["ProjectRentaInfo"],
	getFilteredSuperProjectsInfo: GraphQLTypes["SuperProjectInfoPagination"],
	getOneSuperProject: GraphQLTypes["SuperProject"],
	getRentaBySuperProjectId: GraphQLTypes["SuperProjectRentaDetailsInfo"],
	getDevisByProjectId: GraphQLTypes["DevisForProject"],
	getFunction: GraphQLTypes["Function"],
	getFunctions: Array<GraphQLTypes["Function"]>,
	isUsersCanBeChangedInTask: GraphQLTypes["DevisForProjectProd"],
	getTaskListByProjectId: Array<GraphQLTypes["TaskShortInfo"]>,
	getTasksInternByProjectId: Array<GraphQLTypes["TaskShortInfo"]>,
	getTasksInfoByUserId: Array<GraphQLTypes["TaskForUserInfo"]>,
	getPlanningByProjectId: GraphQLTypes["Planning"],
	getPlanningForPeriodByUserId: Array<GraphQLTypes["UserPlanningInfo"]>,
	getTimesUserPlanningsForPeriodByUserId: GraphQLTypes["TimeUserPlanningInfo"],
	getAllTimesUserPlanningsForPeriodByProjectId: GraphQLTypes["TimeUserPlanningInfo"],
	getTimesUserPlanningsForPeriodByProjectId: Array<GraphQLTypes["UserTimePlanningInfo"]>,
	getTimesForPeriodByUserId: Array<GraphQLTypes["TimeInfo"]>,
	getExportUserTimesForPeriodByProjectId: GraphQLTypes["ProjectUserTimesInfo"],
	getHolidaysForPeriod: Array<GraphQLTypes["Holidays"]>,
	getAllHolidays: Array<GraphQLTypes["Holidays"]>,
	getPoste: GraphQLTypes["Poste"],
	getPostes: Array<GraphQLTypes["Poste"]>
};
	["AuthType"]:{
        	__typename:"Tokens" | "CustomError"
        	['...on Tokens']: '__union' & GraphQLTypes["Tokens"];
	['...on CustomError']: '__union' & GraphQLTypes["CustomError"];
};
	["Tokens"]: {
	__typename: "Tokens",
	access_token: string,
	refresh_token: string
};
	["CustomError"]: {
	__typename: "CustomError",
	error: GraphQLTypes["EError"],
	message: string
};
	["EError"]: EError;
	["FindManyUserWithFiltersInput"]: {
		skip: number,
	take: number,
	searchInput: string,
	orderBy?: GraphQLTypes["UserOrderByInput"] | undefined,
	filterBy?: GraphQLTypes["UserFilterByInput"] | undefined,
	adminIncluded?: boolean | undefined
};
	["UserOrderByInput"]: {
		email?: GraphQLTypes["OrderByEnum"] | undefined,
	firstName?: GraphQLTypes["OrderByEnum"] | undefined,
	lastName?: GraphQLTypes["OrderByEnum"] | undefined,
	lastConnectionAt?: GraphQLTypes["OrderByEnum"] | undefined,
	role?: GraphQLTypes["OrderByEnum"] | undefined
};
	["OrderByEnum"]: OrderByEnum;
	["UserFilterByInput"]: {
		role?: Array<number> | undefined
};
	["FilterProjectInput"]: {
		skip?: number | undefined,
	take?: number | undefined,
	userId?: number | undefined,
	userRole?: string | undefined,
	searchInProjectName?: string | undefined,
	orderBy?: GraphQLTypes["ProjectOrderByInput"] | undefined,
	projectType: GraphQLTypes["ProjectType"]
};
	["ProjectOrderByInput"]: {
		name?: GraphQLTypes["OrderByEnum"] | undefined
};
	["FilterSuperProjectInput"]: {
		skip?: number | undefined,
	take?: number | undefined,
	userId?: number | undefined,
	userRole?: string | undefined,
	searchInSuperProjectName?: string | undefined,
	orderBy?: GraphQLTypes["SuperProjectOrderByInput"] | undefined
};
	["SuperProjectOrderByInput"]: {
		name?: GraphQLTypes["OrderByEnum"] | undefined
};
	["CreateOrUpdateUsersInTaskInput"]: {
		id: number,
	users: Array<number>
};
	["FilterUserPlanningInput"]: {
		userId: number,
	startDate: GraphQLTypes["DateTime"],
	endDate: GraphQLTypes["DateTime"]
};
	["FilterTimeUserPlanningByProjectInput"]: {
		projectId: number,
	startDate: GraphQLTypes["DateTime"],
	endDate: GraphQLTypes["DateTime"]
};
	["FilterTimeInput"]: {
		userId: number,
	startDate: GraphQLTypes["DateTime"],
	endDate: GraphQLTypes["DateTime"]
};
	["FilterHolidaysInput"]: {
		startDate: GraphQLTypes["DateTime"],
	endDate: GraphQLTypes["DateTime"]
};
	["Mutation"]: {
	__typename: "Mutation",
	askForResetPassword: boolean,
	resetPassword: boolean,
	verifyEmail: boolean,
	register: GraphQLTypes["AuthType"],
	askForRegister: boolean,
	finishRegister: boolean,
	finishRegisterAsGuest: boolean,
	refresh: GraphQLTypes["AuthType"],
	logout: boolean,
	createUser: GraphQLTypes["Users"],
	updateUser: GraphQLTypes["Users"],
	deleteUser: boolean,
	deleteFile: boolean,
	uploadFile: boolean,
	createProject: boolean,
	createProjectIntern: boolean,
	updateProject: boolean,
	setRetroPlanningDone: boolean,
	setPlanningDone: boolean,
	updateRetroPlanningDone: boolean,
	updatePlanningDone: boolean,
	deleteProject: boolean,
	createSuperProject: boolean,
	updateSuperProject: boolean,
	deleteSuperProject: boolean,
	createDevisInitial: boolean,
	createDevisProd: boolean,
	updateDevisInitial: boolean,
	updateDevisProd: boolean,
	updateDevisProdDate: boolean,
	updateDevisInitDurationExpected: boolean,
	createDevisInit: boolean,
	updateTaskOrder: GraphQLTypes["DevisForProjectProd"],
	updateTaskSupp: GraphQLTypes["DevisForProjectProd"],
	updateTaskRetro: GraphQLTypes["DevisForProjectProd"],
	createOrUpdateUsersInTaskByFunctionType: GraphQLTypes["DevisForProjectProd"],
	createOrUpdateUsersInTask: GraphQLTypes["DevisForProjectProd"],
	createOrUpdateUsersInTaskIntern: boolean,
	createTaskSupp: GraphQLTypes["DevisForProjectProd"],
	createTaskIntern: boolean,
	deleteTask: GraphQLTypes["DevisForProjectProd"],
	deleteTaskIntern: boolean,
	createOrUpdateTaskPlanning: GraphQLTypes["DevisForProjectProd"],
	createUserPlanning: GraphQLTypes["DevisForProjectProd"],
	deleteUserPlanning: boolean,
	createOrUpdateTime: boolean,
	deleteTime: boolean,
	createHolidays: boolean,
	deleteHolidays: boolean,
	updateHolidaysForPeriod: Array<GraphQLTypes["Holidays"]>
};
	["RegisterUserInput"]: {
		email: string,
	roleId: number
};
	["CreateUserInput"]: {
		email: string,
	password: string,
	firstName: string,
	lastName: string,
	roleId: number
};
	["UpdateUserInput"]: {
		email?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	roleId?: number | undefined,
	id: number
};
	/** The `Upload` scalar type represents a file upload. */
["Upload"]: "scalar" & { name: "Upload" };
	["CreateProjectInput"]: {
		name: string,
	projectManagerId: number,
	marginPercent?: number | undefined,
	projectType?: GraphQLTypes["ProjectType"] | undefined
};
	["UpdateProjectInput"]: {
		name?: string | undefined,
	projectManagerId?: number | undefined,
	marginPercent?: number | undefined,
	projectType?: GraphQLTypes["ProjectType"] | undefined,
	id: number
};
	["CreateSuperProjectInput"]: {
		name: string,
	projectIds?: Array<number> | undefined
};
	["UpdateSuperProjectInput"]: {
		name?: string | undefined,
	projectIds?: Array<number> | undefined,
	id: number
};
	["CreateDevisInput"]: {
		projectId: number,
	type: GraphQLTypes["DevisType"],
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	createDevisInitInput: Array<GraphQLTypes["CreateDevisInitInput"]>
};
	["CreateDevisInitInput"]: {
		id?: number | undefined,
	devisId?: number | undefined,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	duration: number,
	durationExpected: number,
	durationUsed?: number | undefined,
	amountUsed?: number | undefined,
	posteId: number,
	order?: number | undefined,
	customPosteName?: string | undefined
};
	["CreateDevisProdInput"]: {
		projectId: number,
	type: GraphQLTypes["DevisType"],
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	finishDate?: GraphQLTypes["DateTime"] | undefined,
	createTaskInput: Array<GraphQLTypes["CreateTaskInput"]>
};
	["CreateTaskInput"]: {
		devisId?: number | undefined,
	projectId: number,
	name: string,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	order?: number | undefined,
	duration: number,
	devisInitId?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean
};
	["UpdateDevisInput"]: {
		id: number,
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	createDevisInitInput: Array<GraphQLTypes["CreateDevisInitInput"]>
};
	["UpdateDevisProdInput"]: {
		id: number,
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	finishDate?: GraphQLTypes["DateTime"] | undefined,
	updateTaskInput: Array<GraphQLTypes["UpdateTaskInput"]>
};
	["UpdateTaskInput"]: {
		devisId?: number | undefined,
	projectId: number,
	name: string,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	order?: number | undefined,
	duration: number,
	devisInitId?: number | undefined,
	durationDays?: number | undefined,
	durationClientDays?: number | undefined,
	visibleInRetro: boolean,
	calculatedAsAmount: boolean,
	id?: number | undefined,
	stateType?: GraphQLTypes["StateType"] | undefined
};
	["UpdateDevisProdDateInput"]: {
		id: number,
	signedDate?: GraphQLTypes["DateTime"] | undefined,
	finishDate?: GraphQLTypes["DateTime"] | undefined
};
	["UpdateDevisInitDurationExpectedInput"]: {
		id: number,
	durationExpected: number
};
	["UpdateTaskOrderInput"]: {
		id: number,
	order?: number | undefined
};
	["UpdateTaskSuppInput"]: {
		id: number,
	name?: string | undefined,
	functionType: GraphQLTypes["FunctionType"],
	amount?: number | undefined,
	amountSpend?: number | undefined,
	duration?: number | undefined
};
	["UpdateTaskRetroInput"]: {
		id: number,
	startDateInit: number,
	durationDays?: number | undefined
};
	["CreateOrUpdateUsersByFunctionTypeInput"]: {
		projectId: number,
	usersByFunctionType: Array<GraphQLTypes["UsersByFunctionTypeInput"]>
};
	["UsersByFunctionTypeInput"]: {
		functionType: GraphQLTypes["FunctionType"],
	usersIds: Array<number>
};
	["CreateTaskSuppInput"]: {
		projectId: number,
	name: string,
	functionType: GraphQLTypes["FunctionType"],
	amount: number,
	amountSpend?: number | undefined,
	duration: number,
	supp?: boolean | undefined
};
	["CreateTaskInternInput"]: {
		projectId: number,
	name: string
};
	["UpdateTaskPlanningInput"]: {
		projectId: number,
	taskId: number,
	dates: Array<GraphQLTypes["UpdateDatePlanningInput"]>
};
	["UpdateDatePlanningInput"]: {
		id?: number | undefined,
	date: GraphQLTypes["DateTime"],
	duration: number
};
	["CreateUserPlanningInput"]: {
		userId: number,
	taskPlanningId: number,
	date: GraphQLTypes["DateTime"],
	duration: number
};
	["CreateOrUpdateTimeInput"]: {
		id?: number | undefined,
	taskId: number,
	userId: number,
	date: GraphQLTypes["DateTime"],
	duration: number,
	comment?: string | undefined
};
	["CreateHolidaysInput"]: {
		date: GraphQLTypes["DateTime"]
};
	["UpdateHolidaysInput"]: {
		dates: Array<GraphQLTypes["DateTime"]>,
	startDate: GraphQLTypes["DateTime"],
	endDate: GraphQLTypes["DateTime"]
}
    }
export const enum AccountStatus {
	PENDING_ACTIVATION = "PENDING_ACTIVATION",
	ENABLED = "ENABLED",
	DISABLED = "DISABLED"
}
export const enum NotificationState {
	SEEN = "SEEN",
	NOT_SEEN = "NOT_SEEN"
}
export const enum ProjectType {
	INTERN = "INTERN",
	EXTERN = "EXTERN"
}
export const enum FunctionType {
	GP = "GP",
	IP_GAME = "IP_GAME",
	ARTI = "ARTI",
	IT = "IT",
	CLIENT = "CLIENT",
	INTERN = "INTERN"
}
export const enum StateType {
	CREATED = "CREATED",
	IN_PROGRESS = "IN_PROGRESS",
	CLOSED = "CLOSED"
}
export const enum DevisType {
	INITIAL = "INITIAL",
	PROD = "PROD"
}
export const enum EError {
	AUTH = "AUTH",
	PROJECT_NOT_EXIST = "PROJECT_NOT_EXIST",
	SUPER_PROJECT_NOT_EXIST = "SUPER_PROJECT_NOT_EXIST",
	USER_NOT_FOUND = "USER_NOT_FOUND",
	DEVIS_NOT_EXIST = "DEVIS_NOT_EXIST",
	DEVIS_TASK_NOT_FOUND = "DEVIS_TASK_NOT_FOUND",
	TASK_NOT_EXIST = "TASK_NOT_EXIST",
	TASK_UPDATE_ERROR = "TASK_UPDATE_ERROR",
	USER_PLANNING_NOT_EXIST = "USER_PLANNING_NOT_EXIST",
	TIME_NOT_EXIST = "TIME_NOT_EXIST",
	HOLIDAY_NOT_EXIST = "HOLIDAY_NOT_EXIST",
	HOLIDAYS_NOT_CREATED = "HOLIDAYS_NOT_CREATED",
	TASK_CREATE_ERROR = "TASK_CREATE_ERROR",
	USERS_CANT_BE_DELETED = "USERS_CANT_BE_DELETED"
}
export const enum OrderByEnum {
	ASC = "ASC",
	DESC = "DESC"
}

type ZEUS_VARIABLES = {
	["DateTime"]: ValueTypes["DateTime"];
	["AccountStatus"]: ValueTypes["AccountStatus"];
	["NotificationState"]: ValueTypes["NotificationState"];
	["ProjectType"]: ValueTypes["ProjectType"];
	["FunctionType"]: ValueTypes["FunctionType"];
	["StateType"]: ValueTypes["StateType"];
	["DevisType"]: ValueTypes["DevisType"];
	["EError"]: ValueTypes["EError"];
	["FindManyUserWithFiltersInput"]: ValueTypes["FindManyUserWithFiltersInput"];
	["UserOrderByInput"]: ValueTypes["UserOrderByInput"];
	["OrderByEnum"]: ValueTypes["OrderByEnum"];
	["UserFilterByInput"]: ValueTypes["UserFilterByInput"];
	["FilterProjectInput"]: ValueTypes["FilterProjectInput"];
	["ProjectOrderByInput"]: ValueTypes["ProjectOrderByInput"];
	["FilterSuperProjectInput"]: ValueTypes["FilterSuperProjectInput"];
	["SuperProjectOrderByInput"]: ValueTypes["SuperProjectOrderByInput"];
	["CreateOrUpdateUsersInTaskInput"]: ValueTypes["CreateOrUpdateUsersInTaskInput"];
	["FilterUserPlanningInput"]: ValueTypes["FilterUserPlanningInput"];
	["FilterTimeUserPlanningByProjectInput"]: ValueTypes["FilterTimeUserPlanningByProjectInput"];
	["FilterTimeInput"]: ValueTypes["FilterTimeInput"];
	["FilterHolidaysInput"]: ValueTypes["FilterHolidaysInput"];
	["RegisterUserInput"]: ValueTypes["RegisterUserInput"];
	["CreateUserInput"]: ValueTypes["CreateUserInput"];
	["UpdateUserInput"]: ValueTypes["UpdateUserInput"];
	["Upload"]: ValueTypes["Upload"];
	["CreateProjectInput"]: ValueTypes["CreateProjectInput"];
	["UpdateProjectInput"]: ValueTypes["UpdateProjectInput"];
	["CreateSuperProjectInput"]: ValueTypes["CreateSuperProjectInput"];
	["UpdateSuperProjectInput"]: ValueTypes["UpdateSuperProjectInput"];
	["CreateDevisInput"]: ValueTypes["CreateDevisInput"];
	["CreateDevisInitInput"]: ValueTypes["CreateDevisInitInput"];
	["CreateDevisProdInput"]: ValueTypes["CreateDevisProdInput"];
	["CreateTaskInput"]: ValueTypes["CreateTaskInput"];
	["UpdateDevisInput"]: ValueTypes["UpdateDevisInput"];
	["UpdateDevisProdInput"]: ValueTypes["UpdateDevisProdInput"];
	["UpdateTaskInput"]: ValueTypes["UpdateTaskInput"];
	["UpdateDevisProdDateInput"]: ValueTypes["UpdateDevisProdDateInput"];
	["UpdateDevisInitDurationExpectedInput"]: ValueTypes["UpdateDevisInitDurationExpectedInput"];
	["UpdateTaskOrderInput"]: ValueTypes["UpdateTaskOrderInput"];
	["UpdateTaskSuppInput"]: ValueTypes["UpdateTaskSuppInput"];
	["UpdateTaskRetroInput"]: ValueTypes["UpdateTaskRetroInput"];
	["CreateOrUpdateUsersByFunctionTypeInput"]: ValueTypes["CreateOrUpdateUsersByFunctionTypeInput"];
	["UsersByFunctionTypeInput"]: ValueTypes["UsersByFunctionTypeInput"];
	["CreateTaskSuppInput"]: ValueTypes["CreateTaskSuppInput"];
	["CreateTaskInternInput"]: ValueTypes["CreateTaskInternInput"];
	["UpdateTaskPlanningInput"]: ValueTypes["UpdateTaskPlanningInput"];
	["UpdateDatePlanningInput"]: ValueTypes["UpdateDatePlanningInput"];
	["CreateUserPlanningInput"]: ValueTypes["CreateUserPlanningInput"];
	["CreateOrUpdateTimeInput"]: ValueTypes["CreateOrUpdateTimeInput"];
	["CreateHolidaysInput"]: ValueTypes["CreateHolidaysInput"];
	["UpdateHolidaysInput"]: ValueTypes["UpdateHolidaysInput"];
}