import { styled } from 'styled-components';
import { classic } from '~/themes';

export const SH1 = styled.h1<{
  $color?: string;
  $fontweight?: string;
  $fontsize?: string;
  $textalign?: string;
  $letterspacing?: string;
  $texttransform?: string;
  $margin?: string;
  $lineheight?: string;
}>`
  font-family: 'ProximaNova', sans-serif !important;
  color: ${({ $color }) =>
    $color ? $color : `${classic.token?.colorText}`}!important;
  font-weight: ${({ $fontweight }) =>
    $fontweight ? $fontweight : 800}!important;
  font-size: ${({ $fontsize }) => ($fontsize ? $fontsize : '36px')}!important;
  letter-spacing: ${({ $letterspacing }) =>
    $letterspacing ? $letterspacing : 'normal'}!important;
  text-transform: ${({ $texttransform }) =>
    $texttransform ? $texttransform : 'normal'}!important;
  text-align: ${({ $textalign }) =>
    $textalign ? $textalign : 'center'}!important;
  margin: ${({ $margin }) => ($margin ? $margin : 0)}!important;
  line-height: ${({ $lineheight }) =>
    $lineheight ? $lineheight : 'normal'}!important;

  @media screen and (max-width: 992px) {
    font-size: 24px !important;
  }
`;

export const SH2 = styled.h2<{
  $color?: string;
  $fontweight?: string;
  $fontsize?: string;
  $textalign?: string;
  $letterspacing?: string;
  $texttransform?: string;
  $margin?: string;
  $lineheight?: string;
}>`
  font-family: 'ProximaNova', sans-serif !important;
  color: ${({ $color }) =>
    $color ? $color : `${classic.token?.colorText}`}!important;
  font-weight: ${({ $fontweight }) =>
    $fontweight ? $fontweight : 800}!important;
  font-size: ${({ $fontsize }) => ($fontsize ? $fontsize : '30px')}!important;
  letter-spacing: ${({ $letterspacing }) =>
    $letterspacing ? $letterspacing : 'normal'}!important;
  text-transform: ${({ $texttransform }) =>
    $texttransform ? $texttransform : 'normal'}!important;
  text-align: ${({ $textalign }) =>
    $textalign ? $textalign : 'center'}!important;
  margin: ${({ $margin }) => ($margin ? $margin : 0)}!important;
  line-height: ${({ $lineheight }) =>
    $lineheight ? $lineheight : 'normal'}!important;

  @media screen and (max-width: 992px) {
    font-size: 20px !important;
  }
`;

export const SH3 = styled.h3<{
  $color?: string;
  $fontweight?: string;
  $fontsize?: string;
  $textalign?: string;
  $letterspacing?: string;
  $texttransform?: string;
  $margin?: string;
  $lineheight?: string;
}>`
  font-family: 'ProximaNova', sans-serif !important;
  color: ${({ $color }) =>
    $color ? $color : `${classic.token?.colorText}`}!important;
  font-weight: ${({ $fontweight }) =>
    $fontweight ? $fontweight : 800}!important;
  font-size: ${({ $fontsize }) => ($fontsize ? $fontsize : '20px')}!important;
  letter-spacing: ${({ $letterspacing }) =>
    $letterspacing ? $letterspacing : 'normal'}!important;
  text-transform: ${({ $texttransform }) =>
    $texttransform ? $texttransform : 'normal'}!important;
  text-align: ${({ $textalign }) =>
    $textalign ? $textalign : 'center'}!important;
  margin: ${({ $margin }) => ($margin ? $margin : 0)}!important;
  line-height: ${({ $lineheight }) =>
    $lineheight ? $lineheight : 'normal'}!important;

  @media screen and (max-width: 992px) {
    font-size: 16px !important;
  }
`;

export const SText = styled.p<{
  $color?: string;
  $fontweight?: string;
  $fontsize?: string;
  $textalign?: string;
  $letterspacing?: string;
  $texttransform?: string;
  $textdecoration?: string;
  $cursor?: string;
  $margin?: string;
  $lineheight?: string;
}>`
  font-family: 'ProximaNova', sans-serif !important;
  color: ${({ $color }) =>
    $color ? $color : `${classic.token?.colorText}`}!important;
  font-weight: ${({ $fontweight }) =>
    $fontweight ? $fontweight : 500}!important;
  font-size: ${({ $fontsize }) => ($fontsize ? $fontsize : '16px')}!important;
  letter-spacing: ${({ $letterspacing }) =>
    $letterspacing ? $letterspacing : 'normal'}!important;
  text-transform: ${({ $texttransform }) =>
    $texttransform ? $texttransform : 'normal'}!important;
  text-align: ${({ $textalign }) =>
    $textalign ? $textalign : 'center'}!important;
  text-decoration: ${({ $textdecoration }) =>
    $textdecoration ? $textdecoration : 'none'}!important;
  cursor: ${({ $cursor }) => ($cursor ? $cursor : 'normal')}!important;
  margin: ${({ $margin }) => ($margin ? $margin : 0)}!important;
  line-height: ${({ $lineheight }) =>
    $lineheight ? $lineheight : 'normal'}!important;

  @media screen and (max-width: 992px) {
    font-size: 14px !important;
  }
`;

export const SPText = styled.p<{
  $color?: string;
  $fontweight?: string;
  $fontsize?: string;
  $textalign?: string;
  $letterspacing?: string;
  $texttransform?: string;
  $textdecoration?: string;
  $cursor?: string;
  $margin?: string;
  $lineheight?: string;
}>`
  font-family: 'ProximaNova', sans-serif !important;
  color: ${({ $color }) =>
    $color ? $color : `${classic.token?.colorText}`}!important;
  font-weight: ${({ $fontweight }) =>
    $fontweight ? $fontweight : 500}!important;
  font-size: ${({ $fontsize }) => ($fontsize ? $fontsize : '16px')}!important;
  letter-spacing: ${({ $letterspacing }) =>
    $letterspacing ? $letterspacing : 'normal'}!important;
  text-transform: ${({ $texttransform }) =>
    $texttransform ? $texttransform : 'normal'}!important;
  text-align: ${({ $textalign }) =>
    $textalign ? $textalign : 'center'}!important;
  text-decoration: ${({ $textdecoration }) =>
    $textdecoration ? $textdecoration : 'none'}!important;
  cursor: ${({ $cursor }) => ($cursor ? $cursor : 'normal')}!important;
  margin: ${({ $margin }) => ($margin ? $margin : 0)}!important;
  line-height: ${({ $lineheight }) =>
    $lineheight ? $lineheight : 'normal'}!important;

  @media screen and (max-width: 992px) {
    font-size: 14px !important;
  }
`;

export const SLink = styled.a<{
  $color?: string;
  $fontweight?: string;
  $fontsize?: string;
  $textalign?: string;
  $letterspacing?: string;
  $texttransform?: string;
  $textdecoration?: string;
  $margin?: string;
  $lineheight?: string;
}>`
  font-family: 'ProximaNova', sans-serif !important;
  color: ${({ $color }) =>
    $color ? $color : `${classic.token?.colorText}`}!important;
  font-weight: ${({ $fontweight }) =>
    $fontweight ? $fontweight : 500}!important;
  font-size: ${({ $fontsize }) => ($fontsize ? $fontsize : '16px')}!important;
  letter-spacing: ${({ $letterspacing }) =>
    $letterspacing ? $letterspacing : 'normal'}!important;
  text-transform: ${({ $texttransform }) =>
    $texttransform ? $texttransform : 'normal'}!important;
  text-align: ${({ $textalign }) =>
    $textalign ? $textalign : 'center'}!important;
  text-decoration: ${({ $textdecoration }) =>
    $textdecoration ? $textdecoration : 'none'}!important;
  cursor: pointer;
  margin: ${({ $margin }) => ($margin ? $margin : 0)}!important;
  line-height: ${({ $lineheight }) =>
    $lineheight ? $lineheight : 'normal'}!important;

  @media screen and (max-width: 992px) {
    font-size: 14px !important;
  }
`;

export const StyledButton = styled.button<{
  $borderradius?: string;
  $padding?: string;
  $margin?: string;
  $disabled?: string;
}>`
  font-family: 'ProximaNova', sans-serif !important;
  border-radius: ${({ $borderradius }) =>
    $borderradius ? $borderradius : '16px'}!important;
  border: none !important;
  padding: ${({ $padding }) => ($padding ? $padding : '18px 23px')}!important;
  margin: ${({ $margin }) => ($margin ? $margin : '0')}!important;
  font-size: 14px !important;
  font-weight: 900 !important;
  font-family: inherit !important;
  min-width: 100px;
  background-image: linear-gradient(
    112deg,
    #009cb4 -6%,
    #00446f 110%
  ) !important;
  cursor: pointer !important;
  box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.16) !important;
  transition: background-image 2.25s ease !important;

  &:hover {
    background-image: ${({ $disabled }) =>
      $disabled === 'true'
        ? 'linear-gradient(112deg,#009cb4 -6%,#00446f 110% )'
        : 'linear-gradient(112deg,#00446f -6%,#009cb4 110%)'}!important;
  }
`;

export const ClassicButton = styled.a<{
  $borderradius?: string;
  $padding?: string;
  $margin?: string;
  $disabled?: string;
}>`
  text-decoration: none;
  color: #fff;
  font-family: 'ProximaNova', sans-serif !important;
  border-radius: ${({ $borderradius }) =>
    $borderradius ? $borderradius : '16px'}!important;
  border: none !important;
  padding: ${({ $padding }) => ($padding ? $padding : '18px 23px')}!important;
  margin: ${({ $margin }) => ($margin ? $margin : '0')}!important;
  font-size: 14px !important;
  font-weight: 900 !important;
  font-family: inherit !important;
  background: ${({ $disabled }) =>
    $disabled === 'false'
      ? 'grey'
      : classic.token?.colorFillSecondary}!important;
  cursor: ${({ $disabled }) =>
    $disabled === 'false' ? 'unset' : 'pointer'}!important;
  transition: background 0.5s !important;

  &:hover {
    background: ${({ $disabled }) =>
      $disabled === 'false' ? 'grey' : classic.token?.colorPrimary}!important;
  }
`;

export const ContentLayout = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

export const FormGroup = styled.a<{
  $gap?: string;
  $direction?: string;
}>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: ${({ $direction }) =>
    $direction === 'true' ? $direction : 'column'}!important;
  gap: ${({ $gap }) => ($gap === 'true' ? $gap : 0)}!important;
`;
