import DefaultLayout from '~/pages/layouts/DefaultLayout';
import SuperProjectTable from '~/pages/tables/SuperProjectTable';

function SuperProjectPage() {
  return (
    <>
      <DefaultLayout>
        <SuperProjectTable />
      </DefaultLayout>
    </>
  );
}

export default SuperProjectPage;
