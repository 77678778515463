import { ProjectType } from '@zeus/index';

import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

export type IPropsDurationSmall = {
  duration: number;
  onChange?: (duration: number) => void;
  $allowCorrection?: boolean;
  $opaque?: boolean;
  $align?: string;
  $color?: string;
  $backgroundColor?: string;
  onIconClick?: () => void;
  projectType?: ProjectType;
  onFocus?: () => void;
  $borderLeftColor?: string;
};

function DurationItemSmall({
  duration,
  onChange,
  onFocus,
  $allowCorrection,
  $align,
  $opaque,
  $color,
  $backgroundColor,
  $borderLeftColor,
  onIconClick,
  projectType,
}: IPropsDurationSmall) {
  const [value, setValue] = useState<string>('00:00');

  useEffect(() => {
    const _hours = Math.floor(duration / 60);
    const _minutes = duration % 60;

    const strHours = `${_hours.toString()}`;
    const strMinutes = `${_minutes.toString()}`;
    const strFormaredHours = strHours.length === 1 ? '0' + strHours : strHours;
    const strFormaredMinutes =
      strMinutes.length === 1 ? '0' + strMinutes : strMinutes;
    setValue(strFormaredHours + ':' + strFormaredMinutes);
  }, [duration]);

  return (
    <Container $align={$align}>
      <SInputTime
        onFocus={onFocus}
        type="time"
        $color={$color}
        $backgroundColor={$backgroundColor}
        $opaque={$opaque}
        $fontStyle={projectType === ProjectType.INTERN ? 'italic' : 'normal'}
        $allowCorrection={$allowCorrection}
        $borderLeftColor={$borderLeftColor}
        value={value}
        onChange={(e) => {
          const arr = e.target.value.split(':');
          const _duration = Number(arr[0]) * 1 * 60 + Number(arr[1]);
          onChange && onChange(_duration);
          setValue(e.target.value);
        }}
      ></SInputTime>

      <SDivClick
        $allowCorrection={$allowCorrection}
        onClick={(e) => {
          e.preventDefault();
          onIconClick && onIconClick();
        }}
      ></SDivClick>
    </Container>
  );
}

export default DurationItemSmall;

const SInputTime = styled.input<{
  $allowCorrection?: boolean;
  $opaque?: boolean;
  $color?: string;
  $backgroundColor?: string;
  $fontStyle?: string;
  $borderLeftColor?: string;
}>`
  background: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : 'transparent'};
  color: ${({ $opaque, $color }) => {
    if ($color) return $opaque ? `${$color}40` : $color;
    return $opaque ? '#4a4a4a80' : '#4a4a4a';
  }};
  cursor: pointer;
  font-size: 12px;
  font-style: ${({ $fontStyle }) => ($fontStyle ? $fontStyle : 'normal')};
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 5px;
  height: 30px;

  border-left-color: ${({ $borderLeftColor }) =>
    $borderLeftColor ? $borderLeftColor : 'transparent'};
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  position: relative;
  pointer-events: ${({ $allowCorrection }) =>
    $allowCorrection ? 'auto' : 'none'};
  &::-webkit-calendar-picker-indicator {
    filter: opacity(0.5) drop-shadow(0 0 0 black);
  }
  width: 80px;

  /* &[type='time']::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    width: 10%;
    left: 35%;
  } */
`;
const SDivClick = styled.div<{ $allowCorrection?: boolean }>`
  cursor: ${({ $allowCorrection }) => ($allowCorrection ? 'pointer' : 'auto')};
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: ${({ $allowCorrection }) =>
    $allowCorrection ? 'auto' : 'none'};
  width: 23px;
`;
const Container = styled.div<{ $align?: string }>`
  align-items: center;
  display: flex;
  justify-content: ${({ $align }) => ($align ? $align : 'center')};
  position: relative;
  width: auto;
  & > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
