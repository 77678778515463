import { InfoCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useLingui, Trans } from '@lingui/react';
import {
  Input,
  Typography,
  Form,
  DatePicker,
  Button,
  Tooltip,
  ConfigProvider,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import frFR from 'antd/es/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { GQL_DEVIS_BY_PROJECTID } from '~/gql/devis/devis';
import {
  GQL_GET_ONE_PROJECT,
  GQL_UPDATE_PROJECT,
  StoredProjectFullInfo,
  GQL_SET_RETRO_PLANNING_DONE,
} from '~/gql/project/project';

import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import TasksRetroPlanning from '~/pages/components/common/planning/TasksRetroPlanning';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ManagerLayout from '~/pages/layouts/ManagerLayout';
import { classic } from '~/themes/classic';

const dateFormat = 'DD-MM-YYYY';

function RetroPlanningPage() {
  const formRef = useRef<FormInstance>(null);
  const params = useParams();
  const { id } = params;
  const { Text } = Typography;
  const {
    currentProject,
    setCurrentProject,
    currentDevisProd,
    setCurrentDevisProd,
  } = useDataUserStore();
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);

  const [projectName, setProjectName] = useState<string>('');
  const [project, setProject] = useState<StoredProjectFullInfo | null>(null);

  const [dateSigned, setDateSigned] = useState<Date | undefined>(
    currentDevisProd?.signedDate as Date
  );
  const [dateFinish, setDateFinish] = useState<Date | undefined>(
    currentDevisProd?.finishDate as Date
  );
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const [setRetroPlanningDone] = useMutation(GQL_SET_RETRO_PLANNING_DONE, {
    client: authClient,
  });

  const [updateProject] = useMutation(GQL_UPDATE_PROJECT, {
    client: authClient,
  });

  const { refetch: fetchDevis } = useQuery(GQL_DEVIS_BY_PROJECTID, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDevisByProjectId.devisProd) {
        setCurrentDevisProd(data.getDevisByProjectId.devisProd);

        setDateSigned(data.getDevisByProjectId.devisProd.signedDate);
        setDateFinish(data.getDevisByProjectId.devisProd.finishDate);
      }
    },
    onError: (e) => {
      console.log('erreur dans GQL_DEVIS_BY_PROJECTID', e);
    },
  });

  const { refetch: fetchProject } = useQuery(GQL_GET_ONE_PROJECT, {
    variables: {
      id: Number(id),
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProject(data.getOneProject);
      setCurrentProject(data.getOneProject);
      setProjectName(data.getOneProject.name);
    },
    onError: (e) => {
      console.log('erreur dans GQL_GET_ONE_PROJECT', e);
    },
  });

  useEffect(() => {
    void getDevis();
    void getProject();
  }, []);

  useEffect(() => {
    if (project) setProjectName(project.name);
  }, [project]);

  async function getDevis() {
    setLoading(true);
    await fetchDevis();
    setLoading(false);
  }

  async function getProject() {
    setLoading(true);
    await fetchProject();
    setLoading(false);
  }

  async function handleSetRetroPlanningDone() {
    if (!project?.id) return;
    const { data: _data } = await setRetroPlanningDone({
      variables: {
        id: project.id,
      },
    });

    if (_data) {
      setCurrentProject({ ...project, retroPlanningDone: true });
    }
  }

  async function handleChangeProjectName() {
    if (!project) return;
    await updateProject({
      variables: {
        id: project.id,
        name: projectName,
      },
    });
  }

  return (
    <>
      <DefaultLayout>
        <ManagerLayout>
          {loading ? (
            <p>{i18n._('common.loading')} ...</p>
          ) : (
            <SDivContentWrapper>
              <SDivTitle>
                <Form
                  ref={formRef}
                  name="retro-page"
                  style={{ width: '100%' }}
                  initialValues={{
                    dateSigned: currentDevisProd?.signedDate
                      ? dayjs(currentDevisProd.signedDate as string)
                      : null,
                  }}
                >
                  <Text style={{ marginRight: '10px' }}>
                    {i18n._('form.project-name')}
                  </Text>
                  <Input
                    style={{ width: '250px', marginRight: '10px' }}
                    placeholder={i18n._('form.project-name.placeholder')}
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    suffix={
                      <Tooltip title={i18n._('form.project-name.advice')}>
                        <InfoCircleOutlined
                          style={{ color: 'rgba(0,0,0,.45)' }}
                        />
                      </Tooltip>
                    }
                    onPressEnter={handleChangeProjectName}
                  />
                  <Text style={{ margin: '10px' }}>
                    {i18n._('form.project.manager')}
                  </Text>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: classic.token?.colorPrimary,
                    }}
                  >
                    {project?.projectManager.firstName +
                      ' ' +
                      project?.projectManager.lastName}
                  </Text>

                  <SDiv>
                    <Form.Item
                      name="dateSigned"
                      style={{ margin: '10px 10px' }}
                      label={i18n._('form.date.prod')}
                      rules={[
                        { required: true, message: i18n._('errors.required') },
                      ]}
                    >
                      <DatePicker
                        value={dayjs(dateSigned as Date)}
                        format={dateFormat}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      name="dateFinish"
                      style={{
                        width: '300px',
                        margin: '10px 10px',
                      }}
                      label={i18n._('form.date.prod.end')}
                      rules={[
                        { required: true, message: i18n._('errors.required') },
                      ]}
                    >
                      <ConfigProvider locale={frFR}>
                        <DatePicker
                          disabled
                          value={dayjs(dateFinish as Date)}
                          format={dateFormat}
                        />
                      </ConfigProvider>
                    </Form.Item>
                    <SDivActions>
                      <Tooltip title={i18n._('print.retro-planning')}>
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={() => setIsPrinting(true)}
                          icon={<PrinterOutlined />}
                          type="primary"
                          shape="circle"
                        />
                      </Tooltip>

                      <Button
                        style={{ width: '200px' }}
                        disabled={
                          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                          currentProject?.retroPlanningDone ||
                          currentDevisProd === null
                        }
                        onClick={handleSetRetroPlanningDone}
                        type="primary"
                      >
                        <Trans id="common.actions.retro.finish" />
                      </Button>
                    </SDivActions>
                  </SDiv>
                </Form>
              </SDivTitle>
              <SDivWrapper>
                <TasksRetroPlanning
                  isPrinting={isPrinting}
                  changeIsPrinting={setIsPrinting}
                />
              </SDivWrapper>
            </SDivContentWrapper>
          )}
        </ManagerLayout>
      </DefaultLayout>
    </>
  );
}

export default RetroPlanningPage;

const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SDivTitle = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const SDivWrapper = styled.div`
  display: flex;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const SDivActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
