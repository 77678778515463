import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { StoredProjectUserTimesInfo } from '~/gql/time/time';
import { getHoursAndMinutesStrByDurationForTimes } from '~/utils/utils/utils';

type IProps = {
  data: StoredProjectUserTimesInfo;
  projectName: string;
  startDate: Date;
  endDate: Date;
  fileName: string;
};

export const exportProjectDetails = async ({
  data,
  projectName,
  startDate,
  endDate,
  fileName,
}: IProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Employées-Réalisé');

  // ajouter une ligne projectName
  const _projectName = [`Nom de projet :`, ` ${projectName}`];
  worksheet.addRow(_projectName);
  worksheet.getCell('B1').font = {
    bold: true,
  };

  worksheet.mergeCells(1, 2, 1, 10);
  worksheet.addRow([]);

  worksheet.getColumn(1).width = 15;
  worksheet.getColumn(2).width = 30;

  const _date = [
    `Du ${new Date(startDate).toLocaleDateString()} au ${new Date(endDate).toLocaleDateString()}`,
  ];
  const dateRow = worksheet.addRow(_date);
  _date.forEach((_cell, index) => {
    const excelCell = dateRow.getCell(1 + index); // headers.length +
    excelCell.font = {
      bold: true,
    };
  });

  worksheet.mergeCells(3, 1, 3, 10);
  worksheet.addRow([]);

  const headers = ['Date', "Nom de l'employée", 'Durée', 'Durée min'];
  const headerRow = worksheet.addRow(headers);
  headers.forEach((_cell, index) => {
    const excelCell = headerRow.getCell(1 + index); // 1 = colonne de début
    excelCell.font = {
      bold: true,
    };
    excelCell.border = {
      bottom: { style: 'thin', color: { argb: '000B446F' } },
    };
  });

  // ajouter les données
  let currentDateRow = 6; // numéro de row où commence le tableau

  for (const date of data.calendar) {
    let currentRow = currentDateRow;
    for (const time of date.userTime) {
      const calendarData: any[] = [];
      calendarData.push(`${new Date(date.date as Date).toLocaleDateString()} `);
      calendarData.push(`${time.user?.lastName} ${time.user?.firstName}`);
      calendarData.push(
        `${getHoursAndMinutesStrByDurationForTimes(time.duration)} `
      );
      calendarData.push(time.duration);
      worksheet.addRow(calendarData);
      currentRow = currentRow + 1;
    }
    if (currentRow !== currentDateRow + 1) {
      worksheet.mergeCells(currentDateRow, 1, currentRow - 1, 1);
    }
    currentDateRow = currentRow;
  }

  worksheet.addRow([]);

  // footer
  const total = data.calendar.reduce(function (acc: any, obj: any) {
    return obj.totalTimeByDate !== undefined ? acc + obj.totalTimeByDate : acc;
  }, 0);

  const footers = [
    '',
    'Total',
    `${getHoursAndMinutesStrByDurationForTimes(total)} `,
    total,
  ];

  const footRow = worksheet.addRow(footers);
  for (let index = 1; index < footers.length; index++) {
    //const element = footers[index];
    const excelCell = footRow.getCell(1 + index); // headers.length +
    excelCell.font = {
      bold: true,
    };
    excelCell.border = {
      top: { style: 'thin', color: { argb: '000B446F' } },
    };
  }
  // footers.forEach((_cell, index) => {
  //   const excelCell = footRow.getCell(2 + index); // headers.length +
  //   excelCell.font = {
  //     bold: true,
  //   };
  //   excelCell.border = {
  //     top: { style: 'thin', color: { argb: 'FF00FF00' } },
  //   };
  // });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};
