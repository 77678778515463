import { useParams } from 'react-router-dom';
import UserTasksTimesTable from '~/pages/components/common/time/UserTasksTimesTable';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import EmployeesLayout from '~/pages/layouts/EmployeesLayout';

function EmployeeDetailsPage() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <DefaultLayout>
        <EmployeesLayout>
          {id && <UserTasksTimesTable ids={[Number(id)]} />}
        </EmployeesLayout>
      </DefaultLayout>
    </>
  );
}

export default EmployeeDetailsPage;
