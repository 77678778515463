import { Selector, $, GraphQLTypes, InputType } from '@zeus/index';

import { typedGql } from '@zeus/typedDocumentNode';
import { taskShortInfoSelector } from '~/gql/task/task';

const projectInfoForSuperProjectSelector = Selector(
  'ProjectInfoForSuperProject'
)({
  id: true,
  name: true,
});

const SuppRentaInfoSelector = Selector('SuppRentaInfo')({
  amountByTasks: true,
  amountSpendByTasks: true,
  durationByTasks: true,
  timesByTasks: true,
});
const devisInitRentaInfoSelector = Selector('DevisInitRentaInfo')({
  id: true,
  amount: true,
  duration: true,
  durationExpected: true,
  durationByTasks: true,
  durationUsed: true,
  amountUsed: true,
  customPosteName: true,
  functionType: true,
  amountByTasks: true,
  amountSpendByTasks: true,
  timesByTasks: true,
  deltaDurationProd: true,
  deltaAmountProd: true,
  deltaDurationSold: true,
  deltaSommeSold: true,
  // TODO add tasks
  poste: {
    id: true,
    name: true,
  },
});
const taskRentaInfoSelector = Selector('TaskRentaInfo')({
  id: true,
  amount: true,
  amountSpend: true,
  calculatedAsAmount: true,
  deltaDuration: true,
  duration: true,
  name: true,
  order: true,
  sommeTime: true,
  supp: true,
  userRentaInfo: {
    userId: true,
    userFirstName: true,
    userLastName: true,
    planningDuration: true,
    sommeTime: true,
    deltaDuration: true,
  },
  time: {
    id: true,
    comment: true,
    date: true,
    duration: true,
    taskId: true,
    user: {
      firstName: true,
      lastName: true,
      id: true,
    },
    userId: true,
  },
});

export const projectRentaInfoBaseSelector = Selector('ProjectRentaInfoBase')({
  projectId: true,
  name: true,
  rentaPercent: true,
  projectDurationSold: true,
  projectAmountSold: true,
  projectDuration: true,
  projectDeltaDurationProd: true,
  projectDeltaAmountProd: true,
  projectSommeTime: true,
  projectSommeAmount: true,
  projectSommeAmountSpend: true,
  projectDurationUsed: true,
  projectAmountUsed: true,
  projectDeltaDurationSold: true,
  projectDeltaSommeSold: true,
});

const projectRentaInfoSelector = Selector('ProjectRentaInfo')({
  id: true,
  name: true,
  projectType: true,
  renta: projectRentaInfoBaseSelector,
  projectManager: {
    id: true,
    firstName: true,
    lastName: true,
  },
  devisInit: devisInitRentaInfoSelector,
  tasks: taskRentaInfoSelector,
  supp: SuppRentaInfoSelector,
});

const projecInfoSelector = Selector('ProjectInfo')({
  id: true,
  name: true,
  startDateInit: true,
  startDatePlanning: true,
  marginPercent: true,
  projectManagerId: true,
  retroPlanningDone: true,
  planningDone: true,
});

const projecFullInfoSelector = Selector('ProjectFullInfo')({
  id: true,
  name: true,
  startDateInit: true,
  startDatePlanning: true,
  marginPercent: true,
  projectManagerId: true,
  projectManager: {
    firstName: true,
    lastName: true,
    email: true,
    roleName: true,
    id: true,
  },
  retroPlanningDone: true,
  planningDone: true,
});

const projectShortInfoSelector = Selector('ProjectShortInfo')({
  id: true,
  name: true,
  tasks: taskShortInfoSelector,
});

export const GQL_CREATE_PROJECT = typedGql('mutation')({
  createProject: [
    {
      createProjectInput: {
        name: $('name', 'String!'),
        projectManagerId: $('projectManagerId', 'Int!'),
      },
    },
    true,
  ],
});

export const GQL_CREATE_PROJECT_INTERN = typedGql('mutation')({
  createProjectIntern: [
    {
      createProjectInput: {
        name: $('name', 'String!'),
        projectManagerId: $('projectManagerId', 'Int!'),
      },
    },
    true,
  ],
});

export const GQL_UPDATE_PROJECT = typedGql('mutation')({
  updateProject: [
    {
      updateProjectInput: {
        id: $('id', 'Int!'),
        name: $('name', 'String'),
        projectManagerId: $('projectManagerId', 'Int'),
        marginPercent: $('marginPercent', 'Int'),
      },
    },
    true,
  ],
});

export const GQL_GET_ONE_PROJECT = typedGql('query')({
  getOneProject: [
    {
      id: $('id', 'Int!'),
    },
    projecFullInfoSelector,
  ],
});

export const GQL_GET_RENTA_BY_PROJECT_ID = typedGql('query')({
  getRentaByProjectId: [
    {
      id: $('id', 'Int!'),
    },
    projectRentaInfoSelector,
  ],
});

export const GQL_FILTERED_PROJECTS = typedGql('query')({
  getFilteredProjectsInfo: [
    {
      filterProjectInput: {
        skip: $('skip', 'Int'),
        take: $('take', 'Int'),
        searchInProjectName: $('searchInProjectName', 'String'),
        projectType: $('projectType', 'ProjectType!'),
      },
    },
    {
      projects: { ...projecInfoSelector },
      total: true,
    },
  ],
});

export const GQL_PROJECTS_BY_MANAGER = typedGql('query')({
  getAllProjectByManager: {
    ...projectInfoForSuperProjectSelector,
  },
});

export const GQL_PROJECTS_WITH_TASKS = typedGql('query')({
  getAllProjectsWithTasks: [
    {
      takeAll: $('takeAll', 'Boolean!'),
    },
    projectShortInfoSelector,
  ],
});

export const GQL_DELETE_PROJECT = typedGql('mutation')({
  deleteProject: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_SET_RETRO_PLANNING_DONE = typedGql('mutation')({
  setRetroPlanningDone: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_UPDATE_RETRO_PLANNING_DONE = typedGql('mutation')({
  updateRetroPlanningDone: [
    {
      id: $('id', 'Int!'),
      retroPlanningDone: $('retroPlanningDone', 'Boolean!'),
    },
    true,
  ],
});

export const GQL_SET_PLANNING_DONE = typedGql('mutation')({
  setPlanningDone: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_UPDATE_PLANNING_DONE = typedGql('mutation')({
  updatePlanningDone: [
    {
      id: $('id', 'Int!'),
      planningDone: $('planningDone', 'Boolean!'),
    },
    true,
  ],
});

export type StoredProjectInfo = InputType<
  GraphQLTypes['ProjectInfo'],
  typeof projecInfoSelector
>;

export type StoredProjectFullInfo = InputType<
  GraphQLTypes['ProjectFullInfo'],
  typeof projecFullInfoSelector
>;

export type StoredProjectShortInfo = InputType<
  GraphQLTypes['ProjectShortInfo'],
  typeof projectShortInfoSelector
>;

export type StoredProjectRentaInfo = InputType<
  GraphQLTypes['ProjectRentaInfo'],
  typeof projectRentaInfoSelector
>;

export type StoredTaskRentaInfo = InputType<
  GraphQLTypes['TaskRentaInfo'],
  typeof taskRentaInfoSelector
>;

export type StoredDevisInitRentaInfo = InputType<
  GraphQLTypes['DevisInitRentaInfo'],
  typeof devisInitRentaInfoSelector
>;

export type StoredSuppRentaInfo = InputType<
  GraphQLTypes['SuppRentaInfo'],
  typeof SuppRentaInfoSelector
>;

export type StoredProjectForSuperProject = InputType<
  GraphQLTypes['ProjectInfoForSuperProject'],
  typeof projectInfoForSuperProjectSelector
>;

export type StoredProjectRentaInfoBase = InputType<
  GraphQLTypes['ProjectRentaInfoBase'],
  typeof projectRentaInfoBaseSelector
>;
