import { GraphQLTypes, InputType, Selector } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { scalars } from '~/gql/utils/scalars';

const functionSelector = Selector('Function')({
  id: true,
  name: true,
  order: true,
});

export const GQL_FUNCTIONS = typedGql('query', { scalars })({
  getFunctions: {
    ...functionSelector,
  },
});

export type StoredFunction = InputType<
  GraphQLTypes['Function'],
  typeof functionSelector
>;
