import { DeleteOutlined } from '@ant-design/icons';
import { useLingui, Trans } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import {
  InputNumber,
  Input,
  Button,
  Tooltip,
  Checkbox,
  //CheckboxProps,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { StoredFunction } from '~/gql/function/function';
import {
  SDevisTh,
  SDevisTd,
  SDevisTableHead,
  SDevisTable,
  SDivBtnAddRow,
} from '~/pages/components/styles/styles';
import DurationItem from '~/pages/components/ui/DurationItem';
import { DevisInitRow, DevisProdRow } from '~/pages/types/types';

type IProps = {
  row?: DevisInitRow;
  tasks: DevisProdRow[];
  functionTypes: StoredFunction[];
  onTasksChange?: (tasks: DevisProdRow[], id: number) => void;
  withoutAdd?: boolean;
  disabled?: boolean;
};

function AddTaskByDevisInitRow({
  row,
  onTasksChange,
  tasks,
  withoutAdd,
  disabled,
}: IProps) {
  const { i18n } = useLingui();

  const [currentTasks, setCurrentTasks] = useState<DevisProdRow[]>(tasks);
  const [tasksAmount, setTasksAmount] = useState<number>(0);
  const [tasksDuration, setTasksDuration] = useState<number>(0);

  useEffect(() => {
    setCurrentTasks(tasks);
  }, [row, tasks]);

  useEffect(() => {
    setTasksAmount(
      tasks.reduce(function (acc: any, obj: any) {
        return acc + obj.amount;
      }, 0)
    );
    setTasksDuration(
      tasks.reduce(function (acc: any, obj: any) {
        return acc + obj.duration;
      }, 0)
    );
  }, [currentTasks]);

  const onChangeTaskName = (name: string, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].taskName = name;
      setCurrentTasks(_tasks);
      onTasksChange && onTasksChange(_tasks, row?.id ?? 0);
    }
  };

  const onChangeTaskAmount = (amount: number, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].amount = amount;
      setCurrentTasks(_tasks);
      onTasksChange && onTasksChange(_tasks, row?.id ?? 0);
    }
  };

  const onChangeTaskDuration = (duration: number, index: number) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].duration = duration;
      setCurrentTasks(_tasks);
      onTasksChange && onTasksChange(_tasks, row?.id ?? 0);
    }
  };

  const onChangeTaskVisibleInRetro = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].visibleInRetro = e.target.checked;
      setCurrentTasks(_tasks);
      onTasksChange && onTasksChange(_tasks, row?.id ?? 0);
    }
  };

  const onChangeTaskCalculatedAsAmount = (
    e: CheckboxChangeEvent,
    index: number
  ) => {
    const _tasks = [...currentTasks];
    if (_tasks[index]) {
      _tasks[index].calculatedAsAmount = e.target.checked;
      setCurrentTasks(_tasks);
      onTasksChange && onTasksChange(_tasks, row?.id ?? 0);
    }
  };

  function addElement() {
    const _rows = [...currentTasks];

    _rows.push({
      functionType: row?.functionType ?? FunctionType.GP,
      amount: 0,
      duration: 0,
      devisInitId: row?.id ?? 0,
      taskName: '',
      visibleInRetro: true,
      supp: false,
      calculatedAsAmount: false,
    });
    setCurrentTasks(_rows);
  }

  function onDeleteClick(ind: number) {
    const _tasks = [...currentTasks];
    _tasks.splice(ind, 1);
    setCurrentTasks(_tasks);
    onTasksChange && onTasksChange(_tasks, row?.id ?? 0);
  }

  return (
    <SDevisTable $margin="0 0">
      <SDevisTableHead>
        <tr>
          <SDevisTh $padding="0 5px" $minWidth="80px" $textAlign="left">
            {i18n._(row?.functionType ?? '')}
          </SDevisTh>
          <SDevisTh $minWidth="280px" $padding="0px">
            <SDivDetails>
              <InputNumber
                style={{ width: '150px', margin: '5px', fontWeight: 'bold' }}
                placeholder={i18n._('form.amount.placeholder')}
                value={row?.amount ?? 0}
                controls={false}
                step="0.01"
                disabled={true}
                addonAfter="€"
              />
              <DurationItem
                $bold
                $allowCorrection={false}
                duration={row?.duration ?? 0}
              />
            </SDivDetails>
          </SDevisTh>
          <SDevisTh $padding="0px" $boldInputNumber>
            <InputNumber
              style={{ width: '150px', margin: '5px' }}
              placeholder={i18n._('form.amount.placeholder')}
              controls={false}
              value={tasksAmount}
              step="0.01"
              addonAfter="€"
              disabled={true}
            />
          </SDevisTh>
          <SDevisTh $padding="0px">
            <DurationItem
              $bold
              $allowCorrection={false}
              duration={tasksDuration}
            />
          </SDevisTh>
          <SDevisTh>In retro ?</SDevisTh>
          <SDevisTh>Calc comme somme</SDevisTh>
        </tr>
      </SDevisTableHead>
      <tbody>
        {currentTasks.map((task, index) => (
          <tr key={index}>
            <SDevisTd $textAlign="left" $padding="0 0 0 15px">
              {task.functionType}
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <Input
                placeholder={i18n._('form.task-name.placeholder')}
                onChange={(e) => onChangeTaskName(e.target.value, index)}
                value={task.taskName}
                disabled={disabled}
              />
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <InputNumber
                style={{ width: '150px', margin: '5px' }}
                placeholder={i18n._('form.amount.placeholder')}
                onChange={(value) => onChangeTaskAmount(value ?? 0, index)}
                controls={false}
                value={task.amount}
                step="0.01"
                addonAfter="€"
                disabled={disabled}
              />
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <DurationItem
                $allowCorrection={!disabled}
                duration={task.duration}
                onChange={(value) => onChangeTaskDuration(value, index)}
              />
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <Checkbox
                checked={task.visibleInRetro}
                onChange={(e) => onChangeTaskVisibleInRetro(e, index)}
              />
            </SDevisTd>
            <SDevisTd $textAlign="center">
              <Checkbox
                checked={task.calculatedAsAmount}
                onChange={(e) => onChangeTaskCalculatedAsAmount(e, index)}
              />
            </SDevisTd>
            {!disabled && (
              <SDevisTd>
                <Tooltip title="supprimer">
                  <Button
                    type="default"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => onDeleteClick(index)}
                  />
                </Tooltip>
              </SDevisTd>
            )}
          </tr>
        ))}
        {!withoutAdd && (
          <tr>
            <th colSpan={3}>
              <SDivBtnAddRow>
                <Button
                  onClick={addElement}
                  type="default"
                  style={{ backgroundColor: '#0B446F4D', color: '#0B446F' }}
                >
                  <Trans id="common.add.row" />
                </Button>
              </SDivBtnAddRow>
            </th>
          </tr>
        )}
      </tbody>
    </SDevisTable>
  );
}

export default AddTaskByDevisInitRow;

const SDivDetails = styled.div`
  align-items: center;
  display: flex;
  & > div > div > div > div > input {
    font-weight: bold;
  }
`;
