import { GraphQLTypes, InputType, Selector } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { scalars } from '~/gql/utils/scalars';

const rolesSelector = Selector('Roles')({
  id: true,
  name: true,
});

export const GQL_ROLES = typedGql('query', { scalars })({
  getRoles: {
    ...rolesSelector,
  },
});

export type StoredRoles = InputType<
  GraphQLTypes['Roles'],
  typeof rolesSelector
>;
