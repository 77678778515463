import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { parseJwt } from '~/utils/jwt';

export type Payload = {
  given_name?: string;
  family_name?: string;
  upn?: string;
  email: string;
  firstName: string;
  lastName: string;
  roles?: string;
  sub: number; // User id
  exp: number; // Date of expiration
  iat: number; // Date of creation
  access_token: string;
  refresh_token: string;
};

type AuthState = {
  imLoggedAndValid: () => boolean;
  payload: Payload | null;
  setPayload: (payload: Payload) => void;
  unSetPayload: () => void;
  logout: () => void;
};

export const useAuth = create<AuthState>()(
  persist(
    (set, get) => ({
      imLoggedAndValid: () => {
        if (typeof window === 'undefined') return false;
        const payload = parseJwt(get().payload?.access_token ?? 'vide');
        const payloadStorage = get().payload;
        if (payload && payloadStorage) {
          const newData: Payload = {
            access_token: payloadStorage.access_token,
            refresh_token: payloadStorage.refresh_token,
            firstName: payload.firstName,
            lastName: payload.lastName,
            exp: payloadStorage.exp,
            iat: payloadStorage.iat,
            sub: payloadStorage.sub,
            email: payload.email,
            roles: payload.roles,
          };
          if (!newData.refresh_token && newData.exp * 1000 < Date.now()) {
            get().unSetPayload();
            return false;
          }
          set({
            payload: newData,
          });
          return true;
        }

        get().unSetPayload();
        return false;
      },
      payload: null,
      setPayload: (payload: Payload) => {
        set({
          payload,
        });
      },
      unSetPayload: () => {
        set({
          payload: null,
        });
      },
      logout: () => {
        get().unSetPayload();
      },
    }),
    {
      name: 'auth-storage',
    }
  )
);
