import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useLingui } from '@lingui/react';
import { ProjectType } from '@zeus/index';
//import { Tooltip } from 'antd'
import styled from 'styled-components';

import { getColorForDuration } from '~/pages/components/common/utils-table/utils';
//import { getColorForDuration } from '~/pages/components/common/utils-table/utils';

import {
  SDevisTable,
  SDevisTableHead,
  STableTr,
  SRentaSpan,
} from '~/pages/components/styles/styles';
import { TTaskForUserInfo } from '~/pages/types/types';

import { classic } from '~/themes';
import { getHoursAndMinutesStrByDurationForTimes } from '~/utils/utils/utils';
//import { getHoursAndMinutesStrByDurationForTimes } from '~/utils/utils/utils';

type IProps = {
  tasks: TTaskForUserInfo[];
  onTaskOpen: (id: number) => void;
};

function TasksInfoByUser({ tasks, onTaskOpen }: IProps) {
  const { i18n } = useLingui();
  return (
    <Container>
      <STable>
        <SDevisTableHead>
          <tr>
            <th>{i18n._('common.project')}</th>
            <th>{i18n._('common.task.assigned')}</th>
            <th>{i18n._('common.hours.planning')}</th>
            <th>{i18n._('common.times.hours')}</th>
            <th>{i18n._('common.result')}</th>
            <th>{i18n._('form.project.manager')}</th>
          </tr>
        </SDevisTableHead>
        <tbody>
          {tasks.map((t, index) => (
            <STableTrBorder key={index}>
              <STd
                $height={
                  t.isOpened
                    ? `${t.timeAndUserPlanning.length * 22 + 22}px`
                    : '22px'
                }
                $align="left"
                $fontStyle={
                  t.projectType === ProjectType.EXTERN ? 'normal' : 'italic'
                }
              >
                <SDivWrapperItem>{t.projectName}</SDivWrapperItem>
              </STd>
              <STd
                $height={
                  t.isOpened
                    ? `${t.timeAndUserPlanning.length * 22 + 22}px`
                    : '22px'
                }
              >
                <SDivWrapperItem>
                  <SDivTaskItem
                    $align="space-between"
                    onClick={() => onTaskOpen(t.id)}
                  >
                    <SSpan
                      $fontStyle={
                        t.projectType === ProjectType.EXTERN
                          ? 'normal'
                          : 'italic'
                      }
                    >
                      {t.name}
                    </SSpan>

                    {t.timeAndUserPlanning.length > 0 && (
                      <span style={{ fontWeight: 'bold' }}>
                        {t.isOpened ? (
                          <CaretUpOutlined />
                        ) : (
                          <CaretDownOutlined />
                        )}
                      </span>
                    )}
                  </SDivTaskItem>
                  {t.isOpened && (
                    <SDivOpenedItem>
                      {t.timeAndUserPlanning.map((tAndUp, ind) => (
                        <SDivTaskItem
                          $borderTop={ind === 0 ? true : false}
                          $borderBotton={
                            ind === t.timeAndUserPlanning.length - 1
                              ? false
                              : true
                          }
                          key={ind}
                          $align="flex-end"
                        >
                          {new Date(tAndUp.date as Date).toLocaleDateString()}
                        </SDivTaskItem>
                      ))}
                    </SDivOpenedItem>
                  )}
                </SDivWrapperItem>
              </STd>
              <STd
                $height={
                  t.isOpened
                    ? `${t.timeAndUserPlanning.length * 22 + 22}px`
                    : '22px'
                }
              >
                <SDivWrapperItem>
                  <SDivTaskItem $align="flex-end">
                    {t.allUserPlanning
                      ? getHoursAndMinutesStrByDurationForTimes(
                          t.allUserPlanning
                        )
                      : '-'}
                  </SDivTaskItem>
                  {t.isOpened && (
                    <SDivOpenedItem>
                      {t.timeAndUserPlanning.map((tAndUp, ind) => (
                        <SDivTaskItem
                          $borderTop={ind === 0 ? true : false}
                          $borderBotton={
                            ind === t.timeAndUserPlanning.length - 1
                              ? false
                              : true
                          }
                          key={ind}
                          $align="flex-end"
                        >
                          {tAndUp.userPlanningDuration
                            ? getHoursAndMinutesStrByDurationForTimes(
                                tAndUp.userPlanningDuration
                              )
                            : '-'}
                        </SDivTaskItem>
                      ))}
                    </SDivOpenedItem>
                  )}
                </SDivWrapperItem>
              </STd>
              <STd
                $height={
                  t.isOpened
                    ? `${t.timeAndUserPlanning.length * 22 + 22}px`
                    : '22px'
                }
              >
                <SDivWrapperItem>
                  <SDivTaskItem $align="flex-end">
                    {t.allTime
                      ? getHoursAndMinutesStrByDurationForTimes(t.allTime)
                      : '-'}
                  </SDivTaskItem>
                  {t.isOpened && (
                    <SDivOpenedItem>
                      {t.timeAndUserPlanning.map((tAndUp, ind) => (
                        <SDivTaskItem
                          $borderTop={ind === 0 ? true : false}
                          $borderBotton={
                            ind === t.timeAndUserPlanning.length - 1
                              ? false
                              : true
                          }
                          key={ind}
                          $align="flex-end"
                        >
                          {tAndUp.timeDuration
                            ? getHoursAndMinutesStrByDurationForTimes(
                                tAndUp.timeDuration
                              )
                            : '-'}
                        </SDivTaskItem>
                      ))}
                    </SDivOpenedItem>
                  )}
                </SDivWrapperItem>
              </STd>
              <STd
                $height={
                  t.isOpened
                    ? `${t.timeAndUserPlanning.length * 22 + 22}px`
                    : '22px'
                }
              >
                <SDivWrapperItem>
                  <SDivTaskItem $align="flex-end">
                    <SRentaSpan
                      color={getColorForDuration(t.allUserPlanning - t.allTime)}
                    >
                      {t.projectType === ProjectType.INTERN
                        ? '-'
                        : t.allUserPlanning - t.allTime
                          ? getHoursAndMinutesStrByDurationForTimes(
                              t.allUserPlanning - t.allTime
                            )
                          : '-'}
                    </SRentaSpan>
                  </SDivTaskItem>
                  {t.isOpened && (
                    <SDivOpenedItem>
                      {t.timeAndUserPlanning.map((tAndUp, ind) => (
                        <SDivTaskItem
                          $borderTop={ind === 0 ? true : false}
                          $borderBotton={
                            ind === t.timeAndUserPlanning.length - 1
                              ? false
                              : true
                          }
                          key={ind}
                          $align="flex-end"
                        >
                          <SRentaSpan
                            color={getColorForDuration(
                              tAndUp.userPlanningDuration - tAndUp.timeDuration
                            )}
                          >
                            {t.projectType === ProjectType.EXTERN
                              ? getHoursAndMinutesStrByDurationForTimes(
                                  tAndUp.userPlanningDuration -
                                    tAndUp.timeDuration
                                )
                              : '-'}
                          </SRentaSpan>
                        </SDivTaskItem>
                      ))}
                    </SDivOpenedItem>
                  )}
                </SDivWrapperItem>
              </STd>

              <STd
                $align="right"
                $height={
                  t.isOpened
                    ? `${t.timeAndUserPlanning.length * 22 + 22}px`
                    : '22px'
                }
              >
                <SDivWrapperItem>
                  <SDivTaskItem $align="left">{`${t.projectManagerFirstName} ${t.projectManagerLastName}`}</SDivTaskItem>
                </SDivWrapperItem>
              </STd>
            </STableTrBorder>
          ))}
        </tbody>
      </STable>
    </Container>
  );
}

export default TasksInfoByUser;

const Container = styled.div<{ $firstBorderTop?: boolean }>`
  width: 100%;
  & > div:first-child {
    border-top: ${({ $firstBorderTop }) =>
      $firstBorderTop
        ? `1px solid ${classic.token?.colorFillSecondary}`
        : 'none'};
  }
`;

const SDivWrapperItem = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: flex-start;
`;

const SSpan = styled.span<{ $fontStyle?: string; $fontWeight?: string }>`
  font-style: ${({ $fontStyle }) => ($fontStyle ? $fontStyle : 'normal;')};
`;

export const SDivTaskItem = styled.div<{
  fontWeight?: string;
  $align?: string;
  $bg?: string;
  $borderBotton?: boolean;
  $borderTop?: boolean;
}>`
  align-items: center;
  border-top: ${({ $borderTop }) =>
    $borderTop ? '1px solid #0b446f80' : 'nome'};
  border-bottom: ${({ $borderBotton }) =>
    $borderBotton ? '1px solid #0b446f80' : 'nome'};
  display: flex;
  background-color: ${({ $bg }) => ($bg ? $bg : 'transparent')};
  justify-content: ${({ $align }) => ($align ? $align : 'flex-end')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
  width: 100%;
  padding: 0 5px;
`;

const STd = styled.td<{
  $align?: string;
  $fontStyle?: string;
  $height?: string;
}>`
  font-style: ${({ $fontStyle }) => ($fontStyle ? $fontStyle : 'normal')};
  text-align: ${({ $align }) => ($align ? $align : 'right;')};
  height: ${({ $height }) => ($height ? $height : '22px')};
`;

const STable = styled(SDevisTable)`
  border-collapse: collapse;
`;

const STableTrBorder = styled(STableTr)`
  border-bottom: 1px solid grey;
`;

const SDivOpenedItem = styled.div<{ $borderBotton?: boolean }>`
  width: 100%;
`;

// const SDivItem = styled.div<{
//   $width?: string;
//   $align?: string;
//   fontWeight?: string;
// }>`
//   align-items: center;
//   display: flex;
//   justify-content: ${({ $align }) => ($align ? $align : 'center')};
//   font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
//   width: ${({ $width }) => ($width ? $width : 'auto')};
// `;
