/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  GQL_GET_RENTA_BY_SUPER_PROJECT_ID,
  StoredSuperProjectRenta,
} from '~/gql/super-project/super-project';
import { authClient } from '~/helpers/apollo';
import RentaDetails from '~/pages/components/common/details/RentaDetails';
import { classic } from '~/themes/classic';

function SuperProjectRenta() {
  const params = useParams();
  const { id } = params;
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>('');
  const [superProjectRenta, setSuperProjectRenta] =
    useState<StoredSuperProjectRenta | null>(null);

  const { refetch: fetchSuperProjectRenta } = useQuery(
    GQL_GET_RENTA_BY_SUPER_PROJECT_ID,
    {
      variables: {
        id: Number(id),
      },
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setSuperProjectRenta(data.getRentaBySuperProjectId);
        setProjectName(data.getRentaBySuperProjectId.name);
      },

      onError: (e) => {
        console.log('erreur dans GQL_GET_ONE_PROJECT', e);
      },
    }
  );

  useEffect(() => {
    void getSuperProjectRenta();
  }, []);

  useEffect(() => {
    if (superProjectRenta) setProjectName(superProjectRenta?.name);
  }, [superProjectRenta]);

  async function getSuperProjectRenta() {
    setLoading(true);
    await fetchSuperProjectRenta();
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <SDivContentWrapper>
          {superProjectRenta &&
            !superProjectRenta.renta.projectDurationSold && (
              <>
                <SSpan color={`${classic.token?.colorWarning}`}>
                  {i18n._('common.warning')}
                </SSpan>{' '}
                <SSpan
                  color={`${classic.token?.colorWarning}`}
                  $textTransform="normal"
                >
                  {i18n._('common.renta.warning')}
                </SSpan>
              </>
            )}

          <SDivWrapper>
            <SDivHeader>
              <SSpan>
                {i18n._('common.project')} : {projectName}
              </SSpan>
              <SSpan>
                {i18n._('common.intern.renta')} :{' '}
                {superProjectRenta?.renta.rentaPercent.toFixed(2) + '%'}
              </SSpan>
            </SDivHeader>
            {superProjectRenta?.renta && (
              <RentaDetails
                renta={superProjectRenta?.renta}
                projectName={projectName}
              />
            )}
            <br />
            <br />
            {superProjectRenta?.projectsRenta?.map((rentaItem, index) => (
              <div key={index}>
                {rentaItem.renta && (
                  <>
                    <RentaDetails
                      borderTop={index === 0}
                      renta={rentaItem?.renta}
                      projectName={rentaItem.name}
                      withoutHeader
                    />
                  </>
                )}
              </div>
            ))}
            <br />
            <br />
          </SDivWrapper>
        </SDivContentWrapper>
      )}
    </>
  );
}

export default SuperProjectRenta;

const SDivHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: ${classic.token?.colorPrimary};
  border-radius: 8px 8px 0 0;
  padding: 14px;
  width: 100%;
`;
const SDivContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSpan = styled.span<{ color?: string; $textTransform?: string }>`
  color: ${({ color }) => (color ? color : ` ${classic.token?.colorWhite}`)};
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: ${({ $textTransform }) =>
    $textTransform ? $textTransform : 'uppercase'};
`;
