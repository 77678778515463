import {
  UnorderedListOutlined,
  ContactsOutlined,
  LogoutOutlined,
  SettingOutlined,
  TeamOutlined,
  ReadOutlined,
  NotificationOutlined,
  FieldTimeOutlined,
  //AuditOutlined,
  ReadFilled,
  BookOutlined,
} from '@ant-design/icons';
import logo from '@assets/images/mysg-logo-min.png';
import { useLingui } from '@lingui/react';
import { Layout, Menu, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '~/helpers/store/auth';
import { SDivLogo } from '../components/styles/styles';

const { Sider } = Layout;

function NavBar() {
  const { i18n } = useLingui();
  const [value, setValue] = useState<string>('');
  const { payload } = useAuth();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const key = pathname.split('/')[1];

    setValue(key);
  }, [pathname]);

  if (value.length <= 0) {
    return <></>;
  }

  const adminItems = [
    {
      key: 'manager-projects',
      icon: <ReadOutlined />,
      label: (
        <div>
          {i18n._('common.projects')}
          <Link to="/manager-projects" />
        </div>
      ),
    },
    {
      key: 'super-projects',
      icon: <BookOutlined />,
      label: (
        <div>
          {i18n._('common.super.projects')}
          <Link to="/super-projects" />
        </div>
      ),
    },
    // {
    //   key: 'projects-intern',
    //   icon: <AuditOutlined />,
    //   label: (
    //     <div>
    //       {i18n._('common.projects.intern')}
    //       <Link to="/projects-intern" />
    //     </div>
    //   ),
    // },
    {
      key: 'manager-intern',
      icon: <ReadFilled />,
      label: (
        <div>
          {i18n._('common.projects.intern')}
          <Link to="/manager-intern" />
        </div>
      ),
    },
    {
      key: 'employees',
      icon: <ContactsOutlined />,
      label: (
        <div>
          {i18n._('common.employees')}
          <Link to="/employees" />
        </div>
      ),
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: (
        <div>
          {i18n._('common.users')}
          <Link to="/users" />
        </div>
      ),
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: (
        <div>
          {i18n._('common.settings')}
          <Link to="/settings" />
        </div>
      ),
    },
  ];

  // const addItems = [
  //   {
  //     key: 'files',
  //     icon: <FileSyncOutlined />,
  //     label: (
  //       <div>
  //         {i18n._('common.files')}
  //         <Link to="/files" />
  //       </div>
  //     ),
  //   },
  // ];

  const managerItems = [
    {
      key: 'manager-projects',
      icon: <ReadOutlined />,
      label: (
        <div>
          {i18n._('common.projects')}
          <Link to="/manager-projects" />
        </div>
      ),
    },
    {
      key: 'super-projects',
      icon: <ReadOutlined />,
      label: (
        <div>
          {i18n._('common.super.projects')}
          <Link to="/super-projects" />
        </div>
      ),
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: (
        <div>
          {i18n._('common.users')}
          <Link to="/users" />
        </div>
      ),
    },
  ];

  const menuItems = [
    {
      key: 'notifications',
      icon: <NotificationOutlined />,
      label: (
        <div>
          {i18n._('common.notifications')}
          <Link to="/notifications" />
        </div>
      ),
    },
    {
      key: 'times',
      icon: <FieldTimeOutlined />,
      label: (
        <div>
          {i18n._('common.times.user')}
          <Link to="/times" />
        </div>
      ),
    },
    {
      key: 'tasks',
      icon: <UnorderedListOutlined />,
      label: (
        <div>
          {i18n._('common.tasks.user')}
          <Link to="/tasks" />
        </div>
      ),
    },

    // {
    //   key: 'logout',
    //   icon: <LogoutOutlined />,
    //   label: (
    //     <div>
    //       {i18n._('common.logout')}
    //       <Link to="/logout" />
    //     </div>
    //   ),
    // },
  ];

  const menuLogoutItems = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: (
        <div>
          {i18n._('common.logout')}
          <Link to="/logout" />
        </div>
      ),
    },
  ];
  // collapsible collapsed
  return (
    <Sider collapsible breakpoint="lg" theme="light">
      <SDivLogo>
        <img src={logo} alt="" />
      </SDivLogo>
      <Menu
        theme="light"
        mode="inline"
        style={{ paddingTop: '50px', borderInlineEnd: 'none' }}
        defaultSelectedKeys={[value]}
        items={
          payload?.roles === 'Admin' || payload?.roles === 'Viewer'
            ? menuItems.concat(adminItems)
            : payload?.roles === 'Manager'
              ? menuItems.concat(managerItems)
              : menuItems
        }
      />
      <Divider />
      <Menu
        mode="inline"
        theme="light"
        style={{ borderInlineEnd: 'none' }}
        defaultSelectedKeys={[value]}
        items={menuLogoutItems}
      />
    </Sider>
  );
}

export default NavBar;
