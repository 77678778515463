import {
  InfoCircleOutlined,
  SolutionOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';

import { useLingui } from '@lingui/react';
import { Layout, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

const { Sider } = Layout;

function InternNavBar() {
  const { i18n } = useLingui();
  const [value, setValue] = useState<string>('');

  const params = useParams();
  const { id } = params;

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const key = pathname.split('/')[2];

    setValue(key);
  }, [pathname]);

  if (value.length <= 0) {
    return <></>;
  }

  const menuItems = [
    {
      key: 'info',
      icon: <IssuesCloseOutlined />,
      label: (
        <div>
          {i18n._('common.info')}
          <Link to={id ? `/manager-intern/info/${id}` : '/manager-intern'} />
        </div>
      ),
    },

    {
      key: 'project-details',
      icon: <SolutionOutlined />,
      label: (
        <div>
          {i18n._('common.intern.details')}
          <Link
            to={
              id ? `/manager-intern/project-details/${id}` : '/manager-intern'
            }
          />
        </div>
      ),
    },
    {
      key: 'project-renta',
      icon: <InfoCircleOutlined />,
      label: (
        <div>
          {i18n._('common.intern.renta')}
          <Link
            to={id ? `/manager-intern/project-renta/${id}` : '/manager-intern'}
          />
        </div>
      ),
    },
  ];

  return (
    <Sider collapsed breakpoint="lg" theme="light">
      <Menu
        theme="light"
        mode="inline"
        style={{ paddingTop: '50px', borderInlineEnd: 'none' }}
        defaultSelectedKeys={[value]}
        items={menuItems}
      />
    </Sider>
  );
}

export default InternNavBar;
