import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';

import { useLingui, Trans } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import type { DatePickerProps } from 'antd';
import {
  InputNumber,
  Button,
  DatePicker,
  Select,
  Form,
  Tooltip,
  Typography,
  Input,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
} from 'react-beautiful-dnd';
import { styled } from 'styled-components';

import { StoredDevitInit, GQL_UPDATE_DEVIS_INITIAL } from '~/gql/devis/devis';
import { StoredFunction } from '~/gql/function/function';
import { StoredPoste } from '~/gql/poste/poste';
import { GQL_UPDATE_PROJECT } from '~/gql/project/project';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import { HandIcon } from '~/pages/components/common/utils-table/icons';
import { isCanSave } from '~/pages/components/common/utils-table/utils';

import {
  SDevisTh,
  SDevisTd,
  SDevisTableHead,
  SDevisTable,
  SDevisTableFoot,
  SDivBtnAddRow,
  SDivBtn,
} from '~/pages/components/styles/styles';
import DurationItem from '~/pages/components/ui/DurationItem';
import { DevisInitRowExtended } from '~/pages/types/types';

export type TCurrentOrder = {
  currentOrder: number;
};
export type TDevisInitRowExtendedCurrentOrder = TCurrentOrder &
  DevisInitRowExtended;

type IProps = {
  devisInit: StoredDevitInit;
  functionTypes: StoredFunction[];
  postes: StoredPoste[];
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

function EditDevisInitP({
  devisInit,
  functionTypes,
  confirm,
  close,
  postes,
}: IProps) {
  const { i18n } = useLingui();
  const { Text } = Typography;
  const { currentProject, setCurrentProject } = useDataUserStore();
  const [addedCount, setAddedCount] = useState<number>(-1);
  const [rows, setRows] = useState<TDevisInitRowExtendedCurrentOrder[]>([]);
  const [dateSigned, setDateSigned] = useState<Date | undefined>(undefined);
  const [marge, setMarge] = useState<number>(
    currentProject?.marginPercent ?? 0
  );
  const [canBeSaved, setCanBeSaved] = useState<boolean>(true);
  const formRef = React.useRef<FormInstance>(null);

  const [updateDevis] = useMutation(GQL_UPDATE_DEVIS_INITIAL, {
    client: authClient,
  });

  const [updateProject] = useMutation(GQL_UPDATE_PROJECT, {
    client: authClient,
  });

  useEffect(() => {
    if (devisInit.devisInit) {
      const _rows: TDevisInitRowExtendedCurrentOrder[] =
        devisInit.devisInit.map((el: any, index) => {
          const fnEl = functionTypes.find((ft) => ft.name === el.functionType);
          const posteEl = postes.find((p) => p.id === el.posteId);
          return {
            amount: el.amount,
            duration: el.duration,
            durationExpected: el.durationExpected,
            durationUsed: el.durationUsed,
            amountUsed: el.amountUsed,
            functionType: el.functionType,
            functionTypeId: fnEl?.id,
            order: el.order,
            id: el?.id,
            posteId: el.posteId,
            posteName: posteEl?.name ?? '',
            customPosteName: el.customPosteName,
            currentOrder: index,
          };
        });
      setRows(_rows);
    }
  }, [devisInit]);

  useEffect(() => {
    if (currentProject) setMarge(currentProject.marginPercent ?? 0);
  }, [currentProject]);

  function onChangeAmount(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].amount = value;

    setRows(_rows);
  }

  function onChangeAmountUsed(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].amountUsed = value;

    setRows(_rows);
  }

  function onChangeDuration(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].duration = value;
    setRows(_rows);
  }

  function onChangeDurationExpected(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].durationExpected = value;

    setRows(_rows);
  }

  function onChangeDurationUsed(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].durationUsed = value;

    setRows(_rows);
  }

  function onChangeFunctionType(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].functionTypeId = value;
    const _functionType = functionTypes.find((el) => el.id === value);
    if (_functionType) _rows[ind].functionType = _functionType.name;
    setRows(_rows);
  }

  function onChangePoste(value: number, ind: number) {
    const _rows = [...rows];
    _rows[ind].posteId = value;
    const posteEl = postes.find((el) => el.id === value);

    _rows[ind].posteName = posteEl?.name ?? '';
    _rows[ind].customPosteName = '';

    setRows(_rows);
  }

  const onChangeCustomPosteName = (value: string, ind: number) => {
    const _rows = [...rows];
    _rows[ind].customPosteName = value;

    setRows(_rows);
  };

  function onDeleteClick(ind: number) {
    const _rows = [...rows];
    _rows.splice(ind, 1);
    setRows(_rows);
  }

  function addElement() {
    const _rows = [...rows];
    const fnEl = functionTypes.find((el) => el.name === FunctionType.GP);

    _rows.push({
      id: addedCount,
      functionType: FunctionType.GP,
      amount: 0,
      duration: 0,
      durationExpected: 0,
      durationUsed: 0,
      amountUsed: 0,
      functionTypeId: fnEl?.id ?? 1,
      order: _rows.length, //fnEl?.order,
      posteId:
        postes.find((el) => el.name === FunctionType.GP)?.id ?? postes[0].id,
      posteName:
        postes.find((el) => el.name === FunctionType.GP)?.name ??
        postes[0].name,
      customPosteName: '',
      currentOrder: _rows.length,
    });
    setAddedCount((old) => old - 1);
    setRows(_rows);
  }

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0); // setUTCHours
    setDateSigned(new Date(d));
  };

  async function submit() {
    const _rows = [...rows];

    if (currentProject && marge !== currentProject.marginPercent) {
      void handleChangeMarge();
    }

    const { data: _data } = await updateDevis({
      variables: {
        id: devisInit.id,
        ...(dateSigned && { signedDate: dateSigned }),
        createDevisInitInput: _rows
          .filter((r) => r.amount !== 0 || r.duration !== 0) // demandé par cdp de sipprimer cette condition, mais pas accepté par direction de projet
          .map((el, index) => {
            return {
              ...(el.id && el.id > 0 && { id: el.id }),
              functionType: el.functionType as FunctionType,
              amount: el.amount,
              duration: el.duration,
              durationExpected: el.durationExpected,
              durationUsed: el.durationUsed,
              amountUsed: el.amountUsed,
              posteId: el.posteId,
              order: index,
              ...(el.customPosteName !== undefined && {
                customPosteName: el.customPosteName,
              }),
            };
          }),
      },
    });

    if (_data) {
      confirm();
    }
    close();
  }

  async function handleChangeMarge() {
    if (!currentProject?.id) return;

    const { data: _data } = await updateProject({
      variables: {
        id: currentProject.id,
        name: currentProject.name,
        marginPercent: Number(marge),
      },
    });

    if (_data) {
      setCurrentProject({ ...currentProject, marginPercent: marge });
    }
  }

  function handlerDragEnd(result: DropResult) {
    const tmpItems = [...rows];
    const [selectedRow] = tmpItems.splice(result.source.index, 1);
    if (result.destination) {
      tmpItems.splice(result.destination.index, 0, selectedRow);
      setRows(tmpItems);
    }
  }

  useEffect(() => {
    if (rows.length !== 0) {
      setCanBeSaved(isCanSave(rows));
    }
  }, [rows]);

  return (
    <Container>
      <Form
        {...layout}
        ref={formRef}
        name="edit-devis-initp"
        initialValues={{
          dateSigned: devisInit.signedDate
            ? dayjs(devisInit.signedDate as string)
            : null,
          marge: currentProject?.marginPercent ?? 0,
        }}
        style={{ maxWidth: '100%' }}
      >
        <SDivBtn>
          <Button
            style={{ marginRight: '10px' }}
            htmlType="submit"
            onClick={close}
            type="default"
          >
            <Trans id="common.actions.cancel" />
          </Button>
          <Button
            htmlType="submit"
            style={{ width: '200px' }}
            onClick={submit}
            type="primary"
            disabled={!canBeSaved}
          >
            <Trans id="devis.init.save" />
          </Button>
        </SDivBtn>
        <SDiv>
          <SDivItem>
            <Text style={{ marginRight: '10px' }}>
              {i18n._('form.date.signed')} :
            </Text>
            <Form.Item name="dateSigned" style={{ margin: '10px 10px' }}>
              <DatePicker
                style={{ width: '150px' }}
                format={dateFormat}
                onChange={onChangeDate}
                minDate={dayjs().subtract(2, 'year')}
              />
            </Form.Item>
          </SDivItem>
          <SDivItem>
            <Text style={{ marginRight: '10px' }}>
              {i18n._('form.marge.expected')} :
            </Text>
            <Form.Item
              name="marge"
              style={{ width: '100%', margin: '10px 10px' }}
            >
              <InputNumber
                style={{ width: '150px', marginRight: '10px' }}
                placeholder={i18n._('form.marge.placeholder')}
                onChange={(value) => setMarge(value ?? 0)}
                controls={false}
                min={0}
                value={marge}
                addonAfter="%"
              />
            </Form.Item>
          </SDivItem>
        </SDiv>
        <DragDropContext onDragEnd={(results) => handlerDragEnd(results)}>
          <SDevisTable>
            <SDevisTableHead>
              <tr>
                <SDevisTh $minWidth="30px"></SDevisTh>
                <SDevisTh $minWidth="80px">
                  {i18n._('common.function.type')}
                </SDevisTh>
                <SDevisTh $minWidth="150px">{i18n._('common.poste')}</SDevisTh>
                <SDevisTh $minWidth="150px">{i18n._('common.amount')}</SDevisTh>
                <SDevisTh $minWidth="120px">
                  {i18n._('common.duration')}
                </SDevisTh>

                <SDevisTh $minWidth="120px">
                  <Tooltip title={i18n._('table.duration.expected.tipp')}>
                    {i18n._('common.duration.expected')}{' '}
                  </Tooltip>
                </SDevisTh>

                <SDevisTh $minWidth="120px">
                  <Tooltip title={i18n._('table.duration.used.tipp')}>
                    {i18n._('common.duration.used')}{' '}
                  </Tooltip>
                </SDevisTh>

                <SDevisTh $minWidth="150px">
                  {' '}
                  <Tooltip title={i18n._('table.amount.used.tipp')}>
                    {i18n._('common.amount.used')}{' '}
                  </Tooltip>
                </SDevisTh>
              </tr>
            </SDevisTableHead>
            <Droppable droppableId="1234">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {rows.map((el, index) => (
                    <Draggable
                      draggableId={el.currentOrder.toString()}
                      index={index}
                      key={el.id}
                    >
                      {(providedDraggable) => (
                        <tr
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                        >
                          <SDevisTh
                            $minWidth="30px"
                            {...providedDraggable.dragHandleProps}
                          >
                            <SDivTableItem>
                              <SIcon>
                                <HandIcon style={{ fontSize: '28px' }} />
                              </SIcon>
                            </SDivTableItem>
                          </SDevisTh>
                          <SDevisTd>
                            <SDivTableItem>
                              <Select
                                showSearch
                                style={{ width: '80px' }}
                                placeholder={i18n._(
                                  'form.project.manager.placeholder'
                                )}
                                value={el.functionTypeId}
                                onChange={(value) =>
                                  onChangeFunctionType(value, index)
                                }
                                optionFilterProp="label"
                                options={functionTypes.map((item) => ({
                                  value: item.id,
                                  label: i18n._(item.name),
                                }))}
                              />
                            </SDivTableItem>
                          </SDevisTd>
                          <SDevisTd>
                            <SDivTableItem>
                              <Select
                                showSearch
                                onChange={(value) =>
                                  onChangePoste(value, index)
                                }
                                style={{ width: '150px' }}
                                value={el.posteId}
                                optionFilterProp="label"
                                options={postes.map((item) => ({
                                  value: item.id,
                                  label: i18n._(item.name),
                                }))}
                              />
                              {el.posteName === 'Autre' && (
                                <Input
                                  value={el.customPosteName}
                                  style={{
                                    width: '150px',
                                    marginRight: '10px',
                                    marginTop: '5px',
                                  }}
                                  placeholder={i18n._('form.poste.cutom.name')}
                                  onChange={(e) =>
                                    onChangeCustomPosteName(
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              )}
                            </SDivTableItem>
                          </SDevisTd>
                          <SDevisTd $textAlign="right">
                            <SDivTableItem>
                              <InputNumber
                                style={{ width: '100%' }}
                                placeholder={i18n._('form.amount.placeholder')}
                                onChange={(value) =>
                                  onChangeAmount(value ?? 0, index)
                                }
                                controls={false}
                                value={el.amount}
                                step="0.01"
                                addonAfter="€"
                              />
                            </SDivTableItem>
                          </SDevisTd>
                          <SDevisTd>
                            <SDivTableItem>
                              <DurationItem
                                $allowCorrection
                                duration={el.duration}
                                onChange={(value) =>
                                  onChangeDuration(value, index)
                                }
                              />
                            </SDivTableItem>
                          </SDevisTd>
                          <SDevisTd $minWidth="120px">
                            <SDivTableItem>
                              <DurationItem
                                $allowCorrection
                                duration={el.durationExpected}
                                onChange={(value) =>
                                  onChangeDurationExpected(value, index)
                                }
                              />
                            </SDivTableItem>
                          </SDevisTd>
                          <SDevisTd $minWidth="120px">
                            <SDivTableItem>
                              <DurationItem
                                $allowCorrection
                                duration={el.durationUsed ?? 0}
                                onChange={(value) =>
                                  onChangeDurationUsed(value, index)
                                }
                              />
                            </SDivTableItem>
                          </SDevisTd>
                          <SDevisTd $textAlign="right">
                            <SDivTableItem>
                              <InputNumber
                                style={{ width: '100%' }}
                                placeholder={i18n._(
                                  'form.amount.used.placeholder'
                                )}
                                onChange={(value) =>
                                  onChangeAmountUsed(value ?? 0, index)
                                }
                                controls={false}
                                value={el.amountUsed}
                                step="0.01"
                                addonAfter="€"
                              />
                            </SDivTableItem>
                          </SDevisTd>
                          <SDevisTd>
                            <SDivTableItem>
                              <Tooltip title="supprimer">
                                <Button
                                  type="default"
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                  onClick={() => onDeleteClick(index)}
                                />
                              </Tooltip>
                            </SDivTableItem>
                          </SDevisTd>
                        </tr>
                      )}
                    </Draggable>
                  ))}

                  <tr>
                    <th colSpan={3}>
                      <SDivBtnAddRow>
                        <Button
                          onClick={() => addElement()}
                          type="default"
                          style={{
                            backgroundColor: '#0B446F4D',
                            color: '#0B446F',
                          }}
                        >
                          <Trans id="common.add.row" />
                        </Button>
                      </SDivBtnAddRow>
                    </th>
                  </tr>
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
            <SDevisTableFoot>
              <tr>
                <SDevisTh colSpan={3}>{i18n._('common.total')}</SDevisTh>
                <SDevisTd $textAlign="right">
                  <InputNumber
                    style={{ width: '150px', marginRight: '10px' }}
                    placeholder={i18n._('form.amount.placeholder')}
                    controls={false}
                    disabled
                    value={rows.reduce(function (acc: any, obj: any) {
                      return acc + obj.amount;
                    }, 0)}
                    step="0.01"
                    addonAfter="€"
                  />
                </SDevisTd>
                <SDevisTd>
                  <DurationItem
                    duration={rows.reduce(function (acc: any, obj: any) {
                      return acc + obj.duration;
                    }, 0)}
                  />
                </SDevisTd>
                <SDevisTd>
                  <DurationItem
                    duration={rows.reduce(function (acc: any, obj: any) {
                      return acc + obj.durationExpected;
                    }, 0)}
                  />
                </SDevisTd>
                <SDevisTd>
                  <DurationItem
                    duration={rows.reduce(function (acc: any, obj: any) {
                      return acc + obj.durationUsed;
                    }, 0)}
                  />
                </SDevisTd>
                <SDevisTd $textAlign="right">
                  <InputNumber
                    style={{ width: '150px' }}
                    placeholder={i18n._('form.amount.used.placeholder')}
                    controls={false}
                    disabled
                    value={rows.reduce(function (acc: any, obj: any) {
                      return acc + obj.amountUsed;
                    }, 0)}
                    step="0.01"
                    addonAfter="€"
                  />
                </SDevisTd>
              </tr>
            </SDevisTableFoot>
          </SDevisTable>
        </DragDropContext>
      </Form>
    </Container>
  );
}

export default EditDevisInitP;

const SDivTableItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const SIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 28px;
  width: 28px;

  & span {
    height: 26px;
    width: 26px;
    position: relative;
    overflow: hidden;
  }
  & img {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
`;

const SDivItem = styled.div`
  align-items: center;
  display: flex;
`;
