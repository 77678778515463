import { useMutation } from '@apollo/client';

import { useLingui, Trans } from '@lingui/react';
import { DevisType, FunctionType } from '@zeus/index';
import type { DatePickerProps } from 'antd';
import { Button, DatePicker, Form, InputNumber, Typography } from 'antd';

import type { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';

import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';

import { GQL_CREATE_DEVIS_PROD, StoredDevitInit } from '~/gql/devis/devis';
import { StoredFunction } from '~/gql/function/function';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import useCalculetedDevisInit from '~/pages/components/common/utils-table/hooks/useCalculetedDevisInit';
import useCalculetedDevisProdEdit from '~/pages/components/common/utils-table/hooks/useCalculetedDevisProdEdit';
import {
  SDevisTable,
  SDevisTableHead,
  SDevisTh,
} from '~/pages/components/styles/styles';
import AddTaskByDevisInitRow from '~/pages/components/ui/AddTaskByDevisInitRow';
import DurationItem from '~/pages/components/ui/DurationItem';
import { DevisInitRowExtended, DevisProdRow } from '~/pages/types/types';

type IProps = {
  devisInit: StoredDevitInit | null;
  functionTypes: StoredFunction[];
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

function AddDevisProd({ functionTypes, devisInit, confirm, close }: IProps) {
  const { i18n } = useLingui();
  const { Text } = Typography;
  const { currentProject } = useDataUserStore();
  const [dateSigned, setDateSigned] = useState<Date | undefined>(undefined);
  const formRef = React.useRef<FormInstance>(null);
  const [devisRows, setDevisRows] = useState<DevisInitRowExtended[]>([]);

  const {
    devisProdAmount,
    devisProdDuration,
    devisProdTaksAmount,
    devisProdTasksDuration,
  } = useCalculetedDevisProdEdit(devisRows, []);
  const { devisInitAmount } = useCalculetedDevisInit(devisInit);

  const [createDevisProd] = useMutation(GQL_CREATE_DEVIS_PROD, {
    client: authClient,
  });

  useEffect(() => {
    // init
    if (devisInit?.devisInit) {
      const _rows: DevisInitRowExtended[] = devisInit.devisInit.map((el) => {
        const fnEl = functionTypes.find((ft) => ft.name === el.functionType);

        return {
          id: el.id,
          amount: el.amount,
          duration: el.duration,
          durationExpected: el.durationExpected,
          functionTypeId: fnEl?.id,
          posteId: el.posteId,
          posteName: '', //TODO
          functionType: el.functionType,
          order: fnEl?.order,
          tasks: [],
        };
      });
      setDevisRows(_rows);
    }
  }, [devisInit]);

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0);
    setDateSigned(new Date(d));
  };

  async function submit() {
    if (!devisInit?.id || !currentProject?.id) return;

    const taskRows = [];
    for (const dr of devisRows) {
      if (dr.tasks) {
        for (const t of dr.tasks) {
          if (dr.id)
            taskRows.push({
              functionType: dr.functionType as FunctionType,
              amount: t.amount,
              duration: t.duration,
              name: t.taskName,
              projectId: currentProject.id,
              devisInitId: dr.id,
              visibleInRetro: t.visibleInRetro,
              calculatedAsAmount: t.calculatedAsAmount,
            });
        }
      }
    }
    const { data: _data } = await createDevisProd({
      variables: {
        type: DevisType.PROD,
        projectId: currentProject.id,
        ...(dateSigned && { signedDate: dateSigned }),
        createTaskInput: taskRows,
      },
    });
    if (_data) {
      confirm();
    }
    close();
  }

  function onChangeTasksInRow(tasks: DevisProdRow[], id: number) {
    const _rows = [...devisRows];
    const ind = _rows.findIndex((el) => el.id === id);
    if (ind !== -1) {
      _rows[ind].tasks = [...tasks];
      setDevisRows(_rows);
    }
  }

  function handleFillByTemplate() {
    //
  }

  if (!devisInit) return null;

  return (
    <Container>
      <Form
        {...layout}
        ref={formRef}
        name="add-devis-prod"
        initialValues={{
          dateSigned: null,
          marge: currentProject?.marginPercent ?? 0,
        }}
        style={{ width: '100%' }}
      >
        <SDivBtn>
          <Button
            style={{ width: '200px' }}
            onClick={handleFillByTemplate}
            type="primary"
          >
            <Trans id="devis.prod.template" />
          </Button>
          <Button
            style={{ marginRight: '10px' }}
            htmlType="submit"
            onClick={close}
            type="default"
          >
            <Trans id="common.actions.cancel" />
          </Button>
          <Button
            htmlType="submit"
            style={{ width: '200px' }}
            onClick={submit}
            type="primary"
          >
            <Trans id="devis.prod.save" />
          </Button>
        </SDivBtn>

        <Form.Item
          name="dateSigned"
          style={{ width: '100%', margin: '10px 10px' }}
          label={i18n._('form.date.prod')}
        >
          <DatePicker
            minDate={dayjs().subtract(2, 'year')}
            format={dateFormat}
            onChange={onChangeDate}
          />
        </Form.Item>
        <SDiv>
          <Form.Item
            name="marge"
            style={{ minWidth: '350px', margin: '10px 10px' }}
            label={i18n._('form.marge.expected')}
          >
            <InputNumber
              style={{ width: '100px', marginRight: '10px' }}
              placeholder={i18n._('form.marge.placeholder')}
              controls={false}
              min={0}
              disabled
              addonAfter="%"
            />
          </Form.Item>
          <Text style={{ margin: '10px' }}>{i18n._('form.marge.real')}</Text>
          <InputNumber
            style={{ width: '100px', marginRight: '10px' }}
            controls={false}
            min={0}
            disabled
            value={100 - Math.round((devisProdAmount * 100) / devisInitAmount)}
            addonAfter="%"
          />
        </SDiv>
        <SDevisTable>
          <SDevisTableHead>
            <tr>
              <SDevisTh colSpan={2} $padding="0px" $minWidth="362px">
                Total
              </SDevisTh>
              <SDevisTh $padding="0px">
                <InputNumber
                  style={{ width: '150px', margin: '5px', fontWeight: 'bold' }}
                  placeholder={i18n._('form.amount.placeholder')}
                  controls={false}
                  value={devisProdAmount}
                  step="0.01"
                  addonAfter="€"
                  disabled={true}
                />
              </SDevisTh>
              <SDevisTh $padding="0px">
                <DurationItem
                  $allowCorrection={false}
                  duration={devisProdDuration}
                />
              </SDevisTh>
            </tr>
            <tr>
              <SDevisTh colSpan={2} $padding="0px" $minWidth="362px">
                Total tâches à partir de devis
              </SDevisTh>
              <SDevisTh $padding="0px">
                <InputNumber
                  style={{ width: '150px', margin: '5px', fontWeight: 'bold' }}
                  placeholder={i18n._('form.amount.placeholder')}
                  controls={false}
                  value={devisProdTaksAmount}
                  step="0.01"
                  addonAfter="€"
                  disabled={true}
                />
              </SDevisTh>
              <SDevisTh $padding="0px">
                <DurationItem
                  $allowCorrection={false}
                  duration={devisProdTasksDuration}
                />
              </SDevisTh>
            </tr>
          </SDevisTableHead>
        </SDevisTable>
        <SDivTasks>
          {devisRows.map((el, index) => (
            <AddTaskByDevisInitRow
              functionTypes={functionTypes}
              key={index}
              tasks={el.tasks ?? []}
              onTasksChange={onChangeTasksInRow}
              row={{
                functionType: el.functionType,
                amount: el.amount,
                duration: el.duration,
                durationExpected: el.durationExpected,
                posteId: el.posteId,
                id: el.id,
                posteName: '',
              }}
            />
          ))}
        </SDivTasks>
      </Form>
    </Container>
  );
}

export default AddDevisProd;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: auto;
  width: 100%;
`;

const SDivBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const SDivTasks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;
