import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { taskInfoSelector } from '~/gql/task/task';
import { scalars } from '~/gql/utils/scalars';

const devisSelector = Selector('Devis')({
  id: true,
  type: true,
  signedDate: true,
});

const devisInitSelector = Selector('DevisForProjectInit')({
  id: true,
  type: true,
  signedDate: true,
  finishDate: true,
  devisInit: {
    id: true,
    amount: true,
    duration: true,
    durationExpected: true,
    durationUsed: true,
    amountUsed: true,
    posteId: true,
    customPosteName: true,
    functionType: true,
    order: true,
  },
});

export const devisProdSelector = Selector('DevisForProjectProd')({
  id: true,
  type: true,
  signedDate: true,
  finishDate: true,
  tasks: taskInfoSelector,
});

export const GQL_DEVIS_BY_PROJECTID = typedGql('query', { scalars })({
  getDevisByProjectId: [
    {
      id: $('id', 'Int!'),
    },
    {
      devisInit: devisInitSelector,
      devisProd: devisProdSelector,
    },
  ],
});

export const GQL_CREATE_DEVIS_INITIAL = typedGql('mutation')({
  createDevisInitial: [
    {
      createDevisInput: {
        projectId: $('projectId', 'Int!'),
        type: $('type', 'DevisType!'),
        signedDate: $('signedDate', 'DateTime'),
        createDevisInitInput: $(
          'createDevisInitInput',
          '[CreateDevisInitInput!]!'
        ),
      },
    },
    true,
  ],
});

export const GQL_CREATE_DEVIS_PROD = typedGql('mutation')({
  createDevisProd: [
    {
      createDevisProdInput: {
        projectId: $('projectId', 'Int!'),
        type: $('type', 'DevisType!'),
        signedDate: $('signedDate', 'DateTime'),
        finishDate: $('finishDate', 'DateTime'),
        createTaskInput: $('createTaskInput', '[CreateTaskInput!]!'),
      },
    },
    true,
  ],
});

export const GQL_UPDATE_DEVIS_INITIAL_DURATION_EXPECTED = typedGql('mutation')({
  updateDevisInitDurationExpected: [
    {
      updateDevisInitDurationExpectedInput: $(
        'updateDevisInitDurationExpectedInput',
        '[UpdateDevisInitDurationExpectedInput!]!'
      ),
    },
    true,
  ],
});

export const GQL_UPDATE_DEVIS_INITIAL = typedGql('mutation')({
  updateDevisInitial: [
    {
      updateDevisInput: {
        id: $('id', 'Int!'),
        signedDate: $('signedDate', 'DateTime'),
        createDevisInitInput: $(
          'createDevisInitInput',
          '[CreateDevisInitInput!]!'
        ),
      },
    },
    true,
  ],
});

export const GQL_UPDATE_DEVIS_PROD = typedGql('mutation')({
  updateDevisProd: [
    {
      updateDevisProdInput: {
        id: $('id', 'Int!'),
        signedDate: $('signedDate', 'DateTime'),
        finishDate: $('finishDate', 'DateTime'),
        updateTaskInput: $('updateTaskInput', '[UpdateTaskInput!]!'),
      },
    },
    true,
  ],
});

export const GQL_UPDATE_DEVIS_PROD_DATE = typedGql('mutation')({
  updateDevisProdDate: [
    {
      updateDevisProdDateInput: {
        id: $('id', 'Int!'),
        signedDate: $('signedDate', 'DateTime'),
        finishDate: $('finishDate', 'DateTime'),
      },
    },
    true,
  ],
});

export type StoredDevis = InputType<
  GraphQLTypes['Devis'],
  typeof devisSelector
>;

export type StoredDevitInit = InputType<
  GraphQLTypes['DevisForProjectInit'],
  typeof devisInitSelector
>;

export type StoredDevitProd = InputType<
  GraphQLTypes['DevisForProjectProd'],
  typeof devisProdSelector
>;
