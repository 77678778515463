import { ProjectType } from '@zeus/index';
import { StoredTimeUserPlanningInfo } from '~/gql/user-planning/user-planning';
import { TCellTaskTime, TDateTimes } from '~/pages/types/types';

// ---------------    utils for TaskTimesTable ----------------------------

export function isDateNowInPeriod(startDate: Date, endDate: Date): boolean {
  const today = new Date();

  today.setHours(8, 0, 0, 0);
  startDate.setHours(8, 0, 0, 0);
  endDate.setHours(8, 0, 0, 0);

  return today >= startDate && today <= endDate;
}

export function fillCalendarTasksTimesTable(
  _startDate: Date,
  _endDate: Date
): TDateTimes[] {
  const dateStart = new Date(_startDate);
  const dateEnd = new Date(_endDate);
  let currentDate = dateStart;
  const _calendar: TDateTimes[] = [];

  if (dateStart > dateEnd) return [];

  while (currentDate <= dateEnd) {
    const date = currentDate;

    const jourSemaine = date.getDay();
    if (jourSemaine !== 0 && jourSemaine !== 6) {
      // Jours travaillés sont du lundi au vendredi
      const elem = {
        date: date,
        selectible: true,
        label: '',
      };
      _calendar.push(elem);
    } else {
      const elem = {
        date: date,
        selectible: false,
        label: '',
      };
      _calendar.push(elem);
    }
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    currentDate = new Date(nextDate.setHours(8, 0, 0, 0));
  }

  return _calendar;
}

export function getMappedDataTasksTimesTable(
  data: StoredTimeUserPlanningInfo,
  _calendar: TDateTimes[]
): TCellTaskTime[] {
  const result: TCellTaskTime[] = [];

  for (const uP of data.userPlanning) {
    if (uP.task) {
      const indTask = result.findIndex((el) => el.taskId === uP.task?.id);
      if (indTask !== -1) {
        // is la tâche existe dans la liste
        const _existCalender: TDateTimes[] = JSON.parse(
          JSON.stringify(result[indTask].calendar)
        ).map((el: any) => el as TDateTimes);

        // trouver la date
        const indDate = _existCalender.findIndex(
          (el) =>
            new Date(el.date).setHours(8, 0, 0, 0) ===
            new Date(uP.date as Date).setHours(8, 0, 0, 0)
        );
        if (indDate !== -1) {
          _existCalender[indDate].planningDuration =
            (_existCalender[indDate].planningDuration ?? 0) + uP.duration;
        }
        result[indTask].calendar = [..._existCalender];
        //
      } else {
        const taskCalendar: TDateTimes[] = JSON.parse(
          JSON.stringify(_calendar)
        ).map((el: any) => el as TDateTimes);

        // trouver la date
        const indDate = taskCalendar.findIndex(
          (el) =>
            new Date(el.date).setHours(8, 0, 0, 0) ===
            new Date(uP.date as Date).setHours(8, 0, 0, 0)
        );
        if (indDate !== -1) {
          taskCalendar[indDate].planningDuration = uP.duration;
        }

        result.push({
          taskId: uP.task.id,
          taskName: uP.task.name,
          projectId: uP.task.projectId,
          projectName: uP.task.projectName,
          projectType: uP.task.projectType,
          allowModify: uP.duration ? false : true,
          calendar: [...taskCalendar],
        });
      }
    }
  }

  for (const t of data.time) {
    if (t.task) {
      const indTask = result.findIndex((el) => el.taskId === t.task?.id);
      if (indTask !== -1) {
        // task userPlannning existe pour cette tâche
        const _existCalender: TDateTimes[] = JSON.parse(
          JSON.stringify(result[indTask].calendar)
        ).map((el: any) => el as TDateTimes);

        // trouver la date
        const indDate = _existCalender.findIndex(
          (el) =>
            new Date(el.date).setHours(8, 0, 0, 0) ===
            new Date(t.date as Date).setHours(8, 0, 0, 0)
        );
        if (indDate !== -1) {
          _existCalender[indDate].timeId = t.id;
          _existCalender[indDate].time =
            (_existCalender[indDate].time ?? 0) + t.duration;
          _existCalender[indDate].timeId = t.id;
          _existCalender[indDate].timeComment = t.comment;
        }
        result[indTask].calendar = [..._existCalender];
      } else {
        // task userPlannning n'existe pas pour cette tâche
        const taskCalendar: TDateTimes[] = JSON.parse(
          JSON.stringify(_calendar)
        ).map((el: any) => el as TDateTimes);

        // trouver la date
        const indDate = taskCalendar.findIndex(
          (el) =>
            new Date(el.date).setHours(8, 0, 0, 0) ===
            new Date(t.date as Date).setHours(8, 0, 0, 0)
        );
        if (indDate !== -1) {
          taskCalendar[indDate].timeId = t.id;
          taskCalendar[indDate].time = t.duration;
          taskCalendar[indDate].timeId = t.id;
          taskCalendar[indDate].timeComment = t.comment;
        }

        result.push({
          taskId: t.task.id,
          taskName: t.task.name,
          projectId: t.task.projectId,
          projectType: t.task.projectType,
          projectName: t.task.projectName,
          allowModify: true,
          calendar: [...taskCalendar],
        });
      }
    }
  }

  return result;
}

export function calcTotalPlanningDuration(mappedData: TCellTaskTime[]): number {
  let result = 0;
  for (const mT of mappedData) {
    const itRes = mT.calendar.reduce(function (acc: any, obj: TDateTimes) {
      return obj.planningDuration !== undefined
        ? acc + obj.planningDuration
        : acc;
    }, 0);

    result = result + itRes;
  }
  return result;
}

export function calcTotalPlanningDurationByProjectType(
  mappedData: TCellTaskTime[],
  projectType: ProjectType
): number {
  let result = 0;
  for (const mT of mappedData) {
    if (mT.projectType === projectType) {
      const itRes = mT.calendar.reduce(function (acc: any, obj: TDateTimes) {
        return obj.planningDuration !== undefined
          ? acc + obj.planningDuration
          : acc;
      }, 0);

      result = result + itRes;
    }
  }
  return result;
}

export function calcTotalTimes(mappedData: TCellTaskTime[]): number {
  let result = 0;
  for (const mT of mappedData) {
    const itRes = mT.calendar.reduce(function (acc: any, obj: TDateTimes) {
      return obj.time !== undefined ? acc + obj.time : acc;
    }, 0);

    result = result + itRes;
  }
  return result;
}

export function calcTotalTimesByProjectType(
  mappedData: TCellTaskTime[],
  projectType: ProjectType
): number {
  let result = 0;
  for (const mT of mappedData) {
    if (mT.projectType === projectType) {
      const itRes = mT.calendar.reduce(function (acc: any, obj: TDateTimes) {
        return obj.time !== undefined ? acc + obj.time : acc;
      }, 0);

      result = result + itRes;
    }
  }
  return result;
}

export function calcTotalTimeByDate(
  date: Date,
  mappedData: TCellTaskTime[]
): number {
  const initDate = date.setHours(8, 0, 0, 0);
  let result = 0;
  for (const mT of mappedData) {
    const calenderItem = mT.calendar.find(
      (el) => new Date(el.date).setHours(8, 0, 0, 0) === initDate
    );
    if (calenderItem?.time !== undefined) result = result + calenderItem.time;
  }
  return result;
}

export function calcTotalTimeByDateByProjectType(
  date: Date,
  mappedData: TCellTaskTime[],
  projectType: ProjectType
): number {
  const initDate = date.setHours(8, 0, 0, 0);
  let result = 0;
  for (const mT of mappedData) {
    if (mT.projectType && mT.projectType === projectType) {
      const calenderItem = mT.calendar.find(
        (el) => new Date(el.date).setHours(8, 0, 0, 0) === initDate
      );
      if (calenderItem?.time !== undefined) result = result + calenderItem.time;
    }
  }
  return result;
}

export function calcTotalPlanningDurationByDate(
  date: Date,
  mappedData: TCellTaskTime[]
): number {
  const initDate = date.setHours(8, 0, 0, 0);
  let result = 0;
  for (const mT of mappedData) {
    const calenderItem = mT.calendar.find(
      (el) => new Date(el.date).setHours(8, 0, 0, 0) === initDate
    );
    if (calenderItem?.planningDuration !== undefined)
      result = result + calenderItem.planningDuration;
  }
  return result;
}

export function calcTotalPlanningDurationByDateByProjectType(
  date: Date,
  mappedData: TCellTaskTime[],
  projectType: ProjectType
): number {
  const initDate = date.setHours(8, 0, 0, 0);
  let result = 0;
  for (const mT of mappedData) {
    if (mT.projectType && mT.projectType === projectType) {
      const calenderItem = mT.calendar.find(
        (el) => new Date(el.date).setHours(8, 0, 0, 0) === initDate
      );
      if (calenderItem?.planningDuration !== undefined)
        result = result + calenderItem.planningDuration;
    }
  }
  return result;
}

// ---------------  fin  utils for TaskTimesTable ----------------------------
