import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

const holidaysSelector = Selector('Holidays')({
  id: true,
  date: true,
});

export const GQL_GET_HOLIDAYS_FOR_PERIOD = typedGql('query')({
  getHolidaysForPeriod: [
    {
      filterHolidaysInput: {
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    holidaysSelector,
  ],
});

export const GQL_ALL_HOLIDAYS = typedGql('query')({
  getAllHolidays: {
    ...holidaysSelector,
  },
});

export const GQL_UPDATE_HOLIDAYS_FOR_PERIOD = typedGql('mutation')({
  updateHolidaysForPeriod: [
    {
      updateHolidaysInput: {
        dates: $('dates', '[DateTime!]!'),
        startDate: $('startDate', 'DateTime!'),
        endDate: $('endDate', 'DateTime!'),
      },
    },
    holidaysSelector,
  ],
});

export const GQL_DELETE_HOLIDAYS = typedGql('mutation')({
  deleteHolidays: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_CREATE_HOLIDAYS = typedGql('mutation')({
  createHolidays: [
    {
      createHolidaysInput: {
        date: $('date', 'DateTime!'),
      },
    },
    true,
  ],
});

export type StoredHolidays = InputType<
  GraphQLTypes['Holidays'],
  typeof holidaysSelector
>;
