import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { useState, useEffect } from 'react';

import { StoredProjectForSuperProject } from '~/gql/project/project';
import { GQL_DELETE_SUPER_PROJECT } from '~/gql/super-project/super-project';

import { authClient } from '~/helpers/apollo';
import ModalCustom from '~/pages/components/ui/ModalCustom';
import { SPText } from '~/utils/ui/utils';

type IProps = {
  project: StoredProjectForSuperProject;
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

function DeleteSuperProject({
  title,
  isOpen,
  confirm,
  close,
  project,
}: IProps) {
  const { i18n } = useLingui();
  const [error, setError] = useState<boolean>(false);

  const [deleteSuperProject] = useMutation(GQL_DELETE_SUPER_PROJECT, {
    client: authClient,
    onError: () => {
      setError(true);
    },
  });

  useEffect(() => {
    setError(false);
  }, [project]);

  async function submit() {
    const { data: _data } = await deleteSuperProject({
      variables: {
        id: project.id,
      },
    });
    if (_data) {
      confirm();
    } else {
      setError(true);
    }
  }

  return (
    <ModalCustom
      title={title}
      confirmDisabled={error ? true : false}
      isOpen={isOpen}
      confirm={submit}
      close={close}
    >
      {!error ? (
        <>
          <SPText $textalign="left">
            {i18n._('table.super.project.delete.text')}
          </SPText>
          <SPText $textalign="left">
            {i18n._('table.super.project.delete.confirm')} {project.name} ?
          </SPText>
        </>
      ) : (
        <SPText $textalign="left">
          {i18n._('table.super.project.delete.error')}
        </SPText>
      )}
    </ModalCustom>
  );
}

export default DeleteSuperProject;
