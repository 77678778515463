import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

export const GQL_UPLOAD = typedGql('mutation')({
  uploadFile: [{ file: $('file', 'Upload!') }, true],
});

const downloadFile = Selector('DownloadFile')({
  buffer: true,
  fileMimeType: true,
  fileName: true,
});

export const GQL_DOWNLOAD = typedGql('query')({
  downloadFile: [{ eTag: $('eTag', 'String!') }, { ...downloadFile }],
});

export type StoredDownload = InputType<
  GraphQLTypes['DownloadFile'],
  typeof downloadFile
>;

const filesSelector = Selector('MyFiles')({
  eTag: true,
  createdAt: true,
  fileMimeType: true,
  fileName: true,
  owner: true,
});

export const GQL_ALL_MY_FILES = typedGql('query')({
  getAllMyFiles: {
    ...filesSelector,
  },
});

export type StoredFiles = InputType<
  GraphQLTypes['MyFiles'],
  typeof filesSelector
>;
