import { CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { Modal, notification } from 'antd';
import { GQL_DELETE_USER, StoredUsers } from '~/gql/users/users';
import { authClient } from '~/helpers/apollo';
import { classic } from '~/themes';
import { SH2, SText, StyledButton } from '~/utils/ui/utils';

type IProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: () => void;
  rowSelected: StoredUsers;
};

function UserDeleteModal({ open, setOpen, refresh, rowSelected }: IProps) {
  const { i18n } = useLingui();

  const [deleteUser] = useMutation(GQL_DELETE_USER, {
    client: authClient,
    onCompleted: () => {
      notification.success({
        message: i18n._('delete.user'),
        description: i18n._('good.delete.user'),
        placement: 'topRight',
      });
      refresh();
      setOpen(false);
    },
    onError: () => {
      notification.error({
        message: i18n._('delete.user'),
        description: i18n._('bad.delete.user'),
        placement: 'topRight',
      });
      setOpen(false);
    },
  });

  const handleDeleteUser = async () => {
    await deleteUser({ variables: { id: rowSelected.id } });
  };

  return (
    <Modal
      open={open}
      centered
      closeIcon={false}
      style={{ textAlign: 'center' }}
      footer={false}
      onCancel={() => setOpen(false)}
    >
      <SH2 $color={classic.token?.colorPrimary}>{i18n._('delete.user')}</SH2>
      <SText $margin="15px 0">{i18n._('delete.user.first-message')}</SText>
      <SText $fontweight="bold">{i18n._('delete.second-message')}</SText>
      <StyledButton $margin="15px 0 0" onClick={handleDeleteUser}>
        {i18n._('common.delete')}
      </StyledButton>
      <CloseOutlined
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fontSize: '25px',
          cursor: 'pointer',
          color: classic.token?.colorFillSecondary,
        }}
        onClick={() => setOpen(false)}
      />
    </Modal>
  );
}

export default UserDeleteModal;
