import { useMutation, useQuery } from '@apollo/client';
import { Trans, useLingui } from '@lingui/react';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { GQL_ALL_HOLIDAYS } from '~/gql/holidays/holidays';
import { StoredTaskInfo } from '~/gql/task/task';
import { GQL_CREATE_OR_UPDATE_TASK_PLANNING } from '~/gql/task-planning/task-planning';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import AddTaskSupp from '~/pages/components/common/planning/AddTaskSupp';
import TasksPlanningTable from '~/pages/components/common/planning/TasksPlanningTable';
import {
  fillPlanningCalendar,
  getDaysByDuration,
} from '~/pages/components/common/utils-table/utils';
import {
  TDatePlanning,
  TaskInfoWithPlanningCalender,
} from '~/pages/types/types';
import { compareByOrder, getHoursStrByDuration } from '~/utils/utils/utils';

type IProps = {
  isPrinting: boolean;
  changeIsPrinting: (value: boolean) => void;
};

function TasksPlanning({ isPrinting, changeIsPrinting }: IProps) {
  const { i18n } = useLingui();
  const [calendar, setCalendar] = useState<TDatePlanning[]>([]);

  const { currentDevisProd, currentProject, setCurrentDevisProd } =
    useDataUserStore();
  const [mappedTasks, setMappedTasks] = useState<
    TaskInfoWithPlanningCalender[]
  >([]);

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const [createOrUpdateTaskPlanning] = useMutation(
    GQL_CREATE_OR_UPDATE_TASK_PLANNING,
    {
      client: authClient,
    }
  );

  const { refetch: getAllHolidays } = useQuery(GQL_ALL_HOLIDAYS, {
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  function mapTaskPlanning(
    _tasks: StoredTaskInfo[],
    _calendar: TDatePlanning[]
  ): TaskInfoWithPlanningCalender[] {
    const itemsMapped = JSON.parse(
      JSON.stringify(_tasks.filter((t) => t.visibleInRetro))
    );
    const items: StoredTaskInfo[] = itemsMapped.map(
      (el: any) => el as StoredTaskInfo
    );

    const res: TaskInfoWithPlanningCalender[] = items.map((task) => {
      const taskCalendar: TDatePlanning[] = JSON.parse(
        JSON.stringify(_calendar)
      ).map((el: any) => el as TDatePlanning);
      const filteredTaskCalendar: TDatePlanning[] = JSON.parse(
        JSON.stringify(_calendar)
      )
        .map((el: any) => el as TDatePlanning)
        .filter((it: TDatePlanning) => it.selectible);

      if (task.startDateInit !== undefined && task.durationDays !== undefined) {
        for (let i = 0; i < task.durationDays; i++) {
          filteredTaskCalendar[task.startDateInit + i].retroChecked = true;
        }
      }

      for (let i = 0; i < filteredTaskCalendar.length; i++) {
        const ind = taskCalendar.findIndex(
          (el) => el.date === filteredTaskCalendar[i].date
        );
        if (ind !== -1)
          taskCalendar[ind].retroChecked = filteredTaskCalendar[i].retroChecked;
      }

      for (const tP of task.taskPlanning) {
        const ind = taskCalendar.findIndex((el) => el.date === tP.date);
        if (ind !== -1) {
          taskCalendar[ind].checked = true;
          taskCalendar[ind].label = getHoursStrByDuration(tP.duration);
          taskCalendar[ind].duration = tP.duration;
          taskCalendar[ind].id = tP.id;
        }
      }

      return {
        id: task.id,
        name: task.name,
        amount: task.amount,
        duration: task.duration,
        functionType: task.functionType,
        devisInitId: task.devisInitId,
        startDateInit: task.startDateInit,
        durationDays: task.durationDays,
        durationClientDays: task.durationClientDays,
        visibleInRetro: task.visibleInRetro,
        supp: task.supp,
        calculatedAsAmount: task.calculatedAsAmount,
        order: task.order,
        taskPlanning: task.taskPlanning,
        projectId: task.projectId,
        projectName: task.projectName,
        user: task.user,
        calendar: [...taskCalendar],
      };
    });
    return res.sort(compareByOrder);
  }

  async function getData() {
    const data = await getAllHolidays();

    if (currentDevisProd?.tasks && currentDevisProd.tasks.length > 0) {
      const _calendar = fillPlanningCalendar(
        currentDevisProd.signedDate as Date,
        currentDevisProd.finishDate as Date,
        getDaysByDuration(currentDevisProd.tasks),
        data.data.getAllHolidays
      );
      setCalendar(_calendar);
      const res = mapTaskPlanning(currentDevisProd.tasks, _calendar);

      setMappedTasks(res);
    }
  }

  useEffect(() => {
    void getData();
  }, [currentDevisProd?.tasks]);

  async function changeCell(itemId: number, calendarIndex: number) {
    const itemsMapped = JSON.parse(JSON.stringify(mappedTasks));
    const items: TaskInfoWithPlanningCalender[] = itemsMapped.map(
      (el: any) => el as TaskInfoWithPlanningCalender
    );

    const itemIndex = items.findIndex((item) => item.id === itemId);

    if (itemIndex !== -1) {
      items[itemIndex].calendar[calendarIndex].checked =
        !items[itemIndex].calendar[calendarIndex].checked;

      const count = items[itemIndex].calendar.filter((el) => el.checked).length;
      const average = items[itemIndex].duration / count;
      for (let j = 0; j < items[itemIndex].calendar.length; j++) {
        if (items[itemIndex].calendar[j].checked)
          items[itemIndex].calendar[j].label = getHoursStrByDuration(
            Math.round(average)
          );
      }
      const _dates: { date: Date; duration: number; id?: number }[] = items[
        itemIndex
      ].calendar
        .filter((el) => el.checked)
        .map((d) => {
          return {
            date: d.date,
            duration: Math.round(average),
            ...(d.id && { id: d.id }),
          };
        });

      if (!currentProject?.id) return;
      const { data: _data } = await createOrUpdateTaskPlanning({
        variables: {
          projectId: currentProject.id,
          taskId: items[itemIndex].id,
          dates: _dates,
        },
      });
      if (_data) {
        setCurrentDevisProd({
          ..._data.createOrUpdateTaskPlanning,
          tasks: _data.createOrUpdateTaskPlanning.tasks?.sort(compareByOrder),
        });
        setMappedTasks(
          JSON.parse(
            JSON.stringify(
              _data.createOrUpdateTaskPlanning.tasks?.sort(compareByOrder)
            )
          ).map((el: any) => el as TaskInfoWithPlanningCalender)
        );
      }
    }
  }

  function handleAddSuppTask() {
    setOpenAdd(true);
  }

  async function onChangeStatePeriod(
    itemId: number,
    startDate: Date,
    endDate: Date,
    dates: Date[]
  ) {
    console.log(itemId, startDate, endDate);

    const items: TaskInfoWithPlanningCalender[] = JSON.parse(
      JSON.stringify(mappedTasks)
    ).map((el: any) => el as TaskInfoWithPlanningCalender);
    const itemIndex = items.findIndex((item) => item.id === itemId);
    if (itemId === -1) return;
    const checkedDates: { date: Date; id?: number | undefined }[] = items[
      itemIndex
    ].taskPlanning
      //.filter((el) => el.duration > 0)
      .map((d) => {
        return {
          date: d.date as Date,
          id: d.id,
        };
      });

    for (const nD of dates) {
      const ind = checkedDates.findIndex(
        (el) =>
          new Date(el.date).setHours(8, 0, 0, 0) ===
          new Date(nD).setHours(8, 0, 0, 0)
      );
      if (ind !== -1) {
        checkedDates.splice(ind, 1);
      } else checkedDates.push({ date: nD });
    }

    const average = items[itemIndex].duration / checkedDates.length;
    const _dates: { date: Date; duration: number; id?: number }[] =
      checkedDates.map((d) => {
        return {
          date: d.date,
          duration: Math.round(average),
          ...(d.id && { id: d.id }),
        };
      });

    if (!currentProject?.id) return;
    const { data: _data } = await createOrUpdateTaskPlanning({
      variables: {
        projectId: currentProject.id,
        taskId: items[itemIndex].id,
        dates: _dates,
      },
    });
    if (_data) {
      setCurrentDevisProd({
        ..._data.createOrUpdateTaskPlanning,
        tasks: _data.createOrUpdateTaskPlanning.tasks?.sort(compareByOrder),
      });
      setMappedTasks(
        JSON.parse(
          JSON.stringify(
            _data.createOrUpdateTaskPlanning.tasks?.sort(compareByOrder)
          )
        ).map((el: any) => el as TaskInfoWithPlanningCalender)
      );
    }
  }

  return (
    <Container>
      {mappedTasks.length > 0 && calendar.length > 0 && (
        <TasksPlanningTable
          refresh={() => {
            void getData();
          }}
          onStatePeriodChange={onChangeStatePeriod}
          onStateCellChange={changeCell}
          calendar={calendar}
          tasks={mappedTasks.filter((el) => !el.supp)}
          tasksSupp={[...mappedTasks.filter((el) => el.supp)].sort(
            compareByOrder
          )}
          isPrinting={isPrinting}
          changeIsPrinting={changeIsPrinting}
        />
      )}
      <>
        <Button
          style={{ width: '300px' }}
          disabled={!currentDevisProd}
          onClick={handleAddSuppTask}
          type="primary"
        >
          <Trans id="common.add.task.supp" />
        </Button>
      </>

      {currentProject && (
        <AddTaskSupp
          projectId={currentProject.id}
          title={i18n._('task.supp.add')}
          isOpen={openAdd}
          close={() => setOpenAdd(false)}
          confirm={() => {
            setOpenAdd(false);
          }}
        />
      )}
    </Container>
  );
}

export default TasksPlanning;

const Container = styled.div`
  width: 100%;
`;
