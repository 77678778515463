import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import {
  Form,
  Select,
  DatePickerProps,
  DatePicker,
  ConfigProvider,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { StoredHolidays } from '~/gql/holidays/holidays';

import { StoredProjectShortInfo } from '~/gql/project/project';
import { GQL_CREATE_OR_UPDATE_TIME } from '~/gql/time/time';

import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';
import { isDateHolidays } from '~/pages/components/common/utils-table/utils';

import DurationItemSmall from '~/pages/components/ui/DurationItemSmall';
import ModalCustom from '~/pages/components/ui/ModalCustom';

type IProps = {
  projects: StoredProjectShortInfo[];
  title: string;
  isOpen: boolean;
  holydays: StoredHolidays[];
  dateDefault?: Date | undefined;
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const dateFormat = 'DD-MM-YYYY';

function AddTime({
  title,
  isOpen,
  confirm,
  close,
  projects,
  holydays,
  dateDefault,
}: IProps) {
  const { i18n } = useLingui();
  const { payload } = useAuth();
  const formRef = React.useRef<FormInstance>(null);
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [selectedTask, setSelectedTask] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    dateDefault ? dateDefault : dayjs().toDate()
  );
  const [comment, setComment] = useState<string>('');
  const [duration, setDuration] = useState<number>(0);
  const [messageAlert, setMessageAlert] = useState<string>('');

  const [createTime] = useMutation(GQL_CREATE_OR_UPDATE_TIME, {
    client: authClient,
  });

  useEffect(() => {
    if (selectedDate) {
      const isHolidays = isDateHolidays(selectedDate, holydays);
      const jourSemaine = new Date(selectedDate).getDay();

      if (jourSemaine === 0 || jourSemaine === 6 || isHolidays) {
        setMessageAlert(
          jourSemaine === 0 || jourSemaine === 6
            ? i18n._('common.day.alert.we')
            : i18n._('common.day.alert.free')
        );
      } else {
        setMessageAlert('');
      }
    } else {
      setMessageAlert('');
    }
  }, [selectedDate]);

  async function submit() {
    if (
      !payload?.sub ||
      !selectedProject ||
      !selectedTask ||
      !selectedDate ||
      !duration
    )
      return;

    const d = selectedDate.setHours(8, 0, 0, 0);

    const { data: _data } = await createTime({
      variables: {
        taskId: selectedTask,
        date: new Date(d),
        duration: duration,
        userId: payload.sub,
        comment: comment,
      },
    });

    if (_data?.createOrUpdateTime) {
      confirm();
    } else {
      handleClose();
    }
  }

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    const d = date.toDate().setHours(8, 0, 0, 0);
    setSelectedDate(new Date(d));
  };

  function handleClose() {
    formRef.current?.resetFields();
    setSelectedTask(null);
    setSelectedProject(null);
    close();
  }

  return (
    <ModalCustom
      confirmDisabled={!selectedProject || !selectedTask || !selectedDate}
      title={title}
      isOpen={isOpen}
      confirm={submit}
      close={handleClose}
    >
      <Form
        {...layout}
        ref={formRef}
        name="time-add"
        initialValues={{
          date: dateDefault ? dayjs(dateDefault as unknown as string) : dayjs(),
          projectName: null,
          taskName: null,
        }}
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          name="projectName"
          label={i18n._('common.project')}
          rules={[
            {
              required: true,
              message: i18n._('form.project-name.placeholder'),
            },
          ]}
        >
          <Select
            placeholder={i18n._('form.project.placeholder')}
            value={selectedProject}
            showSearch
            onChange={setSelectedProject}
            style={{ width: '100%', padding: '2px' }}
            optionFilterProp="label"
            options={projects.map((p) => ({
              value: p.id,
              label: p.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="taskName"
          label={i18n._('common.task.name')}
          rules={[
            {
              required: true,
              message: i18n._('form.task-name.placeholder'),
            },
          ]}
        >
          <ConfigProvider renderEmpty={() => "Choisissez d'abord le projet"}>
            <Select
              placeholder={i18n._('form.task-name.placeholder')}
              value={selectedTask}
              showSearch
              onChange={setSelectedTask}
              style={{ width: '100%', padding: '2px' }}
              optionFilterProp="label"
              options={
                selectedProject !== null
                  ? projects
                      .find((p) => p.id === selectedProject)
                      ?.tasks.map((p) => ({
                        value: p.id,
                        label: p.name,
                      }))
                  : []
              }
            />
          </ConfigProvider>
        </Form.Item>
        <Form.Item
          name="date"
          style={{ width: '100%' }}
          label={i18n._('form.date')}
          validateStatus={messageAlert ? 'warning' : ''}
          help={messageAlert ? messageAlert : ''}
          rules={[
            {
              required: true,
              message: i18n._('form.project-name.placeholder'),
            },
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            placeholder={i18n._('form.date.placeholder')}
            format={dateFormat}
            onChange={onChangeDate}
            //  status={messageAlert ? 'warning' : ''}
          />
        </Form.Item>

        <Form.Item
          name="duration"
          style={{ width: '100%' }}
          label={i18n._('common.times.hours')}
          rules={[
            {
              required: true,
              message: i18n._('form.times.placeholder'),
            },
          ]}
        >
          <DurationItemSmall
            $align="flex-start"
            $allowCorrection
            duration={duration}
            onChange={setDuration}
          />
        </Form.Item>
        <Form.Item name="comment" label={i18n._('form.time.comment')}>
          <TextArea
            rows={4}
            placeholder={i18n._('form.time.comment.placeholder')}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
}

export default AddTime;
