import { useLingui } from '@lingui/react';

import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

type IProps = {
  duration: number;
  onChange?: (duration: number) => void;
  $allowCorrection?: boolean;
  $align?: string;
  $width?: string;
  $bold?: boolean;
};

function DurationItem({
  duration,
  onChange,
  $allowCorrection,
  $align,
  $bold,
  $width,
}: IProps) {
  const { i18n } = useLingui();

  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  useEffect(() => {
    const _hours = Math.floor(duration / 60);
    const _minutes = duration % 60;
    setHours(_hours);
    setMinutes(_minutes);
  }, [duration]);

  function renderMinValue(value: number) {
    if (value.toString().length === 1) {
      return `0${value.toString()}`;
    }
    return `${value.toString()}`;
  }

  function handleChangeMinutes(e: React.ChangeEvent<HTMLInputElement>) {
    if (Number((e.target as HTMLInputElement).value) > 59) {
      setMinutes(59);
      const _duration = hours * 60 + 59;
      onChange && onChange(_duration);
      return;
    }

    setMinutes(Number((e.target as HTMLInputElement).value) * 1);
    const _duration = hours * 60 + Number((e.target as HTMLInputElement).value);
    onChange && onChange(_duration);
  }

  return (
    <Container
      $align={$align}
      $allowCorrection={$allowCorrection}
      $width={$width}
    >
      <SInputCustom
        placeholder="00"
        disabled={!$allowCorrection}
        $allowCorrection={$allowCorrection}
        type="number"
        $bold={$bold}
        onChange={(e) => {
          setHours(Number((e.target as HTMLInputElement).value) * 1);

          const _duration =
            Number((e.target as HTMLInputElement).value) * 1 * 60 + minutes;
          onChange && onChange(_duration);
        }}
        value={Number(hours).toString()}
        min={0}
        width="60px"
      />
      <SSpan $allowCorrection={$allowCorrection} $bold={$bold}>
        {i18n._('common.hours.short')}
      </SSpan>

      <SInputCustom
        $bold={$bold}
        id="minutes"
        maxLength={2}
        disabled={!$allowCorrection}
        $allowCorrection={$allowCorrection}
        type="number"
        onChange={(e) => handleChangeMinutes(e)}
        max={59}
        min={0}
        value={renderMinValue(minutes)}
        width="32px"
      />
    </Container>
  );
}

export default DurationItem;

const Container = styled.div<{
  $align?: string;
  $allowCorrection?: boolean;
  $width?: string;
}>`
  align-items: center;
  background-color: ${({ $allowCorrection }) =>
    $allowCorrection ? `#fff` : 'rgba(0, 0, 0, 0.04)'};
  border-radius: 6px;
  border: 1px solid #4a4a4a3a;
  display: flex;
  justify-content: ${({ $align }) => ($align ? $align : 'center')};
  width: ${({ $width }) => ($width ? $width : 'auto')};
  & > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SSpan = styled.div<{ $allowCorrection?: boolean; $bold?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
  height: 32px;
  padding: 1px;
`;

const SInputCustom = styled.input<{
  width?: string;
  $allowCorrection?: boolean;
  $bold?: boolean;
}>`
  background-color: transparent;

  color: #4a4a4a;
  border-radius: 6px;
  border: none;
  height: 32px;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
  padding: 4px 8px;
  text-align: right;
  width: ${({ width }) => (width ? `${width}` : 'auto')};

  & ::placeholder {
    color: #4a4a4a3a;
  }
  &:focus {
    outline: none;
  }

  & > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
