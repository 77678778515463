import DefaultLayout from '~/pages/layouts/DefaultLayout';
import ProjectTable from '~/pages/tables/ProjectTable';

function ManagerPage() {
  return (
    <>
      <DefaultLayout>
        <ProjectTable />
      </DefaultLayout>
    </>
  );
}

export default ManagerPage;
