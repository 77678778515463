import { useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useState } from 'react';

import { GQL_CREATE_PROJECT_INTERN } from '~/gql/project/project';

import { authClient } from '~/helpers/apollo';
import { useAuth } from '~/helpers/store/auth';

import ModalCustom from '~/pages/components/ui/ModalCustom';

type IProps = {
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function AddProjectIntern({ title, isOpen, confirm, close }: IProps) {
  const { i18n } = useLingui();
  const { payload } = useAuth();

  const [projectName, setProjectName] = useState<string>('');

  const formRef = React.useRef<FormInstance>(null);

  const [createProject] = useMutation(GQL_CREATE_PROJECT_INTERN, {
    client: authClient,
  });

  async function submit() {
    if (!payload?.sub || !projectName) return;

    const { data: _data } = await createProject({
      variables: {
        name: projectName,
        projectManagerId: payload.sub,
      },
    });

    if (_data) {
      confirm();
    } else {
      close();
    }
  }

  return (
    <ModalCustom
      confirmDisabled={!projectName}
      title={title}
      isOpen={isOpen}
      confirm={submit}
      close={close}
    >
      <Form
        {...layout}
        ref={formRef}
        name="project"
        initialValues={{ projectName: '' }}
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          name="projectName"
          label={i18n._('form.project-name')}
          rules={[
            {
              required: true,
              message: i18n._('form.project-name.placeholder'),
            },
          ]}
        >
          <Input
            placeholder={i18n._('form.project-name.placeholder')}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </Form.Item>
      </Form>
    </ModalCustom>
  );
}

export default AddProjectIntern;
