import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { ProjectType } from '@zeus/index';
import { Button, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import {
  GQL_FILTERED_PROJECTS,
  StoredProjectInfo,
} from '~/gql/project/project';

import {
  GQL_GET_TASKS_INTERN_BY_PROJECT_ID,
  StoredTaskShortInfo,
} from '~/gql/task/task';

import { authClient } from '~/helpers/apollo';
//import { useAuth } from '~/helpers/store/auth';
import AddProjectIntern from '~/pages/components/common/project/AddProjectIntern';
import TasksProjectIntern from '~/pages/components/common/project/TasksProjectIntern';
import { TableElement } from '~/pages/components/styles/styles';
import DefaultLayout from '~/pages/layouts/DefaultLayout';
import CommonTable from '~/utils/table/CommonTable';

function ProjectsInternPage() {
  const [filteredProjects, setFilteredProjects] = useState<StoredProjectInfo[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  //const { payload } = useAuth();
  const [sortedInfo, setSortedInfo] = useState<any>();
  const [rowSelect, setRowSelect] = useState<StoredProjectInfo[]>([]);
  const [projectSelected, setProjectSelected] = useState<
    StoredProjectInfo | undefined
  >(undefined);
  const [tasksIntern, setTasksIntern] = useState<StoredTaskShortInfo[]>([]);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [yScroll] = useState(true);
  const [xScroll] = useState<string>();
  const { i18n } = useLingui();

  const columns: ColumnsType<StoredProjectInfo> = [
    {
      title: 'Nom du projet',
      dataIndex: 'name',
      key: 'name',
      // width: 150,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo.order,

      sorter: (a, b) => a.name.length - b.name.length,
      render: (_, record) => (
        <TableElement
          onClick={(e) => {
            e.stopPropagation();
            //   setProjectSelected(record);

            setOpenEdit(true);
          }}
        >
          {record.name}
        </TableElement>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      //    width: 150, // _, record
      render: () => (
        <Space
          size="middle"
          onClick={(e) => {
            e.stopPropagation();
            //     setProjectSelected(record);
            //  setRowSelect([record]);
          }}
        >
          <a
            onClick={(e) => {
              e.stopPropagation();
              //    setProjectSelected(record);

              setOpenEdit(true);
            }}
          >
            <Button icon={<EditTwoTone />} />
          </a>
          <a
            onClick={(e) => {
              e.stopPropagation();
              //    setProjectSelected(record);

              setOpenDelete(true);
            }}
          >
            <Button icon={<DeleteTwoTone />} />
          </a>
        </Space>
      ),
    },
  ];

  const tableColumns = columns.map((item) => ({ ...item }));
  if (xScroll === 'fixed') {
    tableColumns[0].fixed = true;
    tableColumns[tableColumns.length - 1].fixed = 'right';
  }

  const { refetch: getFilteredProjects } = useQuery(GQL_FILTERED_PROJECTS, {
    variables: {
      //   skip: page <= 1 ? 0 : (page - 1) * pageSize,
      //   take: pageSize,
      //   ...(searchValue && {
      //     searchInProjectName: searchValue,
      //   }),
      projectType: ProjectType.INTERN,
    },
    client: authClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFilteredProjects(data.getFilteredProjectsInfo.projects);
      setTotal(data.getFilteredProjectsInfo.total);
    },
  });

  const { refetch: getTasksIntern } = useQuery(
    GQL_GET_TASKS_INTERN_BY_PROJECT_ID,
    {
      variables: {
        id: projectSelected?.id ?? -1,
      },
      skip: projectSelected === undefined,
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTasksIntern(data.getTasksInternByProjectId);
      },
    }
  );

  function onChangeRowSelection(values: StoredProjectInfo[]) {
    setRowSelect(values);
    setProjectSelected(values[0]);
  }

  async function getTasksInternData() {
    setLoading(true);
    if (projectSelected) {
      await getTasksIntern();
    }
    setLoading(false);
  }

  useEffect(() => {
    if (projectSelected) {
      void getTasksInternData();
    }
  }, [projectSelected]);

  async function getFilteredData() {
    setLoading(true);
    await getFilteredProjects();
    setLoading(false);
  }

  useEffect(() => {
    setSearchValue('');
    console.log(rowSelect, openEdit, openDelete); // tmp
  }, []);

  useEffect(() => {
    void handleRefresh();
  }, [page, pageSize, searchValue, sortedInfo]);

  function handleRefresh() {
    void getFilteredData();
  }

  return (
    <>
      <DefaultLayout>
        <>
          <div style={{ paddingTop: ' 25px' }}>
            <CommonTable
              loading={loading}
              data={filteredProjects}
              columns={columns}
              xScroll={xScroll}
              yScroll={yScroll}
              showFooter={false}
              showHeader={true}
              headerTitle={i18n._('common.projects.intern')}
              selectionType="radio"
              top="none"
              bottom="bottomRight"
              addButton
              exportButton
              setOpenAdd={setOpenAdd}
              exportCsv={() => console.log('export')}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              refresh={handleRefresh}
              total={total}
              setSortedInfo={setSortedInfo}
              setRowSelect={onChangeRowSelection}
            />
            <AddProjectIntern
              title={i18n._('table.projects.add')}
              isOpen={openAdd}
              close={() => setOpenAdd(false)}
              confirm={() => {
                setOpenAdd(false);
                handleRefresh();
              }}
            />
          </div>
          {projectSelected && (
            <TasksProjectIntern
              refresh={() => {
                void getTasksIntern();
              }}
              projectId={projectSelected.id}
              tasks={tasksIntern}
            />
          )}
        </>
      </DefaultLayout>
    </>
  );
}

export default ProjectsInternPage;

/*
  <AddProject
              title={i18n._('table.projects.add')}
              isOpen={openAdd}
              close={() => setOpenAdd(false)}
              confirm={() => {
                setOpenAdd(false);
                handleRefresh();
              }}
            />

            {rowSelect && (
              <DeleteProject
                project={rowSelect}
                title={i18n._('table.projects.delete')}
                isOpen={openDelete}
                close={() => setOpenDelete(false)}
                confirm={() => {
                  setOpenDelete(false);
                  handleRefresh();
                }}
              />
            )}
*/
